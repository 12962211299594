import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {closePropertyModal} from './action';
import ContactForm from '../../ContactForm/ContactForm';
import PropertyInfo from './PropertyInfo';
import PropertyInfoNew from './PropertyInfoNew';

/**
 * Main Component
 */
class PropertyModal extends React.Component {
  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      setContactForm: false,
      showContactForm: false,
      pdfContact: false,
      project: {},
      property: {},
      fromImage: false,
      checkFromContact: false,
      rendementTab: false,
    };
  }

  /**
   *
   * @param {any} props
   * @param {any} state
   * @return {*}
   */
  static getDerivedStateFromProps(props, state) {
    state.project = props.propertyModal.project !== undefined ? props.propertyModal.project : {};
    state.property = props.propertyModal.property !== undefined ? props.propertyModal.property : {};
    state.fromImage = props.propertyModal.fromImage !== undefined ? props.propertyModal.fromImage : false;
    state.rendementTab = props.propertyModal.rendementTab !== undefined ? props.propertyModal.rendementTab : false;

    if (state.checkFromContact) {
      state.showContactForm = false;
      state.setContactForm = false;
      state.checkFromContact = false;
    }

    if (props.propertyModal.contactForm !== undefined && !props.propertyModal.fromImage) {
      state.showContactForm = props.propertyModal.contactForm;
      state.setContactForm = props.propertyModal.contactForm;
      state.checkFromContact = true;
    }

    return state;
  }

  /**
   *
   * @param {*} value text output val.
   */
  handleOpenContactModal(value) {
    this.setState({
      setContactForm: value,
      showContactForm: value,
    });
  }

  /**
   *
   * @param {*} value text output val.
   */
  handlePDFCondition(value) {
    this.setState({
      pdfContact: value,
    });
  }

  /**
   *
   * @return {void}
   */
  handleCloseContactModal() {
    // this.props.closePropertyModal();
    this.setState({
      showContactForm: false,
      setContactForm: false,
    });
  }

  /** @return {JSX} */
  render() {
    let content = (this.props.templateCode && this.props.templateCode === 'THOEU') ? (
      <PropertyInfoNew
        setModal={this.props.closePropertyModal}
        project={this.state.project}
        hideModal={this.props.closePropertyModal}
        onOpenContactModal={(value) => this.handleOpenContactModal(value)}
        onOpenContactModalPdf={(value) => this.handlePDFCondition(value)}
        setContactForm={this.state.setContactForm}
        property={this.state.property}
        getMediaBannerDataAll={this.props.getMediaBannerDataAll}
        mediaId={this.props.mediaId}
        fromImage={this.state.fromImage}
        rendementTab={this.props.rendementTab}
        templateConfig={this.props.templateConfig}
      />
    ) : (
      <PropertyInfo
        setModal={this.props.closePropertyModal}
        project={this.state.project}
        hideModal={this.props.closePropertyModal}
        onOpenContactModal={(value) => this.handleOpenContactModal(value)}
        onOpenContactModalPdf={(value) => this.handlePDFCondition(value)}
        setContactForm={this.state.setContactForm}
        property={this.state.property}
        getMediaBannerDataAll={this.props.getMediaBannerDataAll}
        mediaId={this.props.mediaId}
      />
    );

    if (this.state.showContactForm) {
      content = <ContactForm
        setModal={this.handleCloseContactModal.bind(this)}
        contactPdf={this.state.pdfContact}
        setContactForm={this.state.setContactForm}
        project={this.state.project}
        fromPropertyModal={true}
        property={this.state.property}
        hideModal={this.props.closePropertyModal}
      />;
    }

    const styleModal = {
      display: (this.props.propertyModal.state) ? 'block' : 'none',
    };

    // if (this.props.propertyModal.state == true) {
    //   setTimeout(() => {
    //     window.scrollTo(0, 0);
    //   }, 500);
    // };

    return <div style={styleModal}>{content}</div>;
  }
}

PropertyModal.propTypes = {
  propertyModal: PropTypes.object,
  closePropertyModal: PropTypes.func,
  getMediaBannerDataAll: PropTypes.object,
  mediaId: PropTypes.array,
  templateCode: PropTypes.string,
  templateConfig: PropTypes.object,
  rendementTab: PropTypes.bool,
};

const stateToProps = (state) => {
  return state;
};

export default connect(stateToProps, {closePropertyModal})(PropertyModal);
