import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {connect} from 'react-redux';
import {createContactInformation} from './action';
import {iconURL} from '../../helpers/helper';


const ProjectContactInfoHome = ({options, listShowhouse, createContactInformation, getContactFormInformation, contactInformation}) => {
  const [listShowhouseSelect, setShowhouseSelect] = useState({});
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [availableCP, setAvailableCP] = useState(false);
  const [title, setTitle] = useState('Visitez notre maison expo');
  const handleChangeSelect = (selectedOption) => {
    setShowhouseSelect(listShowhouse[selectedOption.value]);
  };

  const showhouseSelect = <Select
    id="selectLocation"
    className="select-location"
    classNamePrefix="select-location"
    name="select-location"
    placeholder="Code postal, ville…"
    options={options}
    onChange={handleChangeSelect}
  />;

  const movingtoStepTwo = () => {
    setStepOne(false);
    setTimeout(() => {
      setStepTwo(true);
    }, 400);
  };

  const movingBackToStepOne = () => {
    setStepTwo(false);
    setTimeout(() => {
      setStepOne(true);
    }, 400);
  };

  const openModal = () => {
    getContactFormInformation.showModal = true;
    getContactFormInformation.sociCode =
      contactInformation.sociCode;
    getContactFormInformation.contactId =
      listShowhouseSelect.id;
    getContactFormInformation.pointContactType =
      listShowhouseSelect.type;
    getContactFormInformation.projectName =
      contactInformation.projectName;
    getContactFormInformation.showhouseName =
      listShowhouseSelect.title;
    getContactFormInformation.purpose =
      'visit_demonstration_apartment';
    getContactFormInformation.projectId =
      contactInformation.projectId;
    getContactFormInformation.contactUs = false;
    getContactFormInformation.isAcheter = false;
    createContactInformation(getContactFormInformation);
  };

  useEffect(() => {
    if (contactInformation.companyName == 'Thomas & Piron HOME' && contactInformation.pointContactType != null) {
      setTitle(contactInformation.pointContactType);
      const customContact = {
        address: contactInformation.address,
        coordinates: contactInformation.coordinates,
        id: contactInformation.id,
        openingHoursInfo: contactInformation.openDoorsInfo,
        phoneNumber: contactInformation.phoneContact,
        type: contactInformation.pointContactType,
      };
      setShowhouseSelect(customContact);
      setAvailableCP(true);
      movingtoStepTwo();
    }
  }, []);


  return <>
    <div className="project-detail-contact" id="projectDetailContact">
      <div className="project-detail-contact__container">
        <h2 className="project-detail-contact__title">{title}</h2>
        {/*  */}
        <div className={`project-detail-contact__maison-step-one ${stepOne ? 'is-show' : ''}`}>
          <div className="project-detail-contact__maison-wrap">
            <div className="project-detail-contact__maison-text">
              <p>Rencontrons-nous dans une de nos maisons expo pour vous présenter en détail les atouts de ce bien.</p>
            </div>
            <div className="project-detail-contact__select-wrap">
              <div className="project-detail-contact__select">
                <div className="card-maison-expo-select">
                  {showhouseSelect}
                </div>
              </div>
              <div className="project-detail-contact__btn-wrap">
                <button type="button" className="project-detail-contact__btn" onClick={movingtoStepTwo} disabled={Object.keys(listShowhouseSelect).length === 0 ? true : false}>Continuer</button>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {Object.keys(listShowhouseSelect).length !== 0 &&
        <div className={`project-detail-contact__maison-step-two ${stepTwo ? 'is-show': ''}`}>
          <div className="project-detail-contact__grid">
            <div className="project-detail-contact__grid-item">
              <div className="project-detail-contact__icon">
                <img src={iconURL('icon-pin-alt.svg')} alt="pin" />
              </div>
              <h3 className="project-detail-contact__grid-title">LOCALISATION</h3>
              <div className="project-detail-contact__text">
                <a href={`https://www.google.com/maps?q=${listShowhouseSelect.coordinates.lat},${listShowhouseSelect.coordinates.lon}`} target="_blank" rel="noreferrer" className="project-detail-contact__link">{listShowhouseSelect.address}</a>
              </div>
            </div>
            <div className="project-detail-contact__grid-item">
              <div className="project-detail-contact__icon">
                <img src={iconURL('icon-hour.svg')} alt="time" />
              </div>
              <h3 className="project-detail-contact__grid-title">HORAIRES</h3>
              <div className="project-detail-contact__text">
                <ul dangerouslySetInnerHTML=
                  {{__html: listShowhouseSelect.openingHoursInfo}}></ul>
              </div>
            </div>
            <div className="project-detail-contact__grid-item">
              <div className="project-detail-contact__icon">
                <img src={iconURL('icon-phone.svg')} alt="contact" />
              </div>
              <h3 className="project-detail-contact__grid-title">CONTACT</h3>
              <div className="project-detail-contact__text">
                <div className="project-detail-contact__phone" dangerouslySetInnerHTML=
                  {{__html: listShowhouseSelect.phoneNumber}}></div>
              </div>
            </div>
          </div>
          <div className="project-detail-contact__cta-wrap">
            <button className="project-detail-contact__cta" onClick={openModal}>Demandez un rendez-vous</button>
          </div>
          {!availableCP ? ( <div className="project-detail-contact__cta-back-wrap">
            <button className="project-detail-contact__cta-back" onClick={movingBackToStepOne}>ou changer de maison expo</button>
          </div>) : null }
        </div>
        }
      </div>
    </div>
  </>;
};

ProjectContactInfoHome.propTypes = {
  project: PropTypes.object,
  options: PropTypes.array,
  listShowhouse: PropTypes.array,
  createContactInformation: PropTypes.func,
  getContactFormInformation: PropTypes.object,
  contactInformation: PropTypes.any,
};
const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createContactInformation},
)(ProjectContactInfoHome);
