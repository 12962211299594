import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import {searchProjects} from '../../services/ExternalApi';
import ProjectItemNd from '../ProjectItem/ProjectItemNd';
import {toggleMap} from './action';
import {createMapLocation} from '../MainMap/action';
import {
  createRequestSearchProjects,
  createRequestGetProjectMarkers,
  createProjectList,
  createLabelTranslate,
  createPurchasePageLastUrl,
  createTotalProjects,
} from '../../pages/action';
import {connect} from 'react-redux';
import {BubleLoading} from '../../helpers/loading';
import {queryStringFormatter} from '../../helpers/helper';
import {loadList} from '../ProjectFilter/control/LoadListProject';
import {toggleMapMobile, createMarkerCollection, createPopupProjectId} from '../MainMap/action';
import {toggleFilterMobile} from '../ProjectFilter/action';
import {toggleProjectListMobile, toggleInvestor} from '../ProjectList/action';
import {isMobileOnly} from 'react-device-detect';
import * as ExternalApi from '../../services/ExternalApi';

/** class for test */
class ProjectList extends Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);

    this.state = {
      sortedByPrice: false,
      sortedByDistance: true,
      hasMore: true,
      loading: true,
      initial: true,
      templateConfig: '',
      sortToggler: true,
      items: [],
    };
    this.mapToggler = this
        .mapToggler
        .bind(this);
    this.investorToggler = this
        .investorToggler
        .bind(this);
    this.loadMore = this
        .loadMore
        .bind(this);
    this.sortTogglerHandler = this
        .sortTogglerHandler
        .bind(this);
  }

  /** @return {void} */
  async investorToggler() {
    this
        .props
        .toggleInvestor();
    let params = await this.props.getRequestSearchProjects;
    let querySearch = queryStringFormatter(this.props.history.location.search);
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        selectInvestor: this.props.getToggleInvestor,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));
    if (this.props.getToggleInvestor) {
      querySearch = {...querySearch, investor: this.props.getToggleInvestor};
      const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
      this.props.history.push({search: `?${queryString}`});
    } else {
      delete querySearch.investor;
      if (Object.keys(querySearch).length > 0) {
        const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
        this.props.history.push({search: `?${queryString}`});
      } else {
        this.props.history.push({});
      }
    }
    this.props.createPurchasePageLastUrl(document.URL);
  }

  /** @return {void} */
  mapToggler() {
    this
        .props
        .toggleMap(this.props.getShowMap);
  }

  /** @return {void} */
  async showMapMobile() {
    this.props.toggleMapMobile(true);
    // await this.loadMore();
  }

  /**
   * @param {Number} page
   * @return {void} */
  async loadMore(page) {
    if (this.state.loading) {
      return;
    }
    const searchParams = await this.props.getRequestSearchProjects;
    let projectListCount = 0;
    let currentPage = 0;
    if (this.props.getProjectList.projects.length !== undefined) {
      projectListCount = Number(this.props.getProjectList.projects.length);
      if (projectListCount == 0) {
        currentPage = 1;
      } else {
        if (searchParams.paging.currentPage !== undefined) {
          currentPage = Number(searchParams.paging.currentPage) + 1;
        }
      }
    }

    searchParams.paging.currentPage = (currentPage);
    await searchProjects(searchParams).then((res) => {
      if (res.data.projects && res.data.projects.length > 0) {
        const projects = this.props.getProjectList.projects;
        let countFromAPI = 0;
        res
            .data
            .projects
            .forEach((project) => {
              projects.push(project);
              countFromAPI++;
            });

        this
            .props
            .createProjectList({'projects': projects, 'loading': false, 'hasMore': (countFromAPI > 0 && countFromAPI === 7)});
      } else {
        searchParams.paging.currentPage = (currentPage) - 1;
        this
            .props
            .createProjectList({'hasMore': false});
      }
    }).catch((err) => {
      console.log('err', err);
      this
          .props
          .createProjectList({'hasMore': false});
    });
  }

  /** @return {void} */
  async componentDidMount() {
    // This is statement for translate label
    const label = this.props.getLabelTranslate;
    label.projectListSortBy = 'Trier par';
    label.projectListPrice = 'Prix';
    label.projectListDistance = 'Distance';
    label.projectListPriceMobile = 'Les moins chers';
    label.projectListDistanceMobile = 'Les plus proches';
    label.projectListShowMap = 'Carte';
    label.projectListFilterInvestor = 'Biens pour investisseurs';
    label.projectListNo = 'NON';
    label.projectListYes = 'OUI';
    label.projectListLoading = 'Chargement en cours';
    label.projectItemProvinceOf = 'Province de';
    label.projectItemFrom = 'à partir de';
    label.projectItemDollar = '€';
    label.projectItemPriceExc = 'Prix TTC';
    label.projectItemShowMap = 'Afficher sur la carte';
    label.projectItemContactUs = 'Contactez-nous';
    label.projectItemLearnMore = 'Voir les details';
    label.projecNoResult = 'Aucun résultat';
    this.setState({
      templateConfig: this.props.templateConfig,
    });
    await this
        .props
        .createLabelTranslate(label);
    if (isMobileOnly) {
      await this.loadMore();
    }
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.items = props.getProjectList.projects;
    state.loading = props.getProjectList.loading;
    if (props.getProjectList.hasMore !== undefined) {
      state.hasMore = props.getProjectList.hasMore;
    }
    state.initial = props.getProjectList.initial;
    return state;
  }

  /**
   * @param {String} sortBy price / distance
   * @return {void}
   */
  async sortHandler(sortBy) {
    let params = await this.props.getRequestSearchProjects;
    params = {
      ...params,
      templateConfig: {
        ...this.props.templateConfig,
      },
    };
    switch (sortBy) {
      case 'price':
        await this.setState((prevState) => {
          return {
            sortedByPrice: !prevState.sortedByPrice,
            sortedByDistance: false,
            loading: true,
          };
        });
        params = {
          ...params,
          sorting: {
            ...params.sorting,
            orderByPriceASC: true,
            orderByPriceDESC: false,
            orderByDistanceASC: false,
            orderByDistanceDESC: false,
          },
        };
        this
            .props
            .createRequestSearchProjects({
              ...params.projectPropertiesSearchCriteria,
              ...params.sorting,
              ...params.templateConfig,
            });
        const prixParams = await this.props.getRequestSearchProjects;
        this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
        this.props.createTotalProjects({'loading': true, 'count': 0});
        const dataProjects = await loadList(prixParams);
        this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
        this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
        this.setState({loading: false});
        break;

      case 'distance':
        await this.setState((prevState) => {
          return {
            sortedByDistance: !prevState.sortedByDistance,
            sortedByPrice: false,
            loading: true,
          };
        });
        params = {
          ...params,
          sorting: {
            ...params.sorting,
            orderByPriceASC: false,
            orderByPriceDESC: false,
            orderByDistanceASC: true,
            orderByDistanceDESC: false,
          },
        };
        this
            .props
            .createRequestSearchProjects({
              ...params.projectPropertiesSearchCriteria,
              ...params.sorting,
              ...params.templateConfig,
            });
        const distanceParams = await this.props.getRequestSearchProjects;
        this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
        this.props.createTotalProjects({'loading': true, 'count': 0});
        const dataProjectsDistance = await loadList(distanceParams);
        this.props.createProjectList({'projects': dataProjectsDistance.projects, 'loading': false, 'hasMore': (dataProjectsDistance.projects.length > 0)});
        this.props.createTotalProjects({'loading': false, 'count': dataProjectsDistance.count});
        this.setState({loading: false});
        break;

      default:
        return;
        break;
    }
  }
  /**
   * submit
   */
  openFilter() {
    // this.props.toggleFilterMobile(true);
    window.location.reload();
  }
  /**
   * submit
   */
  sortTogglerHandler() {
    const sortTogglerState = !this.state.sortToggler;
    this.setState({sortToggler: sortTogglerState});
    this.sortHandler( sortTogglerState ? 'distance' : 'price');
  }

  /** @return {JSX} */
  render() {
    return (
      <>
        <div className="result-filter-container">
          <div className="left">
            <div className="sortby-container">
              <h4>{this.props.getLabelTranslate.projectListSortBy}</h4>
              <div className="switch-react switch-react--sortby">
                <input
                  type="checkbox"
                  className="toogle-checkbox"
                  name="sort"
                  checked={this.state.sortToggler}
                  onChange={this.sortTogglerHandler}
                />
                <span className="slider round"></span>
                <div className="toggle-text">
                  <span className="toggle-span false">
                    {this.props.getLabelTranslate.projectListPrice}
                  </span>
                  <span className="toggle-span true">
                    {this.props.getLabelTranslate.projectListDistance}
                  </span>
                </div>
              </div>

              {/* <div className="btn-group">
                <button
                  className={priceBtnClasses.join(' ')}
                  onClick={() => this.sortHandler('price')}
                  data-active="false">
                  {this.props.getLabelTranslate.projectListPrice}
                </button>
                <button
                  className={distBtnClasses.join(' ')}
                  onClick={() => this.sortHandler('distance')}
                  data-active="true">
                  {this.props.getLabelTranslate.projectListDistance}
                </button>
              </div> */}
            </div>
          </div>
          <div className="right">
            {this.state.templateConfig && this.state.templateConfig.country_code === 'BE' ? (
              <div className="investor-toggle-container">
                <h4>
                  {this.props.getLabelTranslate.projectListFilterInvestor}
                </h4>
                <div className="switch-react">
                  <input
                    type="checkbox"
                    className="toogle-checkbox"
                    name="filter-investor"
                    checked={this.props.getToggleInvestor}
                    onChange={this.investorToggler}
                    id="filter-investor"
                  />
                  <span className="slider round"></span>
                  <div className="toggle-text">
                    <span className="toggle-span false">
                      {this.props.getLabelTranslate.projectListNo}
                    </span>
                    <span className="toggle-span true">
                      {this.props.getLabelTranslate.projectListYes}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="toogle-container">
              <h4>{this.props.getLabelTranslate.projectListShowMap}</h4>
              <div className="switch-react">
                <input
                  type="checkbox"
                  className="toogle-checkbox"
                  name="show-map"
                  checked={this.props.getShowMap}
                  onChange={this.mapToggler}
                  id="showMap"
                />
                <span className="slider round"></span>
                <div className="toggle-text">
                  <span className="toggle-span false">
                    {this.props.getLabelTranslate.projectListNo}
                  </span>
                  <span className="toggle-span true">
                    {this.props.getLabelTranslate.projectListYes}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`search-result-container-react ${
            this.props.getShowMap ? '' : 'is-show'
          }`}
          id="_resultContainer"
        >
          <div className="list-result-container" id="block_scroll">
            <div className="list-header-result-container-mobile">
              <h2 className="list-header-result-title">
                Trouvez le bien qui vous correspond
              </h2>
              <div
                className="list-header-result-filter-container"
                id="list-header-result-filter-container"
              >
                <button
                  className={`btn-mobile-filter-result ${
                    this.state.sortedByDistance ?
                      this.state.loading ?
                        'is-loading' :
                        'is-active' :
                      ''
                  }`}
                  onClick={() => this.sortHandler('distance')}
                  data-active={this.state.sortedByDistance}
                >
                  {this.props.getLabelTranslate.projectListDistanceMobile}
                </button>
                <button
                  className={`btn-mobile-filter-result ${
                    this.state.sortedByPrice ?
                      this.state.loading ?
                        'is-loading' :
                        'is-active' :
                      ''
                  }`}
                  onClick={() => this.sortHandler('price')}
                  data-active={this.state.sortedByPrice}
                >
                  {this.props.getLabelTranslate.projectListPriceMobile}
                </button>
              </div>
            </div>
            {isMobileOnly ? (
              // this is infinite scroll for mobile device
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={this.loadMore}
                hasMore={this.state.hasMore}
                threshold={200}
                loader={
                  <BubleLoading
                    key={performance.now()}
                    text={this.props.getLabelTranslate.projectListLoading}
                  />
                }
                useWindow={false}
                getScrollParent={() => this.scrollParentRef}
                element="ul"
                id="ul-result"
              >
                {this.state.items?.map((item, i) => {
                  return (
                    <li
                      key={i}
                      id={'li-' + (i + 1)}
                      className="list-item-result-container"
                    >
                      <ProjectItemNd
                        templateConfig={this.state.templateConfig}
                        history={this.props.history}
                        project={item}
                        translate={this.props.getLabelTranslate}
                        getMediaBannerDataAll={
                          this.props.getMediaBannerDataAll
                        }
                      />
                    </li>
                  );
                })}
              </InfiniteScroll>
            ) : (
              // this is infinite scroll for tablet and desktop
              <ul id="ul-result">
                <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={this.loadMore}
                  hasMore={this.state.hasMore}
                  threshold={200}
                  loader={
                    <BubleLoading
                      key={performance.now()}
                      text={this.props.getLabelTranslate.projectListLoading}
                    />
                  }
                  useWindow={false}
                  getScrollParent={() => document.getElementById('_resultContainer')}
                >
                  {this.state.items?.map((item, i) => {
                    return (
                      <li
                        key={i}
                        id={'li-' + (i + 1)}
                        className="list-item-result-container"
                      >
                        <ProjectItemNd
                          templateConfig={this.state.templateConfig}
                          history={this.props.history}
                          project={item}
                          translate={this.props.getLabelTranslate}
                          getMediaBannerDataAll={
                            this.props.getMediaBannerDataAll
                          }
                        />
                      </li>
                    );
                  })}
                </InfiniteScroll>
              </ul>
            )}

            {this.state.items.length == 0 && this.state.loading === false && this.state.initial === false && (
              <div className="purchase-filter-container-warning">
                {this.props.getLabelTranslate.projecNoResult}
              </div>
            )}
            <div className="mobile-sticky-button-container">
              <button
                className="btn-mobile-back-search"
                onClick={() => this.openFilter()}
              >
                <i className="fas fa-search"></i>
                <span>Nouvelle Recherche</span>
              </button>
              <button
                className="btn-mobile-carte"
                onClick={() => this.showMapMobile()}
              >
                <i className="icon-belgium"></i>
                <span>Carte</span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ProjectList.propTypes = {
  getShowMap: PropTypes.bool,
  toggleMap: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  createLabelTranslate: PropTypes.func,
  getRequestSearchProjects: PropTypes.object,
  getProjectList: PropTypes.object,
  createRequestSearchProjects: PropTypes.func,
  createProjectList: PropTypes.func,
  history: PropTypes.object,
  toggleMapMobile: PropTypes.func,
  toggleProjectListMobile: PropTypes.func,
  toggleFilterMobile: PropTypes.func,
  getMediaBannerDataAll: PropTypes.object,
  createMapLocation: PropTypes.func,
  templateConfig: PropTypes.object,
  getToggleInvestor: PropTypes.bool,
  toggleInvestor: PropTypes.func,
  createRequestGetProjectMarkers: PropTypes.func,
  createMarkerCollection: PropTypes.func,
  createPopupProjectId: PropTypes.func,
  createPurchasePageLastUrl: PropTypes.func,
  createTotalProjects: PropTypes.func,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {
  toggleMap,
  createLabelTranslate,
  createRequestSearchProjects,
  createProjectList,
  toggleMapMobile,
  toggleProjectListMobile,
  toggleFilterMobile,
  createMapLocation,
  toggleInvestor,
  createRequestGetProjectMarkers,
  createMarkerCollection,
  createPopupProjectId,
  createPurchasePageLastUrl,
  createTotalProjects,
})(ProjectList);
