import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../pages/action';
import {createContactInformation} from './action';

/**
 * Main Component
 */
class ContactFooter extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
  }

  /**
   * @return {void}
   *
   * @param {*} state;
   * @param {*} props;
   */
  async componentDidMount() {
    this.props.createContactInformation(this.props);
    const label = this.props.getLabelTranslate;
    label.contactBtnFooter = 'Contactez-nous';
    label.text = 'Ce projet vous intéresse ? ' +
        'N’hésitez pas à nous contacter, un agent ' +
        'répondra à toutes vos questions !';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * @param {*} coordinate;
   */
  openModal() {
    this.props.getContactFormInformation.showModal = true;
    this.props.getContactFormInformation.sociCode =
        this.props.contactInformation.sociCode;
    this.props.getContactFormInformation.contactId =
        this.props.contactInformation.id;
    this.props.getContactFormInformation.pointContactType =
        this.props.contactInformation.pointContactType;
    this.props.getContactFormInformation.projectName =
        this.props.contactInformation.projectName;
    this.props.getContactFormInformation.purpose =
        'visit_demonstration_apartment';
    this.props.getContactFormInformation.projectId =
        this.props.contactInformation.projectId;
    this.props.getContactFormInformation.contactUs = true;
    this.props.getContactFormInformation.isAcheter = false;
    this.props.createContactInformation(this.props.getContactFormInformation);
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    const label = this.props.getLabelTranslate;
    return (
      <div className="project-detail-contact-us-container">
        <h2>
          {label.text}
        </h2>
        <div className="field-call-to-action">
          <button id="btnModalContact"
            className="btn btn-large btn-primary btn-modal-absolute-react"
            onClick={this.openModal.bind(this)}
          >
            {label.contactBtnFooter}
          </button>
        </div>
      </div>
    );
  }
}

ContactFooter.propTypes = {
  contactInformation: PropTypes.any,
  createContactInformation: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  createLabelTranslate: PropTypes.func,
  getContactFormInformation: PropTypes.object,
  createContactFormInformation: PropTypes.func,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createLabelTranslate, createContactInformation},
)(ContactFooter);
