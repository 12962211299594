import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {collectImage} from '../HeaderBanner/setImage';
import './ProjectImageSlider.css';
import ProjectImageSliderImage from './ProjectImageSliderImage';

/**
 * Class for project image slider
 */
class ProjectImageSlider extends React.Component {
  /** @return {JSX} */
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
      fade: true,
      responsive: [
        {
          breakpoint: 520,
          settings: {
            dots: false,
          },
        },
      ],
    };

    const images = collectImage(this.props.images, 1200);
    return (
      <div
        className="galerie-image-slick-container"
        id="_ModalBanner" >
        <Slider {...settings} >
          {images.map((image, i) =>
            <ProjectImageSliderImage imgSrc={image} key={i} />,
          )}
        </Slider>
      </div>
    );
  }
}

ProjectImageSlider.propTypes = {
  images: PropTypes.array,
  closeModal: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  res: PropTypes.string,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
};

export default ProjectImageSlider;
