import React from 'react';
import PropTypes from 'prop-types';
import ProjectDetailFilterNew from '../../ProjectFilter/ProjectDetailFilterNew';
import ResultsTableNd from './ResultsTableNd';
import {isMobileOnly} from 'react-device-detect';
import Tooltips from '../../Tooltip/Tooltips';
import {constructSitePlan} from '../../../helpers/helper';
import SiteplanBreadcrumb from './SiteplanBreadcrumb';
const polylabel = require('@mapbox/polylabel');

/**
 * Class for detail page information
 */
class ImageMapsNew extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      svg: '',
      selectedPhase: '',
      floors: [],
      shapeChild: [],
      activeFloor: '0',
      imageTabClass: 'collapse-navbar-list-item',
      listTabClass: 'collapse-navbar-list-item',
      display: 'image',
      firstLoad: true,
      tooltipConditions: {
        conditions: [
          {
            title: 'Prix hors frais et taxes',
            symbol: '1',
            description: '',
          },
          {
            title: 'Prix TVA & droits d’enregistrement compris ainsi que les éventuelles unités secondaires obligatoires',
            symbol: '2',
            description: '',
          },
        ],
        noIcon: true,
      },
      sitePlansDetails: {},
      mappingSitePlan: [],
      excludeSiteType: ['Properties'],
      hoverShape: '',
      textClass: 'text-property',
      projectProperties: {},
      showDetailMap: false,
      propertyDetail: {
        status: '',
        projectName: '',
        propertyName: '',
        peb: '',
        etage: '',
        floor: '',
        surfaceM2: 0,
        surfaceOfBlaconyAndGardenM2: 0,
        numberOfRooms: 0,
        description: '',
        connectedShape: '',
        idType: '',
      },
      siteplanMapProperty: [],
      sitemapLoading: false,
      dtBreadcrumb: [],
    };
    this.clickImageTabClass = this.clickImageTabClass.bind(this);
    this.clickListTabClass = this.clickListTabClass.bind(this);
    this.navigationPhase = this.navigationPhase.bind(this);
  }

  /**
   *
   * @param {any} props
   * @param {any} state
   * @return {*}
   */
  static getDerivedStateFromProps(props, state) {
    let nodeImage = undefined;
    try {
      if (props.getProjectPropertiesDetail.projectProperties.length > 0 ) {
        state.projectProperties = props.getProjectPropertiesDetail.projectProperties;
      }
      state.sitePlansDetails = props.sitePlansDetails;
      state.mappingSitePlan = constructSitePlan(props.sitePlansDetails);
      if ( props.sitePlansDetails === undefined || props.sitePlansDetails.mainInteractiveImageLinks === undefined) {
        state.imageTabClass ='collapse-navbar-list-item hide';
        state.listTabClass ='collapse-navbar-list-item is-active';
        state.display='list';
        return state;
      }

      nodeImage = props.sitePlansDetails.mainInteractiveImageLinks[0];
      if (state.firstLoad) {
        if (props.project.generalConditions.length > 0) {
          const overrideConditions = props.project.generalConditions.filter(function(el) {
            return (el.conditionType == 'Price' || el.conditionType == 'PriceAllIn');
          });

          if (overrideConditions.length) {
            state.tooltipConditions.conditions = overrideConditions;
          }

          state.tooltipConditions.conditions.sort((a, b) => a.conditionType.localeCompare(b.conditionType));
        } else {
          if (props.templateConfig.country_code === 'LU') {
            state.tooltipConditions.conditions = [
              {
                title: 'Prix TVA à 3% comprise sous condition d\'acceptation de votre dossier par l\'administration de la TVA.',
                symbol: '1',
                description: '',
              },
            ];
          }
        }

        if (nodeImage === null || nodeImage === undefined) {
          state.imageTabClass ='collapse-navbar-list-item hide';
          state.listTabClass ='collapse-navbar-list-item is-active';
          state.display='list';
          if (props.location.hash !== '' && props.location.hash == '#liste-des-biens') {
            setTimeout(() => {
              document.getElementById('FilterProjects').scrollIntoView();
            }, 1000);
          }
        } else {
          if (props.location.hash !== '') {
            if (props.location.hash == '#implantation') {
              state.imageTabClass ='collapse-navbar-list-item is-active';
              state.listTabClass ='collapse-navbar-list-item';
              state.display='image';
            } else if (props.location.hash == '#liste-des-biens') {
              state.imageTabClass ='collapse-navbar-list-item';
              state.listTabClass ='collapse-navbar-list-item is-active';
              state.display='list';
            } else {
              state.imageTabClass ='collapse-navbar-list-item is-active';
              state.listTabClass ='collapse-navbar-list-item';
              state.display='image';
            }
            // setTimeout(() => {
            //   document.getElementById('FilterProjects').scrollIntoView();
            // }, 1000);
          } else {
            state.imageTabClass ='collapse-navbar-list-item is-active';
            state.listTabClass ='collapse-navbar-list-item';
            state.display='image';
          }
          state.shapeChild = props.sitePlansDetails.mainImageShapeCoordinates;
        }
      }
    } catch (error) {
      // console.log(error);
    }
    // handle switch tabs
    if (Object.keys(props.tabViews).length > 0) {
      if (props.tabViews.imageTabClass !== '') {
        state.imageTabClass = props.tabViews.imageTabClass;
      } else {
        state.imageTabClass = (state.svg !== '') ? 'collapse-navbar-list-item' : 'collapse-navbar-list-item hide';
      }
      state.listTabClass = props.tabViews.listTabClass;
      state.display = props.tabViews.display;
      state.firstLoad = props.tabViews.firstLoad;
    }
    return state;
  }

  /**
   * @return {void}
   */
  clickImageTabClass() {
    this.props.handleTabViews('plan', (this.state.svg !== ''));
  }

  /**
   * @return {void}
   */
  clickListTabClass() {
    this.props.handleTabViews('list', (this.state.svg !== ''));
  }
  /**
   * declare breadcrumb
   * @return {void}
   */
  componentDidMount() {
    if (this.props.getProjectDetail.sitePlansDetails?.mainImageShapeCoordinates.length >=1) {
      this.setState({
        dtBreadcrumb: [{
          title: this.props.getProjectDetail.projectTitleSEO ? this.props.getProjectDetail.projectTitleSEO : this.props.getProjectDetail.projectName,
          phase: 'Project',
          connectedShape: 'root',
          currentLevel: '0',
        }],
      });
    }
  }

  /**
   * floor details
   *
   */
  async componentDidUpdate() {
    if (Object.keys(this.props.getProjectDetail).length != 0) {
      if (this.state.svg == '') {
        this.interactiveImage(this.state.sitePlansDetails, 'project');
      }
    }
  }

  /**
   *
   * @param {string} codeShape
   * @param {string} centerText
   */
  onMouseEnter(codeShape, centerText=null) {
    this.setState({
      hoverShape: codeShape,
    });
    const text = document.getElementById(`${centerText[0]}${centerText[1]}`);
    if (text!=null) {
      text.classList.remove('text-property');
      text.classList.add('text-property-active');
    }
    const poly = document.getElementById(`p-${centerText[0]}${centerText[1]}`);
    poly.classList.add(`hover`);

    const line = document.getElementById(`l-${centerText[0]}${centerText[1]}`);
    if (line!=null) {
      line.classList.add(`hover`);
    }

    const dot = document.getElementById(`d-${centerText[0]}${centerText[1]}`);
    if (dot!=null) {
      dot.classList.add(`hover`);
    }

    const button = document.getElementById(`f-${centerText[0]}${centerText[1]}`);
    const buttonS = document.getElementById(`s-${centerText[0]}${centerText[1]}`);
    if (button!=null) {
      button.classList.add(`active`);
    }
    if (buttonS!=null) {
      buttonS.classList.add(`active`);
    }
  }

  /**
   * @param {string} centerText
   */
  onMouseLeave(centerText=null) {
    this.setState({
      hoverShape: '',
    });
    const text = document.getElementById(`${centerText[0]}${centerText[1]}`);
    if (text!=null) {
      text.classList.remove('text-property-active');
      text.classList.add('text-property');
    }

    const poly = document.getElementById(`p-${centerText[0]}${centerText[1]}`);
    poly.classList.remove(`hover`);

    const line = document.getElementById(`l-${centerText[0]}${centerText[1]}`);
    if (line!=null) {
      line.classList.remove(`hover`);
    }

    const dot = document.getElementById(`d-${centerText[0]}${centerText[1]}`);
    if (dot!=null) {
      dot.classList.remove(`hover`);
    }

    const button = document.getElementById(`f-${centerText[0]}${centerText[1]}`);
    if (button!=null) {
      button.classList.remove(`active`);
    }
    const buttonS = document.getElementById(`s-${centerText[0]}${centerText[1]}`);
    if (buttonS!=null) {
      buttonS.classList.remove(`active`);
    }
  }
  /**
   * @param {string} levelType
   * @param {string} connectedShape
   * @param {string} idType
   */
  onMouseClick(levelType, connectedShape, idType) {
    const status= {
      'Available': 'disponible',
      'Sold': 'vendu',
      'Option': 'option',
    };
    if (levelType!=='Properties') { // for all level other than properties
      this.setState({
        sitemapLoading: true,
      });
      const thisShape = this.state.mappingSitePlan.filter((item)=>item.codeToShape===connectedShape);
      const checkResult = this.validationSiteplan({
        mainImageShapeCoordinates: thisShape[0].mainImageShapeCoordinates,
        mainInteractiveImageLinks: thisShape[0].mainInteractiveImageLinks,
      });
      if (!checkResult) {
        this.setState({
          showDetailMap: false,
          sitemapLoading: false,
        });
        return;
      }
      if (thisShape && thisShape.length > 0) {
        this.interactiveImage(
            {
              mainImageShapeCoordinates: thisShape[0].mainImageShapeCoordinates,
              mainInteractiveImageLinks: thisShape[0].mainInteractiveImageLinks,
            },
            thisShape[0].levelType,
            thisShape[0].level,
        );
      }
      this.selectPhase(this.props.getProjectDetail, connectedShape);
      const tpBreadcrumb = this.state.dtBreadcrumb;
      tpBreadcrumb.push({
        title: thisShape[0].name,
        phase: thisShape[0].levelType,
        codeToShape: thisShape[0].codeToShape,
        level: thisShape[0].level,
        parentLevel: thisShape[0].parentLevel,
      });
      this.setState({
        showDetailMap: false,
        sitemapLoading: false,
        dtBreadcrumb: tpBreadcrumb,
      });
    } else { // for properties level only
      const that = this;
      this.state.projectProperties.map((value)=>{
        if (value.projectPropertyId === idType) {
          const dtShape = this.state.mappingSitePlan.find((item)=>item.idToShape===idType);
          const tempStatus = dtShape.status;
          const tpBreadcrumb = this.state.dtBreadcrumb;
          const lastPhase = tpBreadcrumb[tpBreadcrumb.length -1].phase;
          if (lastPhase=='Properties') {
            tpBreadcrumb.pop();
          }
          tpBreadcrumb.push({
            title: dtShape.name,
            phase: dtShape.levelType,
            codeToShape: dtShape.codeToShape,
            level: dtShape.level,
            parentLevel: dtShape.parentLevel,
          });

          this.setState({
            propertyDetail: {
              status: status[tempStatus ? tempStatus : value.status],
              propertyName: value.projectPropertyName,
              projectName: this.props.getProjectDetail.projectName,
              peb: value.peb,
              etage: value.etage,
              floor: value.floor,
              surfaceM2: value.surfaceM2,
              surfaceOfBlaconyAndGardenM2: value.surfaceOfBlaconyAndGardenM2,
              numberOfRooms: value.numberOfRooms,
              description: value.description,
              connectedShape: connectedShape,
              idType: idType,
            },
            dtBreadcrumb: tpBreadcrumb,

          }, function() {
            that.showMap();
          });
        }
      });
    }
  }

  /**
   * @param {object} sitePlansDetails
   * @return {bool}
   */
  validationSiteplan(sitePlansDetails) {
    const mainImage = sitePlansDetails.mainInteractiveImageLinks;
    const sC = sitePlansDetails.mainImageShapeCoordinates;
    if (mainImage==null || mainImage.length == 0 ) {
      return false;
    }
    // check child
    for (let i = 0; i < sC.length; i++) {
      if (sC[i].levelType != 'Properties') {
        if (sC[i].labelCoordinates === null || sC[i].labelCoordinates == undefined) {
          return false;
        }
      }
    }
    return true;
  }
  /**
   * @param {object} sitePlansDetails
   * @param {string} level
   * @param {string} activeFloor
   * @return {void}
   */
  interactiveImage(sitePlansDetails, level = 'project', activeFloor = '0') {
    const mainImage = sitePlansDetails.mainInteractiveImageLinks;
    const shapeCoordinates = sitePlansDetails.mainImageShapeCoordinates;
    if (mainImage!=null && mainImage.length > 0 ) {
      // loadingDisplay();
      const namespace = 'http://www.w3.org/2000/svg';
      // use the high resolution image
      const srcImage = mainImage[0];
      if (srcImage === null ) {
        // console.log('No image displayed');
      }
      const backgroundImage = new Image();
      backgroundImage.src = srcImage;

      backgroundImage.onload = async function() {
        /**
           * parse raw coordinates to polygon points format
           * i.e 509,156,444,541,607,568,671,172,509,155 =>
           * 509,156 444,541 607,568 671,172 509,155
           *
           * @param {*} coordinates
           * @return {string}
           */
        const parseCoordinates = (coordinates) => coordinates.split(',')
            .map((coordinate, index) => index % 2 ? `${coordinate},` :
            `${coordinate} `)
            .join('')
            .slice(0, -1); // remove trailing comma

        // todo: make it more readable

        // Svg Example
        /**
         *  you can have multiple polygon with data-* attribute
         *  <svg>
         *     <image></image>
         *     <polygon></polygon>
         *     <polygon></polygon>
         *     <polygon></polygon>
         *  </svg>
         */
        const svg =<svg
          viewBox={`0 0 ${backgroundImage.width} ${backgroundImage.height}`}
          x="0px" y="0px"
          style={{enableBackground: `new 0 0 ${backgroundImage.width} ${backgroundImage.height}`}}
          version="1.1"
          xmlns={`${namespace}`}
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve">
          <image
            style={{overflow: 'visible'}}
            width={`${backgroundImage.width}`}
            height={`${backgroundImage.height}`}
            xlinkHref={`${srcImage}`}>
          </image>
          {shapeCoordinates.
              filter(({shapeCoordinates}) => shapeCoordinates && shapeCoordinates.length > 0).
              map((shape, i) => {
                const shapeStatus = shape.propertyStatusConnectedToShape;
                // const shapeStatusLabel = shapeStatus==='Sold' ? 'Vendu': shapeStatus==='Option'? 'Option' : '';
                let connectedShape = shape.propertyCodeConnectedToShape || coordinate.propertyESIdsConnectedToShape;
                // const childShape = coordinate.childDetails[0];
                const levelType = shape.levelType;
                // use propertyESIdsConnectedToShape for property level
                let idType = '';
                if (levelType==='Properties') {
                  connectedShape = shape.propertyESIdsConnectedToShape +' '+ shape.propertyCodeConnectedToShape ? shape.propertyCodeConnectedToShape : '';
                  idType = shape.propertyESIdsConnectedToShape;
                } else {
                  connectedShape = shape.propertyCodeConnectedToShape;
                }

                const condensed = shape.condensed;
                const classCondensed = condensed ? 'condensed' : '';
                const centerPoint =this.calculateCenter(parseCoordinates(shape.shapeCoordinates));

                // POLYGON ELEMENT
                let classColor = shapeStatus === 'Available'?'blockArea Available':
                shapeStatus === 'Sold' ? 'blockArea Sold' : 'blockArea Option';
                classColor = (levelType !== 'Properties') ? 'blockArea Available': classColor;
                let borderStyle = 'boder-default';
                if (backgroundImage.width > 100 && backgroundImage.width <= 1000) {
                  borderStyle = 'boder-default';
                } else if (backgroundImage.width > 1000 && backgroundImage.width <= 2000) {
                  borderStyle = 'boder-big';
                } else if (backgroundImage.width > 2000 && backgroundImage.width <= 3000) {
                  borderStyle = 'boder-biger';
                } else {
                  borderStyle = 'boder-bigest';
                }
                const polygon = (
                  <polygon
                    key={i}
                    points={`${parseCoordinates(shape.shapeCoordinates)}`}
                    style={{cursor: `pointer`}}
                    className={`${classColor} ${connectedShape} ${borderStyle} ${classCondensed}`}
                    data-propertycode={`${connectedShape}`}
                    data-level={`${level}`}
                    data-leveltype={`${levelType}`}
                    id={`p-${centerPoint[0]}${centerPoint[1]}`}
                    data-status={shapeStatus}
                    onMouseEnter={()=>{
                      this.onMouseEnter(connectedShape, centerPoint);
                    }}
                    onMouseLeave={()=>{
                      this.onMouseLeave(centerPoint);
                    }}
                    onClick={()=>{
                      this.onMouseClick(levelType, connectedShape, idType);
                    }}
                  >
                  </polygon>
                );

                // Return
                return (
                  <>
                    {polygon}
                  </>
                );
              },
              )}
          {shapeCoordinates.
              filter(({shapeCoordinates}) => shapeCoordinates && shapeCoordinates.length > 0).
              map((shape, i) => {
                const shapeStatus = shape.propertyStatusConnectedToShape;
                const labelText = shape.propertyNameConnectedToShape;
                // const shapeStatusLabel = shapeStatus==='Sold' ? 'Vendu': shapeStatus==='Option'? 'Option' : '';
                let connectedShape = shape.propertyCodeConnectedToShape || coordinate.propertyESIdsConnectedToShape;
                // const childShape = coordinate.childDetails[0];
                const levelType = shape.levelType;
                // use propertyESIdsConnectedToShape for property level
                let idType = '';
                if (levelType==='Properties') {
                  connectedShape = shape.propertyESIdsConnectedToShape +' '+ shape.propertyCodeConnectedToShape ? shape.propertyCodeConnectedToShape : '';
                  idType = shape.propertyESIdsConnectedToShape;
                } else {
                  connectedShape = shape.propertyCodeConnectedToShape;
                }

                const status= {
                  'Available': 'disponible',
                  'Sold': 'vendu',
                  'Option': 'option',
                };
                const condensed = shape.condensed;
                const classCondensed = condensed ? 'condensed' : '';
                // TEXT ELEMENT
                const centerPoint =this.calculateCenter(parseCoordinates(shape.shapeCoordinates));
                const textSpan1 = shapeStatus==='Available'? labelText : <tspan x={centerPoint[0]} dy="0.6em">{labelText}</tspan>;
                const textShape =(
                  <>
                    <foreignObject x={centerPoint[0]-20}
                      y={centerPoint[1]-20} width="180" height="85"
                      style={{cursor: `pointer`, overflow: `visible`}}
                      onMouseEnter={()=>{
                        this.onMouseEnter(connectedShape, centerPoint);
                      }}
                      onMouseLeave={()=>{
                        this.onMouseLeave(centerPoint);
                      }}
                      onClick={()=>{
                        this.onMouseClick(levelType, connectedShape, idType);
                      }}
                    >
                      <div className={'content-center '+classCondensed}>
                        {condensed ? '' :
                            <span textAnchor={'middle'}
                              className={'text-property '+shapeStatus+' '+connectedShape}
                              id={`${centerPoint[0]}${centerPoint[1]}`}
                            >
                              {textSpan1}
                            </span>
                        }
                        <div
                          id={`s-${centerPoint[0]}${centerPoint[1]}`}
                          className={'btn btn-'+shapeStatus+' '+connectedShape}>{ condensed ? status[shapeStatus].slice(0, 1) : status[shapeStatus]}
                        </div>
                      </div>
                    </foreignObject>
                  </>
                );

                const labelCoordinate = levelType === 'Properties' ? [] : shape.labelCoordinates.split(',');
                const line = levelType === 'Properties' ? '' : levelType === 'Residences' ? '' : (
                  <line
                    className={'line '+connectedShape}
                    x1={centerPoint[0]}
                    x2={labelCoordinate[0]}
                    y1={centerPoint[1]}
                    y2={labelCoordinate[1]}
                    onMouseEnter={()=>{
                      this.onMouseEnter(connectedShape, centerPoint);
                    }}
                    onMouseLeave={()=>{
                      this.onMouseLeave(centerPoint);
                    }}
                    onClick={()=>{
                      this.onMouseClick(levelType, connectedShape, idType);
                    }}
                    id={`l-${centerPoint[0]}${centerPoint[1]}`}>
                  </line>
                );

                const centerFlytingText = levelType === 'Residences' ? [centerPoint[0]-90, centerPoint[1]-30] : [labelCoordinate[0]-100, labelCoordinate[1]-28];
                const objectsize = {
                  width: '100',
                  height: '100',
                };
                const classLongerText = labelText.length > 14 ? 'longer-text': 'short-text';
                const fliyingText = levelType === 'Properties' ? '': (
                    <foreignObject x={centerFlytingText[0]}
                      y={centerFlytingText[1]} width={objectsize.width} height={objectsize.height}
                      onMouseEnter={()=>{
                        this.onMouseEnter(connectedShape, centerPoint);
                      }}
                      onMouseLeave={()=>{
                        this.onMouseLeave(centerPoint);
                      }}
                      onClick={()=>{
                        this.onMouseClick(levelType, connectedShape, idType);
                      }}
                    >
                      <div className={'flying-button '+connectedShape+' '+classLongerText} id={`f-${centerPoint[0]}${centerPoint[1]}`}>
                        <div
                          className={'text-level'}
                          x={labelCoordinate[0]}
                          y={Number(labelCoordinate[1])}>
                          {labelText}
                        </div>
                      </div>
                    </foreignObject>
                );

                // Dot Element
                const coordinateCenter = shape.centerCoordinates?.split(',');
                const dotElement = levelType === 'Properties' ? '' : levelType === 'Residences' ? '' : coordinateCenter ? (
                    <circle cx={coordinateCenter[0]} cy={coordinateCenter[1]} r="10" stroke="black" strokeWidth="0" className={`dot `+connectedShape} id={`d-${centerPoint[0]}${centerPoint[1]}`}
                      onMouseEnter={()=>{
                        this.onMouseEnter(connectedShape, centerPoint);
                      }}
                      onMouseLeave={()=>{
                        this.onMouseLeave(centerPoint);
                      }}
                      onClick={()=>{
                        this.onMouseClick(levelType, connectedShape, idType);
                      }}
                    />
                ) : '';

                // Return
                return (
                  <>
                    {line}
                    {fliyingText}
                    {levelType==='Properties' ? textShape : ''}
                    {dotElement}
                  </>
                );
              },
              )}
        </svg>;
        this.setState({
          svg: svg,
          activeFloor: activeFloor,
        });
      }.bind(this);
    }
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * coord = [[[0, 0], [1, 0], [1, 1], [0, 1], [0, 0]]];
   */
  calculateCenter(coord) {
    const split = coord.split(',');
    const polyCoord = [];
    split.map((s) =>{
      const arr = s.split(' ');
      const cx = Number(arr[0]);
      const cy = Number(arr[1]);
      polyCoord.push([cx, cy]);
    });
    return polylabel([polyCoord], 1.0);
  }

  // show map function
  /**
   * =
   */
  showMap() {
    this.setState({
      showDetailMap: true,
    });
  }

  // show modal property
  /**
   * =
   */
  showPropertyModal() {
    this.state.projectProperties.map((value)=>{
      if (value.projectPropertyId === this.state.propertyDetail.idType) {
        if (this.state.showDetailMap) {
          this.props.openPropertyModal({project: this.props.getProjectDetail, property: value, fromImage: true});
        }
      }
    });
  }

  // show modal contact form
  /**
   * =
   */
  showContactModal() {
    this.state.projectProperties.map((value)=>{
      if (value.projectPropertyId === this.state.propertyDetail.idType) {
        if (this.state.showDetailMap) {
          this.props.openPropertyModal({project: this.props.getProjectDetail, property: value, fromImage: false, contactForm: true});
        }
      }
    });
  }

  // /**
  //  * @param {string} propertyId
  //  */
  // setPropertyInfo(propertyId) {

  // }

  /**
   * @param {projectDetails} projectDetails
   * @param {propertyCode} propertyCode
   */
  selectPhase = (projectDetails, propertyCode)=> {
    let property;
    projectDetails.floorImageDetails.forEach((x)=>{
      if (x.propertyCode===propertyCode) {
        property=x.propertiesFloors;
      }
    });
    // eslint-disable-next-line no-invalid-this
    this.setState({
      selectedPhase: (propertyCode !== undefined) ? propertyCode : '',
      floors: (property !== undefined) ? property : [],
    });
    setTimeout(() => {
      // eslint-disable-next-line no-invalid-this
      this.props.updateProjectProperties(this.state.selectedPhase);
    }, 100);
  };

  /**
   * @param {*} item
   * @return {void}
   */
  navigationPhase(item) {
    let sitePlanParent = this.state.mappingSitePlan.filter((parentItem) => parentItem.level===item.level);

    if (!sitePlanParent.length) {
      sitePlanParent = this.state.mappingSitePlan;
    }

    const connectedShape = sitePlanParent[0].idToShape || sitePlanParent[0].codeToShape;
    this.interactiveImage(
        {
          mainImageShapeCoordinates: sitePlanParent[0].mainImageShapeCoordinates,
          mainInteractiveImageLinks: sitePlanParent[0].mainInteractiveImageLinks,
        },
        item.level,
        item.level,
    );
    this.props.updateProjectProperties(connectedShape);
    const tempBreadcrumb = [...this.state.dtBreadcrumb];
    const startIndex = tempBreadcrumb.findIndex((x) => x.level == item.level);
    const result = startIndex !== -1 ? tempBreadcrumb.slice(0, startIndex + 1) : [];
    if (item.phase != 'Properties') {
      this.setState({
        showDetailMap: false,
        dtBreadcrumb: result,
      });
    } else {
      this.setState({
        dtBreadcrumb: result,
      });
    }
    this.clickImageTabClass();
  }

  /**
   * @param {*} image
   * @return {void}
   */
  detectFloor = (image) =>{
    if (image!==null) {
      const splitImage = image.split('/');
      const imageName = splitImage[splitImage.length-1];
      const arrImageName = imageName.split('_');
      const floor = arrImageName[arrImageName.length-2];
      return floor;
    }
    return 0;
  }

  /**
     *
     * @return {JSX.Element}
     */
  render() {
    // navbar
    let svgContainer;
    const status= {
      'Available': 'disponible',
      'Sold': 'vendu',
      'Option': 'option',
    };

    const fixingLabel = {
      'available': 'disponible',
      'sold': 'vendu',
    };

    if (this.state.svg !== '' && this.state.display === 'image') {
      const mapCover = `map-cover-loader  ${this.state.sitemapLoading ? 'show' : ''}`;
      svgContainer =
      <React.Fragment>
        <div className="project-detail-settlement-map-container class-1">
          <div className="project-detail-settlement-map">
            <SiteplanBreadcrumb breadcrumb={this.state.dtBreadcrumb} triggerNavigation={(propertyCode)=> this.navigationPhase(propertyCode)} position='siteplan-map'></SiteplanBreadcrumb>
            <div className='project-detail-settlement-map__grid'>
              <h3 className='project-detail-settlement-map__grid--title'>Découvrez votre futur chez-vous.</h3>
              {this.state.mappingSitePlan !== undefined && this.state.activeFloor !== '0' && (
                this.state.mappingSitePlan
                    .filter((item) => item.level===this.state.activeFloor)
                    .map((item, index)=>{
                      return (
                        <>
                          <div key={`${item.level}-${index}`} className="mobile-menu-floor">
                            <a href="#table-page-detail" className="menu-floor-divnk text-back" onClick={()=>{
                              const tpBreadcrumb = this.state.dtBreadcrumb;
                              tpBreadcrumb.pop();
                              this.setState({
                                dtBreadcrumb: tpBreadcrumb,
                              });
                              if ( this.state.showDetailMap ) {
                                this.setState(
                                    {
                                      showDetailMap: false,
                                    },
                                );
                                const shape = document.getElementsByClassName(this.state.propertyDetail.connectedShape);
                                for (let i = 0; i < shape.length; i++) {
                                  shape[i].classList.remove('hover');
                                  shape[i].classList.remove('active');
                                }
                                return false;
                              }
                              const sitePlanParent = this.state.mappingSitePlan.filter((parentItem) => parentItem.level===item.parentLevel);
                              const connectedShape = sitePlanParent[0].idToShape || sitePlanParent[0].codeToShape;
                              this.interactiveImage(
                                  {
                                    mainImageShapeCoordinates: sitePlanParent[0].mainImageShapeCoordinates,
                                    mainInteractiveImageLinks: sitePlanParent[0].mainInteractiveImageLinks,
                                  },
                                  item.levelType,
                                  item.parentLevel,
                              );
                              this.props.updateProjectProperties(connectedShape);
                            }}>
                              <i className="fas fa-chevron-left"></i>
                                Retour au niveau précédant
                            </a>
                          </div>
                        </>
                      );
                    })
              )}
              <div className="project-detail-settlement-map-body">
                <div className={mapCover}></div>
                {this.state.svg}
              </div>
              <div className='project-detail-settlement-map__sidebar'>
                <h3 className='project-detail-settlement-map__sidebar--title'>Découvrez votre futur chez-vous.</h3>
                {this.state.mappingSitePlan !== undefined && this.state.activeFloor !== '0' && (
                  this.state.mappingSitePlan
                      .filter((item) => item.level===this.state.activeFloor)
                      .map((item, index)=>{
                        return (
                          <>
                            <div key={`${item.level}-${index}`} className="desktop-menu-floor">
                              <a href="#table-page-detail" className="menu-floor-divnk text-back" onClick={()=>{
                                const tpBreadcrumb = this.state.dtBreadcrumb;
                                tpBreadcrumb.pop();
                                this.setState({
                                  dtBreadcrumb: tpBreadcrumb,
                                });
                                if ( this.state.showDetailMap ) {
                                  this.setState(
                                      {
                                        showDetailMap: false,
                                      },
                                  );
                                  const shape = document.getElementsByClassName(this.state.propertyDetail.connectedShape);
                                  for (let i = 0; i < shape.length; i++) {
                                    shape[i].classList.remove('hover');
                                    shape[i].classList.remove('active');
                                  }
                                  return false;
                                }
                                const sitePlanParent = this.state.mappingSitePlan.filter((parentItem) => parentItem.level===item.parentLevel);
                                const connectedShape = sitePlanParent[0].idToShape || sitePlanParent[0].codeToShape;
                                this.interactiveImage(
                                    {
                                      mainImageShapeCoordinates: sitePlanParent[0].mainImageShapeCoordinates,
                                      mainInteractiveImageLinks: sitePlanParent[0].mainInteractiveImageLinks,
                                    },
                                    item.levelType,
                                    item.parentLevel,
                                );
                                this.props.updateProjectProperties(connectedShape);
                              }}>
                                <i className="fas fa-chevron-left"></i>
                                Retour au niveau précédant
                              </a>
                            </div>
                          </>
                        );
                      })
                )}
                {!this.state.showDetailMap && (
                  <div className="menu-floor-container" id="menu-floor">
                    <p className="level-type-properties" >Choisissez un élément</p>
                    <ul>
                      {this.state.mappingSitePlan !== undefined && (
                        this.state.mappingSitePlan
                            .filter((item) => item.parentLevel===this.state.activeFloor)
                            .map((item, index)=>{
                              return (
                                <>
                                  <li key={`${item.level}-${index}`}>
                                    <a href="#table-page-detail" className={`menu-floor-link ${item.levelType} ${this.state.hoverShape === item.codeToShape?' is-hover':''}`} onClick={()=>{
                                      if (item.levelType !== 'Properties') {
                                        const connectedShape = item.idToShape || item.codeToShape;
                                        const checkResult = this.validationSiteplan({
                                          mainImageShapeCoordinates: item.mainImageShapeCoordinates,
                                          mainInteractiveImageLinks: item.mainInteractiveImageLinks,
                                        });
                                        if (!checkResult) {
                                          this.setState({
                                            showDetailMap: false,
                                            sitemapLoading: false,
                                          });
                                          return;
                                        }
                                        this.interactiveImage(
                                            {
                                              mainImageShapeCoordinates: item.mainImageShapeCoordinates,
                                              mainInteractiveImageLinks: item.mainInteractiveImageLinks,
                                            },
                                            item.levelType,
                                            item.level,
                                        );
                                        this.props.updateProjectProperties(connectedShape);
                                        if ( item.mainInteractiveImageLinks.length) {
                                          const dtShape = this.state.mappingSitePlan.find((item)=>item.codeToShape===connectedShape);
                                          const tpBreadcrumb = [...this.state.dtBreadcrumb];
                                          tpBreadcrumb.push({
                                            title: dtShape.name,
                                            phase: dtShape.levelType,
                                            codeToShape: dtShape.codeToShape,
                                            level: dtShape.level,
                                            parentLevel: item.level,
                                          });
                                          this.setState({
                                            showDetailMap: false,
                                            dtBreadcrumb: tpBreadcrumb,
                                          });
                                        }
                                      } else {
                                        this.state.projectProperties.map((value)=>{
                                          if (value.projectPropertyId === item.idToShape) {
                                            if (!this.state.showDetailMap) {
                                              const that = this;
                                              const dtShape = this.state.mappingSitePlan.find((x)=>x.idToShape===item.idToShape);
                                              const tpBreadcrumb = this.state.dtBreadcrumb;
                                              const lastPhase = tpBreadcrumb[tpBreadcrumb.length -1].phase;
                                              if (lastPhase=='Properties') {
                                                tpBreadcrumb.pop();
                                              }
                                              tpBreadcrumb.push({
                                                title: dtShape.name,
                                                phase: dtShape.levelType,
                                                codeToShape: dtShape.codeToShape,
                                                level: dtShape.level,
                                                parentLevel: dtShape.parentLevel,
                                              });
                                              this.setState({
                                                propertyDetail: {
                                                  status: item.status, // recheck after bug status fix
                                                  // projectName: value.projectName,
                                                  propertyName: value.projectPropertyName,
                                                  peb: value.peb,
                                                  etage: value.etage,
                                                  floor: value.floor,
                                                  surfaceM2: value.surfaceM2,
                                                  surfaceOfBlaconyAndGardenM2: value.surfaceOfBlaconyAndGardenM2,
                                                  numberOfRooms: value.numberOfRooms,
                                                  description: value.description,
                                                  connectedShape: item.codeToShape,
                                                  idType: item.idToShape,
                                                },
                                                dtBreadcrumb: tpBreadcrumb,
                                              }, function() {
                                                that.showMap();
                                              });
                                            }
                                            // this.props.openPropertyModal({project: this.props.getProjectDetail, property: value, fromImage: true});
                                          }
                                        });
                                      }
                                    }}
                                    onMouseEnter={()=>{
                                      const shape = document.getElementsByClassName(item.codeToShape);
                                      for (let i = 0; i < shape.length; i++) {
                                        shape[i].classList.add('hover');
                                        shape[i].classList.add('active');
                                      }
                                    }} onMouseLeave={()=>{
                                      if (!this.state.showDetailMap) {
                                        const shape = document.getElementsByClassName(item.codeToShape);
                                        for (let i = 0; i < shape.length; i++) {
                                          shape[i].classList.remove('hover');
                                          shape[i].classList.remove('active');
                                        }
                                      }
                                    }}>
                                      {item.name} <div className={'btn btn-'+item.status}>{status[item.status]}</div>
                                    </a>
                                  </li>
                                </>
                              );
                            })
                      )}
                    </ul>
                  </div>
                )}
                {this.state.showDetailMap && (
                  <>
                    <div className='detail-property'
                      onMouseEnter={()=>{
                        const shape = document.getElementsByClassName(this.state.propertyDetail.connectedShape);
                        for (let i = 0; i < shape.length; i++) {
                          shape[i].classList.add('hover');
                          shape[i].classList.add('active');
                        }
                      }}
                      onMouseLeave={()=>{
                        const shape = document.getElementsByClassName(this.state.propertyDetail.connectedShape);
                        for (let i = 0; i < shape.length; i++) {
                          shape[i].classList.remove('hover');
                          shape[i].classList.remove('active');
                        }
                      }}
                    >
                      <div className='detail-property__status-field'>
                        <div className={`btn btn-${this.state.propertyDetail.status == 'Disponible' ? 'available' : this.state.propertyDetail.status.toLowerCase()}`}>
                          {fixingLabel[this.state.propertyDetail.status.toLowerCase()] !== undefined ? fixingLabel[this.state.propertyDetail.status.toLowerCase()] : this.state.propertyDetail.status }
                        </div>
                      </div>
                      <div className='detail-property__project-title'>
                        {this.state.propertyDetail.projectName}
                      </div>
                      <div className='detail-property__property-title'>
                        {this.state.propertyDetail.propertyName}
                      </div>
                      <div className="detail-property__list">
                        <div className="row-detail">
                          <div className="row-detail__field-name">
                            PEB
                          </div>
                          <div className="row-detail__field-value">
                            {this.state.propertyDetail.peb}
                          </div>
                        </div>
                        <div className="row-detail">
                          <div className="row-detail__field-name">
                            Etage
                          </div>
                          <div className="row-detail__field-value">
                            {this.state.propertyDetail.floor}
                          </div>
                        </div>
                        <div className="row-detail">
                          <div className="row-detail__field-name">
                            Surface
                          </div>
                          <div className="row-detail__field-value">
                            {this.state.propertyDetail.surfaceM2}m
                          </div>
                        </div>
                        <div className="row-detail">
                          <div className="row-detail__field-name">
                            Jardin / balcon
                          </div>
                          <div className="row-detail__field-value">
                            {this.state.propertyDetail.surfaceOfBlaconyAndGardenM2}
                          </div>
                        </div>
                        <div className="row-detail">
                          <div className="row-detail__field-name">
                            Chambres
                          </div>
                          <div className="row-detail__field-value">
                            {this.state.propertyDetail.numberOfRooms}
                          </div>
                        </div>
                        <div className="row-detail">
                          <div className="row-detail__field-name">
                            Description
                          </div>
                          <div className="row-detail__field-value">
                            {this.state.propertyDetail.description}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='btn btn-list'>
                      <div className='btn btn-property' onClick={()=>{
                        this.showPropertyModal();
                      }}>
                        {`Plus d'infos`}
                      </div>
                      <div className='btn btn-contact' onClick={()=>{
                        this.showContactModal();
                      }}>
                        Contactez-nouz
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>;
    }

    if (this.state.display === 'list') {
      svgContainer =
      <React.Fragment>
        <div className="project-implantation-react__filter class-2">
          <ProjectDetailFilterNew
            mobile = {isMobileOnly}
            handleUpdate={this.props.filterProjectProperties}
            projectPropertiesDetail={this.props.getProjectPropertiesDetail}
          />
          <Tooltips
            contactInformation={this.props.contactInformation}
            details={this.props.getProjectDetail}
            property={this.state.tooltipConditions}
            wrapperClass="act-tooltip__wrap"
            prefix="act-tooltip-title"
          />
        </div>
        <ResultsTableNd
          project={this.props.project}
          getProjectDetail={this.props.getProjectDetail}
          getProjectPropertiesDetail={this.props.getProjectPropertiesDetail}
          getMediaBannerDataAll={this.props.getMediaBannerDataAll}
          params = {this.props.paramsProjectProperties}
          contactInformation = {this.props.contactInformation}
          setFilterProjectDetail = {this.props.setFilterProjectDetail}
          dtBreadcrumb = {this.state.dtBreadcrumb}
          triggerNavigation={(propertyCode)=> this.navigationPhase(propertyCode)}
          templateConfig={this.props.templateConfig}
        />
      </React.Fragment>;
    }

    if (this.props.mapOnly) {
      if (this.state.svg == '') {
        return '';
      }
      const mapSvgContainer =
      <React.Fragment>
        <h3 className="image-maps-mobile-title class-3">Plan d’implantation et liste des biens</h3>
        <div className="image-maps-mobile-box">
          <div className="project-detail-settlement-map-mobile">
            <div>
              <div className="menu-floor-container-mobile" id="menu-floor">
                <ul>
                  <li>
                    <button className={this.state.activeFloor===''?`menu-floor-link is-active`:`menu-floor-link`} onClick={()=>{
                      this.setState({selectedPhase: '', activeFloor: '', floors: []});
                      this.interactiveImage(this.state.sitePlansDetails, 'project');
                      this.props.updateProjectProperties();
                    }}>
                        Retour au niveau précédant
                    </button>
                  </li>
                  {
                      this.state.selectedPhase!==''&&this.state.floors.length!==0?
                        this.state.floors.map((floor)=>{
                          return <li key={floor.floorCode} className="from-properties">
                            <a href="#table-page-detail" className={this.state.activeFloor===floor.floorCode?'menu-floor-link is-active':'menu-floor-link'}
                              onClick={()=>{
                                this.setState({activeFloor: floor.floorCode});
                                this.interactiveImage(this.state.sitePlansDetails, floor.floorCode);
                                this.props.updateProjectProperties(this.state.selectedPhase, floor.floorCode);
                              }}>
                              Etage {floor.floorCode}
                            </a>
                          </li>;
                        }):null
                  }
                </ul>
              </div>
            </div>
            <div className="project-detail-settlement-map-body-mobile">
              {this.state.svg}
            </div>
          </div>
        </div>
      </React.Fragment>;
      return (
        <div className="image-maps-mobile">
          {mapSvgContainer}
        </div>
      );
    }

    if (this.props.listOnly) {
      return (
        <React.Fragment>
          <ProjectDetailFilterNew mobile
            handleUpdate={this.props.filterProjectProperties}
            projectPropertiesDetail={this.props.getProjectPropertiesDetail}
          />
        </React.Fragment>
      );
    }

    return (
      <div>
        <div className="collapse-navbar">
          <ul className="collapse-navbar-list">
            <li className={this.state.imageTabClass} data-navbar="project-detail-settlement-map-container">
              <a onClick={this.clickImageTabClass} id="image-map">Plan d&lsquo;implantation</a>
            </li>
            <li
              className={this.state.listTabClass}
              data-navbar="project-detail-filter-container">
              <a onClick={this.clickListTabClass} id="filter-element-map">Liste des biens</a>
            </li>
          </ul>
        </div>
        <div>
          {svgContainer}
        </div>
      </div>
    );
  }
}

ImageMapsNew.propTypes = {
  floorImageDetails: PropTypes.array,
  mainImageDetails: PropTypes.object,
  getProjectDetail: PropTypes.object,
  updateProjectProperties: PropTypes.func,
  filterProjectProperties: PropTypes.func,
  createGetProjectPropertiesDetail: PropTypes.func,
  mapOnly: PropTypes.bool,
  listOnly: PropTypes.bool,
  project: PropTypes.object,
  tabViews: PropTypes.object,
  handleTabViews: PropTypes.func,
  getProjectPropertiesDetail: PropTypes.object,
  getMediaBannerDataAll: PropTypes.object,
  paramsProjectProperties: PropTypes.object,
  contactInformation: PropTypes.object,
  location: PropTypes.object,
  setFilterProjectDetail: PropTypes.func,
  sitePlansDetails: PropTypes.object,
  openPropertyModal: PropTypes.func,
  templateConfig: PropTypes.object,
};

export default ImageMapsNew;
