export const PHONE_INFO_BY_COUNTRY_CODE = {
  'LU': {
    'code': '+352',
    'format': '+352 XXX XXX',
  },
  'BE': {
    'code': '+32',
    'format': '+32 X XXX XX XX',
  },
  'FR': {
    'code': '+33',
    'format': '+33 X XX XX XX XX',
  },
};
