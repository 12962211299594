import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../pages/action';
import {createContactInformation} from './action';

/**
 * Main Component
 */
class DetailMoreInfo extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      magazines: [],
      videos: [],
      virtualVisits: [],
      brochures: [],
      openMagazines: false,
      openVideos: false,
      openBrochure: false,
    };
  }

  /**
   * @return {void}
   *
   * @param {*} state;
   * @param {*} props;
   */
  async componentDidMount() {
    this.props.createContactInformation(this.props);
    const label = this.props.getLabelTranslate;
    label.detailMoreInfoBtn = `Plus d'infos ? Contactez-nous`;
    label.detailMoreInfoTitle = 'Plus d’infos sur ce projet';
    label.detailMoreInfoMagazines = 'Magazines et guides';
    label.detailMoreInfoVideos = 'Vidéo du projet';
    label.detailMoreInfoVisitVirtual = 'Visite virtuelle';
    label.detailMoreInfoBrochure = 'Brochure et plans';
    this.props.createLabelTranslate(label);
  }
  /**
   *
   * @param {any} props
   * @param {any} state
   * @return {*}
   */
  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.media).length > 0) {
      Object.keys(props.media).forEach((item)=>{
        state[item] = props.media[item];
      });
    }
    return state;
  }

  /**
   *
   * @param {*} coordinate;
   */
  openModal() {
    this.props.getContactFormInformation.showModal = true;
    this.props.getContactFormInformation.sociCode =
        this.props.contactInformation.sociCode;
    this.props.getContactFormInformation.contactId =
        this.props.contactInformation.id;
    this.props.getContactFormInformation.pointContactType =
        this.props.contactInformation.pointContactType;
    this.props.getContactFormInformation.projectName =
        this.props.contactInformation.projectName;
    this.props.getContactFormInformation.purpose =
        'visit_demonstration_apartment';
    this.props.getContactFormInformation.projectId =
        this.props.contactInformation.projectId;
    this.props.getContactFormInformation.contactUs = true;
    this.props.getContactFormInformation.isAcheter = false;
    this.props.createContactInformation(this.props.getContactFormInformation);
  }

  /**
   *
   * @param {*} coordinate;
   */
  toggleMagazines() {
    this.setState({
      openMagazines: !this.state.openMagazines,
      openVideos: false,
    });
  }

  /**
   *
   * @param {*} coordinate;
   */
  toggleVideos() {
    this.setState({
      openVideos: !this.state.openVideos,
      openMagazines: false,
    });
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    const label = this.props.getLabelTranslate;
    const classInfoMagazine = this.state.openMagazines? 'is-open' : '';
    return (
      <div className="media-card-wrapper">
        <div className="project-detail-content-location-header">
          <h2>{label.detailMoreInfoTitle}</h2>
        </div>
        <div className="project-detail-content-location-body cta-list">
          <ul className="cta-project-detail-list">
            {(this.state.magazines && this.state.magazines.length > 0) && (
              <li>
                <button className={classInfoMagazine + ' cta-more-info'} onClick={this.toggleMagazines.bind(this)}>
                  <img
                    src={
                      process.env.REACT_APP_BASE_DRUPAL_URL +
                      '/themes/thomaspiron/images/icon/icon-book.svg'
                    }
                  />
                  <span>{label.detailMoreInfoMagazines}</span>
                </button>
                <div className="cta-project-detail-child">
                  {this.state.magazines.map((item, i) => {
                    return (<a className="cta-child" key={`videos-${i}`} href={item.url} target="_blank" rel="noopener noreferrer">
                      <img src={
                        process.env.REACT_APP_BASE_DRUPAL_URL +
                        '/themes/thomaspiron/images/icon/icon-download.svg'
                      }/>
                      <span>{item.label}</span>
                    </a>);
                  })}
                </div>
              </li>
            )}
            {(this.state.videos && this.state.videos.length > 0) && (
              <li>
                <button
                  className={`cta-more-info ${this.state.openVideos ? 'is-open' : '' }` }
                  onClick={()=> this.setState({openVideos: !this.state.openVideos}) }>
                  <img
                    src={
                      process.env.REACT_APP_BASE_DRUPAL_URL +
                      '/themes/thomaspiron/images/icon/icon-video.svg'
                    }
                  />
                  <span>{label.detailMoreInfoVideos}</span>
                </button>
                <div className="cta-project-detail-child">
                  {this.state.videos.map((item, i) => {
                    return (<a className="cta-child" key={`videos-${i}`} href={item.url} target="_blank" rel="noopener noreferrer">
                      <img src={
                        process.env.REACT_APP_BASE_DRUPAL_URL +
                        '/themes/thomaspiron/images/icon/icon-download.svg'
                      }/>
                      <span>{item.label}</span>
                    </a>);
                  })}
                </div>
              </li>
            )}
            {(this.state.virtualVisits && this.state.virtualVisits.length > 0) && (
              <li>
                <button
                  className={`cta-more-info ${this.state.openVirtualVisits ? 'is-open' : '' }` }
                  onClick={()=> this.setState({openVirtualVisits: !this.state.openVirtualVisits}) }>
                  <img
                    src={
                      process.env.REACT_APP_BASE_DRUPAL_URL +
                      '/themes/thomaspiron/images/icon/icon-panorama.svg'
                    }
                  />
                  <span>{label.detailMoreInfoVisitVirtual}</span>
                </button>
                <div className="cta-project-detail-child">
                  {this.state.virtualVisits.map((item, i) => {
                    return (<a className="cta-child" key={`virtual-visit-${i}`} href={item.url} target="_blank" rel="noopener noreferrer">
                      <img src={
                        process.env.REACT_APP_BASE_DRUPAL_URL +
                        '/themes/thomaspiron/images/icon/icon-download.svg'
                      }/>
                      <span>{item.label}</span>
                    </a>);
                  })}
                </div>
              </li>
            )}
            {(this.state.brochures && this.state.brochures.length > 0) && (
              <li>
                <button className={`cta-more-info ${this.state.openBrochure ? 'is-open' : '' }` }
                  onClick={()=> this.setState({openBrochure: !this.state.openBrochure}) }>
                  <img
                    src={
                      process.env.REACT_APP_BASE_DRUPAL_URL +
                      '/themes/thomaspiron/images/icon/icon-plans.svg'
                    }
                  />
                  <span>{label.detailMoreInfoBrochure}</span>
                </button>
                <div className="cta-project-detail-child">
                  {this.state.brochures.map((item, i) => {
                    return (<a className="cta-child" key={`brochure-${i}`} href={item.url} target="_blank" rel="noopener noreferrer">
                      <img src={
                        process.env.REACT_APP_BASE_DRUPAL_URL +
                        '/themes/thomaspiron/images/icon/icon-download.svg'
                      }/>
                      <span>{item.label}</span>
                    </a>);
                  })}
                </div>
              </li>
            )}
          </ul>
          <div className="project-detail-content-location-body">
            <div className="field-cta">
              <a className="btn btn-large btn-secondary btn-modal-absolute-react btn-contact-green"
                id="btnModalContactProject"
                style= {{height: 'auto'}}
                onClick={this.openModal.bind(this)}
              >
                {label.detailMoreInfoBtn}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DetailMoreInfo.propTypes = {
  contactInformation: PropTypes.any,
  media: PropTypes.any,
  createContactInformation: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  createLabelTranslate: PropTypes.func,
  getContactFormInformation: PropTypes.object,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createLabelTranslate, createContactInformation},
)(DetailMoreInfo);
