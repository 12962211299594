import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import {toggleFilter} from '../action';
import Modal from 'react-modal';

Modal.setAppElement('#acheter-plugin');

/**
* Class Price Filter.
*/
class SurfaceFilterMobile extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      labelButton: 'Surface ',
      isFilter: false,
      NumberOfM2: {
        Min: 0,
        Max: 0,
      },
      defaultMin: 0,
      defaultMax: 1,
      modalIsOpen: false,
    };

    this._openModal = this._openModal.bind(this);
    this._afterOpenModal = this._afterOpenModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  /**
   * Open Modal
   */
  _openModal() {
    this.setState({modalIsOpen: true});
    this.showSurfaceFilter();
  }

  /**
   * After Open Modal
   */
  _afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  /**
   * Close modal
   */
  _closeModal() {
    this.setState({modalIsOpen: false});
  }

  /**
  * @return {void}
  */
  async componentDidMount() {
    // This statement for translate label
    const label = this.props.getLabelTranslate;
    label.surfaceFilterPlaceholderMobile = 'Surface';
    label.surfaceFilterTitleMobile = 'Surface minimum';
    label.surfaceFilterCancelMobile = 'Fermer';
    label.surfaceFilterApply = 'Appliquer';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    if (props.filterValue !== null && state.NumberOfM2.Min === 0) {
      state.isFilter = true;
      state.NumberOfM2 = {
        Min: Number(props.filterValue.Min),
        Max: Number(props.filterValue.Max),
      };
      state.labelButton = `Min ${props.filterValue.Min} m2 `;
    }
    return state;
  }

  /**
   * Show Surface Filter.
   * @param {number} value text output val.
   */
  showSurfaceFilter() {
    const _getFaramFiltering = (this.props.filterOptions === undefined) ? this.props.getParamsFiltering : this.props.filterOptions;
    if (_getFaramFiltering.minNumberOfM2 !== undefined) {
      this.setState({
        defaultMin: _getFaramFiltering.minNumberOfM2,
        defaultMax: _getFaramFiltering.maxNumberOfM2,
      });

      if (this.state.NumberOfM2.Min===0) {
        this.setState({
          NumberOfM2: {
            Min: _getFaramFiltering.minNumberOfM2,
            Max: _getFaramFiltering.maxNumberOfM2,
          },
        });
      }
      this.props.toggleFilter({
        ...this.props.getFilter,
        'filterType': false,
        'filterPrice': false,
        'filterRoom': false,
        'filterSurface': !this.props.getFilter.filterSurface,
      });
    }
  }

  /**
   * Change Surface Filter.
   * @param {number} value text output val.
   */
  handleChangeSurface(value) {
    this.setState({
      labelButton: this.state.labelButton,
      NumberOfM2: {
        Min: value,
      },
    });
  }

  /**
   * Submit Surface Filter.
   * @param {number} value text output val.
   */
  async submitSurfaceFilter() {
    this.setState({
      isFilter: true,
      NumberOfM2: {
        Min: this.state.NumberOfM2.Min,
        Max: this.props.getParamsFiltering.maxNumberOfM2,
      },
      labelButton: `Min ${this.state.NumberOfM2.Min} m2 `,
      modalIsOpen: false,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterSurface': !this.props.getFilter.filterSurface,
    });
    this.props.submitHandle({
      Min: this.state.NumberOfM2.Min,
      Max: this.props.getParamsFiltering.maxNumberOfM2,
    });
  }

  /**
   * Clear Surface Filter.
   * @param {number} value text output val.
   */
  async cancelSurfaceFilter() {
    this.setState({
      labelButton: 'Surface ',
      isFilter: false,
      NumberOfM2: {
        Min: 0,
        Max: 0,
      },
      modalIsOpen: false,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterSurface': !this.props.getFilter.filterSurface,
    });
    this.props.cancelHandle();
  }

  /**
   * handle close backdrop.
   */
  handleClickBackDrop() {
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': false,
      'filterPrice': false,
      'filterRoom': false,
      'filterSurface': !this.props.getFilter.filterSurface,
    });
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="btn-filter-mobile-container">
        <div className={`btn-filter-mobile ${this.state.isFilter ? 'is-filter' : '' }`} onClick={()=> this._openModal() }>
          <div className="btn-filter-mobile-text">
            <span className="filter-type-text">{this.props.getLabelTranslate.surfaceFilterPlaceholderMobile}</span>
            {/* <span className="filter-type-text-active">{ this.state.isFilter ? `Min ${this.state.NumberOfM2.Min} m` : '' }</span> */}
            {this.state.isFilter && (<span className="filter-type-text-active">{ `Min ${this.state.NumberOfM2.Min} m`}<sup>2</sup></span>)}
            {!this.state.isFilter && (<span className="filter-type-text-active"></span>)}

          </div>
          <div className="btn-filter-mobile-image"><i className="icon-filter-mobile archer"></i></div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this._afterOpenModal}
          onRequestClose={this._closeModal}
          className="modal-acheter"
        >
          <div className="modal-acheter__dialog">
            <div className="modal-acheter__content">
              <div className="modal-acheter__header">
                <h5 className="modal-acheter__title" id="modalAcheterTypeFilterLabel">{this.props.getLabelTranslate.surfaceFilterTitleMobile}</h5><button className="close" onClick={()=> this._closeModal() } ><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-acheter__body">
                <div className="input-range-mobile super">
                  <InputRange
                    minValue={this.state.defaultMin}
                    maxValue={this.state.defaultMax}
                    formatLabel={(value) => `${value} m`}
                    value={this.state.NumberOfM2.Min}
                    allowSameValues
                    onChange={this.handleChangeSurface.bind(this)} />
                </div>
              </div>
              <div className="modal-acheter__footer">
                <button className="btn modal-acheter__btn-close" onClick={()=> this.cancelSurfaceFilter()}>{this.props.getLabelTranslate.surfaceFilterCancelMobile}</button>
                <button className="btn modal-acheter__btn-apply" onClick={()=> this.submitSurfaceFilter()} >{this.props.getLabelTranslate.surfaceFilterApply}</button>
              </div>
            </div>
          </div>
        </Modal>

      </div>
    );
  }
}

SurfaceFilterMobile.propTypes = {
  getParamsFiltering: PropTypes.objectOf(PropTypes.any),
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  submitHandle: PropTypes.func,
  cancelHandle: PropTypes.func,
  filterValue: PropTypes.object,
  filterOptions: PropTypes.object,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {
      createLabelTranslate,
      toggleFilter,
    },
)(SurfaceFilterMobile);
