import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import Slider from 'react-slick';
import Promotion from '../Promotion/Promotion';
import Tooltips from '../../Tooltip/Tooltips';
import {iconURL, createTitleFromProject, orderImages, priceHelper} from '../../../helpers/helper';
import {isMobile} from 'react-device-detect';
import {collectImageWithAltDynamic} from '../../HeaderBanner/setImage';
import InputRange from 'react-input-range';


const PrevArrow = (props) => {
  const {className, onClick} = props;
  const icon = isMobile ? 'arrow-left-red.svg' : 'arrow-slider-white-left.svg';
  return <button onClick={onClick} className={className}>
    <img src={iconURL(icon)} alt="icon" />
  </button>;
};

const NextArrow = (props) => {
  const {className, onClick} = props;
  const icon = isMobile ? 'arrow-right-red.svg' : 'arrow-slider-white-right.svg';
  return <button onClick={onClick} className={className}>
    <img src={iconURL(icon)} alt="icon" />
  </button>;
};

/**
 * Main Component
 */
class PropertyInfoNew extends React.Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      resolution: 1200,
      indexResolution: 5,
      isActive: 'photos',
      isInit: false,
      rendementValue: 500,
      rendementCalculatedValue: '-',
      rendementCalculated: false,
      settings: {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        fade: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  }

  /**
   * @param {any} props
   * @param {any} state
   * @return {*}
   */
  static getDerivedStateFromProps(props, state) {
    if (!state.isInit) {
      state.isActive = props.rendementTab ? 'rendement' : 'photos';
      state.isInit = true;
    }
    return state;
  }

  /**
   * @param {*} state;
   * @param {*} props;
   */
  async componentDidMount() {
    const label = this.props.getLabelTranslate;
    label.localiserBtn = 'Localiser sur une carte';
    label.contactBtn = 'Demander un rendez-vous';
    label.contactLocationTitle = 'Rencontrons-nous';
    label.contactLocationSubtitle = 'sur place';
    label.localisationLabel = 'LOCALISATION';
    label.horairesLabel = 'HORAIRES';
    label.contactLabel = 'CONTACT';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   */
  calculateRendement() {
    const calc = this.state.rendementValue * 12 / this.props.property.priceAllIn;
    const round = Math.round(calc * 10000) / 100;
    this.setState({
      rendementCalculated: true,
      rendementCalculatedValue: round + '%',
    });
  }

  /**
   * writes the slider value to the state.
   * @param {number} value
   */
  changeSliderValue(value) {
    this.setState({
      rendementValue: value,
    });
  }

  /**
   * Close contact modal.
   */
  closeModal() {
    // setTimeout(() => {
    //   window.scrollTo(0, document.body.scrollHeight ||
    //     document.documentElement.scrollHeight);
    // }, 500);
    this.props.setModal();
    this.props.hideModal();
  }

  /**
   * Open contact modal.
   */
  openModal() {
    this.setState({
      setContactForm: true,
      showContactForm: true,

    }, () => {
      this.handleOpenContactModal(this.state.showContactForm, false);
    });
  }

  /**
   * Open contact modal.
   */
  openModalPdf() {
    this.setState({
      setContactForm: true,
      showContactForm: true,
      pdfContact: true,
    }, () => {
      this.handleOpenContactModal(this.state.showContactForm, true);
    });
  }

  /**
   *
   * @param {number} value text output val.
   * @param {number} pdf text output val.
   */
  handleOpenContactModal(value, pdf) {
    this.props.onOpenContactModal(value);
    this.props.onOpenContactModalPdf(pdf);
  }
  /**
   *
   * accepts the id of the button and takes action coordinatelly
   */

  socialMediaButton = (e, projectName) =>{
    const pageUrl = location.protocol + '//' + location.host + location.pathname;
    switch (e.target.id) {
      case 'fa-facebook-f':
        window.open('https://www.facebook.com/sharer.php?u='.concat(pageUrl), 'MsgWindow');
        break;
      case 'fa-linkedin-in':
        window.open('https://www.linkedin.com/shareArticle?mini=false&url='.concat(pageUrl), 'MsgWindow', 'fullscreen=yes');
        break;
      case 'fa-envelope':
        const r = window.open('mailto:?subject='.concat(projectName, '&body=').concat(`Découvrir le projet : [ ${pageUrl} ]`), 'MsgWindow', 'fullscreen=yes');
        setTimeout(function() {
          r.close();
        }, 400);
        break;
    }
  }

  /**
   *
   * @param {string} status text status
   * set Active Tab for pop up modal detail property
   */
  adjustTab(status) {
    this.setState({
      isActive: status,
    });
  }

  /** @return {JSX} */
  render() {
    const backText = this.props.rendementTab !== undefined && this.props.rendementTab ? 'Revenir à recherche' : 'Revenir à la liste des lots';
    let pdfbutton = '';
    if ((Object.keys(this.props.property).length > 0) && (Object.keys(this.props.project).length > 0)) {
      if (this.props.property.propertyPdfLinks.length) {
        pdfbutton = (
          <a
            className="btn btn-border-only btn-light-gray btn-medium-40 icon-left modal-absolute-close-button"
            href="#"
            id="download-pdf"
            // data-modal="modal-plan-contact-form"
            // data-modal-close="modal-popup"
            // data-modal-close-no-animation="true"
            onClick={this.openModalPdf.bind(this)}
            projectname={this.props.project.projectName}
            projectpropertyid={this.props.property.projectPropertyId}
            projectpropertyname={this.props.property.projectPropertyName}
            socicode={this.props.property.sociCode}
            unitcode={this.props.property.unitCode}
            projectpropertytype={this.props.property.projectPropertyType}
            data-pdf-url={this.props.property.propertyPdfLinks[0]}
          >
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13.421H14V15H0V13.421ZM7.77777 8.81995L12.4997 4.02637L13.5994 5.14258L7 11.8422L0.400574 5.14343L1.50031 4.02637L6.22223 8.81836V0H7.77777V8.81995Z"
                fill="currentColor"
              />
            </svg>
            Recevoir les plans
          </a>
        );
      }
    }
    let price = '';
    let priceAllIn = '';
    let priceStatus = ``;
    let priceAllInStatus = ``;
    let pricingCondition = [];
    let pricingConditionPrice = [];
    let pricingConditionAllIn = [];
    if ((Object.keys(this.props.property).length > 0) && (Object.keys(this.props.project).length > 0)) {
      pricingCondition = this.props.property.conditions;
      pricingCondition = pricingCondition.filter(function(el) {
        return el.conditionTypeForProject !== 'GeneralCondition'; // Ignore index 3 for now
      });
      pricingConditionPrice = pricingCondition.filter(function(el) {
        return el.conditionTypeForProject === 'PriceMainProperty';
      });
      pricingConditionAllIn = pricingCondition.filter(function(el) {
        return el.conditionTypeForProject === 'PriceAllIn';
      });
      if (this.props.property.marketingConditions !== []) {
        pricingConditionPrice = pricingConditionPrice.concat(this.props.property.marketingConditions);
        pricingConditionAllIn = pricingConditionAllIn.concat(this.props.property.marketingConditions);
        pricingCondition = pricingCondition.concat(this.props.property.marketingConditions);
      }
      const pricingConditionPricePrix = pricingConditionPrice.map((item) => `(${item.symbol})`);
      const pricingConditionPriceAllIn = pricingConditionAllIn.map((item) => `(${item.symbol})`);
      price = this.props.property.status.toLowerCase() === 'vendu' ? 'Vendu' : `${this.props.property.price?.toLocaleString('nl-be')}€ <span class="price--note">${pricingConditionPricePrix?.join('')}</span>`;
      priceAllIn = this.props.property.status.toLowerCase() === 'vendu' ? 'Vendu' : `${this.props.property.priceAllIn?.toLocaleString('nl-be')}€ <span class="price--note">${pricingConditionPriceAllIn?.join('')}</span>`;
      priceStatus = this.props.property.status.toLowerCase() === 'vendu' ? 'vendu' : ``;
      priceAllInStatus = this.props.property.status.toLowerCase() === 'vendu' ? 'vendu' : ``;

      // disable price if 0
      if ((this.props.property.price < 1 || this.props.property.price === undefined || this.props.property.price === null) && this.props.property.status.toLowerCase() !== 'vendu') {
        price =`-`;
        priceStatus = '-';
      }

      // disable priceAllIn if 0
      if ((this.props.property.priceAllIn < 1 || this.props.property.priceAllIn === undefined || this.props.property.priceAllIn === null) && this.props.property.status.toLowerCase() !== 'vendu') {
        priceAllIn =`-`;
        priceAllInStatus = '-';
      }

      // if price have option
      if (this.props.property.option !== undefined && this.props.property.option.toLocaleLowerCase() !=='any') {
        price =`Option`;
        priceAllIn =`Option`;
        priceStatus = 'option';
        priceAllInStatus = 'option';
      }
      // pricingConditions = this.props.property.conditions;
    }
    const typeTaxesAndRF = 'taxesandrf';
    const typeSecondaryUnits = 'secondaryunits';
    let taxesConditions = {};
    let unitesConditions = {};
    if ((Object.keys(this.props.property).length > 0) && (Object.keys(this.props.project).length > 0)) {
      if (this.props.project.generalConditions.length) {
        const generalCondition = this.props.project.generalConditions;
        const taxesAndRFCondition = generalCondition.filter(function(el) {
          return el.conditionType.toLowerCase() === typeTaxesAndRF;
        });
        const secondaryUnitsCondition = generalCondition.filter(function(el) {
          return el.conditionType.toLowerCase() === typeSecondaryUnits;
        });
        taxesConditions = {
          conditions: taxesAndRFCondition,
          noTitle: true,
        };
        unitesConditions = {
          conditions: secondaryUnitsCondition,
          noTitle: true,
        };
      }
    }

    let propertyImages = [];
    let propertyImageLinks = [];
    if ((Object.keys(this.props.property).length > 0) && (Object.keys(this.props.project).length > 0)) {
      const propertyName = `${createTitleFromProject(this.props.project)} (${this.props.property.projectPropertyName} - ${this.props.property.projectPropertyCode} - ${this.props.property.description})`;
      propertyImages = collectImageWithAltDynamic(this.props.property.propertyImages, this.state.resolution, propertyName);
      propertyImages = orderImages(propertyImages);
      const planResolution = 1200;
      propertyImageLinks = collectImageWithAltDynamic(this.props.property.propertyImageLinks, planResolution, propertyName);
      propertyImageLinks = orderImages(propertyImageLinks);
    }
    return Object.keys(this.props.property).length > 0 &&
      Object.keys(this.props.project).length > 0 ? (
      <>
        <div className="project-detail-modal-react--mobile">
          <div
            className="modal-container-absolute-react project-detail-modal-tpr"
            id="modal-contact-form-react"
          >
            <div className="modal-box">
              <div className="modal-header">
                <div className="row-flex">
                  <div className="col-12 text-right">
                    <div className="button-close-modal-container no-padding">
                      <button
                        onClick={this.closeModal.bind(this)}
                        className="btn btn-icon-only modal-absolute-react-close-button"
                        data-modal-close="modal-popup"
                      >
                        <img
                          src={iconURL('icon-close-modal.svg')}
                          alt="close"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="act-project-detail-popup__wrap">
                <div className="act-project-detail-popup__left-col">
                  <div className="modal-header modal-header-popup-title">
                    <div className="row-flex act-project-detail-estate-header">
                      <div className="col-6">
                        <span className="title-number" id="titleNumber">
                          {this.props.property.projectPropertyName}
                        </span>
                      </div>
                      <div className="col-6 text-right">
                        <div className="icon-group-container icon-popup">
                          <a
                            href="#"
                            onClick={(e) => this.socialMediaButton(e)}
                          >
                            <i
                              className="fab fa-facebook-f"
                              id="fa-facebook-f"
                            ></i>
                          </a>
                          <a
                            href="#"
                            onClick={(e) =>
                              this.socialMediaButton(
                                  e,
                                  `${this.props.project.projectName} - ${this.props.property.projectPropertyName}`,
                              )
                            }
                          >
                            <i className="fas fa-envelope" id="fa-envelope"></i>
                          </a>
                          <a
                            href="#"
                            onClick={(e) =>
                              this.socialMediaButton(
                                  e,
                                  `${this.props.project.projectName} - ${this.props.property.projectPropertyName}`,
                              )
                            }
                          >
                            <i
                              className="fab fa-linkedin-in"
                              id="fa-linkedin-in"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="project-detail-content-container-tpr act-popup-detail-container-mobile">
                    <div className="popup-title">
                      <p className="title-text" id="project_name">
                        {this.props.project.projectName}
                      </p>
                      {(this.props.templateConfig.country_code === 'BE') ? (
                      <p className="location">
                        <span>
                          <a
                            href={`https://maps.google.com/?q=${this.props.project.projectCoordinate.lat},${this.props.project.projectCoordinate.lon}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fas fa-map-marker-alt"></i>
                            <span className="location-province">
                              <span className="province-name">
                                {this.props.project.provinceName}
                              </span>
                            </span>
                          </a>
                        </span>
                      </p>
                      ):(<></>)}
                    </div>
                  </div>
                  <div className="price-popup-detail-react">
                    {(this.props.templateConfig.country_code === 'BE') ? (
                          <>
                            <div className="price-popup-detail-react__first">
                              <div className="price-popup-detail-react__old-price">
                                {priceStatus === '' &&
                                  this.props.property.oldPrice &&
                                  this.props.property.oldPrice >
                                  this.props.property.price && (
                                  <p className="price-popup-detail-react__old-price-text">
                                    {this.props.property.oldPrice?.toLocaleString(
                                        'nl-be',
                                    ) + '€'}
                                  </p>
                                )}
                              </div>
                              <div className="price-popup-detail-react__price-wrap">
                                <div className="price-popup-detail-react__price-wrap-left">
                                  <p className="price-popup-detail-react__price-bold-black">
                                  Prix du bien principal (Hors frais et taxes):
                                  </p>
                                </div>
                                <div className="price-popup-detail-react__price-wrap-right">
                                  <div className="price-popup-detail-react__price-new-wrap">
                                    {priceStatus === '' &&
                                      this.props.property.oldPrice !== undefined &&
                                      this.props.property.price !==
                                      this.props.property.oldPrice && (
                                      <p className="price-popup-detail-react__price-new-flag">
                                            nouveau prix
                                      </p>
                                    )}
                                    <p
                                      className={priceStatus === 'option'? 'price-popup-detail-react__price-big-black':'price-popup-detail-react__price-big-red'}
                                      dangerouslySetInnerHTML={{__html: price}}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                              {priceStatus === '' && (
                                <div className="price-popup-detail-react__price-wrap">
                                  <div className="price-popup-detail-react__price-wrap-left">
                                    <p className="price-popup-detail-react__price-bold-black">
                                      Taxes & droits d’enregistrements du logement:
                                    </p>
                                  </div>
                                  <div className="price-popup-detail-react__price-wrap-right-inline">
                                    <p className="price-popup-detail-react__price-normal-red">
                                      {this.props.property.priceVATAndFees ?
                                          this.props.property.priceVATAndFees?.toLocaleString(
                                              'nl-be',
                                          ) + '€' :
                                          '-'}
                                    </p>
                                    <Tooltips
                                      property={taxesConditions}
                                      wrapperClass="price-popup-detail-react__price-tooltip"
                                      prefix="tooltip-taxes"
                                      openContact={this.openModal.bind(this)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            {this.props.property.propertyPriceLinks !== undefined &&
                                this.props.property.propertyPriceLinks.length > 0 && (
                              <div
                                className="price-popup-detail-react__second"
                                id="sectionListPropertyPrice"
                              >
                                <div className="price-popup-detail-react__price-wrap">
                                  <div className="price-popup-detail-react__price-wrap-left">
                                    <p className="price-popup-detail-react__price-bold-black">
                                            Unités secondaires obligatoires (budget
                                            indicatif)
                                    </p>
                                  </div>
                                  <div className="price-popup-detail-react__price-wrap-right">
                                    <Tooltips
                                      property={unitesConditions}
                                      wrapperClass="price-popup-detail-react__price-tooltip"
                                      prefix="tooltip-unites"
                                      openContact={this.openModal.bind(this)}
                                    />
                                  </div>
                                </div>
                                {this.props.property.propertyPriceLinks.map(
                                    (item, i) => {
                                      return (
                                        <div
                                          className="price-popup-detail-react__price-wrap"
                                          key={`property-price-link-${i}`}
                                        >
                                          <div className="price-popup-detail-react__price-wrap-left">
                                            <p className="price-popup-detail-react__price-big-grey">
                                              {item.numberOfUnits !== null ?
                                                          item.numberOfUnits +
                                                          'x - ' +
                                                          item.label :
                                                          item.label}
                                            </p>
                                          </div>
                                          <div className="price-popup-detail-react__price-wrap-right">
                                            <p className="price-popup-detail-react__price-normal-red">
                                              {priceHelper(item.calculatedPriceTtc)}
                                            </p>
                                            <p className="price-popup-detail-react__price-normal-grey">
                                              {
                                                '(Prix TVA & droits d\'enregistrement compris)'
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    },
                                )}
                              </div>
                            )}
                            <div className="price-popup-detail-react__third">
                              <div className="price-popup-detail-react__old-price">
                                {priceAllInStatus === '' &&
                                    this.props.property.oldPriceAllIn &&
                                    this.props.property.oldPriceAllIn >
                                    this.props.property.priceAllIn && (
                                  <p className="price-popup-detail-react__old-price-text">
                                    {this.props.property.oldPriceAllIn?.toLocaleString(
                                        'nl-be',
                                    ) + '€'}
                                  </p>
                                )}
                              </div>
                              <div className="price-popup-detail-react__price-wrap">
                                <div className="price-popup-detail-react__price-wrap-left">
                                  <p className="price-popup-detail-react__price-bold-black">
                                    {
                                      'Prix complet (Prix TVA & droits d\'enregistrement compris):'
                                    }
                                  </p>
                                </div>
                                <div className="price-popup-detail-react__price-wrap-right">
                                  <p
                                    className={priceAllInStatus === 'option'? 'price-popup-detail-react__price-big-black':'price-popup-detail-react__price-big-red'}
                                    dangerouslySetInnerHTML={{__html: priceAllIn}}
                                  ></p>
                                </div>
                              </div>
                            </div>
                            <Tooltips
                              property={{conditions: pricingCondition}}
                              wrapperClass=""
                              prefix="price-popup-detail-react__tooltip-wrap"
                              openContact={this.openModal.bind(this)}
                            />
                          </>
                      ):(
                          <>
                            <div className="price-popup-detail-react__first">
                              <div className="price-popup-detail-react__old-price">
                                {priceStatus === '' &&
                                    this.props.property.oldPrice &&
                                    this.props.property.oldPrice >
                                    this.props.property.price && (
                                  <p className="price-popup-detail-react__old-price-text">
                                    {this.props.property.oldPrice?.toLocaleString(
                                        'nl-be',
                                    ) + '€'}
                                  </p>
                                )}
                              </div>
                              <div className="price-popup-detail-react__price-wrap">
                                <div className="price-popup-detail-react__price-wrap-left">
                                  <p className="price-popup-detail-react__price-bold-black">
                                    Prix du bien *
                                  </p>
                                </div>
                                <div className="price-popup-detail-react__price-wrap-right">
                                  <div className="price-popup-detail-react__price-new-wrap">
                                    {priceStatus === '' &&
                                        this.props.property.oldPrice !== undefined &&
                                        this.props.property.price !==
                                        this.props.property.oldPrice && (
                                      <p className="price-popup-detail-react__price-new-flag">
                                              nouveau prix
                                      </p>
                                    )}
                                    <p
                                      className={priceStatus === 'option'? 'price-popup-detail-react__price-big-black':'price-popup-detail-react__price-big-red'}
                                      dangerouslySetInnerHTML={{__html: price}}
                                    ></p>
                                  </div>
                                </div>

                              </div>
                              {(this.props.templateConfig.country_code === 'LU') ? (
                                  <>
                                    <p
                                      className="price-note-new"
                                      id="price-note-modal"
                                    >
                                      {'* Prix TVA à 3% comprise sous condition d\'acceptation de votre dossier par l\'administration de la TVA.'}
                                    </p>
                                  </>
                              ): (
                                  <>
                                    <p
                                      className="price-note-new"
                                      id="price-note-modal"
                                    >
                                      * Prix TTC
                                    </p>
                                  </>
                              )}
                            </div>
                          </>
                      )}

                  </div>
                </div>
                <div className="act-project-detail-popup__right-col">
                  <div className="collapse-navbar act-project-detail">
                    <ul className="collapse-navbar-list act-project-detail">
                      {this.props.rendementTab !== undefined && this.props.rendementTab ? (
                            <li
                              className={this.state.isActive == 'rendement' ? 'collapse-navbar-list-item-detail is-active' : 'collapse-navbar-list-item-detail'}
                              data-navbar="rendementModalBlock"
                              id="rendementModalBlockListItem"
                              onClick={(e)=>this.adjustTab('rendement')}
                            >
                              <a id="detail-popup-rendement">Rendement</a>
                            </li>
                        ) : null}
                      <li
                        className={this.state.isActive == 'photos' ? 'collapse-navbar-list-item-detail is-active' : 'collapse-navbar-list-item-detail'}
                        data-navbar="imageModalBlock"
                        id="imageModalBlockListItem"
                        onClick={(e)=>this.adjustTab('photos')}
                      >
                        <a id="detail-popup-photos">Photos</a>
                      </li>
                      {this.props.property.propertyImageLinks.length > 0 ? (
                          <li
                            className={this.state.isActive == 'plans' ? 'collapse-navbar-list-item-detail is-active' : 'collapse-navbar-list-item-detail'}
                            data-navbar="estateDetailContainer"
                            id="estateDetailContainerListItem"
                            onClick={(e)=>this.adjustTab('plans')}
                          >
                            <a id="detail-popup-plan">Plans</a>
                          </li>
                        ) : null}
                    </ul>
                  </div>
                  <div
                    className={this.state.isActive === 'rendement' ? 'collapse-navbar-content-container act-project-detail-popup__slide-wraper' : 'collapse-navbar-content-container act-project-detail-popup__slide-wraper is-hide'}
                    id="rendementModalBlock"
                  >
                    {this.props.rendementTab !== undefined && this.props.rendementTab ? (
                        <div className="rendement-wrapper">
                          <h3 className="rendement-title">
                            Déterminez ci-dessous le loyer que vous souhaitez et calculez votre rendement !
                          </h3>
                          <div className="rendement-content">
                            <div className="white-card">
                              <h4 className="card-title">
                                Loyer souhaité
                              </h4>
                              <div className="slider-wrapper">
                                <form className="form">
                                  <InputRange
                                    maxValue={2000}
                                    minValue={500}
                                    formatLabel={(value) => (value !== null)?`€ ${new Intl.NumberFormat('de-DE').format(value).replace('.', ' ')}`:``}
                                    value={this.state.rendementValue}
                                    step={10}
                                    onChange={this.changeSliderValue.bind(this)}
                                  />
                                </form>
                              </div>
                              <div className="button-wrapper">
                                <button
                                  className="btn-primary btn-medium-40"
                                  onClick={this.calculateRendement.bind(this)}
                                >
                                  Calculez votre rendement
                                </button>
                              </div>
                            </div>
                            { this.state.rendementCalculated === true ? (
                                <>
                                  <div className="white-card">
                                    <div className="result-wrapper">
                                      <div className="result-left">Rendement locatif brut estimé</div>
                                      <div className="result-right">{this.state.rendementCalculatedValue}</div>
                                    </div>
                                  </div>
                                </>
                            ): null}
                          </div>
                        </div>
                    ) : null}
                  </div>
                  <div
                    className={this.state.isActive == 'photos' ? 'collapse-navbar-content-container act-project-detail-popup__slide-wraper' : 'collapse-navbar-content-container act-project-detail-popup__slide-wraper is-hide'}
                    id="imageModalBlock"
                  >
                    {this.props.property.propertyImages.length > 0 ? (
                      <div className="estate-plan-image-slick-container project-detail-popup">
                        <Slider
                          {...this.state.settings}
                          className="implantation-plan-image-slick project-detail slick-dotted"
                        >
                          {propertyImages.map((image, i) => {
                            return (
                              <div
                                className="estate-plan-image-container"
                                key={`estate-plan-image-container-${i}`}
                              >
                                <img
                                  src={image.url}
                                  alt={
                                    image.alt ?
                                      image.alt :
                                      createTitleFromProject(
                                          this.props.property,
                                      )
                                  }
                                  className="implantation"
                                />
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={this.state.isActive == 'plans' ? 'collapse-navbar-content-container estate-detail-container' : 'collapse-navbar-content-container estate-detail-container is-hide'}
                    id="estateDetailContainer"
                  >
                    {this.props.property.propertyImageLinks.length>0?
                    <div className="estate-plan-image-slick-container project-detail-popup">
                      <Slider {...this.state.settings} className="implantation-plan-image-slick project-detail slick-dotted">
                        {propertyImageLinks.map((image, i) =>{
                          return <div className="estate-plan-image-container" key={`estate-plan-image-container-${i}`}>
                            <img
                              src={image.url}
                              alt={
                                image.alt ?
                                  image.alt :
                                  createTitleFromProject(
                                      this.props.property,
                                  )
                              }
                              className="implantation" />
                          </div>;
                        },
                        )}
                      </Slider>
                    </div>: null}
                  </div>

                  {/* for mobile */}
                  <div className="project-detail-content-container-tpr act-popup-detail-container-mobile">
                    <div className="table-container">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <span className="label">PEB : </span>
                              <span className="span-peb" id="peb">
                                {this.props.property.peb}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="label">Etage : </span>
                              <span className="span-floor" id="floor">
                                {' '}
                                {this.props.property.floor}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="label">Surface habitable : </span>
                              <span className="span-surfaceM2" id="surfaceM2">
                                {this.props.property.surfaceM2}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="label">Surface terrain : </span>
                              <span
                                className="span-surfaceOfBlaconyAndGardenM2"
                                id="surfaceOfBlaconyAndGardenM2"
                              >
                                {' '}
                                {
                                  this.props.property
                                      .surfaceOfBlaconyAndGardenM2
                                }
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="label">Chambres : </span>
                              <span
                                className="span-numberOfRooms"
                                id="numberOfRooms"
                              >
                                {' '}
                                {this.props.property.numberOfRooms}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="span-description" id="description">
                              {this.props.property.description}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-detail-content-container-tpr act-popup-detail-container-mobile">
                <div className="project-detail-content-text-container-tpr popup-content">
                  <div className="project-detail-content-text-body-container content-popup">
                    {(this.props.templateConfig.country_code === 'BE') ? (
                        <>
                          {this.props.mediaId &&
                      this.props.property.hasActionOnGoing == true && (
                            <div className="col-12">
                              <div
                                id="divBannerPromo"
                                className="banner-promo-modal-react"
                              >
                                <Promotion
                                  projectMedia={this.props.mediaId}
                                  getMediaBannerDataAll={
                                    this.props.getMediaBannerDataAll
                                  }
                                />
                              </div>
                            </div>
                          )}

                          <div className="starting-price">
                            <div className="starting-price__old">
                              <span className="label">
                          Prix du bien principal (Hors frais et taxes):
                              </span>
                              {priceStatus === '' &&
                          this.props.property.oldPrice &&
                          this.props.property.oldPrice >
                            this.props.property.price && (
                                <span className="price-old">
                                  {this.props.property.oldPrice?.toLocaleString(
                                      'nl-be',
                                  ) + '€'}
                                </span>
                              )}
                            </div>
                            <div className="starting-price__body">
                              {priceStatus === '' &&
                          this.props.property.oldPrice !== undefined &&
                          this.props.property.price !==
                            this.props.property.oldPrice && (
                                <span className="price-new">nouveau prix</span>
                              )}
                              <span
                                className={priceAllInStatus === 'option'? 'price-black':'price'}
                                dangerouslySetInnerHTML={{__html: price}}
                              ></span>
                            </div>
                          </div>
                          {priceStatus === '' && (
                            <div className="section-taxes" id="sectionTaxesMobile">
                              <div className="section-taxes__body">
                                <span className="label">
                            Taxes &amp; droits d’enregistrements du logement:
                                </span>
                                <span className="price" id="priceNewTwoMobile">
                                  {this.props.property.priceVATAndFees ?
                              this.props.property.priceVATAndFees?.toLocaleString(
                                  'nl-be',
                              ) + '€' :
                              '-'}
                                </span>
                                <Tooltips
                                  property={taxesConditions}
                                  wrapperClass="price-popup-detail-react__price-tooltip"
                                  prefix="tooltip-taxes"
                                  openContact={this.openModal.bind(this)}
                                />
                              </div>
                            </div>
                          )}

                          {this.props.property.propertyPriceLinks !== undefined &&
                      this.props.property.propertyPriceLinks.length > 0 && (
                            <div
                              className="mandatory"
                              id="sectionListPropertyPriceMobile"
                            >
                              <div className="mandatory__Header">
                                <span className="label">
                              Unités secondaires obligatoires (budget indicatif)
                                </span>
                                <Tooltips
                                  property={unitesConditions}
                                  wrapperClass=""
                                  prefix="tooltip-unites"
                                />
                              </div>
                              <div className="mandatory__body">
                                <table>
                                  {this.props.property.propertyPriceLinks.map(
                                      (item, i) => {
                                        return (
                                          <tr key={`property-price-link-${i}`}>
                                            <td className="label-large">
                                              {item.label}
                                            </td>
                                            <td className="price-small">
                                              <p>
                                                {priceHelper(item.priceTTC)}
                                              </p>
                                              <p>(Prix TVA & DE compris)</p>
                                            </td>
                                          </tr>
                                        );
                                      },
                                  )}
                                </table>
                              </div>
                            </div>
                          )}
                          <div className="total-price">
                            <div className="total-price__old">
                              <span className="label">
                          Prix complet (Prix TVA & DE compris):
                              </span>
                              {priceAllInStatus === '' &&
                          this.props.property.oldPriceAllIn &&
                          this.props.property.oldPriceAllIn >
                            this.props.property.priceAllIn && (
                                <span className="price-old">
                                  {this.props.property.oldPriceAllIn?.toLocaleString(
                                      'nl-be',
                                  ) + '€'}
                                </span>
                              )}
                            </div>
                            <div className="total-price__body">
                              <span
                                className={priceAllInStatus === 'option'? 'price-black':'price'}
                                id="priceAllInMobile"
                                dangerouslySetInnerHTML={{__html: priceAllIn}}
                              ></span>
                            </div>
                          </div>
                          <Tooltips
                            property={{conditions: pricingCondition}}
                            wrapperClass=""
                            prefix="price-popup-detail-react__tooltip-wrap"
                            openContact={this.openModal.bind(this)}
                          />
                        </>
                      ):(
                          <>
                            {this.props.mediaId &&
                                this.props.property.hasActionOnGoing == true && (
                              <div className="col-12">
                                <div
                                  id="divBannerPromo"
                                  className="banner-promo-modal-react"
                                >
                                  <Promotion
                                    projectMedia={this.props.mediaId}
                                    getMediaBannerDataAll={
                                      this.props.getMediaBannerDataAll
                                    }
                                  />
                                </div>
                              </div>
                            )}

                            <div className="starting-price">
                              <div className="starting-price__old">
                                <span className="label">
                          Prix du bien * :
                                </span>
                                {priceStatus === '' &&
                                    this.props.property.oldPrice &&
                                    this.props.property.oldPrice >
                                    this.props.property.price && (
                                  <span className="price-old">
                                    {this.props.property.oldPrice?.toLocaleString(
                                        'nl-be',
                                    ) + '€'}
                                  </span>
                                )}
                              </div>
                              <div className="starting-price__body">
                                {priceStatus === '' &&
                                    this.props.property.oldPrice !== undefined &&
                                    this.props.property.price !==
                                    this.props.property.oldPrice && (
                                  <span className="price-new">nouveau prix</span>
                                )}
                                <span
                                  className={priceAllInStatus === 'option'? 'price-black':'price'}
                                  dangerouslySetInnerHTML={{__html: price}}
                                ></span>
                              </div>

                            </div>

                            {(this.props.templateConfig.country_code === 'LU') ? (
                                <>
                                  <p
                                    className="price-note-new"
                                    id="price-note-modal"
                                  >
                                    {'* Prix TVA à 3% comprise sous condition d\'acceptation de votre dossier par l\'administration de la TVA.'}
                                  </p>
                                </>
                            ): (
                                <>
                                  <p
                                    className="price-note-new"
                                    id="price-note-modal"
                                  >
                                    * Prix TTC
                                  </p>
                                </>
                            )}
                          </>
                      )}

                  </div>
                </div>
              </div>
              <div className="project-detail-content-container-tpr act-popup-detail-container-mobile">
                <div className="popup-detail-cta-right">
                  {pdfbutton}
                  <a
                    onClick={this.openModal.bind(this)}
                    className="btn btn-primary btn-medium-40 btn-modal-absolute-react modal-absolute-react-close-button"
                  >
                    Contactez-nous
                  </a>
                </div>
              </div>
              <div className="popup-detail-cta mobile">
                <div className="popup-detail-cta-left">
                  <button
                    className="btn btn-back-to-list modal-absolute-close-button"
                    onClick={this.closeModal.bind(this)}
                    data-modal-close="modal-popup"
                  >
                    <i className="fas fa-chevron-left"></i>
                    { this.props.fromImage ? null : <span>{backText}</span> }
                  </button>
                </div>
              </div>
            </div>
          </div>
          ;
        </div>
        <div className="project-detail-modal-react--desktop">
          <div
            className="modal-container-absolute-react project-detail-modal-tpr"
            id="modal-contact-form-react"
          >
            <div className="modal-box">
              <div className="project-detail-close-popup desktop">
                <button
                  onClick={this.closeModal.bind(this)}
                  className="btn btn-icon-only modal-absolute-react-close-button"
                  data-modal-close="modal-popup"
                >
                  <i className="fas fa-chevron-left"></i>
                  { this.props.fromImage ? null : <span>{backText}</span> }
                </button>
              </div>
              <div className="modal-header">
                <div className="row-flex">
                  <div className="col-12 text-right">
                    <div className="button-close-modal-container no-padding">
                      <button
                        onClick={this.closeModal.bind(this)}
                        className="btn btn-icon-only modal-absolute-react-close-button"
                        data-modal-close="modal-popup"
                      >
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.76928 10.4L0.622925 19.5464L1.18861 20.1121L10.335 10.9657L19.4815 20.1122L20.0471 19.5465L10.9006 10.4L20.0471 1.25353L19.4815 0.687849L10.335 9.83435L1.1886 0.687988L0.622913 1.25367L9.76928 10.4Z"
                            fill="#D4D4D4"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="act-project-detail-popup__wrap">
                <div className="act-project-detail-popup__left-col">
                  <div className="modal-header modal-header-popup-title">
                    <div className="row-flex act-project-detail-estate-header">
                      <div className="col-6">
                        <span className="title-number" id="titleNumber">
                          {this.props.property.projectPropertyName}
                        </span>
                      </div>
                      <div className="col-6 text-right">
                        <div className="icon-group-container icon-popup">
                          <a
                            href="#"
                            onClick={(e) => this.socialMediaButton(e)}
                          >
                            <i
                              className="fab fa-facebook-f"
                              id="fa-facebook-f"
                            ></i>
                          </a>
                          <a
                            href="#"
                            onClick={(e) =>
                              this.socialMediaButton(
                                  e,
                                  `${this.props.project.projectName} - ${this.props.property.projectPropertyName}`,
                              )
                            }
                          >
                            <i className="fas fa-envelope" id="fa-envelope"></i>
                          </a>
                          <a
                            href="#"
                            onClick={(e) =>
                              this.socialMediaButton(
                                  e,
                                  `${this.props.project.projectName} - ${this.props.property.projectPropertyName}`,
                              )
                            }
                          >
                            <i
                              className="fab fa-linkedin-in"
                              id="fa-linkedin-in"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="project-detail-content-container popup-detail-container">
                    <div className="project-detail-content-text-container-tpr popup-content">
                      <div className="project-detail-content-text-body-container content-popup">
                        <div className="row-flex">
                          <div className="col-12">
                            <div className="popup-title">
                              <p className="title-text" id="project-name">
                                {this.props.project.projectName}
                              </p>
                              {(this.props.templateConfig.country_code === 'BE') ? (
                              <p className="location">
                                <span>
                                  <a
                                    href={`https://maps.google.com/?q=${this.props.project.projectCoordinate.lat},${this.props.project.projectCoordinate.lon}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i className="fas fa-map-marker-alt"></i>
                                    <span className="location-province">
                                      <span className="province-name">
                                        {this.props.project.provinceName}
                                      </span>
                                    </span>
                                  </a>
                                </span>
                              </p>
                                  ):(<></>)}
                            </div>
                            <table>
                              <tr>
                                <td className="label">PEB</td>
                                <td className="span-peb" id="peb">
                                  {this.props.property.peb}
                                </td>
                              </tr>
                              <tr>
                                <td className="label">Etage</td>
                                <td className="span-floor" id="floor">
                                  {this.props.property.floor}
                                </td>
                              </tr>
                              <tr>
                                <td className="label">Surface habitable</td>
                                <td className="span-surfaceM2" id="surfaceM2">
                                  {this.props.property.surfaceM2}
                                </td>
                              </tr>
                              <tr>
                                <td className="label">Surface terrain</td>
                                <td
                                  className="span-surfaceOfBlaconyAndGardenM2"
                                  id="surfaceOfBlaconyAndGardenM2"
                                >
                                  {
                                    this.props.property
                                        .surfaceOfBlaconyAndGardenM2
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td className="label">Chambres</td>
                                <td
                                  className="span-numberOfRooms"
                                  id="numberOfRooms"
                                >
                                  {this.props.property.numberOfRooms}
                                </td>
                              </tr>
                              <tr>
                                <td className="label">Description</td>
                                <td
                                  className="span-description"
                                  id="description"
                                >
                                  {this.props.property.description}
                                </td>
                              </tr>
                            </table>
                          </div>
                          {this.props.mediaId &&
                            this.props.property.hasActionOnGoing == true && (
                            <div className="col-12">
                              <div
                                id="divBannerPromo"
                                className="banner-promo-modal-react"
                              >
                                <Promotion
                                  projectMedia={this.props.mediaId}
                                  getMediaBannerDataAll={
                                    this.props.getMediaBannerDataAll
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="price-popup-detail-react">

                    {(this.props.templateConfig.country_code === 'BE') ? (
                      <>
                        <div className="price-popup-detail-react__first">
                          <div className="price-popup-detail-react__old-price">
                            {priceStatus === '' &&
                            this.props.property.oldPrice &&
                            this.props.property.oldPrice >
                              this.props.property.price && (
                              <p className="price-popup-detail-react__old-price-text">
                                {this.props.property.oldPrice?.toLocaleString(
                                    'nl-be',
                                ) + '€'}
                              </p>
                            )}
                          </div>
                          <div className="price-popup-detail-react__price-wrap">
                            <div className="price-popup-detail-react__price-wrap-left">
                              <p className="price-popup-detail-react__price-bold-black">
                            Prix du bien principal (Hors frais annexes* et taxes) :
                              </p>
                            </div>
                            <div className="price-popup-detail-react__price-wrap-right">
                              <div className="price-popup-detail-react__price-new-wrap">
                                {priceStatus === '' &&
                                this.props.property.oldPrice !== undefined &&
                                this.props.property.price !==
                                  this.props.property.oldPrice && (
                                  <p className="price-popup-detail-react__price-new-flag">
                                    nouveau prix
                                  </p>
                                )}
                                {(() => {
                                  if (this.props.property.option === 'Reservation') {
                                    return (
                                      <p
                                        className="price-popup-detail-react__price-big-black"
                                        dangerouslySetInnerHTML={{__html: 'Option'}}
                                      ></p>
                                    );
                                  } else {
                                    return (
                                      <p
                                        className="price-popup-detail-react__price-big-red"
                                        dangerouslySetInnerHTML={{__html: price}}
                                      ></p>
                                    );
                                  }
                                })()}
                              </div>
                            </div>
                          </div>
                          {priceStatus === '' && (
                            <div className="price-popup-detail-react__price-wrap">
                              <div className="price-popup-detail-react__price-wrap-left">
                                <p className="price-popup-detail-react__price-bold-black">
                                Taxes & droits d’enregistrements du logement:
                                </p>
                              </div>
                              <div className="price-popup-detail-react__price-wrap-right-inline">
                                <p className="price-popup-detail-react__price-normal-red">
                                  {this.props.property.priceVATAndFees ?
                                  this.props.property.priceVATAndFees?.toLocaleString(
                                      'nl-be',
                                  ) + '€' :
                                  '-'}
                                </p>
                                <Tooltips
                                  property={taxesConditions}
                                  wrapperClass="price-popup-detail-react__price-tooltip"
                                  prefix="tooltip-taxes"
                                  openContact={this.openModal.bind(this)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {this.props.property.propertyPriceLinks !== undefined &&
                        this.props.property.propertyPriceLinks.length > 0 && (
                          <div
                            className="price-popup-detail-react__second"
                            id="sectionListPropertyPrice"
                          >
                            <div className="price-popup-detail-react__price-wrap">
                              <div className="price-popup-detail-react__price-wrap-left">
                                <p className="price-popup-detail-react__price-bold-black">
                                  Unités secondaires obligatoires (budget
                                  indicatif):
                                </p>
                              </div>
                              <div className="price-popup-detail-react__price-wrap-right">
                                <Tooltips
                                  property={unitesConditions}
                                  wrapperClass="price-popup-detail-react__price-tooltip"
                                  prefix="tooltip-unites"
                                  openContact={this.openModal.bind(this)}
                                />
                              </div>
                            </div>
                            {this.props.property.propertyPriceLinks.map(
                                (item, i) => {
                                  return (
                                    <div
                                      className="price-popup-detail-react__price-wrap"
                                      key={`property-price-link-${i}`}
                                    >
                                      <div className="price-popup-detail-react__price-wrap-left">
                                        <p className="price-popup-detail-react__price-big-grey">
                                          {item.numberOfUnits !== null ?
                                          item.numberOfUnits +
                                            'x - ' +
                                            item.label :
                                          item.label}
                                        </p>
                                      </div>
                                      <div className="price-popup-detail-react__price-wrap-right">
                                        <p className="price-popup-detail-react__price-normal-red">
                                          {priceHelper(item.calculatedPriceTtc)}
                                        </p>
                                        <p className="price-popup-detail-react__price-normal-grey">
                                          {
                                            '(Prix TVA & droits d\'enregistrement compris)'
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  );
                                },
                            )}
                          </div>
                        )}
                        <div className="price-popup-detail-react__third">
                          <div className="price-popup-detail-react__old-price">
                            {priceAllInStatus === '' &&
                            this.props.property.oldPriceAllIn &&
                            this.props.property.oldPriceAllIn >
                              this.props.property.priceAllIn && (
                              <p className="price-popup-detail-react__old-price-text">
                                {this.props.property.oldPriceAllIn?.toLocaleString(
                                    'nl-be',
                                ) + '€'}
                              </p>
                            )}
                          </div>
                          <div className="price-popup-detail-react__price-wrap">
                            <div className="price-popup-detail-react__price-wrap-left">
                              <p className="price-popup-detail-react__price-bold-black">
                                {
                                  'Prix complet (Prix TVA & droits d\'enregistrement compris):'
                                }
                              </p>
                            </div>
                            <div className="price-popup-detail-react__price-wrap-right">
                              {(() => {
                                if (this.props.property.option === 'Reservation') {
                                  return (
                                    <p
                                      className="price-popup-detail-react__price-big-black"
                                      dangerouslySetInnerHTML={{__html: 'Option'}}
                                    ></p>
                                  );
                                } else {
                                  return (
                                    <p
                                      className="price-popup-detail-react__price-big-red"
                                      dangerouslySetInnerHTML={{__html: priceAllIn}}
                                    ></p>
                                  );
                                }
                              })()}
                            </div>
                          </div>
                        </div>
                        <Tooltips
                          property={{conditions: pricingCondition}}
                          prefix="price-popup-detail-react__tooltip-wrap"
                          openContact={this.openModal.bind(this)}
                        />
                      </>
                      ):(
                        <>
                          <div className="price-popup-detail-react__first">
                            <div className="price-popup-detail-react__old-price">
                              {priceStatus === '' &&
                                  this.props.property.oldPrice &&
                                  this.props.property.oldPrice >
                                  this.props.property.price && (
                                <p className="price-popup-detail-react__old-price-text">
                                  {this.props.property.oldPrice?.toLocaleString(
                                      'nl-be',
                                  ) + '€'}
                                </p>
                              )}
                            </div>
                            <div className="price-popup-detail-react__price-wrap">
                              <div className="price-popup-detail-react__price-wrap-left">
                                <p className="price-popup-detail-react__price-bold-black">
                                  Prix du bien *
                                </p>
                              </div>
                              <div className="price-popup-detail-react__price-wrap-right">
                                <div className="price-popup-detail-react__price-new-wrap">
                                  {priceStatus === '' &&
                                      this.props.property.oldPrice !== undefined &&
                                      this.props.property.price !==
                                      this.props.property.oldPrice && (
                                    <p className="price-popup-detail-react__price-new-flag">
                                            nouveau prix
                                    </p>
                                  )}
                                  {(() => {
                                    if (this.props.property.option === 'Reservation') {
                                      return (
                                        <p
                                          className="price-popup-detail-react__price-big-black"
                                          dangerouslySetInnerHTML={{__html: 'Option'}}
                                        ></p>
                                      );
                                    } else {
                                      return (
                                        <p
                                          className="price-popup-detail-react__price-big-red"
                                          dangerouslySetInnerHTML={{__html: price}}
                                        ></p>
                                      );
                                    }
                                  })()}
                                </div>
                              </div>
                            </div>
                            {(this.props.templateConfig.country_code === 'LU') ? (
                                <>
                                  <p
                                    className="price-note-new"
                                    id="price-note-modal"
                                  >
                                    {'* Prix TVA à 3% comprise sous condition d\'acceptation de votre dossier par l\'administration de la TVA.'}
                                  </p>
                                </>
                            ): (
                                <>
                                  <p
                                    className="price-note-new"
                                    id="price-note-modal"
                                  >
                                    * Prix TTC
                                  </p>
                                </>
                            )}
                          </div>
                        </>
                      )}

                  </div>
                </div>
                <div className="act-project-detail-popup__right-col">

                  <div className="collapse-navbar act-project-detail">
                    <ul className="collapse-navbar-list act-project-detail">

                      {this.props.rendementTab !== undefined && this.props.rendementTab ? (
                            <li
                              className={this.state.isActive == 'rendement' ? 'collapse-navbar-list-item-detail is-active' : 'collapse-navbar-list-item-detail'}
                              data-navbar="rendementModalBlock"
                              id="rendementModalBlockListItem"
                              onClick={(e)=>this.adjustTab('rendement')}
                            >
                              <a id="detail-popup-rendement">Rendement</a>
                            </li>
                        ) : null}

                      {this.props.property.propertyImages.length > 0 ? (
                        <li
                          className={this.state.isActive == 'photos' ? 'collapse-navbar-list-item-detail is-active' : 'collapse-navbar-list-item-detail'}
                          data-navbar="imageModalBlock"
                          id="imageModalBlockListItem"
                          onClick={(e)=>this.adjustTab('photos')}
                        >
                          <a id="detail-popup-photos">Photos</a>
                        </li>
                        ) : null}

                      {this.props.property.propertyImageLinks.length > 0 ? (
                          <li
                            className={this.state.isActive == 'plans' ? 'collapse-navbar-list-item-detail is-active' : 'collapse-navbar-list-item-detail'}
                            data-navbar="estateDetailContainer"
                            id="estateDetailContainerListItem"
                            onClick={(e)=>this.adjustTab('plans')}
                          >
                            <a id="detail-popup-plan">Plans</a>
                          </li>
                        ) : null}
                    </ul>
                  </div>
                  <div
                    className={this.state.isActive === 'rendement' ? 'collapse-navbar-content-container act-project-detail-popup__slide-wraper' : 'collapse-navbar-content-container act-project-detail-popup__slide-wraper is-hide'}
                    id="rendementModalBlock"
                  >
                    {this.props.rendementTab !== undefined && this.props.rendementTab ? (
                        <div className="rendement-wrapper">
                          <h3 className="rendement-title">
                            Déterminez ci-dessous le loyer que vous souhaitez et calculez votre rendement !
                          </h3>
                          <div className="rendement-content">
                            <div className="white-card">
                              <h4 className="card-title">
                                Loyer souhaité
                              </h4>
                              <div className="slider-wrapper">
                                <form className="form">
                                  <InputRange
                                    maxValue={2000}
                                    minValue={500}
                                    formatLabel={(value) => (value !== null)?`€ ${new Intl.NumberFormat('de-DE').format(value).replace('.', ' ')}`:``}
                                    value={this.state.rendementValue}
                                    step={10}
                                    onChange={this.changeSliderValue.bind(this)}
                                  />
                                </form>
                              </div>
                              <div className="button-wrapper">
                                <button
                                  className="btn-primary btn-medium-40"
                                  onClick={this.calculateRendement.bind(this)}
                                >
                                  Calculez votre rendement
                                </button>
                              </div>
                            </div>
                            { this.state.rendementCalculated === true ? (
                                <>
                                  <div className="white-card">
                                    <div className="result-wrapper">
                                      <div className="result-left">Rendement locatif brut estimé</div>
                                      <div className="result-right">{this.state.rendementCalculatedValue}</div>
                                    </div>
                                  </div>
                                </>
                            ): null}
                            <div className="rendement-btn-container">
                              <a className="btn btn-primary border-only btn-medium-40"
                                href={`/generate/pdf/${this.props.project.projectId}/${this.props.property.projectPropertyId}?rent=${this.state.rendementValue}&rendement=${this.state.rendementCalculatedValue}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Recevoir cette fiche
                              </a>
                            </div>
                          </div>
                        </div>
                    ) : null}
                  </div>
                  <div
                    className={this.state.isActive == 'photos' ? 'collapse-navbar-content-container act-project-detail-popup__slide-wraper' : 'collapse-navbar-content-container act-project-detail-popup__slide-wraper is-hide'}
                    id="imageModalBlock"
                  >
                    {this.props.property.propertyImages.length > 0 ? (
                      <div className="estate-plan-image-slick-container project-detail-popup">
                        <Slider
                          {...this.state.settings}
                          className="implantation-plan-image-slick project-detail-tpr slick-dotted"
                        >
                          {propertyImages.map((image, i) => {
                            return (
                              <div
                                className="estate-plan-image-container"
                                key={`estate-plan-image-container-${i}`}
                              >
                                <img
                                  src={image.url}
                                  alt={
                                    image.alt ?
                                      image.alt :
                                      createTitleFromProject(
                                          this.props.property,
                                      )
                                  }
                                  className="implantation"
                                />
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={this.state.isActive == 'plans' ? 'collapse-navbar-content-container  act-project-detail-popup__slide-wraper estate-detail-container' : 'collapse-navbar-content-container estate-detail-container is-hide'}
                    id="estateDetailContainer"
                  >
                    {this.props.property.propertyImageLinks.length > 0 ? (
                      <div className="estate-plan-image-slick-container project-detail-popup">
                        <Slider
                          {...this.state.settings}
                          className="implantation-plan-image-slick project-detail-tpr slick-dotted"
                        >
                          {propertyImageLinks.map((image, i) => {
                            return (
                              <div
                                className="estate-plan-image-container"
                                key={`estate-plan-image-container-${i}`}
                              >
                                <img
                                  src={image.url}
                                  alt={
                                    image.alt ?
                                      image.alt :
                                      createTitleFromProject(
                                          this.props.property,
                                      )
                                  }
                                  className="implantation"
                                />
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                      ) : null}
                  </div>
                  <div className="popup-detail-cta-right">
                    {pdfbutton}
                    <a
                      onClick={this.openModal.bind(this)}
                      className="btn btn-primary btn-medium-40 btn-modal-absolute-react modal-absolute-react-close-button"
                    >
                      Contactez-nous
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  };
}

NextArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

PropertyInfoNew.propTypes = {
  project: PropTypes.object,
  property: PropTypes.object,
  setModal: PropTypes.any,
  setContactForm: PropTypes.any,
  onOpenContactModal: PropTypes.func,
  onOpenContactModalPdf: PropTypes.func,
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  contactInformation: PropTypes.any,
  hideModal: PropTypes.func,
  mediaId: PropTypes.array,
  getMediaBannerDataAll: PropTypes.object,
  fromImage: PropTypes.bool,
  rendementTab: PropTypes.bool,
  templateConfig: PropTypes.object,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createLabelTranslate},
)(PropertyInfoNew);
