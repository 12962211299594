import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';
import {isMobileOnly} from 'react-device-detect';
import {
  createRequestSearchProjects,
  createRequestGetProjectMarkers,
  createProjectList,
  createTotalProjects,
} from '../../../pages/action';
import {createMarkerCollection, createPopupProjectId} from '../../MainMap/action';
import {showMessage, hideMessage} from '../../InfoMessage/action';
import * as ExternalApi from '../../../services/ExternalApi';
import {createMapLocation} from '../action';
import {toggleMap} from '../../ProjectList/action';
import {connect} from 'react-redux';
import {loadList} from './LoadListProject';
import {queryStringFormatter} from '../../../helpers/helper';
import {searchLocation} from '../../../services/GoogleMapApi';

const googleApiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`;

/**
 * Class Type Filter.
*/
class LocationFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      place: null,
      country: props.templateConfig.country_code,
    };
    this.updateUrl = this.updateUrl.bind(this);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    state.place = props.filterValue;
    return state;
  }

  /**
   * Check if component is updated
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.filterValue !== prevProps.filterValue) {
      if (!this.props.filterValue) {
        this.setState({
          place: null,
          country: this.props.templateConfig.country_code,
        });
      }
    }
  }

  /**
   * open place google map location.
   * @param {number} place text output val.
   */
  async onPlaceSelected(place) {
    // TODO: delete me if search by location working well
    // disable search when its no name
    // if (place.geometry === undefined && place.name.length < 1) {
    //   const querySearch = queryStringFormatter(this.props.history.location.search);
    //   delete querySearch.place;
    //   this.updateUrl(querySearch);
    //   return false;
    // }
    const defaultCordinat = await this.props.getCoordinateMaps;
    let locationNotFound = false;
    let placeName = undefined;
    let centeredCoorinates = {};
    let params = await this.props.getRequestSearchProjects;
    params = {
      ...params,
      templateConfig: {
        ...this.props.templateConfig,
      },
      sorting: {
        ...params.sorting,
        centeredCoorinates: {
          lat: defaultCordinat.lat,
          lon: defaultCordinat.lng,
        },
      },
    };

    if (place.geometry !== undefined) {
      // set center coordinates
      centeredCoorinates = {
        lat: place.geometry.location.lat().toString(),
        lon: place.geometry.location.lng().toString(),
      };
      // set place name
      placeName = place.formatted_address;
      // set parameter
      params = {
        ...params,
        sorting: {
          ...params.sorting,
          centeredCoorinates: centeredCoorinates,
        },
      };
    } else {
      return false;
      // using search if the location not select sugestion
      const requestLocation = await searchLocation(place.name, this.props.templateConfig.country_code);
      const location = requestLocation.data.results[0];
      if (location !== undefined) {
        centeredCoorinates = {
          lat: location.geometry.location.lat,
          lon: location.geometry.location.lng,
        };
        placeName= place.name;
      } else {
        placeName= place.name;
        locationNotFound = placeName !== '' ? true : false;
        centeredCoorinates = {
          lat: defaultCordinat.lat,
          lon: defaultCordinat.lng,
        };
      }
      // set parameter
      params = {
        ...params,
        sorting: {
          ...params.sorting,
          centeredCoorinates: centeredCoorinates,
        },
      };
    }

    const mapLocation = {
      center: {
        lat: Number(centeredCoorinates.lat),
        lng: Number(centeredCoorinates.lon),
      },
      zoom: (locationNotFound || placeName === '') ? 7: 12,
      place_name: placeName !== '' ? placeName : undefined,
      locationNotFound: locationNotFound,
    };
    this.props.createMapLocation(mapLocation);
    this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    const markerParams = this.props.createRequestGetProjectMarkers({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    this.props.createPopupProjectId(null);
    this.props.createMarkerCollection(null);
    this.props.createMarkerCollection(await ExternalApi.getProjectMarkers(markerParams.payload));
    if (isMobileOnly) {
      this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
      this.props.createTotalProjects({'loading': true, 'count': 0});
      const dataProjects = await loadList(params);
      this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
      this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
    }
    this.props.toggleMap(false);
    let querySearch = queryStringFormatter(this.props.history.location.search);
    if (!locationNotFound) {
      this.props.hideMessage();
      if (placeName !== '') {
        querySearch = {...querySearch, place: placeName};
      } else {
        delete querySearch.place;
      }
    } else {
      this.props.hideMessage();
      if (locationNotFound) {
        this.props.showMessage({
          message: `Il n'y a pas de projet pour la localité recherchée. Voici d'autres projet à proximité de votre localité souhaitée:`,
          variant: 'ERROR',
        });
      }
    }
    this.updateUrl(querySearch);
  };
  /**
   *
   * @param {*} querySearch
   */
  updateUrl(querySearch) {
    if (Object.keys(querySearch).length > 0) {
      const queryString = Object.keys(querySearch).map((key) => key + '=' + querySearch[key]).join('&');
      this.props.history.push({search: `?${queryString}`});
    } else {
      this.props.history.push({});
    }
  }
  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div id="location" className="form-group icon-right search-icon">
        <Autocomplete
          apiKey={ googleApiKey }
          onPlaceSelected={ this.onPlaceSelected.bind(this) }
          className="form-control pac-target-input"
          placeholder="Où cherchez-vous ?"
          autoComplete="off"
          defaultValue={this.state.place}
          options={{
            types: ['(regions)'],
            componentRestrictions: {country: this.state.country},
          }}
          id={'place_search'}
        />
      </div>
    );
  }
}

LocationFilter.propTypes = {
  onPlaceSelected: PropTypes.func,
  getRequestSearchProjects: PropTypes.objectOf(PropTypes.any),
  getCoordinateMaps: PropTypes.objectOf(PropTypes.any),
  createMapLocation: PropTypes.func,
  createRequestSearchProjects: PropTypes.func,
  createProjectList: PropTypes.func,
  toggleMap: PropTypes.func,
  history: PropTypes.object,
  createRequestGetProjectMarkers: PropTypes.func,
  createMarkerCollection: PropTypes.func,
  createPopupProjectId: PropTypes.func,
  showMessage: PropTypes.func,
  hideMessage: PropTypes.func,
  filterValue: PropTypes.string,
  templateConfig: PropTypes.object,
  createTotalProjects: PropTypes.func,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(
    mapsStateToProps,
    {
      toggleMap,
      createMapLocation,
      createRequestSearchProjects,
      createProjectList,
      createRequestGetProjectMarkers,
      createMarkerCollection,
      createTotalProjects,
      showMessage,
      hideMessage,
      createPopupProjectId,
    },
)(LocationFilter);
