export const defaultContactFormInput = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  street: null,
  streetNumber: null,
  postalCode: null,
  locality: null,
};

export const initialProjectMarker = {
  'searchProjectCriteria': {
    'priceMin': null,
    'priceMax': null,
    'numberOfRoomsMin': null,
    'numberOfRoomsMax': null,
    'minNumberOfM2': null,
    'maxNumberOfM2': null,
    'selectInvestor': false,
    'parking': false,
    'floorDetails': null,
    'exteriorSpace': false,
    'exteriorSpaceOrientation': null,
    'typesOfGeneralizedProperties': [],
    'projectPropertyCode': '',
    'onlyProjectsWithActionOnGoing': false,
  },
  'language': 'fr',
};

export const initialSearchProject = {
  'paging': {
    'currentPage': 1,
    'pageSize': 7,
  },
  'projectPropertiesSearchCriteria': {
    'priceMin': null,
    'priceMax': null,
    'numberOfRoomsMin': null,
    'numberOfRoomsMax': null,
    'minNumberOfM2': null,
    'maxNumberOfM2': null,
    'typeOfProperty': [],
    'generalizedTypeOfProperty': [],
    'projectIds': null,
    'onlyProjectsWithActionOnGoing': false,
  },
  'sorting': {
    'orderByDateASC': false,
    'orderByDateDESC': true,
    'orderByPriceASC': false,
    'orderByPriceDESC': true,
    'orderByDistanceASC': true,
    'orderByDistanceDESC': true,
    'centeredCoorinates': {
      'lon': '4.4699360',
      'lat': '50.5038870',
    },
  },
  'language': 'fr',
};

export const initialSearchInvestProject = {
  'paging': {
    'currentPage': 1,
    'pageSize': 9,
  },
  'projectPropertiesSearchCriteria': {
    'priceMin': null,
    'priceMax': null,
    'provinceIds': [],
    'generalizedTypeOfProperty': [
      'House',
      'Appartment',
    ],
    'countries': [
      'Belgium',
    ],
  },
  'language': 'fr',
  'imageSize': 1200,
};

export const initialFilterProjectDetail = {
  entities: {},
  filterId: null,
};

export const initialMessage = {
  'state': null,
  'options': {
    autoHideDuration: 6000,
    message: `Hi`,
    variant: 'INFO',
  },
};

export const initialPropertyModal = {
  state: false,
  fromImage: false,
  property: {},
  project: {},
};

export const initialTemplate = {
  template: '',
  country: '',
  country_code: '',
  valid_until: '',
};

export const initialTotalProjectCount = {
  loading: true,
  count: 0,
};
