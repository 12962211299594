import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';

/**
 * Main Component
 */
class FormHeader extends React.Component {
  /**
   * Close contact modal.
   */
  closeModal() {
    // setTimeout(() => {
    //   window.scrollTo(0, document.body.scrollHeight ||
    //     document.documentElement.scrollHeight);
    // }, 500);
    document.documentElement.style.overflow = 'auto';
    this.props.setModal();
    this.setState({
      setContactForm: false,
      showContactForm: false,
    }, () => {
      this.handleOpenContactModal(this.state.showContactForm);
    });
  };

  /**
   *
   * @param {number} value text output val.
   */
  handleOpenContactModal(value) {
    this.props.onCloseContactModal(value);
  }

  /**
   * @return {JSX}
   */
  async componentDidMount() {
    const label = this.props.getLabelTranslate;
    label.cfTitle = 'VOUS SOUHAITEZ NOUS CONTACTER À PROPOS DU PROJET ';
    label.cfVisiteAppartement = 'Visiter un appartement témoin';
    label.cfPdfTitle = 'VOUS SOUHAITEZ RECEVOIR LES PLANS DE NOTRE';
    label.cfPdfTitle2 = 'DU PROJET';
    this.props.createLabelTranslate(label);
  }

  /** @return {JSX} */
  render() {
    const label = this.props.getLabelTranslate;
    let modalTitle = label.cfTitle + '<span class="color-green-secondary" id="nameOfProperty">' + this.props.project.projectName + ' - ' + this.props.property.projectPropertyName + '</span>';
    if (this.props.contactPdf) {
      modalTitle = label.cfPdfTitle + '<span class="color-green-secondary" id="nameOfProperty">' + this.props.property.projectPropertyName + '</span><br>' +
      label.cfPdfTitle2 + '<span class="color-green-secondary" id="nameOfProperty">' + this.props.project.projectName + '</span>';
    }
    return (
      <div className="form-contact-header">
        <p className="title"
          dangerouslySetInnerHTML={{__html: modalTitle}}
        />
        <p className="form-type-text project-detail hide">
          {label.cfVisiteAppartement}
          <span id="unit_code">{this.props.property.unitCode}</span>
        </p>
        <button
          onClick={()=>{
            this.closeModal();
            document.documentElement.style.overflow = 'auto';
          }
          }
          className="modal-absolute-react-close-button"
          data-modal-close="modal-contact-form-react"
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    );
  }
}

FormHeader.propTypes = {
  property: PropTypes.object,
  project: PropTypes.object,
  setModal: PropTypes.func,
  setContactForm: PropTypes.bool,
  closeModal: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  contactPdf: PropTypes.object,
  createLabelTranslate: PropTypes.func,
  onCloseContactModal: PropTypes.func,
};

const mapStateToProps = (state) =>{
  return state;
};

export default connect(mapStateToProps,
    {
      createLabelTranslate,
    },
)(FormHeader);
