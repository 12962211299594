import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import PropertyItem from '../Property/PropertyItem';
import InfiniteScroll from 'react-infinite-scroller';
import * as ExternalApi from '../../../services/ExternalApi';

/**
 * Class for detail page information.
 */
class ResultsTable extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      tableHeader: '',
      tableBody: '',
      trBody: '',
      classForFloor: '',
      classForRoom: '',
      hideFloor: false,
      hideRoom: false,
      projectProperties: [],
      hasMore: true,
      finishSearch: false,
      paramsProperties: {},
      templateCode: '',
      currentPage: 1,
    };
  }

  /**
   * get floor image details
   */
  async componentDidUpdate() {
    if (this.state.tableHeader == '' &&
    Object.keys(this.props.getProjectDetail).length != 0 &&
    Object.keys(this.props.getProjectPropertiesDetail).length != 0) {
      this.resultsTableHeader(this.props.getProjectDetail, this.props.getProjectPropertiesDetail);
    }
  }

  /**
   * @param {Object} props
   * @param {Object} state
   * @return {Object} state
   */
  static getDerivedStateFromProps(props, state) {
    const propertyType = {
      appartment: 1,
      maison: 2,
      terrain: 3,
      commercial: 4,
      bureau: 5,
    };
    state.currentPage= props.params.paging.currentPage;
    if (state.paramsProperties !== props.params) {
      state.hasMore = true;
      state.finishSearch = false;
    }
    if (Object.keys(props.params).length > 0) {
      state.paramsProperties = props.params;
    }
    if (Object.keys(props.getProjectDetail).length > 0) {
      state.projectProperties = props.getProjectPropertiesDetail.projectProperties;
    }
    if (state.projectProperties.length > 0) {
      if (state.projectProperties.filter(function(e) {
        return (e.projectPropertyType === propertyType.appartment || e.projectPropertyType === propertyType.commercial || e.projectPropertyType === propertyType.bureau);
      }).length > 0) {
        state.classForFloor= 'floor-header';
        state.classForRoom= 'room-header';
        state.hideFloor = false;
        state.hideRoom = false;
      } else {
        if (state.projectProperties.filter(function(e) {
          return e.projectPropertyType === propertyType.maison;
        }).length > 0) {
          state.classForRoom = 'room-header';
          state.classForFloor= 'floor-header hide';
          state.hideFloor = true;
          state.hideRoom = false;
        } else {
          state.classForFloor= 'floor-header hide';
          state.classForRoom= 'room-header hide';
          state.hideFloor = true;
          state.hideRoom = true;
        }
      }
    }
    if (state.projectProperties.length === 0 || state.projectProperties.length < 7) {
      state.hasMore = false;
    } else {
      state.hasMore = true;
    }
    if (state.finishSearch === true) {
      state.hasMore = false;
    }
    return state;
  }

  /**
   * @param {Number} page
   * @return {void} */
  loadMore(page) {
    const params = this.state.paramsProperties;
    const next= 1;
    let currentPage;

    // this step is to cover issue #316423
    if (this.props.params.paging.currentPage === undefined) {
      currentPage = 1 + next;
    } else {
      currentPage = this.props.params.paging.currentPage + next;
    }
    params.paging.currentPage = currentPage;
    ExternalApi.getProjectProperties(params).then((response)=>{
      const projectProperties = this.state.projectProperties;
      if (response.data.projectProperties && response.data.projectProperties.length > 0) {
        response.data.projectProperties.forEach((element) => {
          projectProperties.push(element);
        });
        this.setState({
          projectProperties: projectProperties,
          hasMore: true,
        });
      } else {
        this.setState({
          finishSearch: true,
          hasMore: false,
        });
      }
    });
  }

  /**
   * @param {*} detail getProjectDetail
   * @param {*} property getProjectPropertiesDetail
   * @return {void}
   */
  resultsTableHeader(detail, property) {
    // todo create banner
    const count =
    (property.count === undefined) ?
     detail.count : property.count;

    const avaibleProperties = (property.avaibleProperties === undefined) ?
    detail.avaibleProperties : property.avaibleProperties;

    const tableHeader =
      <React.Fragment>
        <div className="banner-promotional-detail banner-promotional-table col-12 p-0"></div>
        <h2>
          {count} résultats
          <span style={{fontSize: '18px'}}>
            ({avaibleProperties} biens disponibles sur {count})
          </span>
        </h2>
        <p className="color-red-primary" style={{marginLeft: 'auto'}}>
          * Prix TTC
        </p>
      </React.Fragment>;

    this.setState({
      tableHeader: tableHeader,
    });
  }

  /**
   * @param {*} detail getProjectDetail
   * @param {*} property getProjectPropertiesDetail
   * @return {void}
   */
  resultsTableHeaderDynamic(detail, property) {
    // todo create banner
    let count = 0;
    let avaibleProperties = 0;
    if (property !== undefined && property.avaibleProperties !== undefined) {
      count =
          (property.count === undefined) ?
              detail.count : property.count;

      avaibleProperties = (property === undefined) ?
          detail.avaibleProperties : property.avaibleProperties;
    }

    const tableHeader =
        <React.Fragment>
          <div className="banner-promotional-detail banner-promotional-table col-12 p-0"></div>
          <h2>
            {count} résultats
            <span style={{fontSize: '18px'}}>
            ({avaibleProperties} biens disponibles sur {count})
            </span>
          </h2>
          <p className="color-red-primary" style={{marginLeft: 'auto'}}>
            * Prix TTC
          </p>
        </React.Fragment>;

    return tableHeader;
  }


  /**
   * @params {void}
   * @return {void}
   */
  defineTypeOfProject() {
    const projectDetail= this.props.getProjectDetail;
    if (projectDetail.countOfGeneralizedPropertyTypes) {
      // const typeOfProjects= Object.keys(projectDetail.countOfGeneralizedPropertyTypes);
    }
  }

  /**
 *
 * @return {JSX.Element}
 */
  render() {
    const loader = <div className="loader-react">Loading ...</div>;
    const items = [];
    const itemsMobile = [];
    this.state.projectProperties.map((property, i) => {
      items.push(
          <PropertyItem key={i}
            property={property}
            hideFloor = {this.state.hideFloor}
            hideRoom = {this.state.hideRoom}
            mediaBanner= {this.props.getMediaBannerDataAll}
            templateCode={this.props.templateCode}
            project={this.props.project}/>,
      );
      itemsMobile.push(
          <PropertyItem key={i}
            property={property}
            hideFloor = {this.state.hideFloor}
            hideRoom = {this.state.hideRoom}
            mediaBanner= {this.props.getMediaBannerDataAll}
            project={this.props.project}
            templateCode={this.props.templateCode}
            mobile
          />,
      );
    });
    let surfaceLabel = 'Surface';
    let terainHeader = '';
    let luClass = '';
    const tableHeader = this.resultsTableHeaderDynamic(this.props.getProjectDetail, this.props.getProjectPropertiesDetail);
    if (this.props.templateCode == 'THOLU') {
      surfaceLabel = 'Habitable';
      terainHeader = <th className="terrain-header"><p>Terrain</p></th>;
      luClass = 'luxembourg';
    }
    return (
      <Fragment>
        {/* for desktop */}
        <div className="project-detail-table-result-container" id="table-page-detail">
          <div className="project-detail-table-result-header row"> {tableHeader}</div>
          <div className="project-detail-table-result-body">
            <table className={'table '+luClass}>
              <thead className="result-table-head">
                <tr>
                  <th className="unit-header">
                    <p>Unité</p>
                  </th>
                  <th className="description-header">
                    <p>Description</p>
                  </th>
                  <th className={this.state.classForRoom}>
                    <p>Chambre(s)</p>
                  </th>
                  <th className={this.state.classForFloor}>
                    <p>Etage</p>
                  </th>
                  <th className="area-header">
                    <p>{surfaceLabel}</p>
                  </th>
                  {terainHeader}
                  <th className="price-header">
                    <p>Prix<span>(TTC)</span></p>
                  </th>
                  <th className="acion-header">
                    <p></p>
                  </th>
                </tr>
              </thead>
              <tbody className="result-table-body" id="table-body">
                <InfiniteScroll
                  pageStart={this.state.currentPage}
                  initialLoad={false}
                  loadMore={this.loadMore.bind(this)}
                  hasMore={this.state.hasMore}
                  threshold={200}
                  loader={loader}
                  useWindow={false}>
                  {items}
                </InfiniteScroll>
                {(this.state.projectProperties && this.state.projectProperties.length === 0) && <><tr><td className="column-no-results"><p>Il n&#39;y a pas de résultat</p></td></tr></>}
              </tbody>
            </table>
          </div>
        </div>

        {/* for mobile */}
        <div className="project-detail-mobile-result">
          <InfiniteScroll
            pageStart={this.state.currentPage}
            initialLoad={false}
            loadMore={this.loadMore.bind(this)}
            hasMore={this.state.hasMore}
            loader={loader}
            element="ul"
            className="project-detail-mobile-result-list"
            useWindow={false}>
            {itemsMobile}
          </InfiniteScroll>
          {(this.state.projectProperties && this.state.projectProperties.length === 0) && (
            <p>Il n&#39;y a pas de résultat</p>
          )}

        </div>
      </Fragment>
    );
  }
}

ResultsTable.propTypes = {
  tableHeader: PropTypes.string,
  tableBody: PropTypes.string,
  getProjectDetail: PropTypes.object,
  getProjectPropertiesDetail: PropTypes.object,
  getMediaBannerDataAll: PropTypes.any,
  project: PropTypes.object,
  params: PropTypes.object,
  templateCode: PropTypes.string,
};

export default ResultsTable;
