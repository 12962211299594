import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../pages/action';
import {
  createContactInformation,
  createContactFormInformation,
  createContactData,
} from './action';
import {
  getCalendarAvailabelSlotbyMonthYear,
  getZipCodes,
  getCities,
  getPostalCodeDrupal,
  getZipCodeByCity,
  getCalendarAvailabelSlot,
  getCitiesForZipCode,
} from '../../services/ExternalApi';
import {submitContactForm} from '../../services/DrupalApi';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import SimpleReactValidator from 'simple-react-validator';
import DayPicker from 'react-day-picker';
import TagManager from 'react-gtm-module';
import {createContactDataLayer} from '../ContactForm/action';
import {PHONE_INFO_BY_COUNTRY_CODE} from '../../helpers/constants';

/**
 * Main Component
 */
class BasicContactForm extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          value: '',
          label: 'Choose',
        },
      ],
      termsCheckbox: false,
      showModal: false,
      contactId: null,
      hourOptions: [],
      minuteOptions: [],
      contactphase: 'openForm',
      disabledHour: true,
      disabledMinute: true,
      errorAppointment: false,
      showDatepicker: false,
      submitResult: {
        image: '',
        message: '',
        messageSecond: '',
      },
      defaultTel: '',
      defaultHour: null,
      defaultMinute: null,
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      city: '',
      cityId: '',
      postalcode: '',
      postalcodeId: '',
      hour: '',
      minute: '',
      dateAppointment: '',
      dateAppointmentFormated: '',
      message: '',
      cityValue: {
        value: '',
        label: '',
      },
      timeout: '',
      cptCode: '',
      project: {},
      arrTrackedEntity: ['TPBat', 'TPHome'],
      goal: false,
      mailbox: '',
      phoneInfo: PHONE_INFO_BY_COUNTRY_CODE.hasOwnProperty(props.templateConfig.country_code) ?
        PHONE_INFO_BY_COUNTRY_CODE[props.templateConfig.country_code]: PHONE_INFO_BY_COUNTRY_CODE['BE'],
      postalCodeCountryMessage: '',
    };

    this.setValidation();
  }

  /**
   * initialize validation for contactform
   * @return {void}
   */
  setValidation() {
    const self = this;
    const companyName = this.props.contactInformation.companyName;

    this.validator = new SimpleReactValidator({
      validators: {
        intlTelNumber: {
          message: 'Veuillez ajouter votre préfixe national. Ex.: ' + this.state.phoneInfo.format,
          rule: (val, params, validator) => {
            const numbers = val.split(/\d/).length - 1;
            return (
              8 <= numbers && numbers <= 20 &&
                validator.helpers.testRegex(val,
                    /^(\+){0,1}(\d|\s|\(|\)){8,20}$/)
            );
          },
        },
        gdpr: {
          message: 'Veuillez accepter notre politique de confidentialité',
          rule: (val) => {
            return (val === true);
          },
        },
        datenotnull: {
          message: 'Veuillez entrer la date du rendez-vous',
          rule: (val) => {
            return (val !== '' && val !== null);
          },
          required: true,
        },
        errorAppointment: {
          message:
              'Ce créneau n’est plus disponible. Veuillez en choisir un autre.',
          rule: (val) => {
            return (val === false);
          },
        },
        letterWithAccents: {
          message: 'The prénom may only contain letters.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[A-Za-zÀ-ÖØ-öø-ÿ\s-.]+$/) && params.indexOf(val) === -1;
          },
          required: true,
        },
        zipNoResult: {
          message: 'Le code postal entré est invalide.',
          rule: (val, params, validator) => {
            if (val !== '') {
              if (self.props.getContactFormInformation.cityOptions !== undefined) {
                if (self.props.getContactFormInformation.cityOptions.length === 0) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return false;
              }
            }
          },
          required: true,
        },
        localite: {
          message: 'Le code postal entré est invalide.',
          rule: (val, params, validator) => {
            if (val === '' && (companyName !== 'Thomas & Piron BATIMENT' || companyName !== 'Thomas & Piron HOME')) {
              return false;
            }
          },
        },
      },
      messages: {
        required: 'Veuillez renseigner votre :attribute',
        lastname: 'Veuillez renseigner votre Nom',
        email: 'Veuillez renseigner votre adresse email',
        localite: 'Le code postal entré est invalide',
      },
    });
  }

  /**
   * @return {void}
   */
  async componentDidMount() {
    this.props.createContactInformation(this.props);
    const label = this.props.getLabelTranslate;
    label.cfTitle = 'VOUS SOUHAITEZ NOUS CONTACTER À PROPOS DU PROJET';
    label.cfSubtitle = 'RENCONTRONS-NOUS SUR PLACE DANS NOTRE';
    label.cfSubtitleShowHouse = 'RENCONTRONS-NOUS DANS LA MAISON EXPO DE';
    label.formFirstName = 'Prénom';
    label.phFirstName = 'Votre prénom';
    label.formLastName = 'Nom';
    label.phLastName = 'Votre nom';
    label.formEmail = 'E-mail';
    label.phEmail = 'E-mail';
    label.formPhone = 'Téléphone';
    label.phPhone = 'Ex.:' + this.state.phoneInfo.format;
    label.formStreet = 'Nom de rue';
    label.phStreet = 'Votre nom de rue';
    label.formStreetNumber = 'Numéro de rue';
    label.phStreetNumber = 'Votre numéro de rue';
    label.formCodePostal = 'Code postal';
    label.phCodePostal = 'Votre code postal';
    label.formCity = 'Localité';
    label.phCity = 'Votre localité';
    label.phDatepicker = 'Choisissez un jour';
    label.mailBox = 'Boîte postale';
    label.phMailBox = 'Votre boîte postale';
    label.formTermSentence = 'POLITIQUE DE CONFIDENTIALITÉ (POUR EN\n' +
        ' SAVOIR PLUS VOYEZ NOTRE\n' +
        ' <a\n' +
        ' href="https://www.thomas-piron.eu/politique-de-confidentialite"\n' +
        ' target="_blank">DÉCLARATION DE CONFIDENTIALITÉ</a>)';
    label.formTermLabel = 'J\'ACCEPTE VOTRE POLITIQUE DE\n' +
        ' CONFIDENTIALITÉ';
    label.formNotes1 = 'Vous pourrez toujours modifier\n' +
        ' votre consentement en nous\n' +
        ' contactant sur l\'adresse privacy@thomas-piron.eu.';
    label.formNotes2 = '* champ obligatoire';
    label.formSubmitBtn = 'Envoyer';
    label.closeModalBtn = 'Fermer';
    label.labelDatepicker = 'CHOISISSEZ LE JOUR';
    label.labelTimepicker = 'CHOISISSEZ L’HEURE';
    label.appointmentLabel = 'Choisissez un de nos ' +
        'créneaux encore disponibles:';
    this.props.createLabelTranslate(label);
    this.setState({
      projectId: this.props.getProjectDetail.projectDetails?
      this.props.getProjectDetail.projectDetails.projectId : null,
      projectName: this.props.getProjectDetail.projectDetails?
      this.props.getProjectDetail.projectDetails.projectName: null,
      sociCode: this.props.getProjectDetail.projectDetails?
      this.props.getProjectDetail.projectDetails.sociCode: null,
      country: 'Belgique',
      countryId: 20,
    });
  }

  /**
   * @return {void}
   * @param {*} prevProps
   * @param {*} prevState
   */
  async componentDidUpdate(prevProps, prevState) {
    // when contactId changed contactform will load appointment data
    if (this.props.getContactFormInformation.contactId !==
        this.state.contactId) {
      this.setState({
        projectId: this.props.getContactFormInformation.projectId,
        contactId: this.props.getContactFormInformation.contactId,
        purpose: this.props.getContactFormInformation.purpose,
        projectName: this.props.getContactFormInformation.projectName,
        sociCode: this.props.getContactFormInformation.sociCode,
      });
    }
    if (this.state.showModal !==
        this.props.getContactFormInformation.showModal) {
      this.setState({
        showModal: this.props.getContactFormInformation.showModal,
      });
      if (this.props.getContactFormInformation.showModal) {
        if (this.props.getContactFormInformation.contactId !== undefined) {
          await this.getAppointmentSlots();
        }
      }
    }
  }

  /**
   * @param {Object} props
   * @param {Object} state
   * @return {Object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.projectId = props.getContactFormInformation.projectId;
    state.contactId = props.getContactFormInformation.contactId;
    state.purpose = props.getContactFormInformation.purpose;
    state.projectName = props.getContactFormInformation.projectName;
    state.sociCode = props.getContactFormInformation.sociCode;
    state.cptCode = props.propertyModal.property.cptCode;
    state.project = props.project != undefined ? props.project : props.contactInformation;

    return state;
  }

  /**
   * get available slots for appointment
   * @return {void}
   */
  async getAppointmentSlots() {
    this.setState({
      hour: null,
      minute: null,
      dateAppointment: null,
      hourOptions: [],
      minuteOptions: [],
      disabledHour: true,
      disabledMinute: true,
    });
    const newDate = new Date((new Date()).valueOf() + 1000*3600*24);
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const availableSlot = await getCalendarAvailabelSlot({
      id: this.props.getContactFormInformation?.contactId,
    });
    this.props.getContactFormInformation.showAppointment = true;
    if (!availableSlot.data.success || availableSlot.data.firstAvailableSlot.date === null) {
      this.props.getContactFormInformation.showAppointment = false;
    }
    let res = await getCalendarAvailabelSlotbyMonthYear(
        {
          id: this.props.getContactFormInformation.contactId,
          month: month,
          year: year,
        },
    );
    res = res.data;
    if (res.success) {
      const dateTimeData = [];
      res.availableDays.forEach(function(item, index) {
        if (item !== undefined && item !== null) {
          const rawDate = item.date.split('.');
          const idx = rawDate[0] + '/' + rawDate[1] + '/' + rawDate[2];
          if ( dateTimeData[idx] == undefined ) {
            dateTimeData[idx] = [];
          }
          // fill the time into array date
          item.slots.forEach(function(time, idxtime) {
            if ( time !== undefined ) {
              const explodeTime = time.time.split(':');
              const hour = parseInt(explodeTime[0]);
              if ( dateTimeData[idx][hour] == undefined ) {
                dateTimeData[idx][hour] = [];
              }
              dateTimeData[idx][hour].push(explodeTime[1]);
            }
          });
        }
      });
      if (Object.keys(dateTimeData).length > 0) {
        this.props.getContactFormInformation.slots = dateTimeData;
      } else {
        this.props.getContactFormInformation.slots = dateTimeData;
      }
    } else {
      this.props.getContactFormInformation.slots = [];
    }
    this.props.createContactInformation(
        this.props.getContactFormInformation,
    );
  }

  /**
   * @return {void}
   * @param {*} date
   */
  async changeMonth(date) {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (year > currentYear || (year === currentYear && month > currentMonth)) {
      await this.getAppointmentSlotsbyMonth(month, year);
    }
  }

  /**
   * get available slots for appointment with param,
   * @return {void}
   * @param {int} month
   * @param {int} year
   */
  async getAppointmentSlotsbyMonth(month, year) {
    this.setState({
      hour: null,
      minute: null,
      dateAppointment: null,
      hourOptions: [],
      minuteOptions: [],
      disabledHour: true,
      disabledMinute: true,
    });
    const availableSlot = await getCalendarAvailabelSlot({
      id: this.props.getContactFormInformation?.contactId,
    });
    this.props.getContactFormInformation.showAppointment = true;
    if (!availableSlot.data.success || availableSlot.data.firstAvailableSlot.date === null) {
      this.props.getContactFormInformation.showAppointment = false;
    }
    let res = await getCalendarAvailabelSlotbyMonthYear(
        {
          id: this.props.getContactFormInformation.contactId,
          month: month,
          year: year,
        },
    );
    res = res.data;
    if (res.success) {
      const dateTimeData = this.props.getContactFormInformation.slots;
      res.availableDays.forEach(function(item, index) {
        if (item !== undefined && item !== null) {
          const rawDate = item.date.split('.');
          const idx = rawDate[0] + '/' + rawDate[1] + '/' + rawDate[2];
          if ( dateTimeData[idx] == undefined ) {
            dateTimeData[idx] = [];
          }
          // fill the time into array date
          item.slots.forEach(function(time, idxtime) {
            if ( time !== undefined ) {
              const explodeTime = time.time.split(':');
              const hour = parseInt(explodeTime[0]);
              if ( dateTimeData[idx][hour] == undefined ) {
                dateTimeData[idx][hour] = [];
              }
              dateTimeData[idx][hour].push(explodeTime[1]);
            }
          });
        }
      });
      this.props.getContactFormInformation.slots = dateTimeData;
      this.props.createContactInformation(
          this.props.getContactFormInformation,
      );
    }
  }

  /**
   *
   * @return {void};
   */
  closeModal() {
    this.props.getContactFormInformation.showModal = false;
    this.props.getContactFormInformation.staticSelect = false;
    this.props.getContactFormInformation.postalCode = '';
    this.props.getContactFormInformation.idpostalCode = '';
    this.props.getContactFormInformation.cityOptions = [];
    this.props.getContactFormInformation.showAppointment = false;
    this.props.getContactFormInformation.slots = [];
    this.props.createContactInformation(this.props.getContactFormInformation);
    this.resetForm('openForm');
  }

  /**
   *
   * @param {string} statusForm;
   * @return {void};
   */
  resetForm(statusForm) {
    this.setState({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      contactphase: statusForm,
      city: '',
      cityId: '',
      postalcode: '',
      postalcodeId: '',
      hour: '',
      minute: '',
      dateAppointment: '',
      hourOptions: [],
      minuteOptions: [],
      disabledHour: true,
      disabledMinute: true,
      termsCheckbox: false,
      showDatepicker: false,
      dateAppointmentFormated: '',
      message: '',
      cityValue: {
        value: '',
        label: '',
      },
      defaultHour: null,
      defaultMinute: null,
      cptCode: '',
      mailBox: '',
      postalCodeCountryMessage: '',
    });
    this.validator.hideMessages();
    this.forceUpdate();
    document.getElementById('popupProjectDetailContactForm').reset();
  }

  /**
   * @return {void}
   */
  createDatalayer() {
    if (this.state.arrTrackedEntity.indexOf(this.props.contactInformation.entity) !== -1 ) {
      const contactDataLayer = this.props.createContactDataLayer(this.state);
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM,
        dataLayer: contactDataLayer.payload,
      });
    }
  }
  /**
   *
   * @return {void};
   */
  async submitForm() {
    if (this.validator.allValid()) {
      const formOdoo = this.props.createContactData(this.state);
      this.createDatalayer();
      let contactphase = 'loading';
      let errorAppointment = false;
      this.setState({
        contactphase,
      });
      const urlNew = formOdoo.payload.propertyData.url.split( '?' );
      formOdoo.payload.propertyData.url = urlNew[0];
      let res = await submitContactForm(
          {
            data: formOdoo.payload,
            country: this.props.templateConfig.country,
          },
      );
      res = res.data;
      let image = 'tp-icons-newsletter-new-success.svg';
      let message = 'Votre demande a bien été envoyée';
      let messageSecond = 'Nous vous contacterons dans les plus brefs délais';

      contactphase = 'showResult';
      if (!res.success) {
        image = 'tp-icons-newsletter-new-fail.svg';
        message = 'Notre site internet rencontre un problème.';
        messageSecond = 'Un email vous sera néanmoins envoyé ' +
            'pour confirmer votre rendez-vous.';
        res.processResults.forEach(function(item) {
          if (item.process === 'CreateAppointment' && item.result === 'Error') {
            contactphase = 'openForm';
            errorAppointment = true;
          }
        });
        this.validator.showMessages();
      } else {
        this.resetForm('loading');
      }
      await this.setState({
        submitResult: {
          image,
          message,
          messageSecond,
        },
        contactphase,
        errorAppointment,
      });
      if (errorAppointment) {
        await this.getAppointmentSlots();
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @return {void};
   * @param {*} values;
   */
  async handleInputChange(values) {
    const inputValue = document.getElementsByName('your-locality')[0].value;
    if (inputValue) {
      let res = await getZipCodes(
          {id: inputValue},
      );
      res = res.data;
      if (res.success) {
        this.props.getContactFormInformation.postalCode = res.hits[0].name;
        this.props.getContactFormInformation.idpostalCode = res.hits[0].id;
        this.props.createContactInformation(
            this.props.getContactFormInformation,
        );
        this.setState({
          postalcode: res.hits[0].name,
          postalcodeId: res.hits[0].id,
          city: inputValue,
          cityId: inputValue,
        });
      }
    }
  };

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @return {array};
   *
   * @param {string} input
   * @param {*} callback
   */
  async loadOptions(input, callback) {
    let defaultOption = this.state.options;
    if (input.length > 2) {
      let res = await getCities(
          {name: input},
      );
      res = res.data;
      defaultOption = res.hits.map(function(item) {
        return {
          value: item.id,
          label: item.name,
        };
      });
    } else {
      if (this.props.getContactFormInformation.postalCode) {
        const res = await getCities(
            {id: this.props.getContactFormInformation.postalCode},
        );
        defaultOption = res.hits.map(function(item) {
          return {
            value: item.id,
            label: item.name,
          };
        });
      }
    }
    return defaultOption;
  }

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @return {void};
   * @param {*} e
   */
  async changeOptions(e) {
    const input = e.target.value;
    this.setState({
      postalcode: input,
    });
    this.props.getContactFormInformation.staticSelect = false;
    const minLength = 3;
    if (input.length > minLength) {
      this.props.getContactFormInformation.loadingOptions = true;
      this.props.createContactInformation(
          this.props.getContactFormInformation,
      );
      let resCities = await getCitiesForZipCode(
          {id: input},
      );
      //  reset form zip code
      this.setState({
        postalcode: null,
        postalcodeId: null,
        city: null,
        cityId: null,
      });
      if (resCities.data.success) {
        resCities = resCities.data.hits;
        const formatZipcode = resCities.map(function(item) {
          return {
            value: item.id,
            label: item.name,
          };
        });
        if (resCities.length > 0) {
          this.props.getContactFormInformation.idpostalCode =
              resCities[0].zipCode.id;
          this.props.getContactFormInformation.staticSelect = true;
          this.props.getContactFormInformation.cityOptions = formatZipcode;
          this.props.getContactFormInformation.loadingOptions = false;
          this.setState({
            postalCodeCountryMessage: '',
            postalcode: input,
            postalcodeId: resCities[0].zipCode.id,
            city: formatZipcode[0].label,
            cityId: formatZipcode[0].value,
            cityValue: formatZipcode[0],
          });
        } else {
          this.props.getContactFormInformation.cityOptions = [];
          this.setState({
            postalCodeCountryMessage: this.props.templateConfig.country_code === 'LU' ? 'Actuellement, seuls les codes postaux du Luxembourg peuvent être saisis. Si vous avez besoin d\'aide, veuillez contacter directement Thomas-Piron en utilisant les coordonnées trouvées en bas de la page.': '',
            postalcode: null,
            postalcodeId: null,
            city: null,
            cityId: null,
            cityValue: {
              value: '',
              label: '',
            },
          });
        }
      }
    } else {
      this.props.getContactFormInformation.cityOptions = [];
      this.setState({
        postalCodeCountryMessage: '',
        postalcode: null,
        postalcodeId: null,
        city: null,
        cityId: null,
        cityValue: {
          value: '',
          label: '',
        },
      });
    }
    this.props.createContactInformation(
        this.props.getContactFormInformation,
    );
  }

  /**
   *
   * @param {*} value
   */
  dateChange(value) {
    const selectedYear = '' + value.getFullYear();
    const selectedMonth = '' + (value.getMonth()+1);
    const selectedDay = '' + value.getDate();
    const selectedDate = selectedDay.padStart(2, '0') + '/' +
        selectedMonth.padStart(2, '0') + '/' + selectedYear;
    const hourOptions = [];
    hourOptions.push({
      value: '--',
      label: '--',
    });
    if (
      this.props.getContactFormInformation.slots[selectedDate] !== undefined
    ) {
      this.props.getContactFormInformation.slots[
          selectedDate].forEach(function(item, key) {
        hourOptions.push({
          value: key,
          label: key,
        });
      });
      this.setState({
        hourOptions,
        dateAppointment: value,
        dateAppointmentFormated: selectedDate,
        disabledHour: false,
        disabledMinute: false,
        errorAppointment: false,
        showDatepicker: false,
        hour: null,
        minute: null,
      });
    } else {
      this.validator.showMessages();
    }
  }

  /**
   *
   * @param {*} e
   */
  changeStaticOptions(e) {
    this.setState({
      city: e.label,
      cityId: e.value,
      cityValue: e,
    });
    this.validatePostalCode(e.value);
  }

  /**
   * @return {void};
   * @param {*} e
   */
  changePostalCode(e) {
    const self = this;
    const promise = new Promise( (resolve) => {
      clearTimeout(this.state.timeout);
      this.setState({
        timeout: setTimeout(() => {
          resolve(self.getPostalCode(e));
        }, 1000),
      });
    });
    return promise;
  }

  /**
   *
   * @param {*} e
   */
  async getPostalCode(e) {
    const minLength = 3;
    if (e.length < minLength) {
      return;
    }
    if (this.state.postalcode!==null && this.state.postalcode===[]) {
      return;
    }
    const dt = await getPostalCodeDrupal(e);
    const formatZipcode = dt.data.map(function(item) {
      return {
        value: item.id,
        label: item.text,
      };
    });
    if (dt.data.length > 0) {
      return formatZipcode;
    } else {
      return [];
    }
  }

  /**
  *
  * @param {*} e
  */
  async validatePostalCode(e) {
    const dt = await getZipCodeByCity(e);
    if (dt.data.data.name !== undefined) {
      if (this.state.postalcode !== dt.data.data.name) {
        this.setState({
          postalcode: dt.data.data.name,
        });
      }
    }
  }

  /**
   *
   * @param {*} e
   */
  setFirstname(e) {
    this.setState({
      firstname: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setLastname(e) {
    this.setState({
      lastname: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setPhone(e) {
    this.setState({
      defaultTel: e.target.value,
      phone: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setDefaultValuePhone(e) {
    if (e.target.value === '') {
      this.setState({
        defaultTel: this.state.phoneInfo.code,
        phone: this.state.phoneInfo.code,
      });
    }
  }

  /**
   *
   * @param {*} e
   */
  setStreet(e) {
    this.setState({
      street: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setStreetNumber(e) {
    this.setState({
      streetNumber: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setMailBox(e) {
    this.setState({
      mailbox: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setTextMessage(e) {
    this.setState({
      message: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  async setPostalCode(e) {
    this.setState({
      postalcode: e.target.value,
    });
    this.changeOptions(e);
  }

  /**
   *
   * @param {*} e
   */
  setLocality(e) {
    this.setState({
      city: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setTermsCheckbox(e) {
    this.setState({
      termsCheckbox: e.target.checked,
    });
  }

  /**
   *
   * @param {*} value
   */
  setHour(value) {
    const dateSlots = this.props.getContactFormInformation.slots;
    const hour = document.getElementsByName('appointment-hours')[0].value;
    const selectedYear = '' + this.state.dateAppointment.getFullYear();
    const selectedMonth = '' + (this.state.dateAppointment.getMonth()+1);
    const selectedDay = '' + this.state.dateAppointment.getDate();
    const selectedDate = selectedDay.padStart(2, '0') + '/' +
        selectedMonth.padStart(2, '0') + '/' + selectedYear;
    const selectedHour = dateSlots[selectedDate][hour];
    if (selectedHour !== undefined) {
      const minuteOptions = selectedHour.map(function(item) {
        return {
          value: item,
          label: item,
        };
      });
      this.setState({
        disabledMinute: false,
        hour,
        minuteOptions,
      });
    }
  }

  /**
   *
   * @param {*} value
   */
  setMinute(value) {
    const minute = document.getElementsByName('appointment-minutes')[0].value;
    this.setState({
      minute: minute,
    });
  }

  /**
   *
   * @param {*} e
   */
  setGoal(e) {
    this.setState({
      goal: e.target.value === '0' ? false : true,
    });
  }

  /**
   *
   * @param {*} day
   * @return {boolean}
   */
  slotsAppointment(day) {
    if (this.props.getContactFormInformation.slots!== undefined ) {
      const selectedYear = '' + day.getFullYear();
      const selectedMonth = '' + (day.getMonth()+1);
      const selectedDay = '' + day.getDate();
      const selectedDate = selectedDay.padStart(2, '0') + '/' +
          selectedMonth.padStart(2, '0') + '/' + selectedYear;
      return (
        this.props.getContactFormInformation.slots[selectedDate] !== undefined
      );
    }
  }

  /**
   * @return {void}
   */
  openDatepicker() {
    this.setState({
      showDatepicker: !this.state.showDatepicker,
      hour: '',
      defaultHour: null,
      defaultMinute: null,
    });
  }

  /**
   * @param {*} defaultHour
   */
  handleChangeHour(defaultHour) {
    this.setState({defaultHour});
  };

  /**
   * @param {*} defaultMinute
   */
  handleChangeMinute(defaultMinute) {
    this.setState({defaultMinute});
  };

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    this.validator.purgeFields();
    const styleModal = {
      display: (this.props.getContactFormInformation.showModal) ?
          'block' : 'none',
    };
    const styleAppointment = {
      display: (this.props.getContactFormInformation.showAppointment) ?
          'block' : 'none',
    };
    const styleDatepicker = {
      display: (this.state.showDatepicker) ?
          'block' : 'none',
    };
    const postalCode = this.props.getContactFormInformation.postalCode;
    const label = this.props.getLabelTranslate;

    let zipcodeSelect = '';
    let subtitleInfo = '';
    let timeClass= '';
    if (this.state.disabledHour) {
      timeClass = '';
    } else {
      timeClass = 'event-form';
    }
    const hourSelect = <Select
      id="selectInputHour"
      className= "select-input-time"
      classNamePrefix="select-input-time"
      name="appointment-hours"
      placeholder="--"
      options={this.state.hourOptions}
      onInputChange={this.setHour.bind(this)}
      onChange={this.handleChangeHour.bind(this)}
      isDisabled={this.state.disabledHour}
      value={this.state.defaultHour}
    />;
    const minuteSelect = <Select
      id="selectInputMinute"
      className= "select-input-time"
      classNamePrefix="select-input-time"
      name="appointment-minutes"
      placeholder="--"
      options={this.state.minuteOptions}
      onInputChange={this.setMinute.bind(this)}
      onChange={this.handleChangeMinute.bind(this)}
      isDisabled={this.state.disabledMinute}
      value={this.state.defaultMinute}
    />;
    if (this.props.getContactFormInformation.staticSelect) {
      zipcodeSelect = <Select
        type="text"
        id="selectLocality"
        name="your-locality"
        options={this.props.getContactFormInformation.cityOptions}
        onChange={this.changeStaticOptions.bind(this)}
        value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
        noOptionsMessage={() => 'Indiquez votre localité'}
        placeholder={label.phCity}
      />;
    } else {
      zipcodeSelect = <AsyncSelect
        type="text"
        id="selectLocality required"
        name="your-locality"
        defaultOptions={this.props.getContactFormInformation.cityOptions}
        onChange={this.changeStaticOptions.bind(this)}
        loadOptions={this.changePostalCode.bind(this)}
        value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
        cacheOptions
        noOptionsMessage={() => 'Indiquez votre localité'}
        placeholder={label.phCity}
      />;
    }
    let phaseFormClass = 'hide';
    let phaseLoadingClass = 'hide';
    let phaseResultClass = 'hide';
    switch (this.state.contactphase) {
      case 'openForm':
        phaseFormClass = '';
        break;
      case 'loading':
        phaseLoadingClass = '';
        break;
      case 'showResult':
        phaseResultClass = '';
        break;
      default:
        phaseFormClass = '';
        break;
    }
    if (this.props.getContactFormInformation.pointContactType) {
      subtitleInfo = label.cfSubtitle +
          '<span class="subtitle" id="titleContactType">' +
        this.props.getContactFormInformation.pointContactType +
          '</span>';
      if (this.props.getContactFormInformation.pointContactType == 'Maison expo') {
        if (this.props.getContactFormInformation.showhouseName !== undefined) {
          subtitleInfo = label.cfSubtitleShowHouse +
              '<span class="subtitle" id="titleContactType">' +
              this.props.getContactFormInformation.showhouseName +
              '</span>';
        } else {
          subtitleInfo = label.cfSubtitleShowHouse +
              '<span class="subtitle" id="titleContactType">' +
              this.props.getContactFormInformation.pointContactType +
              '</span>';
        }
      }
    }
    if (this.props.getContactFormInformation.isAcheter && this.props.getContactFormInformation.pointContactType) {
      subtitleInfo = this.props.getContactFormInformation.pointContactType +
          ' DU PROJET :' +
          '<span class="subtitle" id="titleContactType">' +
        this.props.getContactFormInformation.projectName +
          '</span>';
    }
    const tomorrow = new Date(
        ( new Date()).valueOf() + 1000*3600*24,
    );
    const nextYear = new Date((
      new Date()).valueOf() + 1000*3600*24*365,
    );
    //  if companyName is Thomas & Piron BATIMENT or Thomas & Piron HOME localite and postal code no need to required
    const checkCompanyNameTpHome = 'thomas & piron home';
    const checkCompanyNameTpBatiment = 'thomas & piron batiment';
    const companyName = this.props.contactInformation.companyName;
    const validationSpan = <span className="color-green-secondary">*</span>;
    let postalCodeValidation = '|required';
    let telephoneValidation = '|required';
    let localiteValidation = '|required';
    if (companyName !== undefined&&
      (companyName.toLowerCase() === checkCompanyNameTpHome||
      companyName.toLowerCase() === checkCompanyNameTpBatiment) &&
      (this.props.templateConfig.template != 'THOFR')) {
      postalCodeValidation = '';
      localiteValidation = '';
      if (this.props.getContactFormInformation.contactUs) {
        telephoneValidation = '';
      }
    }
    let entity = '';
    if (this.props.contactInformation.entity) {
      entity = this.props.contactInformation.entity.toLowerCase();
    }
    return (
      <div className="modal-container-absolute-react hide form-contact-popup"
        id="modal-contact-form-react"
        style={styleModal}
      >
        <div className="modal-box">
          <div className="modal-body">
            <div className="form-contact-container margin-0 w-100">
              <div className="form-contact-header">
                <p className="title">
                  <span id="titleTopModal">
                    {label.cfTitle}
                  </span>
                  <span className="color-green-secondary"
                    id="nameOfProperty"
                  >
                    {this.props.contactInformation.projectName}
                  </span>
                </p>
                <p className="form-type-text project-detail hide">
                  Visiter un appartement témoin
                  <span id="unit_code"></span>
                </p>
                <button className="modal-absolute-react-close-button"
                  data-modal-close="modal-contact-form-react"
                  onClick={this.closeModal.bind(this)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className={'form-contact-body ' + phaseFormClass}>
                <div className="column-2 flex-100">
                  {((!this.props.getContactFormInformation.contactUs && !this.props.getContactFormInformation.isAcheter)||
                  (this.props.getContactFormInformation.contactUs && this.props.getContactFormInformation.isAcheter)) && (
                    <div className="form-title">
                      <span id="modalTextTitle"
                        dangerouslySetInnerHTML={{__html: subtitleInfo}}
                      />
                    </div>
                  )}
                  <form id="popupProjectDetailContactForm"
                    type="post"
                  >
                    <input type="hidden"
                      id="project_type"
                      name="project_type"
                      value=
                        {this.props.getContactFormInformation.pointContactType}
                    />
                    <div className="form-container">
                      <div className="form-group">
                        <input type="hidden" name="office" value="39" />
                        <label htmlFor="firstNameInput_appointment">
                          {label.formFirstName}
                          <span className="color-green-secondary">*</span>
                        </label>
                        <input type="text"
                          name="firstname"
                          className="form-control"
                          placeholder={label.phFirstName}
                          id="firstNameInput_appointment"
                          value={this.state.firstname}
                          onChange={this.setFirstname.bind(this)}
                        />
                        {this.validator.message(
                            'Prénom', this.state.firstname,
                            'required|letterWithAccents',
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="lastNameInput_appointment">
                          {label.formLastName}
                          <span className="color-green-secondary">*</span>
                        </label>
                        <input type="text"
                          name="lastname"
                          className="form-control"
                          placeholder={label.phLastName}
                          id="lastNameInput_appointment"
                          value={this.state.lastname}
                          onChange={this.setLastname.bind(this)}
                        />
                        {this.validator.message(
                            'Nom', this.state.lastname,
                            'required|letterWithAccents',
                        )}
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <label htmlFor="emailInput_appointment">
                          {label.formEmail}
                          <span className="color-green-secondary">*</span>
                        </label>
                        <input type="email"
                          className="form-control"
                          placeholder={label.phEmail}
                          id="emailInput_appointment"
                          name="email"
                          value={this.state.email}
                          onChange={this.setEmail.bind(this)}
                        />
                        {this.validator.message(
                            'adresse email', this.state.email,
                            'required|email',
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="telephoneInput">
                          {label.formPhone}
                          {(telephoneValidation === '|required') && validationSpan}
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder={label.phPhone}
                          id="telephoneInput"
                          name="phone"
                          onChange={this.setPhone.bind(this)}
                          onClick={this.setDefaultValuePhone.bind(this)}
                          value={this.state.phone}
                          defaultValue={this.state.defaultTel}
                        />
                        {this.validator.message(
                            'numéro de téléphone', this.state.phone,
                            'intlTelNumber'+telephoneValidation,
                        )}
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <label htmlFor="streetInput">
                          {label.formStreet}
                        </label>
                        <input type="text"
                          name="street"
                          className="form-control"
                          placeholder={label.phStreet}
                          id="streetInput"
                          onChange={this.setStreet.bind(this)}
                        />
                      </div>
                      {entity === 'tpbat' && (
                        <div className="form-container divide">
                          <div className="form-group">
                            <label htmlFor="streetNumberInput">
                              {label.formStreetNumber}
                            </label>
                            <input type="text"
                              name="street-number"
                              className="form-control"
                              placeholder={label.phStreetNumber}
                              id="streetNumberInput"
                              onChange={this.setStreetNumber.bind(this)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="mailBox">
                              {label.mailBox}
                            </label>
                            <input type="text"
                              name="mail-box"
                              className="form-control"
                              placeholder={label.phMailBox}
                              id="mailBox"
                              onChange={this.setMailBox.bind(this)}
                            />
                          </div>
                        </div>
                      )}
                      {entity != 'tpbat' && (
                        <div className="form-group">
                          <label htmlFor="streetNumberInput">
                            {label.formStreetNumber}
                          </label>
                          <input type="text"
                            name="street-number"
                            className="form-control"
                            placeholder={label.phStreetNumber}
                            id="streetNumberInput"
                            onChange={this.setStreetNumber.bind(this)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <label htmlFor="yourPostalCode">
                          {label.formCodePostal}
                          {(postalCodeValidation === '|required') && validationSpan}
                        </label>
                        <input type="text"
                          className="form-control"
                          placeholder={label.phCodePostal}
                          id="yourPostalCode"
                          name="your-postal-code"
                          defaultValue={postalCode}
                          value={this.state.postalcode}
                          onChange={this.setPostalCode.bind(this)}
                        />
                        {this.validator.message(
                            'codepostal', this.state.postalcode,
                            'zipNoResult'+postalCodeValidation,
                        )}
                        {this.state.postalCodeCountryMessage && (
                          <div className="srv-validation-message-custom">
                            {this.state.postalCodeCountryMessage}
                          </div>
                        )}
                      </div>
                      {/*
                        TODO: uncomment if need select on city selection
                        FIXME: if not using select anymore please delete
                      */}
                      <div className="form-group">
                        <label htmlFor="selectLocality">
                          {label.formCity}
                          {(localiteValidation === '|required') && validationSpan}
                        </label>
                        <div className="select-container
                        select-locality-container"
                        >
                          {zipcodeSelect}
                          {this.validator.message(
                              'localité', this.state.cityId,
                              'localite'+localiteValidation,
                          )}
                        </div>
                      </div>
                    </div>

                    {entity === 'tpbat' && (
                      <div className='form-container'>
                        <div className="form-group">
                          <label htmlFor="appartmentGoal">
                            Je cherche un appartement
                          </label>
                          <div className="form-group-radio">
                            <input type="radio" checked={this.state.goal === false} name="appartmentGoal" id="radio1" onChange={this.setGoal.bind(this)} value="0" ></input>
                            <label htmlFor="radio1"><span>Pour y vivre</span></label>
                            <input type="radio" checked={this.state.goal === true} name="appartmentGoal" id="radio2" onChange={this.setGoal.bind(this)} value="1" ></input>
                            <label htmlFor="radio2"><span>Pour y investir</span></label>
                          </div>
                        </div>
                      </div>
                    )}

                    {!this.props.getContactFormInformation.isAcheter && (
                      <div id="messageField">
                        <div className="form-container">
                          <div className="form-group form-full">
                            <label htmlFor="messageTextArea">Message</label>
                            <textarea name="message" id="messageTextArea" className="form-control" onChange={this.setTextMessage.bind(this)}></textarea>
                          </div>
                        </div>
                      </div>
                    )}

                    {!this.props.getContactFormInformation.contactUs && this.props.getContactFormInformation.showAppointment && (
                      <div
                        className="available-slot-container"
                        style= {styleAppointment}
                      >
                        <div className="form-container calendar-title">
                          <div className="form-group form-full">
                            <h4 className="form-calendar-title">
                              {label.appointmentLabel}
                            </h4>
                            <h4 className="error" id="error-apointment"></h4>
                          </div>
                        </div>
                        <div className="form-container">
                          <div className="form-group calendar">
                            <label htmlFor="contactCalendarInput">
                              {label.labelDatepicker}
                            </label>
                            <div className="input-container">
                              <input type="text"
                                className={`form-control event-input ${this.state.showDatepicker ? 'is-show' : ''}`}
                                readOnly
                                name="appointment-date"
                                id="contactCalendarInput"
                                placeholder="Choisissez un jour"
                                onClick={this.openDatepicker.bind(this)}
                                value={this.state.dateAppointmentFormated}
                              />
                            </div>
                            <div id="datepicker"
                              className="hasDatepicker"
                              style={styleDatepicker}
                            >
                              <div className="datepicker__wrap event-input">
                                <DayPicker
                                  onDayClick={this.dateChange.bind(this)}
                                  disabledDays={{
                                    before: tomorrow,
                                    after: nextYear,
                                  }}
                                  modifiers={ {
                                    disabled: {daysOfWeek: [0]},
                                    selected: this.slotsAppointment.bind(this),
                                    highlighted: new Date(2020, 9, 19),
                                  }}
                                  onMonthChange = {this.changeMonth.bind(this)}
                                  renderDay={(day) => {
                                    const date = day.getDate();
                                    return <div className="datepicker__date">
                                      {date}
                                    </div>;
                                  }}
                                />
                              </div>
                            </div>
                            {this.validator.message(
                                'dateAppointment', this.state.dateAppointment,
                                'datenotnull',
                            )}
                            {this.validator.message(
                                'dateAppointment', this.state.errorAppointment,
                                'errorAppointment',
                            )}
                          </div>
                          <div className="form-group calendar">
                            <label htmlFor="selectInputHour">
                              {label.labelTimepicker}
                            </label>
                            <div className="select-calendar-container">
                              <div className={`select-calendar-wrapper ${timeClass}`}>
                                {hourSelect}
                                {this.validator.message(
                                    'hour', this.state.hour,
                                    'required',
                                )}
                              </div>
                              <span>h</span>
                              <div className={`select-calendar-wrapper ${timeClass}`}>
                                {minuteSelect}
                                {this.validator.message(
                                    'minute', this.state.minute,
                                    'required',
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="checkbox-group-container">
                      <div className="purchase-policy-heading">
                        <span
                          dangerouslySetInnerHTML=
                            {{__html: label.formTermSentence}}
                        /> :
                        <span className="color-green-secondary">*</span>
                      </div>
                      <div className="form-container">
                        <div className="form-group inline
                        form-full form-group-checkbox">
                          <input className="form-control"
                            type="checkbox"
                            name="checkbox-privacy"
                            id="checkboxPrivacy"
                            onClick={this.setTermsCheckbox.bind(this)}
                          />
                          <label htmlFor="checkboxPrivacy">
                            <i>{label.formTermLabel}</i>
                          </label>
                        </div>
                        {this.validator.message(
                            'terms', this.state.termsCheckbox,
                            'gdpr',
                        )}
                        <i className="note">
                          {label.formNotes1}
                        </i>
                      </div>
                    </div>
                    <div className="checkbox-group-container">
                      <div className="form-group">
                        {label.formNotes2}
                      </div>
                    </div>
                    <div className="action-container full">
                      <div className="captcha-container">
                      </div>
                      <div className="button-container">
                        <button type="button"
                          className="btn btn-xlarge btn-secondary"
                          onClick={this.submitForm.bind(this)}
                        >
                          {label.formSubmitBtn}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className={'loading-container ' + phaseLoadingClass}>
                <div className="loading">
                  <i className="fas fa-spinner fa-spin"></i>
                </div>
                <div className="loading-text">
                  <span className='loading-text__title'>Chargement en cours</span>
                </div>
              </div>
              <div className={'message-container ' + phaseResultClass}>
                <div className="image-message-container">
                  <img id='imageMessage'
                    src={
                      process.env.REACT_APP_HOST +
                      '/static/assets/images/icons/' +
                      this.state.submitResult.image
                    }
                    alt=""
                  />
                </div>
                <div className="text-message-container">
                  <p id="textMessage">{this.state.submitResult.message}</p>
                  <p id="textMessageSecondary">
                    {this.state.submitResult.messageSecond}
                  </p>
                </div>
                <div className="text-message-cta">
                  <button
                    className="btn btn-secondary
                    btn-large modal-absolute-react-close-button"
                    data-modal-close="modal-contact-form-react"
                    onClick={this.closeModal.bind(this)}
                  >
                    {label.closeModalBtn}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BasicContactForm.propTypes = {
  createContactInformation: PropTypes.func,
  showModal: PropTypes.bool,
  createLabelTranslate: PropTypes.func,
  closeModal: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getContactFormInformation: PropTypes.object,
  contactInformation: PropTypes.object,
  getProjectDetail: PropTypes.object,
  createContactData: PropTypes.func,
  propertyModal: PropTypes.object,
  createContactDataLayer: PropTypes.func,
  project: PropTypes.object,
  templateConfig: PropTypes.object,
};

const mapStateToProps = (state) =>{
  return state;
};

export default connect(mapStateToProps,
    {
      createLabelTranslate,
      createContactInformation,
      createContactFormInformation,
      createContactData,
      createContactDataLayer,
    },
)(BasicContactForm);
