import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toggleFilter} from '../action';

/**
 * Class Available Filter.
*/
class AvailableFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      labelFilter: ' Bien en vente uniquement',
      isAvailable: false,
    };
  }

  /**
  * @return {void}
  */
  async componentDidMount() {
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    // if (props.filterValue !== null && (state.value.min === 0 && state.value.max === 0)) {
    //   state.isFilter = true;
    //   state.value = {min: Number(props.filterValue.min), max: Number(props.filterValue.min)};
    //   state.labelButton = rangePriceLabel(Number(props.filterValue.min), Number(props.filterValue.min));
    // }
    // if (state.value.min == null && state.value.max == null) {
    //   state.disabledButton = true;
    // }
    const filterParams = props.getFilterProjectDetail;
    if (filterParams.filterId) {
      const filterAvailable = filterParams.entities[filterParams.filterId].filterAvailable;
      if (filterAvailable) {
        const isAvailable = filterAvailable.includes('Available');
        state.isAvailable = isAvailable;
      }
    }
    return state;
  }

  /**
   * Check if component is updated
   * @param {*} prevProps
   */
  //   componentDidUpdate(prevProps) {
  //     if (this.props.filterValue !== prevProps.filterValue) {
  //       if (!this.props.filterValue) {
  //         const _getFaramFiltering = (this.props.filterOptions === undefined) ? this.props.getParamsFiltering : this.props.filterOptions;
  //         this.setState({
  //           labelButton: 'Prix ',
  //           isFilter: false,
  //           value: {
  //             min: _getFaramFiltering.priceMin,
  //             max: _getFaramFiltering.priceMax,
  //           },
  //         });
  //       }
  //     }
  //   }

  /**
   * Change Available Filter.
   * @param {object} e valye output checkbox.
   */
  setAvailableCheckbox(e) {
    this.props.toggleFilter({
      ...this.props.getFilter,
      'availableFilter': e.target.checked,
    });
    this.setState({
      isAvailable: e.target.checked,
    });
    this.props.submitHandle(e.target.checked);
  }

  /**
   * Submit Price Filter.
   * @param {number} value text output val.
   */
  //   async submitPriceFilter() {
  //     this.setState({
  //       isFilter: true,
  //       value: this.state.value,
  //       labelButton: rangePriceLabel(this.state.value.min, this.state.value.max),
  //     });
  //     this.props.toggleFilter({
  //       ...this.props.getFilter,
  //       'filterPrice': !this.props.getFilter.filterPrice,
  //     });
  //     this.props.submitHandle(this.state.value);
  //   }


  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="checkbox-group-container-filter">
        <div className="form-container">
          <div className="form-group inline form-full form-group-checkbox visually-hidden">
            <input className="form-control"
              type="checkbox"
              name="checkbox-available"
              id={this.props.showOption}
              value="Available"
              checked={this.state.isAvailable}
              onChange={this.setAvailableCheckbox.bind(this)}
            />
            <label htmlFor={this.props.showOption}>
              {this.state.labelFilter}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

AvailableFilter.propTypes = {
  getParamsFiltering: PropTypes.objectOf(PropTypes.any),
  getFilterProjectDetail: PropTypes.objectOf(PropTypes.any),
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  submitHandle: PropTypes.func,
  showOption: PropTypes.func,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps, {
  toggleFilter,
},
)(AvailableFilter);
