import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../pages/action';

/**
 * Class for detail page breadcrumb.
 */
class Breadcrumb extends React.Component {
  /**
  * @return {void}
  */
  componentDidMount() {
    const label = this.props.getLabelTranslate;
    label.breadcrumbFirst = 'Breadcrumb';
    label.breadcrumbHome = 'Accueil';
    label.breadcrumbBuyProperty = 'Acheter un bien';
    this.props.createLabelTranslate(label);
  }

  /**
     *
     * @return {JSX.Element}
     */
  render() {
    let breadcrumbTitle;
    if (this.props.projectName && this.props.projectName !== '') {
      breadcrumbTitle =
            <li>
              <span className="breadcrumb-title">
                {this.props.projectName}
              </span>
            </li>;
    }
    return (
      <div id="block-thomaspiron-breadcrumbs">
        <nav role="navigation" aria-labelledby="system-breadcrumb">
          <h2 id="system-breadcrumb" className="visually-hidden">
            {this.props.getLabelTranslate.breadcrumbFirst}
          </h2>
          <ol>
            <li>
              <a href="/">{this.props.getLabelTranslate.breadcrumbHome}</a>
            </li>
            <li>
              <a href="/acheter-un-bien">
                {this.props.getLabelTranslate.breadcrumbBuyProperty}
              </a>
            </li>
            {breadcrumbTitle}
          </ol>
        </nav>
      </div>
    );
  }
}

Breadcrumb.propTypes = {
  projectName: PropTypes.string,
  getLabelTranslate: PropTypes.object,
  createLabelTranslate: PropTypes.func,
};

const stateToProps= (state)=>{
  return state;
};


export default connect(stateToProps,
    {createLabelTranslate},
)(Breadcrumb);
