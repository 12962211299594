import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import {toggleFilter} from '../action';

/**
* Class Price Filter.
*/
class SurfaceFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      labelButton: 'Surface ',
      isFilter: false,
      NumberOfM2: {
        Min: 0,
        Max: 0,
      },
      defaultMin: 0,
      defaultMax: 1,
    };
  }

  /**
  * @return {void}
  */
  async componentDidMount() {
    // This statement for translate label
    const label = this.props.getLabelTranslate;
    label.surfaceFilterMinOf = 'Minimum de';
    label.surfaceFilterCancel = 'Annuler';
    label.surfaceFilterApply = 'Appliquer';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    if (props.filterValue !== null && state.NumberOfM2.Min === 0) {
      state.isFilter = true;
      state.NumberOfM2 = {
        Min: Number(props.filterValue.Min),
        Max: Number(props.filterValue.Max),
      };
      state.labelButton = `Min ${props.filterValue.Min} m2 `;
    }
    return state;
  }

  /**
   * Check if component is updated
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.filterValue !== prevProps.filterValue) {
      if (!this.props.filterValue) {
        this.setState({
          labelButton: 'Surface ',
          isFilter: false,
          NumberOfM2: {
            Min: 0,
            Max: 0,
          },
        });
      }
    }
  }

  /**
   * Show Surface Filter.
   * @param {number} value text output val.
   */
  showSurfaceFilter() {
    const _getFaramFiltering = (this.props.filterOptions === undefined) ? this.props.getParamsFiltering : this.props.filterOptions;
    if (_getFaramFiltering.minNumberOfM2 !== undefined) {
      this.setState({
        defaultMin: _getFaramFiltering.minNumberOfM2,
        defaultMax: _getFaramFiltering.maxNumberOfM2,
      });

      if (this.state.NumberOfM2.Min===0) {
        this.setState({
          NumberOfM2: {
            Min: _getFaramFiltering.minNumberOfM2,
            Max: _getFaramFiltering.maxNumberOfM2,
          },
        });
      }
      this.props.toggleFilter({
        ...this.props.getFilter,
        'filterType': false,
        'filterPrice': false,
        'filterRoom': false,
        'filterSurface': !this.props.getFilter.filterSurface,
      });
    }
  }

  /**
   * Change Surface Filter.
   * @param {number} value text output val.
   */
  handleChangeSurface(value) {
    this.setState({
      labelButton: this.state.labelButton,
      NumberOfM2: {
        Min: value,
      },
    });
  }

  /**
   * Submit Surface Filter.
   * @param {number} value text output val.
   */
  async submitSurfaceFilter() {
    this.setState({
      isFilter: true,
      NumberOfM2: {
        Min: this.state.NumberOfM2.Min,
        Max: this.state.defaultMax,
      },
      labelButton: `Min ${this.state.NumberOfM2.Min} m2 `,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterSurface': !this.props.getFilter.filterSurface,
    });
    this.props.submitHandle({
      Min: this.state.NumberOfM2.Min,
      Max: this.state.defaultMax,
    });
  }

  /**
   * Clear Surface Filter.
   * @param {number} value text output val.
   */
  async cancelSurfaceFilter() {
    this.setState({
      labelButton: 'Surface ',
      isFilter: false,
      NumberOfM2: {
        Min: 0,
        Max: 0,
      },
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterSurface': !this.props.getFilter.filterSurface,
    });
    this.props.cancelHandle();
  }

  /**
   * handle close backdrop.
   */
  handleClickBackDrop() {
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': false,
      'filterPrice': false,
      'filterRoom': false,
      'filterSurface': !this.props.getFilter.filterSurface,
    });
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="btn-filter-container">
        <button
          className=
            {`btn bn-small btn-filter btn-filter-surface btn-width-large 
            ${!this.props.getFilter.filterSurface ? ' ' : 'is-collapsed'} 
            ${!this.state.isFilter ? ' ' : 'is-filter'}
            ${this.props.getFilter.filterSurface ? 'is-up' : ''}
            `}
          onClick={this.showSurfaceFilter.bind(this)}
        >
          <span className="span-price-filter">{this.state.labelButton}</span>
          <i className="fas fa-chevron-down"></i>
        </button>
        <div className="filter-box-container filter-surface">
          <div className="filter-body-container-react">
            <p className="label-surface">
              {this.props.getLabelTranslate.surfaceFilterMinOf} m<sup>2</sup>&nbsp;:&nbsp;
              <span className="highlight surface-number"
                id="highlight-total-filter"><br/>
                {this.state.NumberOfM2.Min} m<sup>2</sup>
              </span>
            </p>
            <div className="slider-container">
              <form className="form">
                <InputRange
                  minValue={this.state.defaultMin}
                  maxValue={this.state.defaultMax}
                  formatLabel={(value) => ``}
                  value={this.state.NumberOfM2.Min}
                  allowSameValues={true}
                  onChange={this.handleChangeSurface.bind(this)} />
              </form>
            </div>
          </div>
          <div className="filter-action-container">
            <button className="btn-filter-cancel"
              onClick={this.cancelSurfaceFilter.bind(this)}>
              {this.props.getLabelTranslate.surfaceFilterCancel}
            </button>
            <button className="btn-filter-submit"
              onClick={this.submitSurfaceFilter.bind(this)}>
              {this.props.getLabelTranslate.surfaceFilterApply}
            </button>
          </div>
        </div>
        {/* eslint-disable-next-line max-len */}
        { this.props.getFilter.filterSurface && ( <div className="is-collapsed" onClick={ ()=> this.handleClickBackDrop()}/>) }

      </div>
    );
  }
}

SurfaceFilter.propTypes = {
  getParamsFiltering: PropTypes.objectOf(PropTypes.any),
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  submitHandle: PropTypes.func,
  cancelHandle: PropTypes.func,
  filterValue: PropTypes.object,
  filterOptions: PropTypes.object,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {
      createLabelTranslate,
      toggleFilter,
    },
)(SurfaceFilter);
