import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import {toggleFilter} from '../action';
import Modal from 'react-modal';

Modal.setAppElement('#acheter-plugin');

/**
 * Class Room Filter Mobile Filter.
*/
class RoomFilterMobile extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      selectRooms: false,
      labelButton: null,
      isFilter: false,
      numberOfRooms: {
        Min: 0,
        Max: 0,
      },
      roomDefaultMin: 0,
      roomDefaultMax: 5,
      modalIsOpen: false,
    };
    this._openModal = this._openModal.bind(this);
    this._afterOpenModal = this._afterOpenModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  /**
   * Open Modal
   */
  _openModal() {
    this.setState({modalIsOpen: true});
  }

  /**
   * After Open Modal
   */
  _afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  /**
   * Close modal
   */
  _closeModal() {
    this.setState({modalIsOpen: false});
  }

  /**
  * @return {void}
  */
  async componentDidMount() {
    // This statement for translate label
    const label = this.props.getLabelTranslate;
    label.roomFilterOfRoomsPlaceholderMobile = 'Nombre de chambres';
    label.roomFilterOfRoomsTitleMobile = 'Nombre de chambres min.';
    label.roomFilterCancelMobile = 'Fermer';
    label.roomFilterApply = 'Appliquer';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    if (props.filterValue !== null && state.numberOfRooms.Min === 0) {
      state.selectRooms = true;
      state.isFilter = true;
      state.labelButton = props.filterValue.Min;
      state.numberOfRooms = {
        Min: Number(props.filterValue.Min),
        Max: Number(props.filterValue.Max),
      };
    }
    return state;
  }

  /**
   * Show Room Filter.
   * @param {number} value text output val.
   */
  showRoomFilter() {
    const _getFaramFiltering = (this.props.filterOptions === undefined) ? this.props.getParamsFiltering : this.props.filterOptions;
    if (_getFaramFiltering.numberOfRoomsMin !== undefined) {
      this.setState({
        selectRooms: this.state.selectRooms,
        roomDefaultMin: _getFaramFiltering.numberOfRoomsMin,
        roomDefaultMax: _getFaramFiltering.numberOfRoomsMax,
      });

      if (this.state.numberOfRooms.Min===0) {
        this.setState({
          numberOfRooms: {
            Min: _getFaramFiltering.numberOfRoomsMin,
            Max: _getFaramFiltering.numberOfRoomsMax,
          },
        });
      }
      this.props.toggleFilter({
        ...this.props.getFilter,
        'filterType': false,
        'filterPrice': false,
        'filterRoom': !this.props.getFilter.filterRoom,
        'filterSurface': false,
      });
    }
  }

  /**
   * Change Room Filter.
   * @param {number} value text output val.
   */
  handleChangeRoom(value) {
    this.setState({
      labelButton: this.state.labelButton,
      numberOfRooms: {
        Min: value,
      },
    });
  }

  /**
   * Submit Room Filter.
   * @param {number} value text output val.
   */
  async submitRoomFilter() {
    this.setState({
      selectRooms: true,
      isFilter: true,
      labelButton: this.state.numberOfRooms.Min,
      numberOfRooms: {
        Min: this.state.numberOfRooms.Min,
        Max: this.props.getParamsFiltering.numberOfRoomsMax,
      },
      modalIsOpen: false,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterRoom': !this.props.getFilter.filterRoom,
    });
    this.props.submitHandle({
      Min: this.state.numberOfRooms.Min,
      Max: this.props.getParamsFiltering.numberOfRoomsMax,
    });
  }

  /**
   * Clear Room Filter.
   * @param {number} value text output val.
   */
  async cancelRoomFilter() {
    this.setState({
      labelButton: null,
      selectRooms: false,
      isFilter: false,
      modalIsOpen: false,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterRoom': !this.props.getFilter.filterRoom,
    });
    this.props.cancelHandle();
  }
  /**
   * handle close backdrop.
   */
  handleClickBackDrop() {
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': false,
      'filterPrice': false,
      'filterRoom': !this.props.getFilter.filterRoom,
      'filterSurface': false,
    });
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="btn-filter-mobile-container">
        <div className={`btn-filter-mobile ${this.state.isFilter ? 'is-filter' : '' }`} onClick={()=> this._openModal()}>
          <div className="btn-filter-mobile-text">
            <span className="filter-type-text">{this.props.getLabelTranslate.roomFilterOfRoomsPlaceholderMobile}</span>
            <span className="filter-type-text-active">{ this.state.isFilter ? `Nombre de chambres ${ this.state.numberOfRooms.Min == 5 ? '5+' : this.state.numberOfRooms.Min }` : '' }</span>
          </div>
          <div className="btn-filter-mobile-image"><i className="icon-filter-mobile archer"></i></div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this._afterOpenModal}
          onRequestClose={this._closeModal}
          className="modal-acheter"
        >
          <div className="modal-acheter__dialog">
            <div className="modal-acheter__content">
              <div className="modal-acheter__header">
                <h5 className="modal-acheter__title">{this.props.getLabelTranslate.roomFilterOfRoomsTitleMobile}</h5><button className="close" onClick={()=> this._closeModal() } ><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-acheter__body">
                <div className="cb-circle-container">
                  <div className="cb-circle-form-wrapper">
                    <input type="radio" checked={ this.state.numberOfRooms.Min == 1 } name="radioGroupRoom" id="radioMobileRoom1" value="1" onChange={(e)=> this.handleChangeRoom(e.target.value) } ></input>
                    <label htmlFor="radioMobileRoom1"><span>1</span></label>
                  </div>
                  <div className="cb-circle-form-wrapper">
                    <input type="radio" checked={ this.state.numberOfRooms.Min == 2 } name="radioGroupRoom" id="radioMobileRoom2" value="2" onChange={(e)=> this.handleChangeRoom(e.target.value) }></input>
                    <label htmlFor="radioMobileRoom2"><span>2</span></label>
                  </div>
                  <div className="cb-circle-form-wrapper">
                    <input type="radio" checked={ this.state.numberOfRooms.Min == 3 } name="radioGroupRoom" id="radioMobileRoom3" value="3" onChange={(e)=> this.handleChangeRoom(e.target.value) }></input>
                    <label htmlFor="radioMobileRoom3"><span>3</span></label>
                  </div>
                  <div className="cb-circle-form-wrapper">
                    <input type="radio" checked={ this.state.numberOfRooms.Min == 4 } name="radioGroupRoom" id="radioMobileRoom4" value="4" onChange={(e)=> this.handleChangeRoom(e.target.value) }></input>
                    <label htmlFor="radioMobileRoom4"><span>4</span></label>
                  </div>
                  <div className="cb-circle-form-wrapper">
                    <input type="radio" checked={ this.state.numberOfRooms.Min == 5 } name="radioGroupRoom" id="radioMobileRoom5" value="5" onChange={(e)=> this.handleChangeRoom(e.target.value) }></input>
                    <label htmlFor="radioMobileRoom5"><span>5+</span></label>
                  </div>
                </div>
              </div>
              <div className="modal-acheter__footer">
                <button className="btn modal-acheter__btn-close" onClick={()=> this.cancelRoomFilter()}>{this.props.getLabelTranslate.roomFilterCancelMobile}</button>
                <button className="btn modal-acheter__btn-apply" onClick={()=> this.submitRoomFilter()} >{this.props.getLabelTranslate.roomFilterApply}</button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

RoomFilterMobile.propTypes = {
  getParamsFiltering: PropTypes.objectOf(PropTypes.any),
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  submitHandle: PropTypes.func,
  cancelHandle: PropTypes.func,
  filterValue: PropTypes.object,
  filterOptions: PropTypes.object,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect( mapsStateToProps, {
  createLabelTranslate,
  toggleFilter,
},
)(RoomFilterMobile);
