import React from 'react';
import {ImagePrimary, ImageSecond, CountImage} from './setImage';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ProjectImageSliderModal
  from '../ProjectImageSlider/ProjectImageSliderModal';


/**
 * Class for detail page header banner.
 */
class HeaderBanner extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      imageLinks: [],
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  /** @return {void} */
  handleOpenModal() {
    this.setState({showModal: true});
  }

  /** @return {void} */
  handleCloseModal() {
    this.setState({showModal: false});
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.imageLinks = props.imageLinks;
    return state;
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    return (
      <div className="jumbotron-project-images-container">
        <ProjectImageSliderModal
          showModal={this.state.showModal}
          closeModal={this.handleCloseModal}
          images={this.state.imageLinks} />
        <div className="row">
          <ImagePrimary images={this.state.imageLinks}></ImagePrimary>
          <ImageSecond images={this.state.imageLinks}></ImageSecond>
        </div>
        <div className="button-see-more-container">
          <button
            onClick={this.handleOpenModal}
            className="btn btn-large btn-white btn-icon-left btn-modal"
            data-modal="modal-galerie"
            id ="btnShowGalerie">
            <i className="fas fa-eye"></i>
            <span>
              <span className="text-desktop">Voir toutes les photos</span>
              <span className="text-mobile">Toutes les photos </span>
               (
              <span id="count_modal"><span>
                <CountImage images={this.state.imageLinks}></CountImage>
              </span></span>
            )</span>
          </button>
        </div>
      </div>
    );
  }
}

HeaderBanner.propTypes = {
  imageLinks: PropTypes.array,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps)(HeaderBanner);
