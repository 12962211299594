import React, {Component} from 'react';
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import {toggleFilter} from '../action';
import {rangePriceLabel} from '../../../helpers/helper';

/**
 * Class Price Filter.
*/
class PriceFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      labelButton: 'Prix ',
      isFilter: false,
      value: {
        min: 0,
        max: 0,
      },
      priceDefaultMin: 0,
      priceDefaultMax: 900000,
      disabledButton: false,
    };
  }

  /**
  * @return {void}
  */
  async componentDidMount() {
    // This statement for translate label
    const label = this.props.getLabelTranslate;
    label.priceFilterPrice = 'Prix';
    label.priceFilterCancel = 'Annuler';
    label.priceFilterApply = 'Appliquer';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    if (props.filterValue !== null && (state.value.min === 0 && state.value.max === 0)) {
      state.isFilter = true;
      state.value = {min: Number(props.filterValue.min), max: Number(props.filterValue.min)};
      state.labelButton = rangePriceLabel(Number(props.filterValue.min), Number(props.filterValue.min));
    }
    if (state.value.min == null && state.value.max == null) {
      state.disabledButton = true;
    }
    return state;
  }

  /**
   * Check if component is updated
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.filterValue !== prevProps.filterValue) {
      if (!this.props.filterValue) {
        const _getFaramFiltering = (this.props.filterOptions === undefined) ? this.props.getParamsFiltering : this.props.filterOptions;
        this.setState({
          labelButton: 'Prix ',
          isFilter: false,
          value: {
            min: _getFaramFiltering.priceMin,
            max: _getFaramFiltering.priceMax,
          },
        });
      }
    }
  }

  /**
   * Show Price Filter.
   * @param {number} value text output val.
   */
  showPriceFilter() {
    const _getFaramFiltering = (this.props.filterOptions === undefined) ? this.props.getParamsFiltering : this.props.filterOptions;
    if (_getFaramFiltering.priceMin !== undefined) {
      this.setState({
        isFilterClass: 'is-collapsed',
        priceDefaultMin: _getFaramFiltering.priceMin,
        priceDefaultMax: _getFaramFiltering.priceMax,
      });

      if (this.state.value.min===0 && this.state.value.max===0) {
        this.setState({
          value: {
            min: _getFaramFiltering.priceMin,
            max: _getFaramFiltering.priceMax,
          },
        });
      }
      this.props.toggleFilter({
        ...this.props.getFilter,
        'filterType': false,
        'filterPrice': !this.props.getFilter.filterPrice,
        'filterRoom': false,
        'filterSurface': false,
      });
    }
  }

  /**
   * Change Price Filter.
   * @param {number} value text output val.
   */
  handleChangePrice(value) {
    this.setState({
      value: value,
      labelButton: rangePriceLabel(value.min, value.max),
    });
  }

  /**
   * Submit Price Filter.
   * @param {number} value text output val.
   */
  async submitPriceFilter() {
    this.setState({
      isFilter: true,
      value: this.state.value,
      labelButton: rangePriceLabel(this.state.value.min, this.state.value.max),
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterPrice': !this.props.getFilter.filterPrice,
    });
    this.props.submitHandle(this.state.value);
  }

  /**
   * Clear Price Filter.
   * @param {number} value text output val.
   */
  async cancelPriceFilter() {
    this.setState({
      labelButton: `Prix `,
      isFilter: false,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterPrice': !this.props.getFilter.filterPrice,
    });
    this.props.cancelHandle();
  }

  /**
   * handle close backdrop.
   */
  handleClickBackDrop() {
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': false,
      'filterPrice': !this.props.getFilter.filterPrice,
      'filterRoom': false,
      'filterSurface': false,
    });
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="btn-filter-container">
        <button
          className=
            {`btn bn-small btn-filter btn-filter-price btn-width-large
            ${!this.props.getFilter.filterPrice ? ' ' : 'is-collapsed'} 
            ${!this.state.isFilter ? ' ' : 'is-filter'}
            ${this.props.getFilter.filterPrice ? 'is-up' : ''}`}
          onClick={this.showPriceFilter.bind(this)}
        >
          <span className="span-price-filter">{this.state.labelButton}</span>
          <i className="fas fa-chevron-down"></i>
        </button>
        <div className="filter-box-container filter-price">
          <div className="filter-body-container-react">
            <p className="label-price">
              {this.props.getLabelTranslate.priceFilterPrice} :
              <span className="highlight total" id="highlight-total-filter">
                {rangePriceLabel(this.state.value.min, this.state.value.max)}
              </span>
            </p>
            <div className="slider-container">
              <form className="form">
                <InputRange
                  maxValue={this.state.priceDefaultMax}
                  minValue={this.state.priceDefaultMin}
                  formatLabel={(value) => (value !== null)?`€ ${new Intl.NumberFormat('de-DE').format(value).replace('.', ' ')}`:``}
                  value={this.state.value}
                  allowSameValues={true}
                  step={1000}
                  onChange={this.handleChangePrice.bind(this)} />
              </form>
            </div>
          </div>
          <div className="filter-action-container">
            <button className="btn-filter-cancel"
              onClick={this.cancelPriceFilter.bind(this)}>
              {this.props.getLabelTranslate.priceFilterCancel}
            </button>
            <button disabled={this.state.disabledButton} className="btn-filter-submit"
              onClick={this.submitPriceFilter.bind(this)}>
              {this.props.getLabelTranslate.priceFilterApply}
            </button>
          </div>
        </div>

        {/* eslint-disable-next-line max-len */}
        { this.props.getFilter.filterPrice && ( <div className="is-collapsed" onClick={ ()=> this.handleClickBackDrop()}/>) }

      </div>
    );
  }
}

PriceFilter.propTypes = {
  getParamsFiltering: PropTypes.objectOf(PropTypes.any),
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  submitHandle: PropTypes.func,
  cancelHandle: PropTypes.func,
  filterValue: PropTypes.object,
  filterOptions: PropTypes.object,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps, {
  createLabelTranslate,
  toggleFilter,
},
)(PriceFilter);
