import {combineReducers} from 'redux';
import _ from '../helpers/lodash';
import {
  defaultContactFormInput,
  initialSearchProject,
  initialProjectMarker,
  initialFilterProjectDetail,
  initialMessage,
  initialPropertyModal,
  initialTemplate,
  initialTotalProjectCount,
  initialSearchInvestProject,
} from './initialState';
import * as Actions from '../components/InfoMessage/action';
import * as ActionsFilter from '../components/ProjectFilter/action';
import * as ActionsProperty from '../components/ProjectDetail/Property/action';
import * as ActionsPage from '../pages/action';

const getDefaultCoordinate = (lastParams=[], action) => {
  return {
    lat: 50.503887,
    lng: 4.4699359999999615,
  };
};
const getProjectDetail = (lastParams={}, action) => {
  if (action.type ==='REQUEST_PROJECT_DETAIL') {
    return action.payload;
  } else {
    return lastParams;
  }
};
const getParamsFiltering = (lastParams=[], action)=>{
  if (action.type ==='PARAMS_FILTERING') {
    return Object.assign({}, action.payload);
  } else {
    return Object.assign({}, lastParams);
  }
};

const getShowMap = (lastParams=true, action) => {
  if (action.type === 'TOGGLE_MAP') {
    return !action.payload;
  } else {
    return lastParams;
  }
};

const getDataMarketingMedia = (lastParams =[], action)=>{
  if (action.type === 'DATA_MARKETING_MEDIA') {
    return action.payload;
  }
  return lastParams;
};

const getRequestSearchProjects = (lastParams = initialSearchProject, action)=>{
  if (action.type === 'REQUEST_SEARCH_PROJECTS') {
    return {...lastParams, ...action.payload};
  } else {
    return lastParams;
  }
};

const getLabelTranslate = (lastParams={}, action)=>{
  if (action.type === 'TRANSLATE') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getAreaSelectedId = (lastParams='', action) => {
  if (action.type === 'AREA_SELECTED_ID') {
    return action.payload;
  }
  return lastParams;
};

const getProjectPropertiesDetail = (lastParams={}, action) =>{
  if (action.type === 'REQUEST_GET_PROJECT_PROPERTIES_DETAIL') {
    return action.payload;
  } {
    return lastParams;
  }
};

const getCountProperty = (lastParams ='', action) =>{
  if (action.type === 'INPUT_COUNT_PROPERTY') {
    return action.payload;
  }
  return lastParams;
};

const getCountPropertyType = (lastParams = [], action) => {
  if (action.type === 'INPUT_COUNT_PROPERTY_TYPE') {
    return action.payload;
  }
  return lastParams;
};

const getCoordinateMaps = (lastParams={}, action)=>{
  if (action.type === 'DATA_COORDINATE_DETAIL') {
    return action.payload;
  } else {
    return {
      lat: 50.503887,
      lng: 4.4699359999999615,
    };
  }
};


const getContactFormInformation = (lastParams={}, action)=>{
  if (action.type === 'CONTACT_FORM_INFORMATION') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getFilter = (
    lastParams={
      'filterType': false,
      'filterPrice': false,
      'filterRoom': false,
      'filterSurface': false,
    }, action) => {
  if (action.type === 'TOGGLE_FILTER') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getContactFormInput = (lastParams = defaultContactFormInput, action) => {
  if (action.type === 'CONTACT_FORM_INPUT') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getRequestGetProjectMarkers = (lastParams=initialProjectMarker, action)=>{
  if (action.type === 'REQUEST_GET_PROJECT_MARKERS') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getMarkerCollection = (lastParams={}, action)=>{
  if (action.type === 'MARKER_COLLECTION') {
    return action.payload;
  }
  return lastParams;
};

const getMarkerInBounds = (lastParams={}, action)=>{
  if (action.type === 'MARKER_IN_BOUNDS') {
    return action.payload;
  }
  return lastParams;
};

const getProjectList = (lastParams={'projects': [], 'loading': false, 'hasMore': true, 'initial': true}, action) => {
  if (action.type ==='REQUEST_PROJECT_LIST') {
    if (action.payload.projects === undefined) {
      action.payload = {...lastParams, 'hasMore': action.payload.hasMore};
    } else {
      if (action.payload.projects.length > 0 && !action.payload.loading && action.payload.hasMore === undefined) {
        action.payload.hasMore = false;
      }
      if (action.payload.projects.length > 0 && action.payload.projects.length < 7) {
        action.payload.hasMore = false;
      }
    }
    action.payload.initial = false;
    return action.payload;
  } else {
    return lastParams;
  }
};


const getPOIData = (lastParams=[], action) => {
  if (action.type ==='POI_DATA') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getLockSearch = (lastParams=true, action) => {
  if (action.type ==='LOCK_SEARCH') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getMapLocation = (lastParams=true, action) => {
  if (action.type ==='MAP_LOCATION') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getHoveringProjectId = (lastParams=null, action) => {
  if (action.type ==='HOVERING_PROJECTID') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getPopupProjectId = (lastParams=null, action) => {
  if (action.type ==='POPUP_PROJECTID') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getPurchasePageLastUrl = (lastParams=null, action) => {
  if (action.type ==='PURCHASE_PAGE_LAST_URL') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getFilterProjectDetail = (state = initialFilterProjectDetail, action)=>{
  switch (action.type) {
    case ActionsFilter.OPEN_PROJECT_FILTER: {
      return {
        ...state,
        filterId: action.payload,
      };
    }
    case ActionsFilter.SET_PROJECT_FILTER: {
      const exist = _.has(state.entities, action.payload.id);
      return {
        ...state,
        entities: (exist)?
        _.cloneDeep(_.set(state.entities, action.payload.id, {...state.entities[action.payload.id], ...action.payload})):
        _.cloneDeep(_.assign({[action.payload.id]: action.payload}, state.entities)),
      };
    }
    case ActionsFilter.RESET_PROJECT_FILTER: {
      return state = initialFilterProjectDetail;
    }
    default: {
      return state;
    }
  }
};

const message = (state = initialMessage, action) => {
  switch (action.type) {
    case Actions.SHOW_MESSAGE: {
      return {
        state: true,
        options: {
          ...initialMessage.options,
          ...action.options,
        },
      };
    }
    case Actions.HIDE_MESSAGE: {
      return {
        ...state,
        state: null,
      };
    }
    default: {
      return state;
    }
  }
};

const getToggleFilterMobile = (lastParams=true, action) => {
  if (action.type ==='TOGGLE_FILTER_MOBILE') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getToggleProjectListMobile = (lastParams=false, action) => {
  if (action.type ==='TOGGLE_PROJECT_LIST_MOBILE') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getToggleMap = (lastParams=false, action) => {
  if (action.type ==='TOGGLE_MAP') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getToggleInvestor = (lastParams=false, action) => {
  if (action.type ==='TOGGLE_FILTER_INVESTOR') {
    return !lastParams;
  } else {
    return lastParams;
  }
};

const getToggleMapMobile = (lastParams=false, action) => {
  if (action.type ==='TOGGLE_MAP_MOBILE') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getTemplateConfig = (lastParams=false, action) => {
  if (action.type ==='SET_TEMPLATE_CONFIG') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const propertyModal = (state = initialPropertyModal, action) => {
  switch (action.type) {
    case ActionsProperty.OPEN_PROPERTY_MODAL: {
      return {
        ...state,
        state: true,
        fromImage: action.options.fromImage,
        project: action.options.project,
        property: action.options.property,
        contactForm: action.options.contactForm !== undefined ? action.options.contactForm : undefined,
      };
    }
    case ActionsProperty.CLOSE_PROPERTY_MODAL: {
      return {
        ...state,
        state: false,
        fromImage: false,
        contactForm: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

const templateConfig = (state = initialTemplate, action) => {
  switch (action.type) {
    case ActionsPage.SET_TEMPLATE_CONFIG: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const getTotalProjects = (lastParams=initialTotalProjectCount, action) => {
  if (action.type ==='TOTAL_PROJECTS') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getContactDataLayer = (lastParams=null, action) => {
  if (action.type ==='CONTACT_DATA_LAYER') {
    return action.payload;
  } else {
    return lastParams;
  }
};

// PageInvestir reducers

const getRequestSearchInvestProjects = (lastParams = initialSearchInvestProject, action)=>{
  if (action.type === 'REQUEST_SEARCH_INVEST_PROJECTS') {
    return {...lastParams, ...action.payload};
  } else {
    return lastParams;
  }
};

const getProjectInvestList = (lastParams={'projects': [], 'loading': false, 'currentPage': 1, 'pageSize': 9, 'totalPages': 1, 'initial': true}, action) => {
  if (action.type ==='REQUEST_PROJECT_INVEST_LIST') {
    if (action.payload.projects === undefined) {
      // action.payload = {...lastParams, 'hasMore': action.payload.hasMore};
    } else {
      if (action.payload.projects.length > 0 && !action.payload.loading && action.payload.hasMore === undefined) {
        // action.payload.hasMore = false;
      }
      if (action.payload.projects.length > 0 && action.payload.projects.length < 7) {
        // action.payload.hasMore = false;
        // action.payload.initial = false;
      }
    }
    return action.payload;
  } else {
    return lastParams;
  }
};

const getProjectInvestFilters = (lastParams= {'provinces': {}, 'minPrice': 0, 'maxPrice': 0}, action) => {
  if (action.type ==='PROJECT_INVEST_AVAILABLE_FILTERS') {
    return action.payload;
  } else {
    return lastParams;
  }
};

const getParamsInvestFiltering = (lastParams= {
  'paging': {
    'currentPage': 1,
    'pageSize': 9,
  },
  'projectPropertiesSearchCriteria': {
    'priceMin': null,
    'priceMax': null,
    'generalizedTypeOfProperty': [
      'House',
      'Appartment',
    ],
    'countries': [
      'Belgium',
    ],
    'provinceIds': [],
  },
}, action)=>{
  if (action.type ==='PARAMS_INVEST_FILTERING') {
    return Object.assign({}, action.payload);
  } else {
    return Object.assign({}, lastParams);
  }
};

// END of PageInvestir reducers


export default combineReducers({
  getProjectDetail,
  getParamsFiltering,
  getDataMarketingMedia,
  getRequestSearchProjects,
  getDefaultCoordinate,
  getLabelTranslate,
  getCoordinateMaps,
  getContactFormInformation,
  getAreaSelectedId,
  getProjectPropertiesDetail,
  getCountProperty,
  getCountPropertyType,
  getShowMap,
  getFilter,
  getContactFormInput,
  getRequestGetProjectMarkers,
  getMarkerCollection,
  getMarkerInBounds,
  getProjectList,
  getPOIData,
  getLockSearch,
  getMapLocation,
  getPopupProjectId,
  getHoveringProjectId,
  getPurchasePageLastUrl,
  getFilterProjectDetail,
  getTemplateConfig,
  message,
  getToggleFilterMobile,
  getToggleProjectListMobile,
  getToggleMap,
  getToggleMapMobile,
  getToggleInvestor,
  propertyModal,
  templateConfig,
  getTotalProjects,
  getContactDataLayer,
  getProjectInvestList,
  getParamsInvestFiltering,
  getRequestSearchInvestProjects,
  getProjectInvestFilters,
});
