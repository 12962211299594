import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import ProjectImageSlider from './ProjectImageSlider';

/**
 * Project Image Slider Modal
 */
class ProjectImageSliderModal extends Component {
  /** @return {JSX} */
  render() {
    return (
      <ReactModal
        closeTimeoutMS={350}
        overlayClassName="modal-overlay-gallery"
        className={
          // eslint-disable-next-line max-len
          this.props.showModal ? 'modal-container-gallery-show' : 'modal-container-gallery-hide'
        }

        isOpen={this.props.showModal}>
        <div className="modal-box with-close-button">
          <button
            onClick={this.props.closeModal}
            className="modal-close-button">
            <i className="fas fa-times"></i>
          </button>
          <div className="modal-body">
            <ProjectImageSlider
              images={this.props.images} />
            <div className="modal-galerie-pinch">
              <div className="modal-galerie-pinch-container">
                <img src={`${process.env.REACT_APP_BASE_DRUPAL_URL +
                  '/themes/thomaspiron/images/icon-pinch.svg'}`} />
                <span>Pincer pour zoomer</span>
              </div>
              <div className="modal-galerie-pinch-container">
                <img src={`${process.env.REACT_APP_BASE_DRUPAL_URL +
                  '/themes/thomaspiron/images/icon-slide.svg'}`} />
                <span>Deux doigts pour glisser</span>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

ProjectImageSliderModal.propTypes = {
  images: PropTypes.array,
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default ProjectImageSliderModal;
