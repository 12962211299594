import React from 'react';
import PropTypes from 'prop-types';

const ProjectIframe = ({project}) => {
  if (project.iframeLink != null) {
    return <div className="project-detail-iFrame" id="3d-model">
      <div className="project-detail-iFrame__container">
        <iframe src={project.iframeLink} title="Iframe Project Detail"></iframe>
      </div>
    </div>;
  }
  return <></>;
};

ProjectIframe.propTypes = {
  project: PropTypes.object,
};

export default ProjectIframe;
