import React from 'react';
import PropTypes from 'prop-types';

const PriceBlockNd = (props) => {
  const project = props.project;
  if (project.action) {
    // TODO: for now hardcoded in future when API ready uncomment me
    // const pricingCondition = (project.action.conditions !== null) ? project.action.conditions : [];
    // const pricingConditionPrice = pricingCondition.filter(function(el) {
    //   return el.price === true;
    // });
    // const pricingConditionPriceAllIn = pricingCondition.filter(function(el) {
    //   return el.priceAllIn === true;
    // });
    let justHouse = true;
    let labelPrix = 'Prix du bien principal';
    if (project.entity == 'TPHome') {
      for (const propertyType of project.countOfTypeOfPropertiesInProject) {
        if (propertyType.generalizedTypeOfProperty !== 'House') {
          justHouse = false;
        }
      }
      if (justHouse == true) {
        labelPrix = 'Prix hors frais et taxes';
      }
    }

    if (props.templateConfig.country_code !== 'BE') {
      labelPrix = 'Prix du bien';
    }

    return (
      <div className="price-nd">
        <div className={ 'price-nd__content' + (props.templateConfig && props.templateConfig.country_code !== 'BE' ? ' single-price' : '') }>
          <div className="price-nd__item">
            <p className="price-nd__label price-nd__label--dark">{labelPrix} <span>(1)</span></p>
            {(project.action.oldPrice !== null && project.action.oldPrice > 0 && project.action.oldPrice > project.action.newPrice) && <div className='price-nd__mobile-view'><p className="price-nd__old-price">{project.action.oldPrice.toLocaleString('nl-be')+'€'}</p></div>}
            <p className="price-nd__label price-nd__label--red"> à partir de </p>
            <div className="price-nd__price-wrap">
              {(project.action.oldPrice !== null && project.action.oldPrice > 0 && project.action.oldPrice > project.action.newPrice) && <div className='price-nd__desktop-view'><p className="price-nd__old-price">{project.action.oldPrice.toLocaleString('nl-be')+'€'}</p></div>}
              {/* <p className="price-nd__new-price">{(project.action.newPrice > 0) ? project.action.newPrice.toLocaleString('nl-be')+'€' : '-'} <span>{pricingConditionPrice.map((item) => (`(${item.symbol})`))}</span></p> */}
              <p className="price-nd__new-price">{(project.action.newPrice > 0) ? project.action.newPrice.toLocaleString('nl-be')+'€' : '-'}</p>
            </div>
          </div>
          {(props.templateConfig && props.templateConfig.country_code === 'BE') ? (
              <div className="price-nd__item">
                <p className="price-nd__label price-nd__label--dark">Prix complet <span>(2)</span></p>
                {(project.action.oldPriceAllIn !== null && project.action.oldPriceAllIn > 0 && project.action.oldPriceAllIn > project.action.newPriceAllIn) && <div className='price-nd__mobile-view'><p className="price-nd__old-price">{project.action.oldPriceAllIn.toLocaleString('nl-be')+'€'}</p></div>}
                <p className="price-nd__label price-nd__label--red"> à partir de </p>
                <div className="price-nd__price-wrap">
                  {(project.action.oldPriceAllIn !== null && project.action.oldPriceAllIn > 0 && project.action.oldPriceAllIn > project.action.newPriceAllIn) && <div className='price-nd__desktop-view'><p className="price-nd__old-price">{project.action.oldPriceAllIn.toLocaleString('nl-be')+'€'}</p></div>}
                  {/* <p className="price-nd__new-price">{(project.action.newPriceAllIn > 0) ? project.action.newPriceAllIn.toLocaleString('nl-be')+'€' : '-'} <span>{pricingConditionPriceAllIn.map((item) => (`(${item.symbol})`))}</span></p> */}
                  <p className="price-nd__new-price">{(project.action.newPriceAllIn > 0) ? project.action.newPriceAllIn.toLocaleString('nl-be')+'€' : '-'}</p>
                </div>
              </div>
          ):(<></>)}
        </div>
      </div>
    );
  }
  return <div></div>;
};

PriceBlockNd.propTypes = {
  project: PropTypes.object,
  templateConfig: PropTypes.object,
};

export default PriceBlockNd;
