import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';

/**
 * @param {*} project
 * @return {void}
 */
const ProgramProject = ({project: {description = '', introductionContent = '', programElements = [], elements = []}}) => {
  const [isShow, setShow] = useState(false);
  const [isHaveMore, setIsHaveMore] = useState(false);
  const sortedProgramElements = programElements?.sort((a, b) => (a.elementNumber > b.elementNumber) ? 1 : ((b.elementNumber > a.elementNumber) ? -1 : 0));

  useEffect(() => {
    /**
     *
     * @param {*} status
     */
    function handleHaveMoreChange(status) {
      setIsHaveMore(status);
    };

    if (document.getElementById('programmeDuProject')) {
      const height = document.getElementById('programmeDuProject').clientHeight;
      if (height > 475) {
        handleHaveMoreChange(true);
      }
    }

    return function cleanup() {
      handleHaveMoreChange(false);
    };
  });

  const setShowHandle = () => {
    setShow(!isShow);
  };

  if (!introductionContent && !programElements.length && !elements.length) {
    return <div className="program-content-project"></div>;
  }

  return <div className="program-content-project">
    <div className="program-content-project__container">
      <h2 className="program-content-project__title">Programme du projet immobilier</h2>
      <div className={`program-content-project__grid-row`} id="programmeDuProject">
        <div className={`program-content-project__grid-left-col ${isShow || !isHaveMore ? '' : 'is-hide'}`}>
          <div className="au-programme-wrapper-rd">
            {sortedProgramElements.length > 0 ? (<h3 className="au-programme-wrapper-rd__title">Au programme</h3>) : ''}
            {
              sortedProgramElements.length > 0 && (<ul className="au-programme-wrapper-rd__list">
                {sortedProgramElements.map((item, index)=>{
                  return <li key={`program-element-${index}`} className="au-programme-wrapper-rd__list-item">
                    <div className="au-programme-wrapper-rd__list-item-wrap">
                      <h3 className="au-programme-wrapper-rd__list-item-number">{item.number}</h3>
                      <img className="au-programme-wrapper-rd__list-item-image" src={item.imageUrl} alt="Image programme" />
                      <p className="au-programme-wrapper-rd__list-item-text">{item.text}</p>
                    </div>
                  </li>;
                })}
              </ul>)
            }

          </div>
          <div className="program-content-project__description" dangerouslySetInnerHTML={{__html: introductionContent? description: ''}}></div>
        </div>
        {(elements.length > 0) && <div className="program-content-project__grid-right-col">
          <div className="elements-rd">
            <h3 className="elements-rd__title">Eléments clés du projet</h3>
            <ul className="elements-rd__list">
              {elements.map((item, index)=>{
                return <li className="elements-rd__list-item" key={`element-${index}`}>{item}</li>;
              })}
            </ul>
          </div>
        </div>}
        {isHaveMore && <div className="program-content-project__cta-wrap">
          <div className={`program-content-project__cta ${isShow ? '' : 'is-hide'}`} onClick={setShowHandle}>
            <span>{isShow ? 'Cacher le descriptif' : 'Voir le descriptif complet'}</span>
            <i className="fas fa-chevron-down"></i>
          </div>
        </div>}
      </div>
    </div>
  </div>;
};

ProgramProject.propTypes = {
  project: PropTypes.object,
  description: PropTypes.string,
};

export default ProgramProject;
