import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import PropertyInvestItem from './PropertyInvestItem';
import * as ExternalApi from '../../services/ExternalApi';
import {BubleLoading} from '../../helpers/loading';
import Pager from 'react-pager';

/**
 * Class for filtering project.
 */
class ProjectInvestDetail extends React.Component {
  /**
   * @param {*} props props object.
   */
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      allProperties: [],
      properties: [],
      projectId: '',
      loading: true,
      projectDetails: {},
      pageTotal: 1,
      pageCurrent: 0,
      pageItemsPerPage: 9,
      pageVisiblePage: 3,
    };
  }

  /**
   * @return {void}
   */
  async componentDidMount() {

  }

  /**
   * Check if component is updated
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.project !== null) {
      if (typeof this.props.project === 'string') {
        if (this.state.projectId !== this.props.project) {
          this.getProperties(this.props.project);
        }
      } else {
        if (prevProps.project === null || (prevProps.project.hasOwnProperty('projectId') && prevProps.project.projectId !== this.props.project.projectId)) {
          this.setState({
            projectDetails: this.props.project,
          });
          this.getProperties(this.props.project.projectId);
        }
      }
    } else {
      if (prevProps.project !== null) {
        this.setState({
          allProperties: [],
          properties: [],
          projectId: '',
          pageCurrent: 0,
          pageTotal: 1,
        });
      }
    }
  }

  /**
   * gets the project properties
   * @param {string} projectId
   */
  async getProjectDetails(projectId) {
    const params = {
      'projectDetails': {
        'projectId': projectId,
        'imageSizes': [
          1200,
          800,
          400,
        ],
      },
      'language': 'fr',
    };

    const response = await ExternalApi.getProjectDetails(params);
    return response.data.projectDetails;
  }


  /**
   * gets the project properties
   * @param {string} projectId
   */
  async getProperties(projectId) {
    if (this.state.projectId === projectId) {
      return;
    }

    // Set this early so there aren't multiple api calls due to waiting on the api.
    this.setState({
      projectId: projectId,
    });

    this.scrollToTop();

    const projectDetails = await this.getProjectDetails(projectId);

    const params = {
      'projectId': projectId,
      'paging': {
        'currentPage': 1,
        'pageSize': 999,
      },
      'projectPropertiesSearchCriteria': {
        'selectInvestor': true,
        'propertiesAvailableSoldOption': [
          'Available',
        ],
      },
      'countries': [
        'Belgium',
      ],

      'language': 'fr',
      'imageSizes': [
        1200,
        800,
        400,
      ],
    };

    const response = await ExternalApi.getProjectProperties(params);
    const projectProperties = [];

    if (response.data.projectProperties && response.data.projectProperties.length > 0) {
      response.data.projectProperties.sort((a, b) => a.price > b.price ? 1 : -1).forEach((element) => {
        if ([1, 2].includes(element.projectPropertyType)) {
          projectProperties.push(element);
        }
      });

      this.setState({
        allProperties: projectProperties,
        properties: projectProperties.slice(0, this.state.pageItemsPerPage),
        projectId: projectId,
        projectDetails: projectDetails,
        pageTotal: Math.ceil(projectProperties.length / this.state.pageItemsPerPage),
        pageCurrent: 0,
      });
    }
  }

  /**
   * Back button event
   */
  resetProject() {
    this.props.handleActiveProject(null);
  }

  /**
   * Handles a page change broadcasted by the pager. Get new data here.
   * @param {number} newPage
   */
  handlePageChanged(newPage) {
    this.setState({
      pageCurrent: newPage,
      properties: this.state.allProperties.slice(this.state.pageItemsPerPage * newPage, this.state.pageItemsPerPage * newPage + this.state.pageItemsPerPage),
    });
    this.scrollToTop();
  }

  /**
   * Scrolls to list
   * @return {Promise<void>}
   */
  async scrollToTop() {
    this.myRef.current.scrollIntoView();
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    return (
      <Fragment>
        <div className='invest-project-detail'>
          {this.props.project !== null && (
            <>
              <h3 ref={this.myRef}>
                <span className="black-title">Voici les biens disponibles à :</span>
              </h3>
              <h2>{this.state.projectDetails?.projectName}</h2>
              <div className="invest-address">{this.state.projectDetails?.address}</div>

              <button className="backbutton" onClick={() => this.resetProject()}>
                <i className="fas fa-chevron-left"></i> Revenir aux résultats
              </button>

              <div className="invest-properties invest-card-container">
                {this.state.properties?.map((item, i) => {
                  return (
                    <PropertyInvestItem
                      key={i}
                      history={this.props.history}
                      property={item}
                      project={this.props.project}
                      projectDetails={this.state.projectDetails}
                    />
                  );
                })}
              </div>
              {this.state.properties.length !== 0 && (
                <Pager
                  total={this.state.pageTotal}
                  current={this.state.pageCurrent}
                  visiblePages={this.state.pageVisiblePage}
                  titles={{first: '<', last: '>'}}
                  onPageChanged={this.handlePageChanged.bind(this)}
                />
              )}
              {this.state.properties.length === 0 && (
                <BubleLoading
                  text={'Chargements en cours...'}
                />
              )}
            </>
          )}
        </div>
      </Fragment>
    );
  }
}

ProjectInvestDetail.propTypes = {
  history: PropTypes.object,
  project: PropTypes.object,
  handleActiveProject: PropTypes.func,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {

    },
)(ProjectInvestDetail);
