import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import Slider from 'react-slick';
import Promotion from '../Promotion/Promotion';
import {TYPE_ESPACE_COMMERCIAL, TYPE_ESPACE_DE_BUREAU, TYPE_TERRAIN_A_BATIR, TYPE_MAISON} from '../../../helpers/propertyTypeVariable';
import {collectImageWithAlt} from '../../HeaderBanner/setImage';
import {createTitleFromProject, orderImages} from '../../../helpers/helper';

/**
 * Main Component
 */
class PropertyInfo extends React.Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      resolution: 1200,
      indexResolution: 5,
      settings: {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        fade: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      surface: 0,
      floor: '-',
      pebDisplay: '',
      etageDisplay: '',
      chambresDisplay: '',
      price: '-',
    };
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {Object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.surface = props.property.surfaceM2;

    // manage surface
    if (props.property.projectPropertyType === TYPE_TERRAIN_A_BATIR) {
      state.surface = props.property.surfaceOfBlaconyAndGardenM2;
    }

    // manage floor
    if (Number(props.property.floor) >= 0 && props.property.floor !== undefined) {
      state.floor = props.property.floor;
    }

    // peb display
    if (props.property.peb === null || props.property.peb === undefined) {
      state.pebDisplay = 'none';
    }

    // etage display
    if (props.property.projectPropertyType === TYPE_MAISON || props.property.projectPropertyType === TYPE_TERRAIN_A_BATIR) {
      state.etageDisplay = 'none';
    }

    // chambres display
    const arrayChambresException = [
      TYPE_TERRAIN_A_BATIR,
      TYPE_ESPACE_COMMERCIAL,
      TYPE_ESPACE_DE_BUREAU,
    ];
    if (arrayChambresException.indexOf(props.property.projectPropertyType) != -1) {
      state.chambresDisplay = 'none';
    }

    // price
    if (
      Number(props.property.price) > 0 &&
      props.property.price !== null &&
      props.property.price !== undefined
    ) {
      state.price = props.property.price;
    }
    return state;
  }
  /**
   * @param {*} state;
   * @param {*} props;
   */
  async componentDidMount() {
    const label = this.props.getLabelTranslate;
    label.localiserBtn = 'Localiser sur une carte';
    label.contactBtn = 'Demander un rendez-vous';
    label.contactLocationTitle = 'Rencontrons-nous';
    label.contactLocationSubtitle = 'sur place';
    label.localisationLabel = 'LOCALISATION';
    label.horairesLabel = 'HORAIRES';
    label.contactLabel = 'CONTACT';
    this.props.createLabelTranslate(label);
  }

  /**
   * Close contact modal.
   */
  closeModal() {
    // setTimeout(() => {
    //   window.scrollTo(0, document.body.scrollHeight ||
    //     document.documentElement.scrollHeight);
    // }, 500);
    this.props.setModal();
    this.props.hideModal();
  }

  /**
   * Open contact modal.
   */
  openModal() {
    this.setState({
      setContactForm: true,
      showContactForm: true,
    }, () => {
      this.handleOpenContactModal(this.state.showContactForm, false);
    });
  }

  /**
   * Open contact modal.
   */
  openModalPdf() {
    this.setState({
      setContactForm: true,
      showContactForm: true,
      pdfContact: true,
    }, () => {
      this.handleOpenContactModal(this.state.showContactForm, true);
    });
  }

  /**
   *
   * @param {number} value text output val.
   * @param {number} pdf text output val.
   */
  handleOpenContactModal(value, pdf) {
    this.props.onOpenContactModal(value);
    this.props.onOpenContactModalPdf(pdf);
  }
  /**
   *
   * accepts the id of the button and takes action coordinatelly
   */

  socialMediaButton = (e, projectName) =>{
    const pageUrl = location.protocol + '//' + location.host + location.pathname;
    switch (e.target.id) {
      case 'fa-facebook-f':
        window.open('https://www.facebook.com/sharer.php?u='.concat(pageUrl), 'MsgWindow');
        break;
      case 'fa-linkedin-in':
        window.open('https://www.linkedin.com/shareArticle?mini=false&url='.concat(pageUrl), 'MsgWindow', 'fullscreen=yes');
        break;
      case 'fa-envelope':
        const r = window.open('mailto:?subject='.concat(projectName, '&body=').concat(`Découvrir le projet : [ ${pageUrl} ]`), 'MsgWindow', 'fullscreen=yes');
        setTimeout(function() {
          r.close();
        }, 400);
        break;
    }
  }

  /** @return {JSX} */
  render() {
    let pdfbutton = '';
    if ((Object.keys(this.props.property).length > 0) && (Object.keys(this.props.project).length > 0)) {
      if (this.props.property.propertyPdfLinks.length) {
        pdfbutton = <a
          className="btn btn-border-only btn-primary
                btn-medium-40 icon-left modal-absolute-react-close-button"
          href="#"
          id="download-pdf"
          // data-modal="modal-plan-contact-form"
          // data-modal-close="modal-popup"
          // data-modal-close-no-animation="true"
          onClick={this.openModalPdf.bind(this)}
          projectname={this.props.project.projectName}
          projectpropertyid={this.props.property.projectPropertyId}
          projectpropertyname={this.props.property.projectPropertyName}
          socicode={this.props.property.sociCode}
          unitcode={this.props.property.unitCode}
          projectpropertytype={this.props.property.projectPropertyType}
          data-pdf-url={this.props.property.propertyPdfLinks[0]}
        >
          <i className="fas fa-download"></i> Recevoir les plans
        </a>;
      }
    }

    let propertyImages = [];
    if ((Object.keys(this.props.property).length > 0) && (Object.keys(this.props.project).length > 0)) {
      const propertyName = `${createTitleFromProject(this.props.project)} (${this.props.property.projectPropertyName} - ${this.props.property.projectPropertyCode} - ${this.props.property.description})`;
      propertyImages = collectImageWithAlt(this.props.property.propertyImages, this.state.resolution, propertyName);
      propertyImages = orderImages(propertyImages);
    }

    return Object.keys(this.props.property).length > 0 &&
      Object.keys(this.props.project).length > 0 ? (
      <div
        className="modal-container-absolute-react project-detail-modal"
        id="modal-contact-form-react"
      >
        <div className="modal-box">
          <div className="modal-header">
            <div className="row-flex">
              <div className="col-12 text-right">
                <div className="button-close-modal-container no-padding">
                  <button
                    onClick={this.closeModal.bind(this)}
                    className="btn btn-icon-only modal-absolute-react-close-button"
                    data-modal-close="modal-popup"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="row-flex project-detail-estate-header">
              <div className="col-12 col-md-4">
                <span className="title-number" id="titleNumber">
                  {this.props.property.projectPropertyName}
                </span>
              </div>
              <div className="col-12 col-md-8 text-right">
                <div className="icon-group-container icon-popup">
                  <span>Partager cette belle opportunité</span>
                  <a href="#" onClick={(e) => this.socialMediaButton(e)}>
                    <i className="fab fa-facebook-f" id="fa-facebook-f"></i>
                  </a>
                  <a href="#" onClick={(e) => this.socialMediaButton(e, `${this.props.project.projectName} - ${this.props.property.projectPropertyName}`)}>
                    <i className="fas fa-envelope" id="fa-envelope"></i>
                  </a>
                  <a
                    href="#"
                    onClick={(e) =>
                      this.socialMediaButton(e, `${this.props.project.projectName} - ${this.props.property.projectPropertyName}`)
                    }
                  >
                    <i className="fab fa-linkedin-in" id="fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="project-detail-content-container
            popup-detail-container"
          >
            <div
              className="project-detail-content-text-container
              popup-content"
            >
              <div
                className="project-detail-content-text-header-container
                  project-detail-header-modal row"
              >
                <div id="divBannerPromo" className="banner-promo-modal">
                  <Promotion
                    projectMedia={this.props.mediaId}
                    getMediaBannerDataAll={this.props.getMediaBannerDataAll}
                  />
                </div>
              </div>
              <div className="popup-title">
                <p className="title-text" id="project-name">
                  {this.props.project.projectName}
                </p>
              </div>
              <div
                className="project-detail-content-text-body-container
                content-popup"
              >
                <p className="price old-price" style={{display: 'none'}}>
                  {this.props.property.oldPrice.toLocaleString('nl-be')}€*
                </p>
                <p className="price">
                  {this.state.price.toLocaleString('nl-be')}€*
                </p>
                <p
                  className="old-price-note"
                  id="old-price-note-modal"
                  style={{fontSize: '18px', display: 'none'}}
                ></p>
                <p
                  className="price-note"
                  id="price-note-modal"
                  style={{fontSize: '18px', lineHeight: '1.6'}}
                >
                  *Prix TTC
                </p>
                <div className="field-body">
                  <p id="content-0" style={{display: this.state.pebDisplay}}>
                    <span className="text-bold">PEB : </span>
                    <span id="peb">{this.props.property.peb}</span>
                  </p>
                  <p
                    id="content-1"
                    style={{display: this.state.etageDisplay}}
                  >
                    <span className="text-bold">Etage : </span>
                    <span id="floor">{this.state.floor || '-'}</span>
                  </p>
                  <p id="content-2">
                    <span className="text-bold">Surface : </span>
                    <span id="surfaceM2">
                      {this.state.surface} m<sup>2</sup>
                    </span>
                  </p>
                  <p id="content-3" style={{display: 'none'}}>
                    <span className="text-bold">Jardin / Balcon : </span>
                    <span id="surfaceOfBlaconyAndGardenM2">
                      {this.props.property.surfaceOfBlaconyAndGardenM2}
                    </span>
                  </p>
                  <p
                    id="content-4"
                    style={{display: this.state.chambresDisplay}}
                  >
                    <span className="text-bold">Chambres : </span>
                    <span id="numberOfRooms">
                      {this.props.property.numberOfRooms}
                    </span>
                  </p>
                  <p id="content-5">
                    <span className="detail"></span>
                    <span id="description">
                      {this.props.property.description}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {this.props.property.propertyImages.length > 0 ? (
            <div className="collapse-navbar project-detail">
              <ul className="collapse-navbar-list project-detail">
                <li
                  className="collapse-navbar-list-item-detail is-active"
                  data-navbar="imageModalBlock"
                  id="imageModalBlockListItem"
                >
                  <a id="detail-popup-photos">Photos</a>
                </li>
                <li
                  className="collapse-navbar-list-item-detail"
                  data-navbar="estateDetailContainer"
                  id="estateDetailContainerListItem"
                  style={{display: 'none'}}
                >
                  <a id="detail-popup-plan">Plans</a>
                </li>
              </ul>
            </div>
          ) : null}
          <div
            className="collapse-navbar-content-container
              implantation-detail-body"
            id="imageModalBlock"
          >
            {this.props.property.propertyImages.length > 0 ? (
              <div className="estate-plan-image-slick-container project-detail-popup">
                <Slider
                  {...this.state.settings}
                  className="implantation-plan-image-slick project-detail slick-dotted"
                >
                  {propertyImages.map((image, i) =>{
                    return <div className="estate-plan-image-container" key={`estate-plan-image-container-${i}`}>
                      <img
                        src={image.url}
                        alt={(image.alt) ? image.alt : createTitleFromProject(this.props.property)}
                        className="implantation" />
                    </div>;
                  },
                  )}
                </Slider>
              </div>
            ) : null}
          </div>
          <div
            className="collapse-navbar-content-container
        estate-detail-container is-hide"
            id="estateDetailContainer"
            style={{display: 'none'}}
          >
            <div className="estate-detail-wrapper popup-modal">
              <div className="estate-detail-body popup-detail-body">
                <div className="estate-plan-image-slick-container">
                  <div className="estate-plan-image-slick project-detail"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-detail-cta">
            <div className="popup-detail-cta-left">
              <button
                onClick={this.closeModal.bind(this)}
                className="btn btn-back-to-list modal-absolute-react-close-button"
                data-modal-close="modal-popup"
              >
                <i className="fas fa-chevron-left"></i>
                <span>Revenir à la liste des lots</span>
              </button>
            </div>
            <div className="popup-detail-cta-right">
              <a
                // onClick={this.props.setContactForm(this)}
                onClick={this.openModal.bind(this)}
                className="btn btn-primary btn-medium-40 btn-modal-absolute-react
                  modal-absolute-react-close-button"
              >
                Contactez-nous
              </a>
              {pdfbutton}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  };
}

PropertyInfo.propTypes = {
  project: PropTypes.object,
  property: PropTypes.object,
  setModal: PropTypes.any,
  setContactForm: PropTypes.any,
  onOpenContactModal: PropTypes.func,
  onOpenContactModalPdf: PropTypes.func,
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  contactInformation: PropTypes.any,
  hideModal: PropTypes.func,
  mediaId: PropTypes.array,
  getMediaBannerDataAll: PropTypes.object,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createLabelTranslate},
)(PropertyInfo);
