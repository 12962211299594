import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const ProjectTopInfoContent = ({
  getMediaBannerDataAll,
  mediaId,
  templateConfig,
  project: {
    countOfGeneralizedPropertyTypes = {},
    pebMin = '',
    pebMax = '',
    provinceName = '-',
    introductionTitle = '',
    introductionContent = '',
    deliveryDate = '',
    description = '',
  },
}) => {
  const [mediaBanner] = useState([]);
  const [divMedia, setDivMedia] = useState('');
  const [cssMedia, setCssMedia] = useState('');
  useEffect(() => {
    if (Object.keys(getMediaBannerDataAll).length !== 0) {
      const result = getMediaBannerDataAll;
      // media promotion
      for (const media of result.medias) {
        mediaBanner[media.id] = {
          'div': media.div,
          'css': media.css,
        };
      }
    }
    if (mediaId.length > 0) {
      if (typeof mediaBanner[mediaId[0].mediaId] !== 'undefined') {
        let divMedia = mediaBanner[mediaId[0].mediaId].div;
        if (mediaId[0].mediaType == 'TopBannerWithOpenning') {
          if (mediaId[0].openningType != null) {
            divMedia = divMedia.replace('[[OpenningType]]', mediaId[0].openningType);
          }
          if (mediaId[0].openningContent != null) {
            divMedia = divMedia.replace('[[OpenningContent]]', mediaId[0].openningContent);
          }
        }
        setDivMedia(divMedia);
        setCssMedia(mediaBanner[mediaId[0].mediaId].css);
      }
    }
  });

  let withDeliveryClass = 'project-detail-info__grid';
  if (deliveryDate != '' && deliveryDate !== null) {
    withDeliveryClass = 'project-detail-info__grid with-delivery';
  }
  const projectIntro = new DOMParser().parseFromString(introductionTitle, 'text/html');
  // const projectIntroContent = new DOMParser().parseFromString(introductionContent, 'text/html');

  return (
    <div className="project-detail-info">
      <div className="project-detail-info__container">
        {/* <div className={`project-detail-info__row ${mediaId.length === 0 ? 'justify-content-center' : ''}`}> */}
        {(introductionTitle || (introductionContent || description) || mediaId.length > 0) && (
          <div className={`project-detail-info__row`}>
            {/* <div className={`project-detail-info__left-col ${mediaId.length === 0 ? 'project-detail-info__left-col--full' : ''}`}> */}
            <div className={`project-detail-info__left-col`}>
              {/* <h2 className={`project-detail-info__title ${mediaId.length === 0 ? 'text-center' : ''}`}> */}
              {introductionTitle && (
                <h2 className={`project-detail-info__title`}>
                  {introductionTitle && projectIntro.body.textContent}
                </h2>
              )}
              <div
                className={`project-detail-info__text`}
                dangerouslySetInnerHTML={{__html: introductionContent ? introductionContent: description}}
              >
                {/* {introductionContent && projectIntroContent.body.textContent} */}
              </div>
            </div>
            {mediaId.length > 0 && (
              <div className="project-detail-info__right-col">
                <div className="project-detail-info__promotion">
                  <style dangerouslySetInnerHTML={{__html: `${cssMedia}`}} />
                  <div
                    className="campaign-marketing-wrapper"
                    dangerouslySetInnerHTML={{__html: divMedia}}
                  ></div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className={withDeliveryClass}>
          {(templateConfig.country_code === 'BE') && (
            <div className="project-detail-info__grid-item">
              <p className="project-detail-info__grid-title">Situation</p>
              <p className="project-detail-info__grid-text">{provinceName}</p>
            </div>
          )}
          <div className="project-detail-info__grid-item">
            <p className="project-detail-info__grid-title">Composition</p>
            <p className="project-detail-info__grid-text">
              {Object.keys(countOfGeneralizedPropertyTypes).length !== 0 ? (
                Object.keys(countOfGeneralizedPropertyTypes).map(
                    (value, index) => {
                      if (index === 0) {
                        return <span key={index}>{value}</span>;
                      } else {
                        return <span key={index}>, {value}</span>;
                      }
                    },
                )
              ) : (
                <span>-</span>
              )}
            </p>
          </div>
          <PebInformation min={pebMin} max={pebMax}></PebInformation>
          <DeliveryDateInformation
            date={deliveryDate}
          ></DeliveryDateInformation>
          {/* TODO : for now we hide */}
          {/* <div className="project-detail-info__grid-item">
            <p className="project-detail-info__grid-title">Date de livraison</p>
            <p className="project-detail-info__grid-text">2 Avril 2021</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {any} props
 * @return {JSX.Element}
 * @constructor
 */
function PebInformation(props) {
  if ((props.min != '' && props.min !== null) && (props.max != '' && props.max !== null)) {
    return (
      <div className="project-detail-info__grid-item">
        <p className="project-detail-info__grid-title">PEB</p>
        <p className="project-detail-info__grid-text">{`${props.min}-${props.max}`}</p>
      </div>
    );
  } else {
    if ((props.min != '' && props.min !== null) || (props.max != '' && props.max !== null)) {
      return (
        <div className="project-detail-info__grid-item">
          <p className="project-detail-info__grid-title">PEB</p>
          <p className="project-detail-info__grid-text">{`${props.min}${props.max}`}</p>
        </div>
      );
    }
  }
  return '';
}

/**
 *
 * @param {any} props
 * @return {JSX.Element}
 * @constructor
 */
function DeliveryDateInformation(props) {
  if (props.date != '' && props.date !== null) {
    return (
      <div className="project-detail-info__grid-item">
        <p className="project-detail-info__grid-title">Date de livraison</p>
        <p className="project-detail-info__grid-text">{props.date}</p>
      </div>
    );
  }
  return '';
}

ProjectTopInfoContent.propTypes = {
  project: PropTypes.object,
  getMediaBannerDataAll: PropTypes.object,
  templateConfig: PropTypes.object,
  mediaId: PropTypes.array,
  countOfGeneralizedPropertyTypes: PropTypes.object,
  pebMax: PropTypes.string,
  pebMin: PropTypes.string,
  provinceName: PropTypes.string,
};

PebInformation.propTypes = {
  min: PropTypes.any,
  max: PropTypes.any,
};

DeliveryDateInformation.propTypes = {
  date: PropTypes.any,
};

export default ProjectTopInfoContent;
