import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import Promotion from '../Promotion/Promotion';

/**
 * Class for detail page information.
 */
class Information extends React.Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state ={
      urlMap: '',
    };
  }

  /**
   *
   *@param {*} props
   *@param {*} state
  * @return {void}
   */
  static getDerivedStateFromProps(props, state) {
    const url = 'https://maps.google.com/?q=';
    state.urlMap = `${url}${props.coordinate.lat},${props.coordinate.lng}`;
    return state;
  }
  /**
  * @return {void}
  */
  componentDidMount() {
    const label = this.props.getLabelTranslate;
    label.informationShare = 'Partager cette belle opportunité ';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * accepts the id of the button and takes action coordinatelly
   */

  socialMediaButton = (e, projectName) =>{
    switch (e.target.id) {
      case 'fa-facebook-f':
        window.open('https://www.facebook.com/sharer.php?u='.concat(window.location.href), 'MsgWindow');
        break;
      case 'fa-linkedin-in':
        window.open('https://www.linkedin.com/shareArticle?mini=false&url='.concat(window.location.href), 'MsgWindow', 'fullscreen=yes');
        break;
      case 'fa-envelope':
        const r = window.open('mailto:?subject='.concat(projectName, '&body=').concat(''), 'MsgWindow', 'fullscreen=yes');
        setTimeout(function() {
          r.close();
        }, 400);
    }
  }
  /**
     *
     * @return {JSX.Element}
     */
  render() {
    return (
      <div className="project-detail-content-text-container col-md-8">
        <div className="project-detail-content-text-header-container">
          <div className="icon-group-container">
            <span>{this.props.getLabelTranslate.informationShare}</span>
            <a href="#" onClick={(e)=>this.socialMediaButton(e)}>
              <i className="fab fa-facebook-f" id="fa-facebook-f"></i>
            </a>
            <a href="#" onClick={(e)=>this.socialMediaButton(e)}>
              <i className="fab fa-linkedin-in" id="fa-linkedin-in"></i>
            </a>
            <a href="#" onClick={(e)=>this.socialMediaButton(e, this.props.projectName)}>
              <i className="fas fa-envelope" id="fa-envelope"></i>
            </a>
          </div>
        </div>
        <Promotion
          projectMedia={this.props.mediaId}
          getMediaBannerDataAll={this.props.getMediaBannerDataAll}
        />
        <div className="project-detail-content-text-body-container">
          <h2 id="project_name">{this.props.projectName}</h2>
          <p className="location">
            <i className="fas fa-map-marker-alt"></i>
            <span>
              <a href={this.state.urlMap} target="_blank" rel="noreferrer">
                <span className="location-province">{this.props.projectAddress}</span>
              </a>
            </span>
          </p>
          <h3 className="field-body-title">Information sur le projet</h3>
          <div className="field-body">
            <p><span className="text-bold"></span></p>
            <p><span className="text-bold"></span></p>
            <p className="detail">
              <span
                dangerouslySetInnerHTML=
                  {{__html: this.props.projectDescription}}>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

Information.propTypes = {
  projectName: PropTypes.string,
  projectAddress: PropTypes.string,
  projectDescription: PropTypes.string,
  projectProvince: PropTypes.string,
  mediaId: PropTypes.array,
  getLabelTranslate: PropTypes.object,
  createLabelTranslate: PropTypes.func,
  getMediaBannerDataAll: PropTypes.object,
  coordinate: PropTypes.object,
};

const stateToProps= (state)=>{
  return state;
};


export default connect(stateToProps,
    {createLabelTranslate},
)(Information);
