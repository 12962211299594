import axios from 'axios';


/**
 * @param {*} params
 * @return {any}
 */
export const getLabelTranslate = async (params) => {
  const url = process.env.REACT_APP_BASE_DRUPAL_URL+
  '/tp-plugin-react/api/translate';
  const formData = new FormData();
  if (typeof params.lang !== 'undefined' &&
  typeof params.value !== 'undefined') {
    if (Object.keys(params.lang).length > 0) {
      formData.append('lang', params.lang);
    }
    if (Object.keys(params.value).length > 0) {
      Object.keys(params.value).forEach(function(key) {
        formData.append('value['+key+']', params.value[key]);
      });
    }
  }
  const result = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'text/json',
    },
  });
  return Promise.resolve(result);
};

export const getDirectionBaseurl = async (params) =>{
  const url = window.origin + '/api/direction-base-url';
  const result = await $.ajax({
    type: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'text/json',
    },
  });
  return Promise.resolve(result);
};

export const getManageableLabel = async () =>{
  const url = window.origin + '/api/manageable-label';
  const result = await $.ajax({
    type: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'text/json',
    },
  });
  return Promise.resolve(result);
};

export const getLabelMeetUs = async () =>{
  const url = window.origin + '/api/label-meet-us';
  const result = await $.ajax({
    type: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'text/json',
    },
  });
  return Promise.resolve(result);
};

export const getPromotionPriority = async () =>{
  const url = window.origin + '/api/get-promo-priority';
  const result = await $.ajax({
    type: 'GET',
    url: url,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'text/json',
    },
  });
  return Promise.resolve(result);
};

export const getTemplateConfig = async () => {
  const url = process.env.REACT_APP_BASE_DRUPAL_URL+
  '/modules/custom/tp_plugin_react/data.json';
  const result = await axios.get(url, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': '*/*',
    },
  });
  return Promise.resolve(result);
};


export const submitContactForm = async (params) => {
  const url = process.env.REACT_APP_BASE_API_CONTACT_URL +
      '/tp-plugin-react/api/contact/submit';

  const data = JSON.stringify({
    'contactFormData': params.data,
    'country': params.country,
    'language': 'fr',
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getManageableContents = async () => {
  const url = process.env.REACT_APP_BASE_DRUPAL_URL + '/tp-plugin-react/api/manageable-contents';

  const result = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      'accept': '*/*',
    },
  });
  return Promise.resolve(result);
};
