import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import {toggleFilter} from '../action';

/**
 * Class Type Filter.
*/
class TypeFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      labelButton: null,
      isFilter: false,
      isAppartement: false,
      isMaison: false,
      isTerrain: false,
      isCommercial: false,
      isBureau: false,
      filterSelected: [],
      selectType: false,
      appartementValue: 1,
      maisonValue: 2,
      terrainValue: 3,
      commercialValue: 4,
      bureauValue: 5,
    };
  }

  /**
   * Load Componnet
   */
  async componentDidMount() {
    // This statement for translate label
    const label = this.props.getLabelTranslate;
    label.typeFilterGoodChoice = 'Type de bien';
    label.typeFilterApartment = 'Appartement';
    label.typeFilterHouse = 'Maison';
    label.typeFilterLandToBuild = 'Terrain à bâtir';
    label.typeFilterCommercialSpace = 'Espace commercial';
    label.typeFilterOfficeSpace = 'Espace de bureau';
    label.typeFilterCancel = 'Annuler';
    label.typeFilterApply = 'Appliquer';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    const filterSelected = props.filterValue;
    if (filterSelected.length > 0 && state.filterSelected.length === 0) {
      const isAppartement = filterSelected.includes(1);
      const isMaison = filterSelected.includes(2);
      const isTerrain = filterSelected.includes(3);
      const isCommercial = filterSelected.includes(4);
      const isBureau = filterSelected.includes(5);

      state.labelButton = filterSelected.length;
      state.isFilter = true;
      state.selectType = true;
      state.isAppartement = isAppartement;
      state.isMaison = isMaison;
      state.isTerrain = isTerrain;
      state.isCommercial = isCommercial;
      state.isBureau = isBureau;
      state.filterSelected = filterSelected;
    }
    return state;
  }

  /**
   * Check if component is updated
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.filterValue !== prevProps.filterValue) {
      if (this.props.filterValue.length === 0) {
        this.setState({
          selectType: false,
          labelButton: null,
          isFilter: false,
          filterSelected: [],
          isAppartement: false,
          isMaison: false,
          isTerrain: false,
          isCommercial: false,
          isBureau: false,
        });
      }
    }
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  showTypeFilter() {
    this.setState({
      selectType: this.state.selectType,
      showFilter: !this.state.showFilter,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': !this.props.getFilter.filterType,
      'filterPrice': false,
      'filterRoom': false,
      'filterSurface': false,
    });
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  appartementCheckBox() {
    if (this.state.isAppartement === true) {
      this.setState({isAppartement: false});
      this.handleRemoveCheckBox(this.state.appartementValue);
    } else {
      this.setState({
        isAppartement: true,
        filterSelected: [...this.state.filterSelected,
          this.state.appartementValue],
      });
    }
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  maisonCheckBox() {
    if (this.state.isMaison === true) {
      this.setState({isMaison: false});
      this.handleRemoveCheckBox(this.state.maisonValue);
    } else {
      this.setState({
        isMaison: true,
        filterSelected: [...this.state.filterSelected,
          this.state.maisonValue],
      });
    }
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  terrainCheckBox() {
    if (this.state.isTerrain === true) {
      this.setState({isTerrain: false});
      this.handleRemoveCheckBox(this.state.terrainValue);
    } else {
      this.setState({
        isTerrain: true,
        filterSelected: [...this.state.filterSelected,
          this.state.terrainValue],
      });
    }
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  commercialCheckBox() {
    if (this.state.isCommercial === true) {
      this.setState({isCommercial: false});
      this.handleRemoveCheckBox(this.state.commercialValue);
    } else {
      this.setState({
        isCommercial: true,
        filterSelected: [...this.state.filterSelected,
          this.state.commercialValue],
      });
    }
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  bureauCheckBox() {
    if (this.state.isBureau === true) {
      this.setState({isBureau: false});
      this.handleRemoveCheckBox(this.state.bureauValue);
    } else {
      this.setState({
        isBureau: true,
        filterSelected: [...this.state.filterSelected,
          this.state.bureauValue],
      });
    }
  }

  /**
   * handle remove checkbox.
   * @param {number} value text output val.
   */
  handleRemoveCheckBox(value) {
    const types = this.state.filterSelected;
    const toRemove = value;
    const index = types.indexOf(toRemove);
    if (index > -1) {
      types.splice(index, 1);
    }
  }

  /**
   * Submit Type Filter.
   * @param {number} value text output val.
   */
  async submitTypeFilter() {
    if (this.state.filterSelected.length > 0) {
      this.setState({
        selectType: true,
        isFilter: true,
        value: this.state.value,
        labelButton: this.state.filterSelected.length,
      });
    } else {
      this.setState({
        isFilter: false,
      });
    }
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': !this.props.getFilter.filterType,
    });
    this.props.submitHandle(this.state.filterSelected);
  }

  /**
   * Clear Type Filter.
   * @param {number} value text output val.
   */
  async cancelTypeFilter() {
    this.setState({
      selectType: false,
      labelButton: null,
      isFilter: false,
      filterSelected: [],
      isAppartement: false,
      isMaison: false,
      isTerrain: false,
      isCommercial: false,
      isBureau: false,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': !this.props.getFilter.filterType,
    });
    this.props.cancelHandle();
  }

  /**
   * handle close backdrop.
   */
  handleClickBackDrop() {
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': !this.props.getFilter.filterType,
      'filterPrice': false,
      'filterRoom': false,
      'filterSurface': false,
    });
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="btn-filter-container">
        <button
          className=
            {`btn bn-small btn-filter btn-filter-type btn-width-large 
            ${!this.props.getFilter.filterType ? ' ' : 'is-collapsed'} 
            ${!this.state.isFilter ? ' ' : 'is-filter'}
            ${this.props.getFilter.filterType ? 'is-up' : ''}
            `}
          onClick={this.showTypeFilter.bind(this)}
        >
          <span className="span-price-filter">
            {this.props.getLabelTranslate.typeFilterGoodChoice}</span>
          {(() => {
            if (this.state.selectType === true) {
              return (
                <div className="badge">
                  <span>{this.state.labelButton}</span>
                </div>
              );
            }
          })()}
          <i className="fas fa-chevron-down"></i>
        </button>
        <div className="filter-box-container">
          <div className="filter-body-container-react">
            <div className="checkbox-filter-input">
              <input type="checkbox" id="1" name="filterType" value="1"
                className="checkbox-control"
                checked={this.state.isAppartement}
                onChange={this.appartementCheckBox.bind(this)} />
              <label htmlFor="1">
                {this.props.getLabelTranslate.typeFilterApartment}</label>
            </div>

            <div className="checkbox-filter-input">
              <input type="checkbox" id="2" name="filterType" value="2"
                className="checkbox-control"
                checked={this.state.isMaison}
                onChange={this.maisonCheckBox.bind(this)} />
              <label htmlFor="2">
                {this.props.getLabelTranslate.typeFilterHouse}</label>
            </div>

            <div className="checkbox-filter-input">
              <input type="checkbox" id="3" name="filterType" value="3"
                className="checkbox-control"
                checked={this.state.isTerrain}
                onChange={this.terrainCheckBox.bind(this)} />
              <label htmlFor="3">
                {this.props.getLabelTranslate.typeFilterLandToBuild}</label>
            </div>

            <div className="checkbox-filter-input">
              <input type="checkbox" id="4" name="filterType" value="4"
                className="checkbox-control"
                checked={this.state.isCommercial}
                onChange={this.commercialCheckBox.bind(this)} />
              <label htmlFor="4">
                {this.props.getLabelTranslate.typeFilterCommercialSpace}</label>
            </div>

            <div className="checkbox-filter-input">
              <input type="checkbox" id="5" name="filterType" value="5"
                className="checkbox-control"
                checked={this.state.isBureau}
                onChange={this.bureauCheckBox.bind(this)} />
              <label htmlFor="5">
                {this.props.getLabelTranslate.typeFilterOfficeSpace}</label>
            </div>
          </div>
          <div className="filter-action-container">
            <button className="btn-filter-cancel"
              onClick={this.cancelTypeFilter.bind(this)}>
              {this.props.getLabelTranslate.typeFilterCancel}
            </button>
            <button className="btn-filter-submit"
              onClick={()=>this.submitTypeFilter()}>
              {this.props.getLabelTranslate.typeFilterApply}
            </button>
          </div>
        </div>
        {/* eslint-disable-next-line max-len */}
        { this.props.getFilter.filterType && ( <div className="is-collapsed" onClick={ ()=> this.handleClickBackDrop()}/>) }
      </div>
    );
  }
}

TypeFilter.propTypes = {
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  history: PropTypes.object,
  submitHandle: PropTypes.func,
  cancelHandle: PropTypes.func,
  filterValue: PropTypes.array,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {
      createLabelTranslate,
      toggleFilter,
    },
)(TypeFilter);
