import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import ProjectItemImages from './ProjectItemImages';
import {connect} from 'react-redux';
import {createMapLocation, createPopupProjectId, createHoveringProjectId, createContactInformation} from './action';
import {setFilterProjectDetail} from '../ProjectFilter/action';
import {constructFilterProjectDetails, createTitleFromProject} from '../../helpers/helper';
import {toggleMapMobile} from '../MainMap/action';
import PriceBlockNd from './PriceBLockNd';
import PriceTooltip from './PriceTooltip';
import PriceAllTooltip from './PriceAllTooltip';

/**
 * @param {Object} props
 * @return {void}
 */
function ProjectItemNd(props) {
  let keysOfType;
  if (props.project.countOfTypeOfPropertiesInProject.length > 1) {
    keysOfType = props.project.countOfTypeOfPropertiesInProject.map(function(x) {
      return x.typeOfPropertyTranslation;
    }).join('-et-');
  } else {
    if (props.project.countOfTypeOfPropertiesInProject.length) {
      keysOfType = props.project.countOfTypeOfPropertiesInProject[0].typeOfPropertyTranslation;
    } else {
      keysOfType ='project';
    }
  }
  let projectName = props.project.projectName ?? '';
  projectName = props.project.projectName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  let url= `${window.origin}/acheter/${keysOfType}/${projectName}/${props.project.projectProvince}/${props.project.projectId}`;
  if (url !== undefined) {
    url = url.replaceAll(' ', '-');
    url = url.replaceAll(/'/g, '');
  } else {
    url = `${window.origin}/acheter/${projectDetail.projectId}`;
  }
  if (props.project.miniSite !== null && props.project.miniSite.length > 0) {
    if (props.project.miniSite.startsWith('http')) {
      url = props.project.miniSite;
    } else {
      url = `https://${props.project.miniSite}`;
    }
  }

  const cardListText = useRef(null);

  const handleItemClick = async function(e) {
    const miniSite = props.project.miniSite;
    e.stopPropagation();
    e.preventDefault();
    const searchParam = await props.getRequestSearchProjects;
    const filterDetailProject = constructFilterProjectDetails({
      ...searchParam.projectPropertiesSearchCriteria,
      ...searchParam.sorting,
      projectId: props.project.projectId,
    });
    let keysOfType;
    if (props.project.countOfTypeOfPropertiesInProject.length > 1) {
      keysOfType = props.project.countOfTypeOfPropertiesInProject.map(function(x) {
        if (x!==undefined) {
          return x.typeOfPropertyTranslation;
        }
        return x;
      }).join('-et-');
    } else {
      if (props.project.countOfTypeOfPropertiesInProject.length) {
        keysOfType = props.project.countOfTypeOfPropertiesInProject[0].typeOfPropertyTranslation;
      } else {
        keysOfType ='project';
      }
    }
    let projectName = props.project.projectName ?? '';
    projectName = projectName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    let urlButton = `/acheter/${keysOfType}/${projectName ? encodeURI(projectName.replaceAll(' ', '-')) : 'undefined'}/${props.project.projectProvince ? encodeURI(props.project.projectProvince.replaceAll(' ', '-')) : 'undefined'}/${props.project.projectId}`;

    if (urlButton !== undefined) {
      urlButton = urlButton.replaceAll(' ', '-');
      urlButton = urlButton.replaceAll(/'/g, '');
    }
    props.setFilterProjectDetail(filterDetailProject);
    if (miniSite !== null && miniSite.length > 0 ) {
      if (miniSite.startsWith('http')) {
        window.open(miniSite);
      } else {
        window.open(`https://${props.project.miniSite}`);
      }
    } else {
      window.location.href = urlButton;
    }
  };

  /**
   *
   * @param {*} e
   */
  const setFilterProject = async function(e) {
    const searchParam = await props.getRequestSearchProjects;
    const filterDetailProject = constructFilterProjectDetails({
      ...searchParam.projectPropertiesSearchCriteria,
      ...searchParam.sorting,
      projectId: props.project.projectId,
    });
    props.setFilterProjectDetail(filterDetailProject);
  };

  /**
   * @param {object} e
   * @return {void}
   */
  const triggerThePopUp = (e) =>{
    e.stopPropagation();
    e.preventDefault();
    const center = {
      lat: props.project.projectCoordinates.lat,
      lng: props.project.projectCoordinates.lon,
    };

    // ste maps location
    props.createMapLocation({
      center,
      zoom: 20,
      projectId: props.project.projectId,
    });

    // create props to maps for set the ID
    props.createPopupProjectId(null);

    setTimeout(function() {
      props.createPopupProjectId(props.project.projectId);
      document.querySelector('.map-canvas-container').classList.add('popup-project-is-show');
    }, 1000);
  };

  /**
   * @param {object} e
   * @return {void}
   */
  const triggerThePopUpMobile = (e) =>{
    e.stopPropagation();
    e.preventDefault();
    const center = {
      lat: props.project.projectCoordinates.lat,
      lng: props.project.projectCoordinates.lon,
    };
    // ste maps location
    props.createMapLocation({
      center,
      zoom: 20,
      projectId: props.project.projectId,
    });

    // create props to maps for set the ID
    props.createPopupProjectId(null);

    setTimeout(() => {
      props.createPopupProjectId(props.project.projectId);
      const pinClusters = document.querySelectorAll('.pin-cluster');
      pinClusters.forEach((element) => {
        element.classList.add('pin-selected');
      });
    }, 1000);
    // create props to maps for set the ID
    props.toggleMapMobile(true);
  };

  /**
   * @param {object} e
   * @return {void}
   */
  const onMouseEnter = (e) =>{
    e.stopPropagation();
    e.preventDefault();
    props.createHoveringProjectId({
      projectId: props.project.projectId,
    });
  };

  /**
   * @param {object} e
   * @return {void}
   */
  const onMouseLeave = (e) =>{
    e.stopPropagation();
    e.preventDefault();
    props.createHoveringProjectId({
      projectId: undefined,
    });
  };
  let hardcodedCondition = {
    conditions: [
      {
        title: 'Prix TTC',
        symbol: '1',
      },
    ],
    noIcon: true,
  };
  if (props.templateConfig.country_code === 'BE') {
    hardcodedCondition = {
      conditions: [
        {
          title: 'Prix hors frais et taxes',
          symbol: '1',
        },
        {
          title: 'Prix TVA & droits d’enregistrement compris ainsi que les éventuelles unités secondaires obligatoires',
          symbol: '2',
        },
      ],
      noIcon: true,
    };
  }

  if (props.templateConfig.country_code === 'LU') {
    hardcodedCondition = {
      conditions: [
        {
          title: 'Prix TVA à 3% comprise sous condition d\'acceptation de votre dossier par l\'administration de la TVA.',
          symbol: '1',
        },
      ],
      noIcon: true,
    };
  }
  return (
    <div className="card-result-react card-result-react-rd" onContextMenu={setFilterProject} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="card-result-main-container">
        <div className="card-result-image-content-container">
          <div
            className="card-result-image-container with-banner">
            <ProjectItemImages
              projectName={createTitleFromProject(props.project)}
              images={props.project.projectCatalogImages}
              isLastOpportunity={props.project.isLastOpportunity}
              isNew={props.project.isNew}
              getMediaBannerDataAll={props.getMediaBannerDataAll}
              mediaTemplates={props.project.mediaTemplates}
              cardListText={cardListText}
            />
          </div>
        </div>
        <a href={url} onContextMenu={setFilterProject} onClick={handleItemClick} className="card-result-text-container">
          <div className="card-result-text-container__list" ref={cardListText}>
            <div className="card-result-text-body">
              <div className="card-body-top-container">
                <div className={`top-content ${props.project.projectName.length <= 20 ? 'top-content--inline' : null }`}>
                  <div className={`card-location-container-tpr ${(props.project.noPropertyProject)?'full-width':''}`}>
                    <h2>{props.project.projectName}</h2>
                  </div>
                  {(props.templateConfig && props.templateConfig.country_code === 'BE') ? (
                      <div className="summary-location-container-rd">
                        <p className="province">{props.project.projectProvince}</p>
                        <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0C2.68993 0 0 2.67949 0 5.97656C0 7.09219 0.310067 8.17797 0.900033 9.1143L5.59327 16.7648C5.67333 16.8943 5.79333 16.974 5.93337 16.9939C6.1233 17.0237 6.3334 16.9441 6.44337 16.7548L11.15 9.03457C11.71 8.1182 12 7.05238 12 5.97656C12 2.67949 9.31007 0 6 0Z" fill="currentColor"/></svg>
                      </div>
                  ):(<></>)}
                </div>
                <div className="summary-number-rd">
                  {props.project.countOfTypeOfPropertiesInProject.map((type, i) =>
                    <div
                      key={type.typeOfPropertyTranslation}
                      className="summary-number-item">
                      {(i > 0) && <span className="summary-separator">&nbsp;-&nbsp;</span>}
                      <span className="summary-number-total">{type.count}&nbsp;</span>
                      <span className="summary-type">{type.typeOfPropertyTranslation.toLowerCase()}</span>
                    </div>,
                  )}
                </div>
                <PriceBlockNd project={props.project} templateConfig={props.templateConfig}></PriceBlockNd>
                <div className="card-result-terms-container red">
                  {!props.project.noPropertyProject && props.project.action && <PriceAllTooltip contactInformation={ props.contactInformation } property={props.project.action}/>}
                </div>
              </div>
            </div>

          </div>
        </a>
      </div>
      <div className="card-result-text-footer">
        <button type="button"
          id="trigger-pop-up-button"
          className="btn btn-primary
                  btn-medium-40 btn-border-only btn-icon-left btn-show-location"
          onClick={triggerThePopUp}
        >
          <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 0C2.68993 0 0 2.67949 0 5.97656C0 7.09219 0.310067 8.17797 0.900033 9.1143L5.59327 16.7648C5.67333 16.8943 5.79333 16.974 5.93337 16.9939C6.1233 17.0237 6.3334 16.9441 6.44337 16.7548L11.15 9.03457C11.71 8.1182 12 7.05238 12 5.97656C12 2.67949 9.31007 0 6 0Z" fill="currentColor"/></svg>
          <span>{props.translate.projectItemShowMap}</span>
        </button>
        <button type="button" className="btn btn-primary btn-medium-40 btn-border-only btn-show-mobile" onClick={triggerThePopUpMobile}><span>Afficher sur la carte</span></button>
        <button
          className="btn btn-primary btn-medium-40"
          onClick={handleItemClick}>
          {props.translate.projectItemLearnMore}
        </button>
      </div>
      <div className="card-result-terms-container">
        {!props.project.noPropertyProject && props.project.action && <PriceTooltip contactInformation={ props.contactInformation } property={hardcodedCondition}/>}
      </div>
    </div>
  );
}


/**
 *
 * @param {any} props
 * @return {JSX.Element}
 * @constructor
 */
function PriceBlock(props) {
  const project = props.data;
  if (props.price > 0) {
    return (
      <div className="price">
        <p className="price-text">
          {project.translate.projectItemFrom+' '}
          {props.price.toLocaleString('nl-be')} {project.translate.projectItemDollar}*<i></i></p>
        <p className="desc-text">*
          {project.translate.projectItemPriceExc}</p>
      </div>
    );
  }
  return <div></div>;
}


ProjectItemNd.propTypes = {
  templateConfig: PropTypes.object,
  project: PropTypes.object,
  translate: PropTypes.object,
  createMapLocation: PropTypes.func,
  getMapLocation: PropTypes.any,
  createPopupProjectId: PropTypes.func,
  createHoveringProjectId: PropTypes.func,
  getRequestSearchProjects: PropTypes.objectOf(PropTypes.any),
  setFilterProjectDetail: PropTypes.func,
  history: PropTypes.object,
  getContactFormInformation: PropTypes.object,
  createContactInformation: PropTypes.func,
  toggleMapMobile: PropTypes.func,
  miniSite: PropTypes.string,
  getMediaBannerDataAll: PropTypes.object,
  getProjectDetail: PropTypes.object,
  getProjectPropertiesDetail: PropTypes.object,
  contactInformation: PropTypes.object,
};

PriceBlock.propTypes = {
  data: PropTypes.object,
  price: PropTypes.number,
};


const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {
      createMapLocation,
      createPopupProjectId,
      createHoveringProjectId,
      setFilterProjectDetail,
      createContactInformation,
      toggleMapMobile,
    },
)(ProjectItemNd);
