import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../pages/action';
import {createContactInformation} from './action';
import {SlideDown} from 'react-slidedown';
import {isMobileOnly} from 'react-device-detect';

/**
 * Main Component
 */
class DetailContactLocation extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      closed: false,
    };
  }

  /**
   * @return {void}
   *
   * @param {*} state;
   * @param {*} props;
   */
  async componentDidMount() {
    this.props.createContactInformation(this.props);
    const label = this.props.getLabelTranslate;
    label.localiserBtn = 'Localiser sur une carte';
    label.contactBtn = 'Demander un rendez-vous';
    label.contactLocationTitle = 'Rencontrons-nous';
    label.contactLocationSubtitle = 'sur place';
    label.localisationLabel = 'LOCALISATION';
    label.horairesLabel = 'HORAIRES';
    label.contactLabel = 'CONTACT';
    this.props.createLabelTranslate(label);
    if (typeof this.props.expand === 'undefined' && isMobileOnly) {
      this.setState({
        closed: true,
      });
    }
  }

  /**
   *
   * @param {*} coordinate;
   */
  handleClick = (coordinate) => {
    window.open('https://maps.google.com?q='+coordinate.lat+','+coordinate.lon );
  }

  /**
   *
   * @param {*} coordinate;
   */
  openModal() {
    this.props.getContactFormInformation.showModal = true;
    this.props.getContactFormInformation.sociCode =
        this.props.contactInformation.sociCode;
    this.props.getContactFormInformation.contactId =
        this.props.contactInformation.id;
    this.props.getContactFormInformation.pointContactType =
        this.props.contactInformation.pointContactType;
    this.props.getContactFormInformation.projectName =
        this.props.contactInformation.projectName;
    this.props.getContactFormInformation.purpose =
        'visit_demonstration_apartment';
    this.props.getContactFormInformation.projectId =
        this.props.contactInformation.projectId;
    this.props.getContactFormInformation.contactUs = false;
    this.props.getContactFormInformation.isAcheter = false;
    this.props.createContactInformation(this.props.getContactFormInformation);
  }

  /**
   * toggle
   * @param {boolean} expand;
   */
  toggle(expand) {
    if (expand) return;
    this.setState({closed: !this.state.closed});
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    const openDoorsInfo = this.props.contactInformation.openDoorsInfo;
    const label = this.props.getLabelTranslate;
    return (
      <div>
        <div className="project-detail-content-location-red-header" onClick={ ()=> this.toggle(this.props.expand) }
          id="contactTypeId"
        >
          <div className="header-title-wrapper">
            <h4>{label.contactLocationTitle}</h4>
            <h5>{label.contactLocationSubtitle}</h5>
          </div>
          <div className="image-wrapper">
            <img src={`${process.env.REACT_APP_BASE_DRUPAL_URL}/themes/thomaspiron/images/booking/tp-mobile-header.png`} />
          </div>
        </div>
        <SlideDown className={`project-detail-content-location-body ${this.state.closed ? 'is-close' : ''}`} closed={ this.state.closed }>
          <div className="field-body">
            <p className="project-contact-type">
              {this.props.contactInformation.pointContactType}
            </p>
            <p
              className="subtitle">
              <i className="fas fa-map-marker-alt"></i>
              {label.localisationLabel}
            </p>
            <p>
              <span>
                {this.props.contactInformation.address}
              </span>
            </p>
            <p
              className="subtitle">
              <i className="fas fa-clock"></i>
              {label.horairesLabel}
            </p>
            <p>
              <ul dangerouslySetInnerHTML={{__html: openDoorsInfo}}></ul>
            </p>
            <p className="subtitle">
              <i className="fas fa-phone"></i>
              {label.contactLabel}
            </p>
            <p>
              <span>
                <a href={'tel:' + this.props.contactInformation.phoneContact}>
                  {this.props.contactInformation.phoneContact}
                </a>
              </span>
            </p>
          </div>
          <div className="field-cta">
            <a
              className="btn btn-large
              btn-border-only
              btn-primary btn-direction"
              style= {{height: 'auto'}}
              onClick={() =>
                this.handleClick(
                    this.props.contactInformation.projectCoordinate,
                )
              }
            >
              {label.localiserBtn}
            </a>
            <a className="btn btn-large btn-primary btn-modal-absolute-react"
              id="btnModalContactProject"
              style= {{height: 'auto'}}
              onClick={this.openModal.bind(this)}
            >
              {label.contactBtn}
            </a>
          </div>
        </SlideDown>
      </div>
    );
  }
}

DetailContactLocation.propTypes = {
  contactInformation: PropTypes.any,
  createContactInformation: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  createLabelTranslate: PropTypes.func,
  getContactFormInformation: PropTypes.object,
  expand: PropTypes.bool,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createLabelTranslate, createContactInformation},
)(DetailContactLocation);
