import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {collectImageWithAltDynamic} from '../HeaderBanner/setImage';
import {connect} from 'react-redux';
import {orderImages, iconURL, getRecomendedResolution} from '../../helpers/helper';

const ProjectItemImages = ({projectName, images, isLastOpportunity, isNew, getMediaBannerDataAll, mediaTemplates, cardListText}) => {
  const [mediaBanner] = useState([]);
  const [divMedia, setDivMedia] = useState('');
  const [cssMedia, setCssMedia] = useState('');
  let resolution = getRecomendedResolution();
  resolution = resolution == 1600 ? 1200 : resolution;
  let imagesList = collectImageWithAltDynamic(images, resolution, projectName, true);
  imagesList = orderImages(imagesList).slice(0, 3);
  const mainImage = (imagesList.length > 0) ? imagesList[0] : '';

  useEffect(() => {
    if (Object.keys(getMediaBannerDataAll).length != 0) {
      const result = getMediaBannerDataAll;
      // media promotion
      for (const media of result.medias) {
        mediaBanner[media.id] = {
          'div': media.div,
          'css': media.css,
        };
      }
    }
    if (mediaTemplates.length > 0) {
      if (typeof mediaBanner[mediaTemplates[0].mediaId] !== 'undefined') {
        let divBanner = mediaBanner[mediaTemplates[0].mediaId].div;
        if (mediaTemplates[0].openningType !== null) {
          divBanner = divBanner.replace('[[OpenningType]]', mediaTemplates[0].openningType);
          divBanner = divBanner.replace('[[OpenningContent]]', mediaTemplates[0].openningContent);
        }
        setDivMedia(divBanner);
        setCssMedia(mediaBanner[mediaTemplates[0].mediaId].css);
      }
    }
  });

  return (
    <div className="card-result-image-container tpr-card-result-slick-list">
      <div className="image-wrapper">
        {mainImage?
          <img src={`${mainImage.url}`} alt={`${mainImage.alt}`}/> : ''
        }
        {isLastOpportunity?
          <div className="banner-triangle">
            <div className="banner-triangle-content">
              <img className="banner-triangle-icon" src={iconURL('icon-runningman.svg')} />
              <span className="banner-triangle-text">DERNIÈRE(S)<br />OPPORTUNITÉ(S)</span>
            </div>
          </div> : ''
        }

        {isNew?
        <div className="banner-triangle">
          <div className="banner-triangle-content is-new">
            <img className="banner-triangle-icon" src={iconURL('icon-bell.svg')} />
            <span className="banner-triangle-text">NOUVEAU</span>
          </div>
        </div> : ''}

        {
          mediaTemplates.length > 0 ?
          <div className="card-result-image-banner-promotional banner-top-only">
            <style dangerouslySetInnerHTML={{__html:
              `${cssMedia}`}} />
            <div className="campaign-marketing-wrapper"
              dangerouslySetInnerHTML=
                {{__html: divMedia}}>
            </div>
          </div> : ''
        }
      </div>
    </div>
  );
};

ProjectItemImages.propTypes = {
  projectName: PropTypes.string,
  images: PropTypes.array,
  isLastOpportunity: PropTypes.bool,
  isNew: PropTypes.bool,
  getMediaBannerDataAll: PropTypes.object,
  mediaTemplates: PropTypes.array,
  cardListText: PropTypes.object,
};

const mapsStateToProps= (state)=>{
  return state;
};

export default connect(mapsStateToProps)(ProjectItemImages);
