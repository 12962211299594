import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../pages/action';
import {createCoordinateMaps} from './action';
const googleApiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`;


/**
 *
 * @param {*} maps
 * @return {any}
 */
function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
      style: maps.ZoomControlStyle.SMALL,
    },
    fullscreenControl: false,
  };
}

/**
 * Main Component
 */
class DetailMaps extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      showMap: true,
      center: {
        lat: 59.95,
        lng: 30.33,
      },
    };
  }

  /**
  * @return {void}
  */
  async componentDidMount() {
    // eslint-disable-next-line max-len
    const coordinate = {};
    coordinate.lat = this.state.center.lat;
    coordinate.lng = this.state.center.lng;
    this.props.createCoordinateMaps(coordinate);

    const label = this.props.getLabelTranslate;
    label.detailMapsTitle = 'Situation du projet';
    this.props.createLabelTranslate(label);
    // eslint-disable-next-line max-len
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    // map container
    return (
      <div
        className="project-detail-content-location-map-container"
        id="miniMapProject">
        <h2 className="text-title">
          {this.props.getLabelTranslate.detailMapsTitle}</h2>
        <div className="map-container" >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: googleApiKey,
            }}
            center={this.props.loc}
            zoom={15}
            defaultCenter={this.state.center}
            options={createMapOptions}
          >
            <DefaultPlace
              lat={this.props.loc.lat }
              lng={this.props.loc.lng }
            />
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}


const DefaultPlace = (props) => {
  const containerIcon = {
    width: '27px',
    height: '43px',
    overflow: 'hidden',
    position: 'absolute',
    left: '-14px',
    top: '-43px',
  };

  return (
    <div style={containerIcon}>
      <img src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png"
        width="27px" height="43px" alt="location"/>
    </div>
  );
};

DetailMaps.propTypes = {
  coordinate: PropTypes.any,
  createLabelTranslate: PropTypes.func,
  createCoordinateMaps: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getCoordinateMaps: PropTypes.object,
  loc: PropTypes.any,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createLabelTranslate, createCoordinateMaps},
)(DetailMaps);
