import React, {Component} from 'react';
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate, createParamsInvestFiltering} from '../../../pages/action';

/**
 * Class Price Filter.
*/
class PriceFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      value: {
        min: 0,
        max: 0,
      },
      priceDefaultMin: 0,
      priceDefaultMax: 0,
      resetTimer: 1,
    };
  }

  /**
  * @return {void}
  */
  async componentDidMount() {
    // This statement for translate label
    const label = this.props.getLabelTranslate;
    label.priceFilterPrice = 'Prix';
    label.priceFilterCancel = 'Annuler';
    label.priceFilterApply = 'Appliquer';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    const minPrice = Math.floor(props.getProjectInvestFilters.minPrice);
    const maxPrice = Math.ceil(props.getProjectInvestFilters.maxPrice);

    state.priceDefaultMin = minPrice;
    state.priceDefaultMax = maxPrice;

    if (state.resetTimer !== props.resetTimer) {
      state.value.min = minPrice;
      state.value.max = maxPrice;
      state.resetTimer = props.resetTimer;
    } else {
      if (state.value.min < minPrice) {
        state.value.min = minPrice;
      }

      if (state.value.max > maxPrice || state.value.max < minPrice) {
        state.value.max = maxPrice;
      }
    }

    return state;
  }

  /**
   * Change Price Filter.
   * @param {number} value text output val.
   */
  handleChangePrice(value) {
    this.setState({
      value: value,
    });

    let params = this.props.getParamsInvestFiltering;
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        'priceMin': value.min,
        'priceMax': value.max,
      },
    };

    this.props.createParamsInvestFiltering(params);
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="investir-filter-container">
        <div className="investir-container filter-price">
          <div className="investir-filter-body-container-react">
            <p className="label-price">
              <span className="price-total" id="highlight-total-filter">
                <span className="invest-price-filter">€ {(typeof (this.state.value.min) === 'undefined') ? 0 : new Intl.NumberFormat('de-DE').format(this.state.value.min) }</span>
                <span className="invest-price-divider">-</span>
                <span className="invest-price-filter">€ {(typeof (this.state.value.max) === 'undefined') ? 0 : new Intl.NumberFormat('de-DE').format(this.state.value.max) }</span>
              </span>
            </p>
            <div className="slider-container">
              <form className="form">
                <InputRange
                  maxValue={this.state.priceDefaultMax}
                  minValue={this.state.priceDefaultMin}
                  formatLabel={(value) => (value !== null)?`€ ${new Intl.NumberFormat('de-DE').format(value).replace('.', ' ')}`:``}
                  value={this.state.value}
                  allowSameValues={true}
                  step={1000}
                  onChange={this.handleChangePrice.bind(this)} />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PriceFilter.propTypes = {
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getFilter: PropTypes.object,
  submitHandle: PropTypes.func,
  cancelHandle: PropTypes.func,
  getProjectInvestFilters: PropTypes.object,
  getParamsInvestFiltering: PropTypes.object,
  filterValue: PropTypes.object,
  filterOptions: PropTypes.object,
  resetTimer: PropTypes.number,
  createParamsInvestFiltering: PropTypes.func,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps, {
  createLabelTranslate,
  createParamsInvestFiltering,
},
)(PriceFilter);
