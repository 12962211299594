import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import {
  createContactInformation,
  createContactFormInformation,
  createContactData,
  createContactDataLayer,
} from '../action';
import {submitContactForm} from '../../../services/DrupalApi';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import SimpleReactValidator from 'simple-react-validator';
import {
  getCitiesForZipCode,
  getZipCodeByCity,
  getPostalCodeDrupal,
} from '../../../services/ExternalApi';
import TagManager from 'react-gtm-module';
import {PHONE_INFO_BY_COUNTRY_CODE} from '../../../helpers/constants';

/**
 * Main Component
 */
class BasicContactForm extends React.Component {
  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          value: '',
          label: 'Choose',
        },
      ],
      termsCheckbox: false,
      showModal: false,
      contactId: null,
      hourOptions: [],
      minuteOptions: [],
      contactphase: 'openForm',
      // contactphase: 'showResult',
      disabledHour: true,
      disabledMinute: true,
      submitResult: {
        image: '',
        message: '',
        messageSecond: '',
      },
      defaultTel: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      city: '',
      cityId: '',
      postalcode: '',
      postalcodeId: '',
      message: '',
      pdf: undefined,
      cityValue: {
        value: '',
        label: '',
      },
      timeout: '',
      cptCode: '',
      project: {},
      arrTrackedEntity: ['TPBat', 'TPHome'],
      goal: false,
      mailbox: '',
      phoneInfo: PHONE_INFO_BY_COUNTRY_CODE.hasOwnProperty(props.templateConfig.country_code) ?
        PHONE_INFO_BY_COUNTRY_CODE[props.templateConfig.country_code]: PHONE_INFO_BY_COUNTRY_CODE['BE'],
      postalCodeCountryMessage: '',
    };

    this.setValidation();
  }

  /**
   * @param {Object} props
   * @param {Object} state
   * @return {Object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.cptCode = props.propertyModal.property.cptCode;
    state.project = props.project;

    return state;
  }

  /**
   * initialize validation for contactform
   */
  setValidation() {
    const self = this;
    const companyName = this.props.project.companyName;
    this.validator = new SimpleReactValidator({
      validators: {
        intlTelNumber: {
          message: 'Veuillez ajouter votre préfixe national. Ex.: '+ this.state.phoneInfo.format,
          rule: (val, params, validator) => {
            const numbers = val.split(/\d/).length - 1;
            return (
              8 <= numbers && numbers <= 20 &&
                validator.helpers.testRegex(val,
                    /^(\+){0,1}(\d|\s|\(|\)){8,20}$/)
            );
          },
        },
        gdpr: {
          message: 'Veuillez accepter notre politique de confidentialité',
          rule: (val) => {
            return (val === true);
          },
        },
        datenotnull: {
          message: 'Veuillez entrer la date du rendez-vous',
          rule: (val) => {
            return (val !== '' && val !== null);
          },
          required: true,
        },
        letterWithAccents: {
          message: 'The prénom may only contain letters.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[A-Za-zÀ-ÖØ-öø-ÿ\s-.]+$/) && params.indexOf(val) === -1;
          },
          required: true,
        },
        zipNoResult: {
          message: 'Le code postal entré est invalide.',
          rule: (val, params, validator) => {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('country') != 'France') {
              if (val !== '') {
                if (self.props.getContactFormInformation.cityOptions !== undefined) {
                  if (self.props.getContactFormInformation.cityOptions.length === 0) {
                    return false;
                  } else {
                    return true;
                  }
                } else {
                  return false;
                }
              }
            } else {
              return true;
            }
          },
          required: true,
        },
        localite: {
          message: 'Le code postal entré est invalide.',
          rule: (val, params, validator) => {
            if (val === '' && (companyName !== 'Thomas & Piron BATIMENT' || companyName !== 'Thomas & Piron HOME')) {
              return false;
            }
          },
        },
      },
      messages: {
        required: 'Veuillez renseigner votre :attribute',
        lastname: 'Veuillez renseigner votre Nom',
        email: 'Veuillez renseigner votre adresse email',
        localite: 'Le code postal entré est invalide',
      },
    });
  }

  /**
   * @return {void}
   */
  async componentDidMount() {
    this.props.createContactInformation(this.props);
    const label = this.props.getLabelTranslate;
    label.cfTitle = 'VOUS SOUHAITEZ NOUS CONTACTER À PROPOS DU PROJET';
    label.cfSubtitle = 'RENCONTRONS-NOUS DANS LA MAISON EXPO DE';
    label.formFirstName = 'Prénom';
    label.phFirstName = 'Votre prénom';
    label.formLastName = 'Nom';
    label.phLastName = 'Votre nom';
    label.formEmail = 'E-mail';
    label.phEmail = 'E-mail';
    label.formPhone = 'Téléphone';
    label.phPhone = 'Ex.: '+ this.state.phoneInfo.format;
    label.formStreet = 'Nom de rue';
    label.phStreet = 'Votre nom de rue';
    label.formStreetNumber = 'Numéro de rue';
    label.phStreetNumber = 'Votre numéro de rue';
    label.formCodePostal = 'Code postal';
    label.phCodePostal = 'Votre code postal';
    label.formCity = 'Localité';
    label.phCity = 'Votre localité';
    label.formTermSentence = 'POLITIQUE DE CONFIDENTIALITÉ (POUR EN\n' +
        ' SAVOIR PLUS VOYEZ NOTRE\n' +
        ' <a\n' +
        ' href="https://www.thomas-piron.eu/politique-de-confidentialite"\n' +
        ' target="_blank">DÉCLARATION DE CONFIDENTIALITÉ</a>)';
    label.formTermLabel = 'J\'ACCEPTE VOTRE POLITIQUE DE\n' +
        ' CONFIDENTIALITÉ';
    label.formNotes1 = 'Vous pourrez toujours modifier\n' +
        ' votre consentement en nous\n' +
        ' contactant sur l\'adresse privacy@thomas-piron.eu.';
    label.formNotes2 = '* champ obligatoire';
    label.formSubmitBtn = 'Envoyer';
    label.closeModalBtn = 'Fermer';
    label.appointmentLabel = 'Choisissez un de nos ' +
        'créneaux encore disponibles:';
    label.mailBox = 'Boîte postale';
    label.phMailBox = 'Votre boîte postale';
    this.props.createLabelTranslate(label);
    const dt = this.props.getProjectDetail.projectDetails === undefined ? this.props.project : this.props.getProjectDetail.projectDetails;
    this.setState({
      projectId: dt.projectId,
      projectName: dt.projectName,
      sociCode: dt.sociCode,
      country: 'Belgique',
      countryId: 20,
    });
  }

  /**
   * @param {*} prevProps
   * @param {*} prevState
   */
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.showModal !==
        this.props.getContactFormInformation.showModal) {
      this.setState({
        showModal: this.props.getContactFormInformation.showModal,
      });
    }
    // when contactId changed contactform will load appointment data
    if (this.props.getContactFormInformation.contactId !==
      this.state.contactId &&
      this.props.getContactFormInformation.contactId !== undefined) {
      this.setState({
        contactId: this.props.getContactFormInformation.contactId,
        purpose: this.props.getContactFormInformation.purpose,
        projectName: this.props.getContactFormInformation.projectName,
        sociCode: this.props.getContactFormInformation.sociCode,
      });
    }
  }

  /**
   *
   * @return {void};
   */
  closeModal() {
    this.props.getContactFormInformation.showModal = false;
    this.props.getContactFormInformation.staticSelect = false;
    this.props.getContactFormInformation.postalCode = '';
    this.props.getContactFormInformation.idpostalCode = '';
    this.props.getContactFormInformation.cityOptions = [];
    this.props.createContactInformation(this.props.getContactFormInformation);
    this.resetForm('openForm');
    setTimeout(() => {
      // window.scrollTo(0, document.body.scrollHeight ||
      // document.documentElement.scrollHeight);
      document.documentElement.style.overflow = 'auto';
    }, 500);
    this.props.setModal();
  }

  /**
   *
   * @param {*} statusForm;
   */
  resetForm(statusForm) {
    this.setState({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      contactphase: statusForm,
      city: '',
      cityId: '',
      postalcode: '',
      postalcodeId: '',
      message: '',
      termsCheckbox: false,
      cptCode: '',
      mailBox: '',
    });
    this.validator.hideMessages();
    this.forceUpdate();
    document.getElementById('popupProjectDetailContactForm').reset();
  }


  /**
   * @return {void}
   */
  createDataLayer() {
    // cerate data layer
    if (this.state.arrTrackedEntity.indexOf(this.state.project?.entity) !== -1) {
      const contactDataLayer = this.props.createContactDataLayer(this.state);
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM,
        dataLayer: contactDataLayer.payload,
      });
    }
  }
  /**
   *
   * @return {void};
   */
  async submitForm() {
    if (this.validator.allValid()) {
      const formOdoo = this.props.createContactData(this.state);
      this.createDataLayer();
      if (this.props.contactPdf) {
        formOdoo.payload.propertyData.pdfPlan = this.props.property.propertyPdfLinks[0];
        formOdoo.payload.purpose = 'PropertyDocumentation';
      } else {
        formOdoo.payload.purpose = 'ContactAboutProperty';
      }
      formOdoo.payload.propertyData.sociCode = parseInt(this.props.property.sociCode) ?? '-';
      formOdoo.payload.propertyData.propertyType = parseInt(this.props.property.projectPropertyType) ?? '-';
      formOdoo.payload.propertyData.projectName = this.props.project.projectName ?? '-';
      formOdoo.payload.propertyData.propertyName = this.props.property.projectPropertyName ?? '-';
      let contactphase = 'loading';
      this.setState({
        contactphase,
      });

      const urlNew = formOdoo.payload.propertyData.url.split( '?' );
      formOdoo.payload.propertyData.url = urlNew[0];
      let res = await submitContactForm(
          {
            data: formOdoo.payload,
            country: this.props.templateConfig.country,
          },
      );
      res = res.data;
      let image = 'tp-icons-newsletter-new-success.svg';
      let message = 'Votre demande a bien été envoyée';
      let messageSecond = 'Nous vous contacterons dans les plus brefs délais';

      contactphase = 'showResult';
      if (!res.success) {
        image = 'tp-icons-newsletter-new-fail.svg';
        message = 'Notre site internet rencontre un problème.';
        messageSecond = 'Un email vous sera néanmoins envoyé ' +
            'pour confirmer votre rendez-vous.';
        res.processResults.forEach(function(item) {
          if (item.process === 'CreateAppointment' && item.result === 'Error') {
            contactphase = 'openForm';
          }
        });
        this.validator.showMessages();
      } else {
        this.resetForm('loading');
      }
      await this.setState({
        submitResult: {
          image,
          message,
          messageSecond,
        },
        contactphase,
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @return {void};
   * @param {*} e
   */
  async changeOptions(e) {
    const input = e.target.value;
    this.props.getContactFormInformation.staticSelect = false;
    const minLength = 3;
    if (input.length > minLength) {
      this.props.getContactFormInformation.loadingOptions = true;
      this.props.createContactInformation(
          this.props.getContactFormInformation,
      );
      let resCities = await getCitiesForZipCode(
          {id: input},
      );
      //  reset form zip code
      this.setState({
        postalcode: null,
        postalcodeId: null,
        city: null,
        cityId: null,
      });
      if (resCities.data.success) {
        resCities = resCities.data.hits;
        const formatZipcode = resCities.map(function(item) {
          return {
            value: item.id,
            label: item.name,
          };
        });
        if (resCities.length > 0) {
          this.props.getContactFormInformation.idpostalCode =
          resCities[0].zipCode.id;
          this.props.getContactFormInformation.staticSelect = true;
          this.props.getContactFormInformation.cityOptions = formatZipcode;
          this.props.getContactFormInformation.loadingOptions = false;
          this.setState({
            postalCodeCountryMessage: '',
            postalcode: input,
            postalcodeId: resCities[0].zipCode.id,
            city: formatZipcode[0].label,
            cityId: formatZipcode[0].value,
            cityValue: formatZipcode[0],
          });
        } else {
          this.props.getContactFormInformation.cityOptions = [];
          this.setState({
            postalCodeCountryMessage: this.props.templateConfig.country_code === 'LU' ? 'Actuellement, seuls les codes postaux du Luxembourg peuvent être saisis. Si vous avez besoin d\'aide, veuillez contacter directement Thomas-Piron en utilisant les coordonnées trouvées en bas de la page.': '',
            postalcode: null,
            postalcodeId: null,
            city: null,
            cityId: null,
            cityValue: {
              value: '',
              label: '',
            },
          });
        }
      }
    } else {
      this.props.getContactFormInformation.cityOptions = [];
      this.setState({
        postalCodeCountryMessage: '',
        postalcode: null,
        postalcodeId: null,
        city: null,
        cityId: null,
        cityValue: {
          value: '',
          label: '',
        },
      });
    }
    this.props.createContactInformation(
        this.props.getContactFormInformation,
    );
  }

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @param {*} e
   */
  changeStaticOptions(e) {
    this.setState({
      city: e.label,
      cityId: e.value,
      cityValue: e,
    });
    this.validatePostalCode(e.value);
  }

  /**
   * @return {void};
   * @param {*} e
   */
  changePostalCode(e) {
    const self = this;
    const promise = new Promise( (resolve) => {
      clearTimeout(this.state.timeout);
      this.setState({
        timeout: setTimeout(() => {
          resolve(self.getPostalCode(e));
        }, 1000),
      });
    });
    return promise;
  }

  /**
   *
   * @param {*} e
   */
  setMailBox(e) {
    this.setState({
      mailbox: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  async getPostalCode(e) {
    const minLength = 3;
    if (e.length < minLength) {
      return;
    }
    if (this.state.postalcode!==null && this.state.postalcode===[]) {
      return;
    }
    const dt = await getPostalCodeDrupal(e);
    const formatZipcode = dt.data.map(function(item) {
      return {
        value: item.id,
        label: item.text,
      };
    });
    if (dt.data.length > 0) {
      return formatZipcode;
    } else {
      return [];
    }
  }

  /**
  *
  * @param {*} e
  */
  async validatePostalCode(e) {
    const dt = await getZipCodeByCity(e);
    if (dt.data.data.name !== undefined) {
      if (this.state.postalcode !== dt.data.data.name) {
        this.setState({
          postalcode: dt.data.data.name,
        });
      }
    }
  }

  /**
   *
   * @param {*} e
   */
  setFirstname(e) {
    this.setState({
      firstname: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setLastname(e) {
    this.setState({
      lastname: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setPhone(e) {
    this.setState({
      defaultTel: e.target.value,
      phone: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setDefaultValuePhone(e) {
    if (e.target.value === '') {
      this.setState({
        defaultTel: this.state.phoneInfo.code,
        phone: this.state.phoneInfo.code,
      });
    }
  }

  /**
   *
   * @param {*} e
   */
  setStreet(e) {
    this.setState({
      street: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setTextMessage(e) {
    this.setState({
      message: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setStreetNumber(e) {
    this.setState({
      streetNumber: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setTermsCheckbox(e) {
    this.setState({
      termsCheckbox: e.target.checked,
    });
  }

  /**
   *
   * @param {*} e
   */
  setPostalCode(e) {
    this.setState({
      postalcode: e.target.value,
    });
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('country') != 'France') {
      this.changeOptions(e);
    }
  }

  /**
   *
   * @param {*} e
   */
  setLocality(e) {
    this.setState({
      city: e.target.value,
    });
  }


  /**
   *
   * @param {*} pdflink
   */
  setPDFLink(pdflink) {
    this.setState({
      pdf: pdflink,
    });
  }

  /**
   *
   * @param {*} e
   */
  setGoal(e) {
    this.setState({
      goal: e.target.value === '0' ? false : true,
    });
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    let indexDynamicRequired = ''; // make it use for telp only
    let bottomRequired = '';
    let phoneValidationType = 'intlTelNumber';
    if (!this.props.contactPdf) {
      indexDynamicRequired = <span className="color-green-secondary">*</span>;
      phoneValidationType = 'required|intlTelNumber';
      bottomRequired = <span className="color-green-secondary">*</span>;
    }
    this.validator.purgeFields();
    const postalCode = this.props.getContactFormInformation.postalCode;
    const label = this.props.getLabelTranslate;
    let zipcodeSelect = '';
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('country') != 'France') {
      if (this.props.getContactFormInformation.staticSelect) {
        zipcodeSelect = <Select
          type="text"
          id="selectLocality"
          name="your-locality"
          options={this.props.getContactFormInformation.cityOptions}
          onChange={this.changeStaticOptions.bind(this)}
          value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
          noOptionsMessage={() => 'Indiquez votre localité'}
          placeholder={label.phCity}
        />;
      } else {
        zipcodeSelect = <AsyncSelect
          type="text"
          id="selectLocality required"
          name="your-locality"
          defaultOptions={this.props.getContactFormInformation.cityOptions}
          onChange={this.changeStaticOptions.bind(this)}
          loadOptions={this.changePostalCode.bind(this)}
          value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
          cacheOptions
          noOptionsMessage={() => 'Indiquez votre localité'}
          placeholder={label.phCity}
        />;
      }
    } else {
      zipcodeSelect = <input type="text"
        className="form-control"
        placeholder={label.phCity}
        id="selectLocality"
        name="your-locality"
        value={this.state.city}
        onChange={this.setLocality.bind(this)}
      />;
    }

    let phaseFormClass = 'hide';
    let phaseLoadingClass = 'hide';
    let phaseResultClass = 'hide';
    switch (this.state.contactphase) {
      case 'openForm':
        phaseFormClass= '';
        break;
      case 'loading':
        phaseLoadingClass= '';
        break;
      case 'showResult':
        phaseResultClass= '';
        break;
      default:
        phaseFormClass= '';
        break;
    }
    const projectNameInput = `${this.props.project.projectName}-
      ${this.props.property.projectPropertyName}`;

    let textAreaInput = '';
    if (!this.props.contactPdf) {
      textAreaInput = <div id="messageField">
        <div className="form-container">
          <div className="form-group form-full">
            <label htmlFor="messageTextArea">Message</label>
            <textarea name="message" id="messageTextArea" className="form-control" onChange={this.setTextMessage.bind(this)}></textarea>
          </div>
        </div>
      </div>;
    }

    //  if entity is TPBAT and contactpdf is true then localite and postal code no need to required
    const companyName = this.props.project.companyName;
    let validationTpBat = '';
    if (companyName === 'Thomas & Piron BATIMENT') {
      validationTpBat= '';
      indexDynamicRequired = '';
      bottomRequired = '';
      phoneValidationType = 'intlTelNumber';
    }
    if (this.props.templateConfig.template == 'THOFR') {
      validationTpBat = '|required';
      bottomRequired = <span className="color-green-secondary">*</span>;
    }
    if (this.props.contactPdf) {
      validationTpBat= '';
      indexDynamicRequired = '';
      phoneValidationType = 'intlTelNumber';
      bottomRequired = '';
    }
    bottomRequired = '';
    const entity = this.props.project.entity.toLowerCase();

    return (
      <Fragment>
        <div className={'form-contact-body ' + phaseFormClass}>
          <div className="column-2 flex-100">
            <form id="popupProjectDetailContactForm"
              type="post"
            >
              <input type="hidden" id="subject_contact" name="subject"
                value="office_buy_house"
              />
              <input type="hidden"
                id="unit_code_input" name="unit_code_input"
                value={this.props.property.unitCode}
              />
              <input type="hidden"
                id="project_type" name="project_type"
                value="contact_about_property"
              />
              <input type="hidden"
                id="property_id_input" name="property_id_input"
                value={this.props.property.cptCode}
              />
              <input type="hidden"
                id="project_name_input" name="project_name_input"
                value={projectNameInput} />
              <input type="hidden"
                id="project_socicode_input"
                name="project_socicode_input"
                value={this.props.property.projectSociCode}
              />
              <div className="form-container">
                <div className="form-group">
                  <input type="hidden" name="office" value="39" />
                  <label htmlFor="firstNameInput_appointment">
                    {label.formFirstName}
                    <span className="color-green-secondary">*</span>
                  </label>
                  <input type="text"
                    name="firstname"
                    className="form-control"
                    placeholder={label.phFirstName}
                    id="firstNameInput_appointment"
                    value={this.state.firstname}
                    onChange={this.setFirstname.bind(this)}
                  />
                  {this.validator.message(
                      'Prénom', this.state.firstname,
                      'required|letterWithAccents',
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="lastNameInput_appointment">
                    {label.formLastName}
                    <span className="color-green-secondary">*</span>
                  </label>
                  <input type="text"
                    name="lastname"
                    className="form-control"
                    placeholder={label.phLastName}
                    id="lastNameInput_appointment"
                    value={this.state.lastname}
                    onChange={this.setLastname.bind(this)}
                  />
                  {this.validator.message(
                      'Nom', this.state.lastname,
                      'required|letterWithAccents',
                  )}
                </div>
              </div>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="emailInput_appointment">
                    {label.formEmail}
                    <span className="color-green-secondary">*</span>
                  </label>
                  <input type="email"
                    className="form-control"
                    placeholder={label.phEmail}
                    id="emailInput_appointment"
                    name="email"
                    value={this.state.email}
                    onChange={this.setEmail.bind(this)}
                  />
                  {this.validator.message(
                      'adresse email', this.state.email,
                      'required|email',
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="telephoneInput">
                    {label.formPhone}
                    {indexDynamicRequired}
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder={label.phPhone}
                    id="telephoneInput"
                    name="phone"
                    onChange={this.setPhone.bind(this)}
                    onClick={this.setDefaultValuePhone.bind(this)}
                    value={this.state.phone}
                    defaultValue={this.state.defaultTel}
                  />
                  {this.validator.message(
                      'numéro de téléphone', this.state.phone,
                      phoneValidationType,
                  )}
                </div>
              </div>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="streetInput">
                    {label.formStreet}
                  </label>
                  <input type="text"
                    name="street"
                    className="form-control"
                    placeholder={label.phStreet}
                    id="streetInput"
                    onChange={this.setStreet.bind(this)}
                  />
                </div>
                {entity === 'tpbat' && (
                  <div className="form-container divide">
                    <div className="form-group">
                      <label htmlFor="streetNumberInput">
                        {label.formStreetNumber}
                      </label>
                      <input type="text"
                        name="street-number"
                        className="form-control"
                        placeholder={label.phStreetNumber}
                        id="streetNumberInput"
                        onChange={this.setStreetNumber.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="mailBox">
                        {label.mailBox}
                      </label>
                      <input type="text"
                        name="mail-box"
                        className="form-control"
                        placeholder={label.phMailBox}
                        id="mailBox"
                        onChange={this.setMailBox.bind(this)}
                      />
                    </div>
                  </div>
                )}
                {entity != 'tpbat' && (
                  <div className="form-group">
                    <label htmlFor="streetNumberInput">
                      {label.formStreetNumber}
                    </label>
                    <input type="text"
                      name="street-number"
                      className="form-control"
                      placeholder={label.phStreetNumber}
                      id="streetNumberInput"
                      onChange={this.setStreetNumber.bind(this)}
                    />
                  </div>
                )}
              </div>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="yourPostalCode">
                    {label.formCodePostal}
                    {bottomRequired}
                  </label>
                  <input type="text"
                    className="form-control"
                    placeholder={label.phCodePostal}
                    id="yourPostalCode"
                    name="your-postal-code"
                    defaultValue={postalCode}
                    value={this.state.postalcode}
                    onChange={this.setPostalCode.bind(this)}
                  />
                  {this.validator.message(
                      'code postal', this.state.postalcode,
                      'zipNoResult'+validationTpBat,
                  )}
                  {this.state.postalCodeCountryMessage !== '' && (
                    <div className="srv-validation-message-custom">
                      {this.state.postalCodeCountryMessage}
                    </div>
                  )}
                </div>
                {/*
                  TODO: uncomment if need select on city selection
                  FIXME: if not using select anymore please delete
                */}
                <div className="form-group">
                  <label htmlFor="selectLocality">
                    {label.formCity}
                    {bottomRequired}
                  </label>
                  <div className="select-container
                  select-locality-container"
                  >
                    {zipcodeSelect}
                    {this.validator.message(
                        'localité', this.state.cityId,
                        'localite'+validationTpBat,
                    )}
                  </div>
                </div>
              </div>
              {entity === 'tpbat' && (
                <div className='form-container'>
                  <div className="form-group">
                    <label htmlFor="appartmentGoal">
                      Je cherche un appartement
                    </label>
                    <div className="form-group-radio">
                      <input type="radio" checked={this.state.goal === false} name="appartmentGoal" id="radio1" onChange={this.setGoal.bind(this)} value="0" ></input>
                      <label htmlFor="radio1"><span>Pour y vivre</span></label>
                      <input type="radio" checked={this.state.goal === true} name="appartmentGoal" id="radio2" onChange={this.setGoal.bind(this)} value="1" ></input>
                      <label htmlFor="radio2"><span>Pour y investir</span></label>
                    </div>
                  </div>
                </div>
              )}
              { textAreaInput }
              <div className="checkbox-group-container">
                <h4 className="purchase-policy-heading">
                  <span
                    dangerouslySetInnerHTML=
                      {{__html: label.formTermSentence}}
                  /> :
                  <span className="color-green-secondary">*</span>
                </h4>
                <div className="form-container">
                  <div className="form-group inline
                  form-full form-group-checkbox">
                    <input className="form-control"
                      type="checkbox"
                      name="checkbox-privacy"
                      id="checkboxPrivacy"
                      onClick={this.setTermsCheckbox.bind(this)}
                    />
                    <label htmlFor="checkboxPrivacy">
                      <i>{label.formTermLabel}</i>
                    </label>
                  </div>
                  {this.validator.message(
                      'terms', this.state.termsCheckbox,
                      'gdpr',
                  )}
                  <i className="note">
                    {label.formNotes1}
                  </i>
                </div>
              </div>
              <div className="checkbox-group-container">
                <div className="form-group">
                  {label.formNotes2}
                </div>
              </div>
              <div className="action-container full">
                <div className="captcha-container">
                </div>
                <div className="button-container">
                  <button type="button"
                    className="btn btn-xlarge btn-secondary"
                    onClick={this.submitForm.bind(this)}
                  >
                    {label.formSubmitBtn}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={'loading-container ' + phaseLoadingClass}>
          <div className="loading">
            <i className="fas fa-spinner fa-spin"></i>
          </div>
          <div className="loading-text">
            <h5>Chargement en cours</h5>
          </div>
        </div>
        <div className={'message-container ' + phaseResultClass}>
          <div className="image-message-container">
            <img id='imageMessage'
              src={
                process.env.REACT_APP_HOST +
                '/static/assets/images/icons/' +
                this.state.submitResult.image
              }
              alt=""
            />
          </div>
          <div className="text-message-container">
            <p id="textMessage">{this.state.submitResult.message}</p>
            <p id="textMessageSecondary">
              {this.state.submitResult.messageSecond}
            </p>
          </div>
          <div className="text-message-cta">
            <button
              className="btn btn-secondary
              btn-large modal-absolute-react-close-button"
              data-modal-close="modal-contact-form-react"
              onClick={this.closeModal.bind(this)}
            >
              {label.closeModalBtn}
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

BasicContactForm.propTypes = {
  createContactInformation: PropTypes.func,
  showModal: PropTypes.bool,
  createLabelTranslate: PropTypes.func,
  closeModal: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getContactFormInformation: PropTypes.object,
  contactInformation: PropTypes.object,
  getProjectDetail: PropTypes.object,
  property: PropTypes.object,
  project: PropTypes.object,
  createContactData: PropTypes.func,
  createContactDataLayer: PropTypes.func,
  setModal: PropTypes.func,
  setContactForm: PropTypes.bool,
  onOpenContactModal: PropTypes.func,
  contactPdf: PropTypes.bool,
  fromPropertyModal: PropTypes.bool,
  propertyModal: PropTypes.object,
  getContactDataLayer: PropTypes.object,
  templateConfig: PropTypes.object,
  getProjectPropertiesDetail: PropTypes.object,
};

const mapStateToProps = (state) =>{
  return state;
};

export default connect(mapStateToProps,
    {
      createLabelTranslate,
      createContactInformation,
      createContactFormInformation,
      createContactData,
      createContactDataLayer,
    },
)(BasicContactForm);
