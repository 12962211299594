import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import PagePurchase from './pages/PagePurchase';
import PageDetail from './pages/PageDetail';
import {setTemplateCode} from './pages/action';
import * as DrupalApi from './services/DrupalApi';
import {Router, Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import history from './helpers/history';
import {BubleLoading} from './helpers/loading';
import PageMinisite from './pages/PageMinisite';
import PageInvestir from './pages/PageInvestir';


/**
 * @param {Object} props
 * @return {void}
 */
function App(props) {
  const [isFinishInit, setFinishInit] = useState(false);
  const [textLoading, setTextLoading] = useState('Chargement des composants');
  const [country, setCountry] = useState('BE');

  setTimeout(() => {
    setTextLoading('Oups, une erreur est survenue. Merci de réessayer plus tard');
  }, 60000);
  useEffect(() => {
    // check with valid date
    if (!props.templateConfig.valid_until || props.templateConfig.valid_until === undefined || props.templateConfig.valid_until === '') {
      getTemplateConfig();
    } else {
      const validDate = Date.parse(props.templateConfig.valid_until);
      const now = Date.parse(Date('now'));
      if (validDate < now || (!props.templateConfig.template || props.templateConfig.template === undefined || props.templateConfig.template === '')) {
        getTemplateConfig();
      } else {
        setCountry(props.templateConfig.country_code);
        setFinishInit(true);
      }
    }
  }, [props.templateConfig]);

  const getTemplateConfig = async () => {
    try {
      const templateCode = await DrupalApi.getTemplateConfig();
      const intervalValid = new Date();//  pass the value in ui since time server and client might be different
      intervalValid.setMinutes(intervalValid.getMinutes()+1);
      templateCode.data.data.valid_until = intervalValid;
      await props.setTemplateCode(templateCode.data.data);
      setCountry(props.templateConfig.country_code);
      setFinishInit(true);
    } catch (err) {
      console.log('Error Fetching API,', err);
    }
  };
  return isFinishInit ? (
    <Router history={history} location={location}>
      <div className={'react-acheter-plugin react-' + country.toLowerCase()} id="react-acheter-plugin">
        <Switch>
          <Route path="/" exact component={PagePurchase} />
          <Route path="/acheter-un-bien/" component={PagePurchase} />
          <Route path="/en/acheter-un-bien/" component={PagePurchase} />
          <Route path="/fr/acheter-un-bien/" component={PagePurchase} />
          <Route path="/de/acheter-un-bien/" component={PagePurchase} />
          <Route
            path="/templates/thomaspiron/liste_lot:queryString"
            component={PageMinisite}
          />
          <Route
            path="/templates/thomaspiron/implantation:queryString"
            component={PageMinisite}
          />
          <Route path="/acheter/:projectid" component={PageDetail} />
          <Route path="/investir/acheter-pour-investir" component={PageInvestir} />
        </Switch>
      </div>
    </Router>
  ) : (
    <div className="react-acheter-plugin" id="react-acheter-plugin">
      <div className="preloader">
        <BubleLoading key={Math.random()} text={textLoading} />
      </div>
    </div>
  );
}

App.propTypes = {
  templateConfig: PropTypes.object,
  setTemplateCode: PropTypes.func,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps, {setTemplateCode})(App);
