import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {iconURL} from '../../helpers/helper';


const HeaderMediaChild = ({
  project,
  project: {
    media: {brochures, magazines, videos, virtualVisits},
  },
}) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const hoverAt = url.searchParams.get('open');
  const [isShowBrochure, setShowBrochure] = useState(hoverAt == 'brochure');
  const [isShowMagazines, setShowMagazine] = useState(hoverAt == 'magazines');
  const [isShowVideos, setShowVideos] = useState(hoverAt == 'video');
  const [isShowVirtualVisit, setShowVirtualVisit] = useState(hoverAt == 'visite');
  const setHandleShowBrochure = () => {
    setShowMagazine(false);
    setShowVideos(false);
    setShowVirtualVisit(false);
    setTimeout(() => {
      setShowBrochure(!isShowBrochure);
    }, 200);
  };

  const setHandleShowMagazine = () => {
    setShowVideos(false);
    setShowVirtualVisit(false);
    setShowBrochure(false);
    setTimeout(() => {
      setShowMagazine(!isShowMagazines);
    }, 200);
  };

  const setHandleShowVideos = () => {
    setShowBrochure(false);
    setShowMagazine(false);
    setShowVirtualVisit(false);
    setTimeout(() => {
      setShowVideos(!isShowVideos);
    }, 200);
  };

  const setHandleVirtualVisit = () => {
    setShowMagazine(false);
    setShowBrochure(false);
    setShowVideos(false);
    setTimeout(() => {
      setShowVirtualVisit(!isShowVirtualVisit);
    }, 200);
  };

  return (
    <>
      {magazines.length > 0 && (
        <div className="project-detail-header-media__box-child">
          <div
            className={`project-detail-header-media__box-anchor-parent ${
              isShowMagazines ? '' : 'is-hide'
            }`}
            onClick={setHandleShowMagazine}
          >
            <img src={iconURL('icon-book.svg')} alt="icon book" />
            <span className="desktop">Magazines et guides</span>
            <span className="mobile">Magazines</span>
          </div>
          <ul
            className={`project-detail-header-media__list desktop ${
              isShowMagazines ? '' : 'is-hide'
            }`}
          >
            {magazines.map((value, index) => {
              return (
                <li
                  key={index}
                  className="project-detail-header-media__list-item"
                >
                  <a
                    href={value.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="project-detail-header-media__anchor"
                  >
                    <img src={iconURL('icon-download.svg')} alt="icon arrow" />
                    <span>{value.label}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {videos.length > 0 && (
        <div className="project-detail-header-media__box-child">
          <div
            className={`project-detail-header-media__box-anchor-parent ${
              isShowVideos ? '' : 'is-hide'
            }`}
            onClick={setHandleShowVideos}
          >
            <img src={iconURL('icon-video.svg')} alt="icon book" />
            <span className="desktop">Vidéo du projet</span>
            <span className="mobile">Vidéo</span>
          </div>
          <ul
            className={`project-detail-header-media__list desktop ${
              isShowVideos ? '' : 'is-hide'
            }`}
          >
            {videos.map((value, index) => {
              return (
                <li
                  key={index}
                  className="project-detail-header-media__list-item"
                >
                  <a
                    href={value.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="project-detail-header-media__anchor"
                  >
                    <img src={iconURL('icon-download.svg')} alt="icon arrow" />
                    <span>{value.label}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {brochures.length > 0 && (
        <div className="project-detail-header-media__box-child">
          <div
            className={`project-detail-header-media__box-anchor-parent ${
              isShowBrochure ? '' : 'is-hide'
            }`}
            onClick={setHandleShowBrochure}
          >
            <img src={iconURL('icon-plans.svg')} alt="icon book" />
            <span className="desktop">Brochure et plans</span>
            <span className="mobile">Plan</span>
          </div>
          <ul
            className={`project-detail-header-media__list desktop ${
              isShowBrochure ? '' : 'is-hide'
            }`}
          >
            {brochures.map((value, index) => {
              return (
                <li
                  key={index}
                  className="project-detail-header-media__list-item"
                >
                  <a
                    href={value.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="project-detail-header-media__anchor"
                  >
                    <img src={iconURL('icon-download.svg')} alt="icon arrow" />
                    <span>{value.label}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {virtualVisits.length > 0 && (
        <div className="project-detail-header-media__box-child">
          <div
            className={`project-detail-header-media__box-anchor-parent ${
              isShowVirtualVisit ? '' : 'is-hide'
            }`}
            onClick={setHandleVirtualVisit}
          >
            <img src={iconURL('icon-panorama.svg')} alt="icon book" />
            <span className="desktop">Visite virtuelle</span>
            <span className="mobile">Visite</span>
          </div>
          <ul
            className={`project-detail-header-media__list desktop ${
              isShowVirtualVisit ? '' : 'is-hide'
            }`}
          >
            {virtualVisits.map((value, index) => {
              return (
                <li
                  key={index}
                  className="project-detail-header-media__list-item"
                >
                  <a
                    href={value.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="project-detail-header-media__anchor"
                  >
                    <img src={iconURL('icon-download.svg')} alt="icon arrow" />
                    <span>{value.label}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {virtualVisits.length > 0 && (
        <ul
          className={`project-detail-header-media__list mobile ${
            isShowVirtualVisit ? '' : 'is-hide'
          }`}
        >
          {virtualVisits.map((value, index) => {
            return (
              <li
                key={index}
                className="project-detail-header-media__list-item"
              >
                <a
                  href={value.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="project-detail-header-media__anchor"
                >
                  <img src={iconURL('icon-download.svg')} alt="icon arrow" />
                  <span>{value.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      )}

      {brochures.length > 0 && (
        <ul
          className={`project-detail-header-media__list mobile ${
            isShowBrochure ? '' : 'is-hide'
          }`}
        >
          {brochures.map((value, index) => {
            return (
              <li
                key={index}
                className="project-detail-header-media__list-item"
              >
                <a
                  href={value.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="project-detail-header-media__anchor"
                >
                  <img src={iconURL('icon-download.svg')} alt="icon arrow" />
                  <span>{value.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      )}

      {videos.length > 0 && (
        <ul
          className={`project-detail-header-media__list mobile ${
            isShowVideos ? '' : 'is-hide'
          }`}
        >
          {videos.map((value, index) => {
            return (
              <li
                key={index}
                className="project-detail-header-media__list-item"
              >
                <a
                  href={value.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="project-detail-header-media__anchor"
                >
                  <img src={iconURL('icon-download.svg')} alt="icon arrow" />
                  <span>{value.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      )}

      {magazines.length > 0 && (
        <ul
          className={`project-detail-header-media__list mobile ${
            isShowMagazines ? '' : 'is-hide'
          }`}
        >
          {magazines.map((value, index) => {
            return (
              <li
                key={index}
                className="project-detail-header-media__list-item"
              >
                <a
                  href={value.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="project-detail-header-media__anchor"
                >
                  <img src={iconURL('icon-download.svg')} alt="icon arrow" />
                  <span>{value.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

HeaderMediaChild.propTypes = {
  project: PropTypes.object,
  brochures: PropTypes.array,
  magazines: PropTypes.array,
  videos: PropTypes.array,
  virtualVisits: PropTypes.array,
};

export default HeaderMediaChild;
