import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {openPropertyModal} from './action';

const PropertyItem = (props) => {
  const handleModal = () => {
    props.openPropertyModal({project: props.project, property: props.property});
  };

  let price = props.property.status.toLowerCase() === 'vendu' ?
      '<p class="price">Vendu</p>' :
      `<p class="price">${props.property.price.toLocaleString('nl-be')}€*</p>`;

  let surface= 0;
  const propertyTypeUseSurface = [1, 2, 4, 5];
  surface = propertyTypeUseSurface.includes(props.property.projectPropertyType) ?
      props.property.surfaceM2 :
      props.property.surfaceOfBlaconyAndGardenM2;
  const surfaceLabel = surface > 0 ? <div> {surface} m<sup>2</sup></div> : '-';

  let terainLabelTable = '';
  let terainLabelMobileLabel = '';
  let terainLabelMobileText = '';
  if (props.templateCode == 'THOLU') {
    const terain = props.property.surfaceOfBlaconyAndGardenM2;
    const terainLabel = terain > 0 ? <div> {terain} m<sup>2</sup></div> : '-';
    terainLabelTable = <td className="terain-item">{terainLabel}</td>;
    terainLabelMobileLabel = <h4 className="desc-title">{'Terrain'}</h4>;
    terainLabelMobileText = <div className="desc-text">{terainLabel}</div>;
  }

  let floor = '-';
  let numberOfRooms = '-';

  if (props.property.floor !== undefined) {
    floor = props.property.floor;
  }

  if (props.property.numberOfRooms !== undefined) {
    numberOfRooms = props.property.numberOfRooms < 1 ? '-' : props.property.numberOfRooms;
  }

  const mediaTemplateIds= [];
  let mediaTemplate= {};
  const htmlBanner=[];
  const classForFloor = props.hideFloor ? 'floor-item hide' : 'floor-item';
  const classForRoom = props.hideRoom ?'room-item hide': 'room-item';

  // if price have option
  if (props.property.option !== undefined && props.property.option.toLocaleLowerCase() !=='any') {
    price =`<p class='price option'> Option </p>`;
  }

  // Update Price Columns
  if (props.property.hasActionOnGoing && props.property.status.toLowerCase() !== 'vendu') {
    props.property.mediaTemplates.map((value)=>{
      if (value.mediaType === 'SpecialPrice') {
        mediaTemplateIds.push(value.mediaId);
        mediaTemplate = value;
      }
    });

    props.mediaBanner.medias.map((value)=>{
      if (mediaTemplateIds.includes(value.id)) {
        const div = value.div
            .replace('[[OldPrice]]', `${props.property.oldPrice.toLocaleString('nl-be')}€*`)
            .replace('[[Price]]', `${props.property.price.toLocaleString('nl-be')}€*`)
            .replace('[[OpenningType]]', `${mediaTemplate.openningType}`)
            .replace('[[OpenningContent]]', `${mediaTemplate.openningContent}`)

        // todo: need fix later
        // OverWrite css

            .replace(
                'class="campaign-banner-price"',
                'class="campaign-banner-price" style= "display: inherit;"',
            )

            .replace(
                'class="campaign-banner-price-top maison"',
                'class="campaign-banner-price-top maison" style= "display: inherit;"',
            )

            .replace(
                'class="campaign-banner-price-bottom maison"',
                'class="campaign-banner-price-bottom maison" style= "display: grid;"',
            );

        htmlBanner.push(`<style>${value.css}</style>${div}`);
      }
    });

    price = htmlBanner.join('');
  }

  // disable price if 0
  if (props.property.price < 1 && props.property.status.toLowerCase() !== 'vendu') {
    price =`<p class="price"> - </p>`;
  }

  if (props.mobile) {
    return (
      <li>
        <div className="list-block-mobile-result">
          <div className="list-block-header"><h4>{props.property.projectPropertyName}</h4></div>
          <div className="list-block-body">
            <div className="list-block-body-content">
              <h4 className="desc-title">Description</h4>
              <div className="desc-text">{props.property.description}</div>
              <h4 className="desc-title">Chambre</h4>
              <div className="desc-text">{numberOfRooms}</div>
              <h4 className="desc-title">Surface</h4>
              <div className="desc-text">{surfaceLabel}</div>
              {terainLabelMobileLabel}
              {terainLabelMobileText}
              <h4 className="desc-title">Prix <span>(TTC)</span></h4>
              <div className="price option" dangerouslySetInnerHTML={{__html: price}}></div>
            </div>
          </div>
          <div className="list-block-cta">
            <button className="btn btn-large btn-primary btn-border-only btn-modal-absolute-react"
              onClick={handleModal}
              projectproperyid={props.property.projectPropertyId}
              cptcode={props.property.cptCode}
              projectsocicode={props.property.projectSociCode}
              unitcode={props.property.unitCode}
              floor={props.property.floor}
            >Plus d’infos</button>
          </div>
        </div>
      </li>
    );
  }


  return (
    <tr>
      <td>
        <p>{props.property.projectPropertyName}</p>
      </td>
      <td>
        <p>{props.property.description}</p>
      </td>
      <td className={classForRoom}>
        <p>{numberOfRooms}</p>
      </td>
      <td className={classForFloor}>
        <p>
          {floor || '-'}
        </p>
      </td>
      <td>
        <p>
          {surfaceLabel}
        </p>
      </td>
      {terainLabelTable}
      <td className="promo-item">
        <div className="price-promo-container">
          <div className="campaign-price-promo" dangerouslySetInnerHTML={{__html: price}}>
          </div>
        </div>
      </td>
      <td>
        <button
          onClick={handleModal}
          projectproperyid={props.property.projectPropertyId}
          cptcode={props.property.cptCode}
          projectsocicode={props.property.projectSociCode}
          unitcode={props.property.unitCode}
          floor={props.property.floor}
          className="btn btn-large
                        btn-secondary btn-modal-absolute-react">
                Plus d’infos
        </button>
      </td>
    </tr>
  );
};

PropertyItem.propTypes = {
  property: PropTypes.object,
  project: PropTypes.object,
  hideFloor: PropTypes.bool,
  hideRoom: PropTypes.bool,
  mediaBanner: PropTypes.object,
  openPropertyModal: PropTypes.func,
  mobile: PropTypes.bool,
  templateCode: PropTypes.string,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(
    mapsStateToProps,
    {
      openPropertyModal,
    },
)(PropertyItem);
