import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {toggleMap} from './action';
import {createMapLocation} from '../MainMap/action';
import {
  createRequestSearchProjects,
  createRequestGetProjectMarkers,
  createProjectList,
  createLabelTranslate,
  createPurchasePageLastUrl,
  createTotalProjects,
} from '../../pages/action';
import {connect} from 'react-redux';
import {toggleMapMobile, createMarkerCollection, createPopupProjectId} from '../MainMap/action';
import {toggleFilterMobile} from '../ProjectFilter/action';
import {toggleProjectListMobile, toggleInvestor} from '../ProjectList/action';
import ProjectInvestItem from './ProjectInvestItem';
import Pager from 'react-pager/dist/pager';

/** class for test */
class ProjectInvestList extends Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      initial: true,
      items: [],
      pageTotal: 1,
      pageCurrent: 1,
      pageVisiblePage: 3,
    };
  }

  /**
   * Handles a page change broadcasted by the pager. Get new data here.
   * @param {number} newPage
   */
  handlePageChanged(newPage) {
    const newPageNumber = newPage + 1;
    this.setState({
      items: [],
      loading: true,
    });
    this.props.handleSearch(newPageNumber);
  }

  /** @return {void} */
  async componentDidMount() {
    // This is statement for translate label
    const label = this.props.getLabelTranslate;
    label.projectListPrice = 'Prix';
    label.projectListLoading = 'Chargement en cours';
    label.projectItemProvinceOf = 'Province de';
    label.projectItemFrom = 'à partir de';
    label.projectItemDollar = '€';
    label.projectItemPriceExc = 'Prix TTC';
    label.projectItemShowMap = 'Afficher sur la carte';
    label.projectItemContactUs = 'Contactez-nous';
    label.projectItemLearnMore = 'Voir les details';
    label.projecNoResult = 'Aucun résultat';
    await this
        .props
        .createLabelTranslate(label);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.items = props.getProjectInvestList.projects;
    state.loading = props.getProjectInvestList.loading;
    state.initial = props.getProjectInvestList.initial;
    state.pageTotal = props.paging.total;
    state.pageCurrent = props.paging.current;
    return state;
  }

  /**
   *
   * @param {*} value
   */
  setActiveProject(value) {
    this.props.handleActiveProject(value);
  }


  /** @return {JSX} */
  render() {
    return (
      <>
        <div
          className={`search-result-container-react`}
          id="_resultContainer"
        >
          <div className="invest-card-container">

            {this.state.items?.map((item, i) => {
              return (
                <ProjectInvestItem
                  key={i}
                  history={this.props.history}
                  project={item}
                  handleActiveProject={this.setActiveProject.bind(this)}
                />
              );
            })}

            {this.state.items.length == 0 && this.state.loading === false && this.state.initial === false && (
              <div className="purchase-filter-container-warning">Nous n’avons pas trouvé de bien correspondant à vos critères de recherche</div>
            )}

            {this.state.items.length !== 0 && (
              <Pager
                total={this.state.pageTotal}
                current={this.state.pageCurrent - 1}
                visiblePages={this.state.pageVisiblePage}
                titles={{first: '<', last: '>'}}
                onPageChanged={this.handlePageChanged.bind(this)}
              />
            )}

          </div>
        </div>
      </>
    );
  }
}

ProjectInvestList.propTypes = {
  getShowMap: PropTypes.bool,
  toggleMap: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  createLabelTranslate: PropTypes.func,
  getRequestSearchProjects: PropTypes.object,
  getProjectInvestList: PropTypes.object,
  createRequestSearchProjects: PropTypes.func,
  createProjectList: PropTypes.func,
  history: PropTypes.object,
  toggleMapMobile: PropTypes.func,
  toggleProjectListMobile: PropTypes.func,
  toggleFilterMobile: PropTypes.func,
  getMediaBannerDataAll: PropTypes.object,
  createMapLocation: PropTypes.func,
  templateConfig: PropTypes.object,
  getToggleInvestor: PropTypes.bool,
  toggleInvestor: PropTypes.func,
  createRequestGetProjectMarkers: PropTypes.func,
  createMarkerCollection: PropTypes.func,
  createPopupProjectId: PropTypes.func,
  createPurchasePageLastUrl: PropTypes.func,
  createTotalProjects: PropTypes.func,
  handleActiveProject: PropTypes.func,
  isSearching: PropTypes.object,
  paging: PropTypes.object,
  handleSearch: PropTypes.func,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {
  toggleMap,
  createLabelTranslate,
  createRequestSearchProjects,
  createProjectList,
  toggleMapMobile,
  toggleProjectListMobile,
  toggleFilterMobile,
  createMapLocation,
  toggleInvestor,
  createRequestGetProjectMarkers,
  createMarkerCollection,
  createPopupProjectId,
  createPurchasePageLastUrl,
  createTotalProjects,
})(ProjectInvestList);
