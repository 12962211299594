import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setFilterProjectDetail} from './action';
import PriceFilter from './control/PriceFilter';
import SurfaceFilter from './control/SurfaceFilter';
import RoomFilter from './control/RoomFilter';
import TypeFilter from './control/TypeFilter';
import PriceFilterMobile from './control/PriceFilterMobile';
import SurfaceFilterMobile from './control/SurfaceFilterMobile';
import RoomFilterMobile from './control/RoomFilterMobile';
import TypeFilterMobile from './control/TypeFilterMobile';
import {SlideDown} from 'react-slidedown';
import {isMobileOnly} from 'react-device-detect';
import AvailableFilter from './control/AvailableFilter';
/**
 * Class for filtering project.
 */
class ProjectDetailFilterNew extends React.Component {
  /**
   * @param {*} props props object.
   */
  constructor(props) {
    super(props);
    this.state = {
      tableHeader: '',
      projectId: null,
      filterType: [],
      filterSurface: null,
      filterPrice: null,
      filterRoom: null,
      roomOptions: {
        numberOfRoomsMin: 0,
        numberOfRoomsMax: 0,
      },
      surfaceOptions: {
        minNumberOfM2: 0,
        maxNumberOfM2: 0,
      },
      priceOptions: {
        priceMin: 0,
        priceMax: 0,
      },
      isAvailable: false,
      closed: false,
      loading: false,
      hasUpdateFirstTime: false,
    };
  }

  /**
   * toggle filter mobile
   */
  toggleFilterMobile() {
    this.setState({closed: !this.state.closed});
  }


  /**
   *
   *@param {*} props
   *@param {*} state
   *@return {*} state
   */
  static getDerivedStateFromProps(props, state) {
    const filterParams = props.getFilterProjectDetail;
    if (filterParams.filterId) {
      state.projectId = filterParams.filterId;
      state.filterType = filterParams.entities[filterParams.filterId].filterType;
      state.filterSurface = filterParams.entities[filterParams.filterId].filterSurface;
      state.filterPrice=filterParams.entities[filterParams.filterId].filterPrice;
      state.filterRoom=filterParams.entities[filterParams.filterId].filterRoom;
      state.roomOptions=filterParams.entities[filterParams.filterId].roomOptions;
      state.surfaceOptions=filterParams.entities[filterParams.filterId].surfaceOptions;
      state.priceOptions=filterParams.entities[filterParams.filterId].priceOptions;
      if (!state.hasUpdateFirstTime) {
        props.handleUpdate();
        state.hasUpdateFirstTime= true;
      }
      return state;
    }
  }

  /**
   * Load Componnet
   */
  async componentDidMount() {
    const filterParams = this.props.getFilterProjectDetail;
    if (filterParams.filterId) {
      this.setState({
        projectId: filterParams.filterId,
        filterType: filterParams.entities[filterParams.filterId].filterType,
        filterSurface: filterParams.entities[filterParams.filterId].filterSurface,
        filterPrice: filterParams.entities[filterParams.filterId].filterPrice,
        filterRoom: filterParams.entities[filterParams.filterId].filterRoom,
        roomOptions: filterParams.entities[filterParams.filterId].roomOptions,
        surfaceOptions: filterParams.entities[filterParams.filterId].surfaceOptions,
        priceOptions: filterParams.entities[filterParams.filterId].priceOptions,
      });
      this.props.handleUpdate();
    }
  }

  /**
   * get floor image details
   */
  async componentDidUpdate() {
    if (this.state.tableHeader === '' && this.props.mobile &&
    Object.keys(this.props.projectPropertiesDetail).length != 0) {
      this.resultsTableHeader(this.props.projectPropertiesDetail);
    }
  }

  /**
   * @param {*} property projectPropertiesDetail
   * @return {void}
   */
  resultsTableHeader(property) {
    // todo create banner
    const count =
    (property.count === undefined) ?
     detail.count : property.count;

    const avaibleProperties = (property.avaibleProperties === undefined) ?
    detail.avaibleProperties : property.avaibleProperties;

    const tableHeader = <h3>{avaibleProperties} biens disponibles sur {count}</h3>;

    this.setState({
      tableHeader: tableHeader,
    });
  }
  /**
   * function to check width screen
   * @return {void}
   */
  checkMobile() {
    const windowWidth = window.innerWidth;
    if (windowWidth < 500) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Submit Type Filter.
   * @param {*} value object filter value.
   */
  async submitTypeFilter(value) {
    this.setState({
      filterType: value,
    });
    this.props.setFilterProjectDetail({id: this.state.projectId, filterType: value});
    const isMobile = this.checkMobile();
    if ( !isMobile) this.props.handleUpdate();
  }

  /**
   * Submit Price Filter.
   * @param {*} value object filter value.
   */
  async submitPriceFilter(value) {
    this.setState({
      filterPrice: value,
    });
    this.props.setFilterProjectDetail({id: this.state.projectId, filterPrice: value});
    const isMobile = this.checkMobile();
    if ( !isMobile) this.props.handleUpdate();
  }

  /**
   * Submit Room Filter.
   * @param {*} value object filter value.
   */
  async submitRoomFilter(value) {
    this.setState({
      filterRoom: value,
    });
    this.props.setFilterProjectDetail({id: this.state.projectId, filterRoom: value});
    const isMobile = this.checkMobile();
    if ( !isMobile) this.props.handleUpdate();
  }

  /**
   * Submit Surface Filter.
   * @param {*} value object filter value.
   */
  async submitSurfaceFilter(value) {
    this.setState({
      filterSurface: value,
    });
    this.props.setFilterProjectDetail({id: this.state.projectId, filterSurface: value});
    const isMobile = this.checkMobile();
    if ( !isMobile) this.props.handleUpdate();
  }

  /**
   * Submit Available Filter.
   * @param {*} value object filter value.
   */
  async submitAvailableFilter(value) {
    this.setState({
      isAvailable: value,
    });
    let setAvailable = [];
    if (value) {
      setAvailable = ['Available'];
    }
    this.props.setFilterProjectDetail({id: this.state.projectId, filterAvailable: setAvailable});
    const isMobile = this.checkMobile();
    if ( !isMobile) this.props.handleUpdate();
  }

  /**
   * Clear Type Filter.
   */
  async cancelTypeFilter() {
    this.setState({
      filterType: [],
    });
    this.props.setFilterProjectDetail({id: this.state.projectId, filterType: []});
    if (!isMobileOnly) this.props.handleUpdate();
  }

  /**
   * Clear Price Filter.
   * @param {*} value object filter value.
   */
  async cancelPriceFilter(value) {
    this.setState({
      filterPrice: value,
    });
    this.props.setFilterProjectDetail({id: this.state.projectId, filterPrice: value});
    if (!isMobileOnly) this.props.handleUpdate();
  }

  /**
   * Clear Room Filter.
   */
  async cancelRoomFilter() {
    this.setState({
      filterRoom: null,
    });
    this.props.setFilterProjectDetail({id: this.state.projectId, filterRoom: null});
    if (!isMobileOnly) this.props.handleUpdate();
  }

  /**
   * Clear Surface Filter.
   */
  async cancelSurfaceFilter() {
    this.setState({
      filterSurface: null,
    });
    this.props.setFilterProjectDetail({id: this.state.projectId, filterSurface: null});
    if (!isMobileOnly) this.props.handleUpdate();
  }


  /**
   * submit filter
   */
  async submitFilter() {
    this.setState({loading: true});
    this.props.handleUpdate(()=> {
      this.setState({loading: false});
    });
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    return (
      <Fragment>
        <div className="purchase-filter-container-react mobile">
          <SlideDown className={'project-detail-filter-container-mobile mobile-filter'} closed={ this.state.closed }>
            <TypeFilterMobile
              submitHandle={this.submitTypeFilter.bind(this)}
              cancelHandle={this.cancelTypeFilter.bind(this)}
              filterValue={this.state.filterType}
            />
            <PriceFilterMobile
              submitHandle={this.submitPriceFilter.bind(this)}
              cancelHandle={this.cancelPriceFilter.bind(this)}
              filterValue={this.state.filterPrice}
              filterOptions={this.state.priceOptions}
            />
            <RoomFilterMobile
              submitHandle={this.submitRoomFilter.bind(this)}
              cancelHandle={this.cancelRoomFilter.bind(this)}
              filterValue={this.state.filterRoom}
              filterOptions={this.state.roomOptions}
            />
            <SurfaceFilterMobile
              submitHandle={this.submitSurfaceFilter.bind(this)}
              cancelHandle={this.cancelSurfaceFilter.bind(this)}
              filterValue={this.state.filterSurface}
              filterOptions={this.state.surfaceOptions}
            />
            <AvailableFilter
              submitHandle={this.submitAvailableFilter.bind(this)}
              showOption="mobileOnly"
            />
            <div className="btn-filter-mobile-container">
              <button
                className="btn btn-filter-search"
                onClick={()=> this.submitFilter() }
                disabled={ this.state.loading }
              >
                { this.state.loading ? (<i className="fas fa-spinner fa-spin"></i>) : 'Appliquer'}
              </button>
            </div>
          </SlideDown>
        </div>
        <div className='project-detail-filter-container-tpr purchase-filter-container'>
          <TypeFilter
            submitHandle={this.submitTypeFilter.bind(this)}
            cancelHandle={this.cancelTypeFilter.bind(this)}
            filterValue={this.state.filterType}
          />
          <PriceFilter
            submitHandle={this.submitPriceFilter.bind(this)}
            cancelHandle={this.cancelPriceFilter.bind(this)}
            filterValue={this.state.filterPrice}
            filterOptions={this.state.priceOptions}
          />
          <RoomFilter
            submitHandle={this.submitRoomFilter.bind(this)}
            cancelHandle={this.cancelRoomFilter.bind(this)}
            filterValue={this.state.filterRoom}
            filterOptions={this.state.roomOptions}
          />
          <SurfaceFilter
            submitHandle={this.submitSurfaceFilter.bind(this)}
            cancelHandle={this.cancelSurfaceFilter.bind(this)}
            filterValue={this.state.filterSurface}
            filterOptions={this.state.surfaceOptions}
          />
          <AvailableFilter
            submitHandle={this.submitAvailableFilter.bind(this)}
            showOption="desktopOnly"
          />
        </div>
      </Fragment>
    );
  }
}

ProjectDetailFilterNew.propTypes = {
  history: PropTypes.object,
  getFilterProjectDetail: PropTypes.objectOf(PropTypes.any),
  setFilterProjectDetail: PropTypes.func,
  handleUpdate: PropTypes.func,
  mobile: PropTypes.bool,
  projectPropertiesDetail: PropTypes.object,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {
      setFilterProjectDetail,
    },
)(ProjectDetailFilterNew);
