import React from 'react';
import PropTypes from 'prop-types';
import ProjectDetailFilter from '../../ProjectFilter/ProjectDetailFilter';

/**
 * Class for detail page information
 */
class ImageMaps extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      svg: '',
      selectedPhase: '',
      floors: [],
      activeFloor: '',
      imageTabClass: 'collapse-navbar-list-item',
      listTabClass: 'collapse-navbar-list-item',
      display: 'image',
      firstLoad: true,
    };
    this.clickImageTabClass = this.clickImageTabClass.bind(this);
    this.clickListTabClass = this.clickListTabClass.bind(this);
  }

  /**
   * @return {void}
   */
  clickImageTabClass() {
    const classForImage = this.state.svg.length < 1 ?
        'collapse-navbar-list-item hide' :
        'collapse-navbar-list-item is-active';

    const classForList = this.state.svg.length < 1 ?
        'collapse-navbar-list-item is-active' :
        'collapse-navbar-list-item';

    this.setState({
      imageTabClass: classForImage,
      listTabClass: classForList,
      display: 'image',
      firstLoad: false,
    });
  }

  /**
   *
   * @param {any} props
   * @param {any} state
   * @return {*}
   */
  static getDerivedStateFromProps(props, state) {
    let nodeImage = undefined;
    try {
      if (props.getProjectDetail.mainImageDetails === undefined || !props.getProjectDetail.mainImageDetails) {
        state.imageTabClass ='collapse-navbar-list-item hide';
        state.listTabClass ='collapse-navbar-list-item is-active';
        state.display='list';
        return state;
      }

      nodeImage = props.getProjectDetail.mainImageDetails.mainInteractiveImageLinks[0];
      if (state.firstLoad) {
        if (nodeImage === null) {
          state.imageTabClass ='collapse-navbar-list-item hide';
          state.listTabClass ='collapse-navbar-list-item is-active';
          state.display='list';
        } else {
          state.imageTabClass ='collapse-navbar-list-item is-active';
          state.listTabClass ='collapse-navbar-list-item';
          state.display='image';
        }
      }
    } catch (error) {
      // console.log(error);
    }

    return state;
  }

  /**
   * @return {void}
   */
  clickListTabClass() {
    const classForImage = this.state.svg.length < 1 ?
        'collapse-navbar-list-item hide' :
        'collapse-navbar-list-item';
    this.setState({
      imageTabClass: classForImage,
      listTabClass: 'collapse-navbar-list-item is-active',
      display: 'list',
      firstLoad: false,
    });
  }

  /**
   * get floor image details
   */
  async componentDidUpdate() {
    if (Object.keys(this.props.getProjectDetail).length != 0) {
      if (this.state.svg == '') {
        this.interativeImage(this.props.getProjectDetail, 'home');
      }
    }
  }

  /**
   * @param {*} projectDetails
   * @param {*} floorCode
   * @return {void}
   */
  interativeImage(projectDetails, floorCode = 'home') {
    const mainImage = projectDetails.mainImageDetails;
    const propertiesDetails = projectDetails.floorImageDetails;
    const imageArr = [];

    if (propertiesDetails.length > 0) {
      propertiesDetails.forEach((property)=>{
        if (property.propertyCode===this.state.selectedPhase) {
          property.propertiesFloors.map((v, i)=>{
            if (v.mainInteractiveImageLinks!==undefined) {
              const floor= this.detectFloor(v.mainInteractiveImageLinks[0]);
              imageArr[floor] = v;
            }
          });
        }
      });
    }

    if (mainImage !== null && mainImage.mainInteractiveImageLinks.length > 0 ) {
      imageArr['home'] = mainImage;
    }

    if (imageArr[floorCode]===undefined) {
      floorCode = 0;
    }

    if (mainImage == null) {
      // todo: need refactor later

    } else {
      if (mainImage.mainInteractiveImageLinks.length < 1 &&
        imageArr.length < 1) {
        // todo: need refactor later
      } else {
        const params = imageArr[floorCode];

        // loadingDisplay();
        const namespace = 'http://www.w3.org/2000/svg';
        // use the high resolution image
        const srcImage = params.mainInteractiveImageLinks[0];
        const shapeImage = params.mainImageShapeCoordinates;
        const loadImage = new Image();
        loadImage.src = srcImage;

        loadImage.onload = async function() {
          /**
             * parse raw coordinates to polygon points format
             * i.e 509,156,444,541,607,568,671,172,509,155 =>
             * 509,156 444,541 607,568 671,172 509,155
             *
             * @param {*} coordinates
             * @return {string}
             */
          const parseCoordinates = (coordinates) => coordinates.split(',')
              .map((coordinate, index) => index % 2 ? `${coordinate},` :
              `${coordinate} `)
              .join('')
              .slice(0, -1); // remove trailing comma

          // todo: make it more readable
          const svg =<svg
            viewBox={`0 0 ${loadImage.width} ${loadImage.height}`}
            x="0px" y="0px"
            style={{enableBackground: `new 0 0 ${loadImage.width} ${loadImage.height}`}}
            version="1.1"
            xmlns={`${namespace}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve">
            <image
              style={{overflow: 'visible'}}
              width={`${loadImage.width}`}
              height={`${loadImage.height}`}
              xlinkHref={`${srcImage}`}>
            </image>
            {shapeImage.
                filter(({shapeCoordinates}) => shapeCoordinates.length > 0).
                map((coordinate, i) =>
                  <polygon
                    key={i}
                    points={`${parseCoordinates(coordinate.shapeCoordinates)}`}
                    style={{cursor: `pointer`, stroke: `#298b90`, strokeWidth: '5', fill: 'transparent'}}
                    className="blockArea"
                    data-propertycode=
                      {`${coordinate.propertyESIdsConnectedToShape ||
                      coordinate.propertyCodeConnectedToShape}`}
                    data-floorcode={`${floorCode}`}
                    onClick={()=>{
                      if (floorCode==='home') {
                        this.selectPhase(this.props.getProjectDetail, coordinate.propertyCodeConnectedToShape || coordinate.propertyESIdsConnectedToShape);
                      } else {
                        this.props.updateProjectProperties('', '', coordinate.propertyESIdsConnectedToShape ||
                      coordinate.propertyCodeConnectedToShape );
                      }
                    }}>
                  </polygon>,
                )}</svg>;

          this.setState({
            svg: svg,
          });

          // Take and slap
          // $('#loading-000').remove();

          // todo enable image action
          // imageAction();
          // this.pushTheSvg(svg);
        }.bind(this);
      }
    }
  }

  /**
   * @param {projectDetails} projectDetails
   * @param {propertyCode} propertyCode
   */
  selectPhase = (projectDetails, propertyCode)=> {
    let property;
    projectDetails.floorImageDetails.forEach((x)=>{
      if (x.propertyCode===propertyCode) {
        property=x.propertiesFloors;
      }
    });
    // eslint-disable-next-line no-invalid-this
    this.setState({
      selectedPhase: propertyCode,
      floors: property,
    });
    setTimeout(() => {
      // eslint-disable-next-line no-invalid-this
      this.props.updateProjectProperties(this.state.selectedPhase);
    }, 100);
  };


  /**
   * @param {*} image
   * @return {void}
   */
  detectFloor = (image) =>{
    if (image!==null) {
      const splitImage = image.split('/');
      const imageName = splitImage[splitImage.length-1];
      const arrImageName = imageName.split('_');
      const floor = arrImageName[arrImageName.length-2];
      return floor;
    }
    return 0;
  }

  /**
     *
     * @return {JSX.Element}
     */
  render() {
    // navbar
    let svgContainer;
    if (this.state.svg !== '' && this.state.display === 'image') {
      svgContainer =
      <React.Fragment>
        <div className="project-detail-settlement-map-container">
          <div className="project-detail-settlement-map">
            <div>
              <div className="menu-floor-container" id="menu-floor">
                <ul>
                  <li>
                    <button className={this.state.activeFloor===''?`menu-floor-link is-active`:`menu-floor-link`} onClick={()=>{
                      this.setState({selectedPhase: '', activeFloor: '', floors: []});
                      this.interativeImage(this.props.getProjectDetail, 'home');
                      this.props.updateProjectProperties();
                    }}>
                        Retour au niveau précédant
                    </button>
                  </li>
                  {
                      this.state.selectedPhase!==''&&this.state.floors.length!==0?
                        this.state.floors.map((floor)=>{
                          return <li key={floor.floorCode} className="from-properties">
                            <a href="#table-page-detail" className={this.state.activeFloor===floor.floorCode?'menu-floor-link is-active':'menu-floor-link'}
                              onClick={()=>{
                                this.setState({activeFloor: floor.floorCode});
                                this.interativeImage(this.props.getProjectDetail, floor.floorCode);
                                this.props.updateProjectProperties(this.state.selectedPhase, floor.floorCode);
                              }}>
                              Etage {floor.floorCode}
                            </a>
                          </li>;
                        }):null
                  }
                </ul>
              </div>
            </div>
            <div className="project-detail-settlement-map-body">
              {this.state.svg}
            </div>
          </div>
        </div>
      </React.Fragment>;
    }
    if (this.state.display === 'list') {
      svgContainer =
      <React.Fragment>
        <ProjectDetailFilter
          handleUpdate={this.props.filterProjectProperties}
          projectPropertiesDetail={this.props.getProjectPropertiesDetail}
        />
      </React.Fragment>;
    }

    if (this.props.mapOnly) {
      if (this.state.svg == '') {
        return '';
      }
      const mapSvgContainer =
      <React.Fragment>
        <h3 className="image-maps-mobile-title">Plan d’implantation et liste des biens</h3>
        <div className="image-maps-mobile-box">
          <div className="project-detail-settlement-map-mobile">
            <div>
              <div className="menu-floor-container-mobile" id="menu-floor">
                <ul>
                  <li>
                    <button className={this.state.activeFloor===''?`menu-floor-link is-active`:`menu-floor-link`} onClick={()=>{
                      this.setState({selectedPhase: '', activeFloor: '', floors: []});
                      this.interativeImage(this.props.getProjectDetail, 'home');
                      this.props.updateProjectProperties();
                    }}>
                        Retour au niveau précédant
                    </button>
                  </li>
                  {
                      this.state.selectedPhase!==''&&this.state.floors.length!==0?
                        this.state.floors.map((floor)=>{
                          return <li key={floor.floorCode} className="from-properties">
                            <a href="#table-page-detail" className={this.state.activeFloor===floor.floorCode?'menu-floor-link is-active':'menu-floor-link'}
                              onClick={()=>{
                                this.setState({activeFloor: floor.floorCode});
                                this.interativeImage(this.props.getProjectDetail, floor.floorCode);
                                this.props.updateProjectProperties(this.state.selectedPhase, floor.floorCode);
                              }}>
                              Etage {floor.floorCode}
                            </a>
                          </li>;
                        }):null
                  }
                </ul>
              </div>
            </div>
            <div className="project-detail-settlement-map-body-mobile">
              {this.state.svg}
            </div>
          </div>
        </div>
      </React.Fragment>;
      return (
        <div className="image-maps-mobile">
          {mapSvgContainer}
        </div>
      );
    }

    if (this.props.listOnly) {
      return (
        <React.Fragment>
          <ProjectDetailFilter mobile
            handleUpdate={this.props.filterProjectProperties}
            projectPropertiesDetail={this.props.getProjectPropertiesDetail}
          />
        </React.Fragment>
      );
    }

    return (
      <div>
        <div className="collapse-navbar">
          <ul className="collapse-navbar-list">
            <li className={this.state.imageTabClass} data-navbar="project-detail-settlement-map-container">
              <a onClick={this.clickImageTabClass} id="image-map">Plan d&lsquo;implantation</a>
            </li>
            <li
              className={this.state.listTabClass}
              data-navbar="project-detail-filter-container">
              <a onClick={this.clickListTabClass} id="filter-element-map">Liste des biens immobiliers</a>
            </li>
          </ul>
        </div>
        <div>
          {svgContainer}
        </div>
      </div>
    );
  }
}

ImageMaps.propTypes = {
  floorImageDetails: PropTypes.array,
  mainImageDetails: PropTypes.object,
  getProjectDetail: PropTypes.object,
  updateProjectProperties: PropTypes.func,
  filterProjectProperties: PropTypes.func,
  createGetProjectPropertiesDetail: PropTypes.func,
  mapOnly: PropTypes.bool,
  listOnly: PropTypes.bool,
  getProjectPropertiesDetail: PropTypes.object,
};

export default ImageMaps;

