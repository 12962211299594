import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../../pages/action';
import {toggleFilter} from '../action';
import Modal from 'react-modal';


Modal.setAppElement('#acheter-plugin');

/**
 * Class Type Filter.
*/
class TypeFilterMobile extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      labelButton: null,
      isFilter: false,
      isAppartement: false,
      isMaison: false,
      isTerrain: false,
      isCommercial: false,
      isBureau: false,
      filterSelected: [],
      selectType: false,
      appartementValue: 1,
      maisonValue: 2,
      terrainValue: 3,
      commercialValue: 4,
      bureauValue: 5,
      modalIsOpen: false,
    };
    this._openModal = this._openModal.bind(this);
    this._afterOpenModal = this._afterOpenModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
  }

  /**
   * Open Modal
   */
  _openModal() {
    this.setState({modalIsOpen: true});
  }

  /**
   * After Open Modal
   */
  _afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  /**
   * Close modal
   */
  _closeModal() {
    this.setState({modalIsOpen: false});
  }

  /**
   * Load Componnet
   */
  async componentDidMount() {
    // This statement for translate label
    const label = this.props.getLabelTranslate;
    label.typeFilterGoodChoice = 'Type de bien';
    label.typeFilterApartment = 'Appartement';
    label.typeFilterHouse = 'Maison';
    label.typeFilterLandToBuild = 'Terrain à bâtir';
    label.typeFilterCommercialSpace = 'Espace commercial';
    label.typeFilterOfficeSpace = 'Espace de bureau';
    label.typeFilterCancelMobile = 'Fermer';
    label.typeFilterApply = 'Appliquer';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    const filterSelected = props.filterValue;
    if (filterSelected.length > 0 && state.filterSelected.length === 0) {
      const isAppartement = filterSelected.includes(1);
      const isMaison = filterSelected.includes(2);
      const isTerrain = filterSelected.includes(3);
      const isCommercial = filterSelected.includes(4);
      const isBureau = filterSelected.includes(5);

      state.labelButton = filterSelected.length;
      state.isFilter = true;
      state.selectType = true;
      state.isAppartement = isAppartement;
      state.isMaison = isMaison;
      state.isTerrain = isTerrain;
      state.isCommercial = isCommercial;
      state.isBureau = isBureau;
      state.filterSelected = filterSelected;
    }
    return state;
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  appartementCheckBox() {
    if (this.state.isAppartement === true) {
      this.setState({isAppartement: false});
      this.handleRemoveCheckBox(this.state.appartementValue);
    } else {
      this.setState({
        isAppartement: true,
        filterSelected: [...this.state.filterSelected,
          this.state.appartementValue],
      });
    }
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  maisonCheckBox() {
    if (this.state.isMaison === true) {
      this.setState({isMaison: false});
      this.handleRemoveCheckBox(this.state.maisonValue);
    } else {
      this.setState({
        isMaison: true,
        filterSelected: [...this.state.filterSelected,
          this.state.maisonValue],
      });
    }
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  terrainCheckBox() {
    if (this.state.isTerrain === true) {
      this.setState({isTerrain: false});
      this.handleRemoveCheckBox(this.state.terrainValue);
    } else {
      this.setState({
        isTerrain: true,
        filterSelected: [...this.state.filterSelected,
          this.state.terrainValue],
      });
    }
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  commercialCheckBox() {
    if (this.state.isCommercial === true) {
      this.setState({isCommercial: false});
      this.handleRemoveCheckBox(this.state.commercialValue);
    } else {
      this.setState({
        isCommercial: true,
        filterSelected: [...this.state.filterSelected,
          this.state.commercialValue],
      });
    }
  }

  /**
   * Show Type Filter.
   * @param {number} value text output val.
   */
  bureauCheckBox() {
    if (this.state.isBureau === true) {
      this.setState({isBureau: false});
      this.handleRemoveCheckBox(this.state.bureauValue);
    } else {
      this.setState({
        isBureau: true,
        filterSelected: [...this.state.filterSelected,
          this.state.bureauValue],
      });
    }
  }

  /**
   * handle remove checkbox.
   * @param {number} value text output val.
   */
  handleRemoveCheckBox(value) {
    const types = this.state.filterSelected;
    const toRemove = value;
    const index = types.indexOf(toRemove);
    if (index > -1) {
      types.splice(index, 1);
    }
  }

  /**
   * Submit Type Filter.
   * @param {number} value text output val.
   */
  async submitTypeFilter() {
    this.setState({modalIsOpen: false});
    if (this.state.filterSelected.length > 0) {
      this.setState({
        selectType: true,
        isFilter: true,
        value: this.state.value,
        labelButton: this.state.filterSelected.length,
      });
    } else {
      this.setState({
        isFilter: false,
      });
    }
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': !this.props.getFilter.filterType,
    });
    this.props.submitHandle(this.state.filterSelected);
  }

  /**
   * Clear Type Filter.
   * @param {number} value text output val.
   */
  async cancelTypeFilter() {
    this.setState({
      selectType: false,
      labelButton: null,
      isFilter: false,
      filterSelected: [],
      isAppartement: false,
      isMaison: false,
      isTerrain: false,
      isCommercial: false,
      isBureau: false,
      modalIsOpen: false,
    });
    this.props.toggleFilter({
      ...this.props.getFilter,
      'filterType': !this.props.getFilter.filterType,
    });
    this.props.cancelHandle();
  }

  /**
   * handle text.
   * @return {text}
   */
  handleTextActive() {
    const labels = [
      {
        val: this.state.isAppartement,
        labelTranslate: this.props.getLabelTranslate.typeFilterApartment,
      },
      {
        val: this.state.isMaison,
        labelTranslate: this.props.getLabelTranslate.typeFilterHouse,
      },
      {
        val: this.state.isTerrain,
        labelTranslate: this.props.getLabelTranslate.typeFilterLandToBuild,
      },
      {
        val: this.state.isCommercial,
        labelTranslate: this.props.getLabelTranslate.typeFilterCommercialSpace,
      },
      {
        val: this.state.isBureau,
        labelTranslate: this.props.getLabelTranslate.typeFilterOfficeSpace,
      },
    ];

    const filteredText = labels.filter((label)=> label.val == true);
    if (filteredText.length == 0) return;
    const result = filteredText.map((label)=> label.labelTranslate).join(', ');
    return result;
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="btn-filter-mobile-container">
        <div className={`btn-filter-mobile ${this.state.isFilter ? 'is-filter' : '' }`} onClick={()=> this._openModal() }>
          <div className="btn-filter-mobile-text">
            <span className="filter-type-text">{this.props.getLabelTranslate.typeFilterGoodChoice}</span>
            <span className="filter-type-text-active">{ this.state.isFilter ? this.handleTextActive() : '' }</span>
          </div>
          <div className="btn-filter-mobile-image">
            <i className="icon-filter-mobile archer"></i>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this._afterOpenModal}
          onRequestClose={this._closeModal}
          className="modal-acheter"
        >
          <div className="modal-acheter__dialog">
            <div className="modal-acheter__content">
              <div className="modal-acheter__header">
                <h5 className="modal-acheter__title" id="modalAcheterTypeFilterLabel">{this.props.getLabelTranslate.typeFilterGoodChoice}</h5><button className="close" onClick={()=> this._closeModal() } ><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-acheter__body">
                <div className="checkbox-filter-input-mobile-rd">
                  <input type="checkbox" readOnly id="1" name="filterType" value="1"
                    className="checkbox-control-mobile"
                    checked={this.state.isAppartement}/>
                  <label htmlFor="1" onClick={()=> this.appartementCheckBox() }>
                    {this.props.getLabelTranslate.typeFilterApartment}</label>
                </div>

                <div className="checkbox-filter-input-mobile-rd">
                  <input type="checkbox" readOnly id="2" name="filterType" value="2"
                    className="checkbox-control-mobile"
                    checked={this.state.isMaison}/>
                  <label htmlFor="2" onClick={() => this.maisonCheckBox() }>
                    {this.props.getLabelTranslate.typeFilterHouse}</label>
                </div>

                <div className="checkbox-filter-input-mobile-rd">
                  <input type="checkbox" readOnly id="3" name="filterType" value="3"
                    className="checkbox-control-mobile"
                    checked={this.state.isTerrain} />
                  <label htmlFor="3" onClick={()=> this.terrainCheckBox() }>
                    {this.props.getLabelTranslate.typeFilterLandToBuild}</label>
                </div>

                <div className="checkbox-filter-input-mobile-rd">
                  <input type="checkbox" readOnly id="4" name="filterType" value="4"
                    className="checkbox-control-mobile"
                    checked={this.state.isCommercial} />
                  <label htmlFor="4" onClick={()=>this.commercialCheckBox()}>
                    {this.props.getLabelTranslate.typeFilterCommercialSpace}</label>
                </div>

                <div className="checkbox-filter-input-mobile-rd">
                  <input type="checkbox" readOnly id="5" name="filterType" value="5"
                    className="checkbox-control-mobile"
                    checked={this.state.isBureau} />
                  <label htmlFor="5" onClick={()=> this.bureauCheckBox()}>
                    {this.props.getLabelTranslate.typeFilterOfficeSpace}</label>
                </div>
              </div>
              <div className="modal-acheter__footer">
                <button className="btn modal-acheter__btn-close" onClick={()=> this.cancelTypeFilter()}>{this.props.getLabelTranslate.typeFilterCancelMobile}</button>
                <button className="btn modal-acheter__btn-apply" onClick={()=> this.submitTypeFilter()} >{this.props.getLabelTranslate.typeFilterApply}</button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

TypeFilterMobile.propTypes = {
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getFilter: PropTypes.object,
  toggleFilter: PropTypes.func,
  history: PropTypes.object,
  submitHandle: PropTypes.func,
  cancelHandle: PropTypes.func,
  filterValue: PropTypes.array,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {
      createLabelTranslate,
      toggleFilter,
    },
)(TypeFilterMobile);
