import httpClient from './httpClient';
import axios from 'axios';
import {getRecomendedResolution} from '../helpers/helper';

const baseProject = process.env.REACT_APP_PROJECT_VERSION || 'Project';

export const getIdProjectFromPrEpl = async (PrEpl, country) => {
  const apiUrl = process.env.REACT_APP_BASE_API_URL +'/'+ process.env.REACT_APP_BASE_PROJECT_VERSION+'/CptEplInfo';
  const data = {
    language: country,
    cptEPL: PrEpl,
  };
  const res = await axios.post(apiUrl, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(res);
};

export const searchProjects = async (params) => {
  const resolution = getRecomendedResolution();
  params.imageSize = resolution == 1600 ? 1200 : resolution; // QA request since most common size is 1200
  const result = await httpClient.post(
      `/${baseProject}/SearchProjects`,
      params,
  );
  return Promise.resolve(result);
};

export const getProjectDetails = async (params) =>{
  const url = process.env.REACT_APP_BASE_API_URL+'/'+
    process.env.REACT_APP_BASE_PROJECT_VERSION+
    '/GetProjectDetails';

  params.projectDetails.imageSizes = [1600, 1200, 800, 400];
  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const GetProjectDetailsMinisite = async (params) => {
  const url = process.env.BASE_URL_API +
    '/api/' +
    process.env.BASE_PROJECT_VERSION +
    '/GetProjectDetails';
  const data = JSON.stringify({
    'projectDetails': {
      'externalCode': {
        'enseCode': params.PrId,
        'cptEplCode': params.PrEpl,
      },
      'imageSizes': [getRecomendedResolution()],
    },
    'language': 'fr',
  });

  const result = await $.ajax({
    type: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.AZURE_API_KEY,
    },
    data: data,
  });
  return Promise.resolve(result);
};

export const GetProjectDetailsContact = async (params) => {
  const url = process.env.BASE_URL_API +
    '/api/' +
    process.env.BASE_PROJECT_VERSION +
    '/GetProjectDetails';

  const data = JSON.stringify({
    'projectDetails': {
      'externalCode': {
        'cptEplCode': params.PrEpl,
      },
      'imageSizes': [getRecomendedResolution()],
    },
    'language': 'fr',
  });

  const result = await $.ajax({
    type: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.AZURE_API_KEY,
    },
    data: data,
  });
  return Promise.resolve(result);
};


export const getInitialProjectSearchCriteria = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL+'/'+
    process.env.REACT_APP_BASE_PROJECT_VERSION+
    '/GetInitialProjectSearchCriteria';

  const data = JSON.stringify(params);

  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};


export const GetSimilarProjects = async (params) => {
  const url = process.env.BASE_URL_API +
    '/api/' +
    process.env.BASE_PROJECT_VERSION +
    '/GetSimilarProjects';

  const data = JSON.stringify(params);
  const result = await $.ajax({
    type: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.AZURE_API_KEY,
    },
    data: data,
  });
  return Promise.resolve(result);
};

export const getProjectProperties = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/' +
    process.env.REACT_APP_BASE_PROJECT_VERSION +
    '/GetProjectProperties';
  // 347881
  params.imageSizes = [1200, 800, 400];
  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};

export const countProjects = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/' +
    process.env.REACT_APP_BASE_PROJECT_VERSION +
    '/CountProjects';

  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};


export const getProjectMarkers = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
  '/' +
  process.env.REACT_APP_BASE_PROJECT_VERSION +
  '/GetProjectMarkers';

  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};


export const GetHouseContactPoints = async (params) => {
  const url = process.env.BASE_URL_API +
    '/api/ContactPoint/GetHouseContactPoints';
  const data = JSON.stringify(params);
  const result = await $.ajax({
    type: 'POST',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const GetAppartmentContactPoints = async (params) => {
  const url = process.env.BASE_URL_API +
    '/api/ContactPoint/GetAppartmentContactPoints';
  const data = JSON.stringify(params);
  const result = await $.ajax({
    type: 'POST',
    url: url,
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getShowHouseContactPoints = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/ContactPoint/GetShowHouseContactPoints';
  const data = JSON.stringify({
    'language': 'fr',
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getPromotionBanner = async () => {
  const result = await httpClient.get(
      `/Media/MarketingMedia`,
  );
  return Promise.resolve(result);
};

export const getContactPoints = async (params, endPoint) => {
  const url = process.env.BASE_URL_API +
    '/api/ContactPoint/' +
    endPoint;

  const data = JSON.stringify(params);
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getCalendarAvailabelSlot = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/ContactPoint/GetFirstAvailabelSlot';

  const data = JSON.stringify({
    'contactPointId': params.id,
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
    data: data,
  });
  return Promise.resolve(result);
};

export const getCalendarAvailabelSlotbyMonthYear = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
    '/ContactPoint/GetAvailableSlots';

  const data = JSON.stringify({
    'contactPointId': params.id,
    'year': params.year,
    'month': params.month,
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};


export const getCities = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
      '/ContactForm/GetCities';

  const data = JSON.stringify({
    'criteria': {
      name: params.name,
      filterId: params.id,
    },
    'paging': {
      'currentPage': 0,
      'pageSize': 99,
    },
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getZipCodes = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
      '/ContactForm/GetZipCodes';

  const data = JSON.stringify({
    'criteria': {
      name: params.name,
      filterId: params.id,
    },
    'paging': {
      'currentPage': 0,
      'pageSize': 1,
    },
  });
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getCitiesForZipCode = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
  '/ContactForm/GetCitiesForZipCode';

  const data = {
    'zipcode': params.id,
    'country': process.env.REACT_APP_COUNTRY_NAME,
  };
  const result = await axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });

  return Promise.resolve(result);
};

export const getPostalCodeDrupal = async (params) => {
  if (params.length < 3) {
    return Promise.resolve(false);
  }
  const url = process.env.REACT_APP_BASE_DRUPAL_URL +
      '/odoo-form/get-cities-by-name';

  const data = {
    'params': {
      'keyword': params,
    },
  };
  const result = await axios.get(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const getZipCodeByCity = async (params) => {
  const url = process.env.REACT_APP_BASE_DRUPAL_URL +
      '/odoo-form/get-zipcode-by-city';

  const data = {
    'params': {
      'city': params,
    },
  };
  const result = await axios.get(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/json',
      'Authorization': process.env.REACT_APP_AZURE_API_KEY,
    },
  });
  return Promise.resolve(result);
};

export const searchInvestProjects = async (params) => {
  const url = process.env.REACT_APP_BASE_API_URL +
      '/' +
      process.env.REACT_APP_BASE_PROJECT_VERSION +
      '/SearchInvestorProjects';

  const result = await httpClient.post(
      url,
      params,
  );
  return Promise.resolve(result);
};
