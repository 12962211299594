import React from 'react';
import PropTypes from 'prop-types';
import Hammer from 'hammerjs';

/** */
class ProjectImageSliderImage extends React.Component {
  /** @param {*} props */
  constructor(props) {
    super(props);
    this.hammerdiv = React.createRef();
  }

  /** */
  componentDidMount() {
    this.hammer = new Hammer.Manager(this.hammerdiv.current, {prevent_defaults: true, touchAction: 'pan-y'});
    const singleTapRec = new Hammer.Tap({event: 'singletap'});
    const doubleTapRec = new Hammer.Tap({event: 'doubletap', taps: 2});
    const pinchRec = new Hammer.Pinch({enable: true});
    const pressRec = new Hammer.Press({enable: true});
    const swipeRec = new Hammer.Swipe({enable: true, direction: Hammer.DIRECTION_ALL});
    this.hammer.add([doubleTapRec, singleTapRec, pinchRec, pressRec, swipeRec]);
    doubleTapRec.recognizeWith(singleTapRec);
    singleTapRec.requireFailure(doubleTapRec);
    this.hammer.on('doubletap', (e) => {
      this.hammerIt(e);
    });
    this.hammer.on('panstart', this.hammerIt.bind(this));
    this.hammer.on('panend', this.hammerIt.bind(this));
    this.hammer.on('pinchin', this.hammerIt.bind(this));
    this.hammer.on('pinchout', this.hammerIt.bind(this));
  }

  /** @param {*} elm  */
  hammerIt = (elm) => {
    let posX = 0;
    let posY = 0;
    let scale = 1;
    let lastScale = 1;
    let lastPosX = 0;
    let lastPostY = 0;
    let maxPosX = 0;
    let maxPosY = 0;
    let transform = '';
    const el = elm;

    if (ev.type == 'doubletap') {
      transform =
        'translate3d(0, 0, 0) ' +
        'scale3d(2, 2, 1) ';
      scale = 2;
      lastScale = 2;
      try {
        if (window.getComputedStyle(el, null).getPropertyValue('-webkit-transform').toString() != 'matrix(1, 0, 0, 1, 0, 0)') {
          transform =
            'translate3d(0, 0, 0) ' +
            'scale3d(1, 1, 1) ';
          scale = 1;
          lastScale = 1;
        }
      } catch (err) { }
      el.style.webkitTransform = transform;
      transform = '';
    }
    if (scale != 1) {
      posX = lastPosX + ev.deltaX;
      posY = lastPostY + ev.deltaY;
      maxPosX = Math.ceil((scale - 1) * el.clientWidth / 2);
      maxPosY = Math.ceil((scale - 1) * el.clientHeight / 2);
      if (posX > maxPosX) {
        posX = maxPosX;
      }
      if (posX < -maxPosX) {
        posX = -maxPosX;
      }
      if (posY > maxPosY) {
        posY = maxPosY;
      }
      if (posY < -maxPosY) {
        posY = -maxPosY;
      }
    }
    if (ev.type == 'pinch') {
      scale = Math.max(.999, Math.min(lastScale * (ev.scale), 4));
    }
    if (ev.type == 'pinchend') {
      lastScale = scale;
    }
    if (ev.type == 'panend') {
      lastPosX = posX < maxPosX ? posX : maxPosX;
      lastPostY = posY < maxPosY ? posY : maxPosY;
    }
    if (scale != 1) {
      transform =
        'translate3d(' + posX + 'px,' + posY + 'px, 0) ' +
        'scale3d(' + scale + ', ' + scale + ', 1)';
    }
    if (transform) {
      el.style.webkitTransform = transform;
    }
  }

  /** @return {JSX} */
  render() {
    return (
      <div className="image-wrapper img-contain" >
        <img src={this.props.imgSrc} alt="slider" ref={this.hammerdiv} />
      </div>
    );
  }
}

ProjectImageSliderImage.propTypes = {
  imgSrc: PropTypes.string,
};

export default ProjectImageSliderImage;
