import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelTranslate, createParamsInvestFiltering} from '../../../pages/action';
import {toggleFilter} from '../action';

/**
 * Class Type Filter.
*/
class TypeFilter extends Component {
  /**
   * @param {number} props The first number val.
   */
  constructor(props) {
    super(props);
    this.state = {
      isAppartementActive: true,
      isMaisonActive: true,
      typeActiveFiltersObject: {
        House: true,
        Appartment: true,
      },
      resetTimer: 1,
    };
  }

  /**
   * Load Componnet
   */
  async componentDidMount() {
    // This statement for translate label
    const label = this.props.getLabelTranslate;
    label.typeFilterApartment = 'Appartements';
    label.typeFilterHouse = 'Maisons';
    this.props.createLabelTranslate(label);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    if (state.resetTimer !== props.resetTimer) {
      state.isAppartementActive = true;
      state.isMaisonActive = true;
      state.typeActiveFiltersObject = {
        House: true,
        Appartment: true,
      };
      state.resetTimer = props.resetTimer;
    }
    return state;
  }

  /**
   *
   * @param {*} event
   */
  updateTypeFilter(event) {
    const key = event.target.value;

    const typeActiveFiltersObject = this.state.typeActiveFiltersObject;
    typeActiveFiltersObject[key] = !typeActiveFiltersObject[key];

    this.setState({
      typeActiveFiltersObject: typeActiveFiltersObject,
      isAppartementActive: key === 'Appartment' ? !this.state.isAppartementActive: this.state.isAppartementActive,
      isMaisonActive: key === 'House' ? !this.state.isMaisonActive: this.state.isMaisonActive,
    });

    const generalizedTypeOfProperty = [];
    Object.entries(typeActiveFiltersObject).map(([key, value]) => {
      if (value) {
        generalizedTypeOfProperty.push(key);
      }
    });

    let params = this.props.getParamsInvestFiltering;
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        generalizedTypeOfProperty: generalizedTypeOfProperty,
      },
    };

    this.props.createParamsInvestFiltering(params);
  }

  /**
   * Add two numbers.
   * @param {number} value text output val.
   * @return {number} The sum of the two numbers.
   */
  render() {
    return (
      <div className="investir-filter-container">
        <div className="investir-filter-box-container">
          <div className="investir-filter-body-container-react horizontal-checkboxes">
            <div className="checkbox-filter-input">
              <input type="checkbox"
                id="filtertype1"
                name="filterType"
                value="Appartment"
                className="checkbox-control"
                checked={this.state.isAppartementActive}
                onChange={(e) => this.updateTypeFilter(e)}
              />
              <label htmlFor="filtertype1">
                {this.props.getLabelTranslate.typeFilterApartment}</label>
            </div>

            <div className="checkbox-filter-input">
              <input type="checkbox"
                id="filtertype2"
                name="filterType"
                value="House"
                className="checkbox-control"
                checked={this.state.isMaisonActive}
                onChange={(e) => this.updateTypeFilter(e)}
              />
              <label htmlFor="filtertype2">
                {this.props.getLabelTranslate.typeFilterHouse}</label>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

TypeFilter.propTypes = {
  createLabelTranslate: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  history: PropTypes.object,
  getParamsInvestFiltering: PropTypes.object,
  createParamsInvestFiltering: PropTypes.func,
  resetTimer: PropTypes.number,
};

const mapsStateToProps = (state) => {
  return state;
};

export default connect(mapsStateToProps,
    {
      createLabelTranslate,
      toggleFilter,
      createParamsInvestFiltering,
    },
)(TypeFilter);
