import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import {connect} from 'react-redux';
import {createLabelTranslate} from '../../pages/action';
import {createCoordinateMaps} from './action';
const googleApiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`;
import {collectImageWithAltDynamic} from '../../components/HeaderBanner/setImage';
import {iconURL, createTitleFromProject} from '../../helpers/helper';


const createMapOptions = (maps) => {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_BOTTOM,
      style: maps.ZoomControlStyle.SMALL,
    },
    fullscreenControl: false,
  };
};


const ProjectSituation = (props) => {
  const defaultCenter = {
    showMap: true,
    center: {
      lat: 59.95,
      lng: 30.33,
    },
  };

  const [center] = useState(defaultCenter);
  useEffect(()=>{
    const coordinate = {};
    coordinate.lat = center.lat;
    coordinate.lng = center.lng;
    props.createCoordinateMaps(coordinate);
    const label = props.getLabelTranslate;
    label.detailMapsTitle = 'Situation';
    props.createLabelTranslate(label);
  }, [center]);


  return (
    <div className="project-detail-situation">
      <div className="project-detail-situation__container">
        <div className="project-detail-situation__header">
          <h2 className="project-detail-situation__title">
            {props.getLabelTranslate.detailMapsTitle}
          </h2>
        </div>

        <div className="project-detail-situation__map-wrapper">
          <div
            className="project-detail-situation__map"
            id="projectDetailSituation"
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                key: googleApiKey,
              }}
              center={props.loc}
              zoom={15}
              defaultCenter={defaultCenter.center}
              options={createMapOptions}
            >
              <ProjectDetailSituasionPin
                project={props.project}
                lat={props.loc.lat}
                lng={props.loc.lng}
              />
            </GoogleMapReact>
          </div>
        </div>
        <div className="project-detail-situation__cta-wrap">
          <a
            href={`http://www.google.com/maps/place/${props.loc.lat},${props.loc.lng}`}
            className="project-detail-situation__cta"
          >
            Voir dans Google Maps
          </a>
        </div>
      </div>
    </div>
  );
};

const ProjectDetailSituasionPin = ({project}) => {
  const imageLink = collectImageWithAltDynamic(project.imageLinks, 400, createTitleFromProject(project));
  let imgSource = '';
  let imgAlt = '';
  imageLink.map((link)=>{
    if (link.isRef || (link.url.includes('REF') && imgSource === '')) {
      imgSource = link.url;
      imgAlt = link.alt;
    }
  });

  if (imgSource === '' && project.imageLinks.length > 0) {
    imgSource = project.imageLinks[0].imageLink;
    imgAlt = project.imageLinks[0].alt;
  }

  return (
    <div className="project-detail-situation-pin__container">
      <div className="project-detail-situation-pin__thumbnail">
        <img
          src={imgSource}
          alt={imgAlt}/>
        <div className='project-detail-situation-pin__title'>{project.projectName}</div>
        <p>{project.address}</p>
      </div>
      <img src={iconURL('icon-pin-map.svg')} className="project-detail-situation-pin__icon" alt="location"/>
    </div>
  );
};

ProjectDetailSituasionPin.propTypes = {
  project: PropTypes.object,
  imageLinks: PropTypes.array,
  projectName: PropTypes.string,
  projectCoordinate: PropTypes.string,
  address: PropTypes.string,
};

ProjectSituation.propTypes = {
  coordinate: PropTypes.any,
  createLabelTranslate: PropTypes.func,
  createCoordinateMaps: PropTypes.func,
  getLabelTranslate: PropTypes.object,
  getCoordinateMaps: PropTypes.object,
  loc: PropTypes.any,
  project: PropTypes.object,
};

const stateToProps= (state)=>{
  return state;
};

export default connect(stateToProps,
    {createLabelTranslate, createCoordinateMaps},
)(ProjectSituation);
